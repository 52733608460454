import { AfterViewChecked, Component, ElementRef, EventEmitter, Inject, Output, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Subscription } from 'rxjs'
// import videojs from 'video.js'
import { DashcamService } from '../../services/dashcam.service'

const commonMin = new Date(new Date().setFullYear((new Date().getFullYear() - 10)));
const commonMax = new Date(new Date().setDate((new Date().getDate())));

@Component({
  selector: 'app-dashcam-date-range-modal',
  templateUrl: './dashcam-date-range-modal.component.html',
  styleUrls: ['./dashcam-date-range-modal.component.css']
})
export class DashcamDateRangeModalComponent implements AfterViewChecked {

  private suscription: Subscription = new Subscription;
  @Output() runEntities = new EventEmitter<any>();

  @ViewChild('videoPlayer', { static: true }) videoElement!: ElementRef;
  player;

  state = 'inputData'
  events = [];
  videoUrls = {};
  selectedVideo = null;
  getLinksSpinner = {}

  rangeFrom: any = {
    min: commonMin,
    max: commonMax,
  };

  rangeTo: any = {
    min: commonMin,
    max: commonMax,
  };

  datePicker: any = {
    from: null,
    to: null
  };

  loadingEvents = false

  selectedPath = null

  memoVideos = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DashcamDateRangeModalComponent>,
    public dialog: MatDialog,
    private dashcamService: DashcamService,
  ) {}

  ngAfterViewChecked() {
  }

  ngOnDestroy() {
    this.suscription.unsubscribe();
  }

  close(): void {
    this.dialogRef.close(false);
  }

  getRangeVideos() {
    const device = this.data.camDevice
    const payload = {
      startDateTime: this.datePicker.from.unix(),
      endDateTime: this.datePicker.to.unix(),
    }

    this.loadingEvents = true

    this.dashcamService.getDashCode(device, payload).subscribe((resp: any) => {
      if (resp && resp.status) {
        this.events = resp.data
      }

      this.loadingEvents = false
    })
  }

  onNoClick(): void  {
    this.dialogRef.close(false);
  }

  downloadSignedUrl(filePath: string): void {
    this.getLinksSpinner = {...this.getLinksSpinner, [filePath]: true}

    this.dashcamService.getSignedUrl(this.data.camDevice.device, filePath).subscribe((resp: any) => {
      resp.data.forEach(element => {
        this.videoUrls = {...this.videoUrls, [filePath]: [...Object.values(element)]}
      });

      this.getLinksSpinner[filePath] = false
      this.selectedPath = filePath;
      this.selectedVideo = this.videoUrls[filePath][0]
    })
  }

  selection(url: string) {
    this.selectedVideo = url;
  }

  checkUrl(url: string) {
    console.log(url)
    return url.includes('.mp4')
  }

  back() {
    this.selectedVideo = null;
    this.selectedPath = null;
  }

  getVideos(selectedPath: string) {
    if (this.memoVideos) {
      return this.memoVideos
    }

    return this.memoVideos = this.videoUrls[selectedPath].filter(url => url.includes('.mp4'))
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
const env: any = environment;

const createGmapsScript = () => {
  const scriptEl = window.document.createElement('script');
  scriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${env.GOOGLE_MAPS_TOKEN || ''}`;
  window.document.body.appendChild(scriptEl);
};

createGmapsScript();

if (env.production) {
  enableProdMode();
}

if ('serviceWorker' in navigator && env.production) {
  navigator.serviceWorker.register('/ngsw-worker.js');
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'chatbox', loadChildren: () => import('./modules/chat-box/chat-box.module').then(m => m.ChatBoxModule)},
  { path: 'home', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)},
  { path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
  { path: 'reset-password', loadChildren: () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
  { path: 'home/drivers', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'home/drivers/:id', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'home/vehicles', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'home/vehicles/:id', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'home/trips/:id', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'home/ranking', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'home/notifications', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'dashboard-email', loadChildren: () => import('./modules/dashboard-email/dashboard-email.module').then(m => m.DashboardEmailModule) },
  { path: 'magicLink/:token', loadChildren: () => import('./modules/magic-link/magic-link.module').then(m => m.MagicLinkModule) },
  { path: 'welcoming', loadChildren: () => import('./modules/welcoming/welcoming.module').then(m => m.WelcomingModule) },
  { path: 'onboarding', loadChildren: () => import('./modules/onboarding/onboarding.module').then(m => m.OnboardingModule) },
  { path: 'home/geofencing', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'home/geofencinglist', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

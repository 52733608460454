import { Component, OnInit } from '@angular/core';
import { appStores } from '../../helpers/helpers';

@Component({
  selector: 'app-mobile-view',
  templateUrl: './mobile-view.component.html',
  styleUrls: ['./mobile-view.component.css']
})
export class MobileViewComponent implements OnInit {

  stores: any = appStores;

  constructor() {

  }

  ngOnInit() {
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexStroke,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexYAxis,
  ChartComponent
} from 'ng-apexcharts';
import { checkForQparam } from '../../../helpers/helpers';
import { TranslationPipe } from '../../../pipes/translation/translation.pipe';
import { DashboardService } from '../../../services/dashboard.service';
import { GeneralService } from '../../../services/general.service';
import { RequestService } from '../../../services/request.service';

const getChartValues = async (time, rs) => {
  const data = await rs.request({
    method: 'POST',
    endpoint: 'dashboard_charts',
    body: { time: { gte: time.from, lte: time.to } }
  });
  if (data.error || !data.data) { return }
  return data.data;
};

export interface ChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  labels: string[];
  legend: ApexLegend;
  subtitle: ApexTitleSubtitle;
  colors: any;
}

@Component({
  selector: 'app-dashboard-template',
  templateUrl: './dashboard-template.component.html',
  styleUrls: ['./dashboard-template.component.css'],
  providers: [ TranslationPipe ]
})
export class DashboardTemplateComponent implements OnInit {

  @ViewChild('chart', {static: false}) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  dashboard: any;
  charts: any = [];
  totalComparison: any;
  assetsBase = 'assets/img/dashboard';
  breakChartBlock: boolean;
  wiData: any;
  hideLink: boolean;

  constructor(
    public ds: DashboardService,
    private rs: RequestService,
    private tp: TranslationPipe,
    private gs: GeneralService,
    public router: Router
  ) { }

  ngOnInit() {
    this.ds.getDashboard.subscribe(async dashboard => {
      this.dashboard = dashboard;
      if (!this.dashboard) { return; }
      // La siguiente línea se comenta porque actualmente no se muestra la gráfica debido a que no se obtiene los datos detalladamente como se requieren,
      // se deja por el posible uso en un futuro.
      // this.initChart(this.dashboard.filter);
    });

    this.gs.wiData.subscribe(data => this.wiData = data);
  }

  goTo(path) {
    return this.router.navigate([path]);
  }

  showOnView() {
    const currentView = window.location.pathname;
    const views = {
      '/dashboard-email': _ => {
        const email = checkForQparam('email');
        this.breakChartBlock = true;
        return !email || email === 'false';
      }
    };
    return views[ currentView ] ? views[ currentView ]() : false;
  }

  async initChart(filter) {
    const values = await getChartValues(filter, this.rs);
    const chartsConfig = this.ds.chartsConfig();
    this.charts = values.map(_ => {
      if (!_.data.length) { return null; }
      const series = _.data.sort((a, b) => parseInt(a.x) - parseInt(b.x)).map(d => String(d.y));
      const labels = _.data.sort((a, b) => parseInt(a.x) - parseInt(b.x)).map(d => String(d.x));

      const formatLabels = {
        hourOfDay: labels.map(l => moment(this.ds.chartTypesStrategies()[_.name][l], 'HH:mm').format('hh:mm a') ),
        dayOfWeek: labels.map(l => this.tp.transform(`WEEKDAYS.${this.ds.chartTypesStrategies()[_.name][l]}`))
      };

      const colors = {
        dayOfWeek: '#fecc5d',
        hourOfDay: '#38b6d1'
      };

      const chart = Object.assign({}, {
        colors: [colors[_.name]]
      }, chartsConfig, {
        series: [{
          name: this.tp.transform('TIMES'),
          type: 'area',
          data: series
        }]
      }, {
        labels: formatLabels[_.name]
      });

      return chart;
    }).filter(Boolean);

    const chartsContainer = document.querySelector('.charts');
    if (chartsContainer.clientWidth < 378 ) {
      this.breakChartBlock = true;
    } else {
      this.breakChartBlock = false;
    }
  }

  async showTotalComparison(total) {
    this.totalComparison = total;
    await this.gs.timeOut(100);
    const comparison: any = document.querySelector('.comparison-tooltip');
    const box: any = document.querySelector(`.${total.id} .value`);
    const top = box.offsetTop + box.clientHeight;
    const left = box.offsetLeft;
    comparison.style.left = `${left}px`;
    comparison.style.top = `${top}px`;
  }

  setDecimals(value, precision = 0) {
    value = +(value || 0);
    const ml = Math.pow(10, precision);
    return Math.round( value * ml ) / ml;
  }

  getTotalStyles(status, type) {
    const commonName = `${type}_${status}`;
    const exclusiveTypes = {
      security: commonName,
      efficiency: commonName
    };
    const img = `${this.assetsBase}/${exclusiveTypes[type] || type}.svg`;
    const colors = {
      ok: '#72cc4a',
      normal: '#faca59',
      error: '#ef6b46'
    };
    return { img, color: colors[status] || '#63a4ff' };
  }

  getTendencyStyles(prevPeriod, percentDifference) {
    const status = !!prevPeriod.value ? prevPeriod.comparisons.find(c => c.type === 'percentDifference').status : null;
    const colors = { ok: 'green', error: 'red', normal: 'yellow' };
    if (percentDifference === 0) {
      return `${this.assetsBase}/flat-${colors[status] || 'gray'}.svg`;
    }
    if (percentDifference > 0.0) {
      return `${this.assetsBase}/up-${colors[status] || 'gray'}.svg`;
    }
    if (percentDifference < 0.0) {
      return `${this.assetsBase}/down-${colors[status] || 'gray'}.svg`;
    }
  }

  roundUp(value) {
    return Math.round(value);
  }

  showComparison(percentDifference) {
    return !isNaN(parseInt(percentDifference));
  }
}

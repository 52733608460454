<div *ngIf="detail">
    <div class="container">
        <div style="color: #595959; padding: 20px;">
            <div style="display: flex; align-items: center; justify-content: center;">
                <img src="{{detail.data?.bigLogo}}" alt="" width="170px" />
            </div>
            <div style="display: flex; align-items: center; justify-content: center;">
                <img src="/assets/icons/dashcam.png" alt="" style="margin-right: 4px;" />
                <span style="font-size: 14px; color: #63A4FF; font-weight: 600">Dashcam</span>
            </div>
            <markdown ngPreserveWhitespaces>
                {{detail.data.description}}
            </markdown>
            <p style="font-size: 14px; font-weight: 600; color: #595959">{{ 'IMAGES_APP' | translation }}</p>
            <div style="display: flex; margin-bottom: 10px;">
                <img *ngFor="let img of detail.data.images" src="{{img}}" width="110px" alt=""
                    style="margin-right: 20px;" />
            </div>
        </div>
        <div class="settings">
            <button mat-button class="btn_action saveBtn" (click)="selectDrivers()">{{'SELECT_YOUR_DRIVERS' |
                translation}}</button>
        </div>
    </div>
</div>
<div class="container">
  <div class="odometer-edit" *ngIf="odometerEdit">
    <div class="odometer-title">
      {{ 'MAINTENANCE.EDITODOMETER' | translation }}
    </div>
    <div class="odometer-section">
      <div class="icon">
        <img src="assets/img/maintenance/odometer-icon.svg" alt="odometer-icon">
      </div>
      <div class="maintenance-form">
        <div>
          <span *ngIf="data.odometer" class="input-name">
            {{ 'MAINTENANCE.ODOMETER' | translation }}
          </span>
          <input name="odometer" class="inputs-text" type="number"
            placeholder="{{ 'MAINTENANCE.ODOMETER' | translation }}" [(ngModel)]="data.odometer">
        </div>
      </div>
    </div>
    <div class="actions">
      <button mat-raised-button class="outlined-button" (click)="dialogRef.close()">
        {{ 'CANCEL' | translation }}
      </button>
      <button mat-raised-button class="outlined-button" (click)="validateOdometerUpdate()">
        {{ 'SAVE' | translation }}
      </button>
    </div>
  </div>
  <div class="maintenance-edit" *ngIf="!odometerEdit">
    <div class="maintenance-title">
      <span *ngIf="data.maintenance && data.maintenance.status">{{ 'MAINTENANCE.SAVE' | translation }}</span>
      <span *ngIf="!data.maintenance || !data.maintenance.status">{{ 'MAINTENANCE.CREATE' | translation }}</span>
    </div>
    <div class="maintenance-form">
      <div class="select-input">
        <span *ngIf="data.maintenance.type" class="input-name">{{ 'MAINTENANCE.MAINTENANCE_TYPE' | translation }}</span>
        <select [disabled]="disableTypeEdit" name="type" class="select-style" type="text" [(ngModel)]="data.maintenance.maintenanceId">
          <option [value]="undefined">{{ 'MAINTENANCE.MAINTENANCE_TYPE' | translation }}</option>
          <option [value]="maintenanceType.id" *ngFor="let maintenanceType of data.maintenanceTypes">
            {{ maintenanceType.name }}
          </option>
        </select>
      </div>
      <div>
        <span *ngIf="data.maintenance.start" class="input-name">
          {{ 'MAINTENANCE.CURRENT_ODOMETER' | translation }}
        </span>
        <input name="odometer" class="inputs-text" type="number" placeholder="{{ 'MAINTENANCE.CURRENT_ODOMETER' | translation }}"
          [(ngModel)]="data.maintenance.start">
      </div>
      <div>
        <span *ngIf="data.maintenance.end" class="input-name">
          {{ 'MAINTENANCE.THRESHOLD' | translation }}
        </span>
        <input name="odometer" class="inputs-text" type="number" placeholder="{{ 'MAINTENANCE.THRESHOLD' | translation }}"
          [(ngModel)]="data.maintenance.end">
      </div>
    </div>
    <div class="maintenance-disclaimer">
      * {{ 'MAINTENANCE.NOTI_THRESHOLD' | translation }}
    </div>
    <div class="actions">
      <button mat-raised-button class="outlined-button" (click)="dialogRef.close()">
        {{ 'CANCEL' | translation }}
      </button>
      <button mat-raised-button class="outlined-button" (click)="maintenanceAction()">
        <span *ngIf="data.maintenance && data.maintenance.status">{{ 'SAVE' | translation }}</span>
        <span *ngIf="!data.maintenance.status">{{ 'CREATE' | translation }}</span>
      </button>
    </div>
  </div>
</div>

<div class="reports-container" *ngIf="currentView === 'report_prepare'">
    <div class="reports-header">
        <h3>{{ 'PERFORMANCE.REPORTS_TITLE' | translation }}</h3>
        <p>{{ 'PERFORMANCE.REPORTS_SUBTITLE' | translation }}</p>
    </div>
    <div class="date-ranges">
        <div class="subtitle"> {{ 'RANGE_FILTER' | translation }} </div>
        <div class="range">
            <span>{{ 'FROM' | translation }}: </span>
            <div class="date-picker from" (click)="openPicker('from')">
                <div>{{ filter.from }}</div>
                <mat-icon>date_range</mat-icon>
            </div>
        </div>
        <div class="range">
            <span>{{ 'TO' | translation }}: </span>
            <div class="date-picker" (click)="openPicker('to')">
                <div>{{ filter.to }}</div>
                <mat-icon>date_range</mat-icon>
            </div>
        </div>
    </div>
    <!-- <div class="report-type">
        <div class="subtitle"> {{ 'TYPE' | translation }} </div>
        <select name="reportType" [(ngModel)]="reportType">
            <option value="undefined">{{ 'PERFORMANCE.SELECT_REPORT_TYPE' | translation }}</option>
            <option *ngFor="let type of reportTypes" [value]="type.type">{{ type.name | translation }}</option>
        </select>
    </div> -->

    <!-- <div class="date-ranges">
      <div class="subtitle">EMAIL</div>
      <div class="range">
        <span>{{ 'TO' | translation }}: </span>
        <p>test@gmail.com</p>
      </div>
      <div class="range">
        <span>cc: </span>
        <input type="email" matInput [(ngModel)]="cc" class="cc-input" />
      </div>
    </div> -->
    <div class="actions">
        <button mat-raised-button class="outlined-button cancel-button" (click)="dialogRef.close()">
            {{ 'CANCEL' | translation }}
        </button>
        <button mat-raised-button class="outlined-button export-button" (click)="exportReport()">
            {{ 'PERFORMANCE.EXPORT' | translation }}
        </button>
    </div>
</div>

<div class="report-requested" *ngIf="currentView === 'report_requested'">
    <h4 class="subtitle">{{ 'PERFORMANCE.GENERATING_REPORT' | translation }}</h4>
    <img src="assets/img/fleetr-wink.svg" alt="fleetr logo">
    <p>{{ 'PERFORMANCE.REPORT_ONTHEWAY' | translation }}</p>
    <button mat-raised-button class="outlined-button" (click)="dialogRef.close()">
        {{ 'PERFORMANCE.UNDERSTOOD' | translation }}
    </button>
</div>

<!-- Date ppickers -->
<div class="pickers-conatiner">
    <mat-form-field class="from" style="display: none;">
        <input readonly matInput [matDatepicker]="pickerFrom" [(ngModel)]="ranges.start" [max]="maxDate" (ngModelChange)="formatDates(ranges)">
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker touchUi #pickerFrom></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="to"  style="display: none;">
        <input readonly matInput [matDatepicker]="pickerTo" [(ngModel)]="ranges.end"
            [max]="maxDate" (ngModelChange)="formatDates(ranges)"
            [min]="minDate">
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker touchUi #pickerTo></mat-datepicker>
    </mat-form-field>
</div>

<button class="closeModalButton" (click)="dialogRef.close()"> x </button>

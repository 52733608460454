import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ExtensionsService } from '../../../services/extensions.service';
import { GeneralModalComponent } from '../../general-modal/general-modal.component';

@Component({
  selector: 'app-extensions-activate',
  templateUrl: './extensions-activate.component.html',
  styleUrls: ['./extensions-activate.component.css']
})
export class ExtensionsActivateComponent implements OnInit {

  @Input('data') data: any;
  @Input('dialogRef') dialogRef: any;
  @Output() runEntities = new EventEmitter<any>();
  errorMessage: any;

  constructor(
    public dialog: MatDialog,
    public es: ExtensionsService
  ) {}

  ngOnInit(): void {
    //This is intentional
  }

  async closeModal() {
    this.dialogRef.close();
  }

  sendDrivers() {
    let body: any;
    body = {integration: this.data?.integration, drivers: this.data?.drivers};
    this.es.activateExtension(body).subscribe(
      (_res) => {
        this.openGeneralModal(this.data);
        this.dialogRef.close();
      },
      (_error) => {
        this.errorMessage = _error.error.error.srcMessage;
      }
    );
  }

  openGeneralModal(data: any) {
    this.dialog.open(GeneralModalComponent, {
      panelClass: "general-modal",
      width: "45vw",
      data: { data, type: 'extensions_activate_success' }
    });
  }

}

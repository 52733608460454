import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-general-modal',
  templateUrl: './general-modal.component.html',
  styleUrls: ['./general-modal.component.css']
})
export class GeneralModalComponent implements OnInit, OnDestroy {

  showPerformance: boolean;
  constructor(
    public dialogRef: MatDialogRef<GeneralModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit() {
    const showPerformanceOn = [ 'stats', 'score', 'reports' ];
    this.showPerformance = showPerformanceOn.some(_ => this.data.type === _);
  }

  ngOnDestroy() {
    this.data = undefined;
  }

  confirm(confirm): void {
    this.dialogRef.close({ confirm });
  }
}

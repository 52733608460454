import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SideToolBarComponent } from './components/side-tool-bar/side-tool-bar.component';
import { SideInfoWindowComponent } from './components/side-info-window/side-info-window.component';
import { FinderComponent } from './components/finder/finder.component';
import { FinderListsComponent } from './components/finder-lists/finder-lists.component';
import { DriverTemplateComponent } from './components/finder-lists/driver-template/driver-template.component';
import { VehicleTemplateComponent } from './components/finder-lists/vehicle-template/vehicle-template.component';
import { GeofenceTemplateComponent } from './components/finder-lists/geofence-template/geofence-template.component';
import { NotificationTemplateComponent } from './components/notifications/notification-template/notification-template.component';

import { CommonModule, registerLocaleData } from '@angular/common';
import localeDeAt from '@angular/common/locales/es-CL';
registerLocaleData(localeDeAt);

import { TimeagoModule } from 'ngx-timeago';
import { AdminTemplateComponent } from './components/finder-lists/admin-template/admin-template.component';
import { DeviceTemplateComponent } from './components/finder-lists/device-template/device-template.component';
import { TranslationModule } from '../app/pipes/translation/translation.module';
import { HttpClientModule } from '@angular/common/http';
import { TripsListComponent } from './components/trips-list/trips-list.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxStarsModule } from 'ngx-stars';
import { JwtModule } from '@auth0/angular-jwt';
import { RankingListComponent } from './components/ranking/ranking-template/ranking-template.component';
import { RankingTemplateComponent } from './components/ranking/ranking.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment as env } from '../environments/environment';
import { ProfileComponent } from './components/profile/profile.component';
import { GeneralModalComponent } from './components/general-modal/general-modal.component';
import { generalFiltersPipe } from './pipes/generalFiltersPipe.module';
import { StatsTemplateComponent } from './components/stats/stats-template/stats-template.component';
import { StatsListComponent } from './components/stats/stats.component';
import { MobileViewComponent } from './components/mobile-view/mobile-view.component';
import { VehicleHealthComponent } from './components/vehicle-health/vehicle-health.component';
import { NotificationSettingsComponent } from './components/notifications/notification-settings/notification-settings.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { VehicleSortingComponent } from './components/vehicle-sorting/vehicle-sorting.component';
import { HealthListComponent } from './components/health-list/health-list.component';
import { HealthDetailComponent } from './components/health-list/health-detail/health-detail.component';
import { VehicleMaintenanceComponent } from './components/vehicle-maintenance/vehicle-maintenance.component';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { CreatingEntityComponent } from './components/creating-entity/creating-entity.component';
import { FormsComponent } from './components/creating-entity/forms/forms.component';
import { DriverAdviceComponent } from './components/driver-advice/driver-advice.component';
import { KpisComponent } from './components/performance/kpis/kpis.component';
import { FiltersComponent } from './components/performance/filters/filters.component';
import { PerformanceComponent } from './components/performance/performance.component';
import { ScoreComponent } from './components/performance/score/score.component';
import { AlertSnackBarComponent } from './components/alert-snack-bar/alert-snack-bar.component';
import { ScoreInfoComponent } from './components/performance/score-info/score-info.component';
import { FeedbackComponent, FeedbackAlertModalComponent } from './components/feedback/feedback.component';
import { ChangePasswordModule } from './components/profile/change-password/change-password.module';
import { SharedDashboardModule } from './components/dashboard/shared-dashboard.module';
import { FleetConfigurationComponent } from './components/profile/fleet-configuration/fleet-configuration.component';
import { EditDriverComponent } from './components/finder-lists/driver-template/edit-driver/edit-driver.component';
import { GeofencingTemplateComponent } from './components/finder-lists/geofence-template/geofencingvehicles-template/geofencingvehicles-template.component';
import { DeleteGeofenceComponent } from './components/finder-lists/geofence-template/delete-geofence/delete-geofence.component';
import { GeofencingCreateComponent } from './components/finder-lists/geofence-template/geofencing-create/geofencing-create.component';
import { GeofenceSortingComponent } from './components/geofence-sorting/geofence-sorting.component';

import { WeekFilterComponent } from './components/ranking/week-filter/week-filter.component';
import { EditVehicleComponent } from './components/finder-lists/vehicle-template/edit-vehicle/edit-vehicle.component';
import { AdminConfigurationComponent } from './components/profile/admin-configuration/admin-configuration.component';
import { DeleteAdminComponent } from './components/profile/admin-configuration/delete-admin/delete-admin.component';
import { ReportsModalComponent } from './components/reports-modal/reports-modal.component';

import { RollbarService, rollbarFactory, RollbarErrorHandler } from './rollbar';
import { EditMaintenanceComponent } from './components/vehicle-maintenance/edit-maintenance/edit-maintenance.component';
import { DeleteMaintenanceComponent } from './components/vehicle-maintenance/delete-maintenance/delete-maintenance.component';
import { ValidateOdometerUpdateComponent } from './components/vehicle-maintenance/validate-odometer-update/validate-odometer-update.component';
import { ValidateHigherOdometerUpdateComponent } from './components/vehicle-maintenance/validate-higher-odometer-update/validate-higher-odometer-update.component';
import { MarkdownModule } from 'ngx-markdown';
import { VehicleTagsSectionComponent } from './components/finder-lists/vehicle-template/vehicle-tags-section/vehicle-tags-section.component';
import { VehicleAddTagsComponent } from './components/finder-lists/vehicle-template/vehicle-add-tags/vehicle-add-tags.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TagComponent } from './components/tag/tag.component';
import { TagDeleteAlertComponent } from './components/tag/tag-delete-alert/tag-delete-alert.component';
import { ModifyPasswordComponent } from './components/profile/modify-password/modify-password.component';
import { ChangePasswordResponseComponent } from './components/profile/modify-password/change-password-response/change-password-response.component';
import { DetailDtcsComponent } from './components/detail-dtcs/detail-dtcs.component';
import { ExtensionsListComponent } from './components/extensions-list/extensions-list.component';
import { ExtensionDetailComponent } from './components/extensions-list/extension-detail/extension-detail.component';
import { DriversListExtensionsComponent } from './components/extensions-list/drivers-list-extensions/drivers-list-extensions.component';
import { ExtensionsActivateSuccessComponent } from './components/extensions-list/extensions-activate-success/extensions-activate-success.component';
import { ExtensionsActivateComponent } from './components/extensions-list/extensions-activate/extensions-activate.component';
import { TripEventsComponent } from './components/trips-list/trip-events/trip-events/trip-events.component';
import { DashcamDateRangeModalComponent } from './components/dashcam-date-range-modal/dashcam-date-range-modal.component';

export function tokenGetter() {
  return localStorage.getItem('id');
}

let providers = [
  { provide: MAT_DATE_LOCALE, useValue: 'es-CL' },
  {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  },
  { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
];

if (env.production) {
  const errorHandler: any = { provide: ErrorHandler, useClass: RollbarErrorHandler };
  const rollbarService: any = { provide: RollbarService, useFactory: rollbarFactory };
  const rollbarProviders = [errorHandler, rollbarService];
  providers = [...providers, ...rollbarProviders];
}

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    SideToolBarComponent,
    ProfileComponent,
    SideInfoWindowComponent,
    FinderComponent,
    FinderListsComponent,
    NotificationsComponent,
    DriverTemplateComponent,
    VehicleTemplateComponent,
    GeofenceTemplateComponent, NotificationTemplateComponent,
    AdminTemplateComponent, DeviceTemplateComponent, TripsListComponent,
    RankingListComponent, RankingTemplateComponent,
    StatsTemplateComponent, StatsListComponent,
    ProfileComponent, GeneralModalComponent, MobileViewComponent,
    VehicleHealthComponent,
    NotificationSettingsComponent,
    NotificationsComponent,
    VehicleSortingComponent,
    HealthListComponent,
    HealthDetailComponent,
    VehicleMaintenanceComponent,
    CreatingEntityComponent,
    FormsComponent,
    DriverAdviceComponent,
    KpisComponent,
    FiltersComponent,
    PerformanceComponent,
    ScoreComponent,
    AlertSnackBarComponent,
    ScoreInfoComponent,
    FeedbackComponent,
    FeedbackAlertModalComponent,
    FleetConfigurationComponent,
    EditDriverComponent,
    EditVehicleComponent,
    GeofencingTemplateComponent,
    DeleteGeofenceComponent,
    GeofencingCreateComponent,
    GeofenceSortingComponent,
    WeekFilterComponent,
    AdminConfigurationComponent,
    DeleteAdminComponent,
    ReportsModalComponent,
    EditMaintenanceComponent,
    DeleteMaintenanceComponent,
    ValidateOdometerUpdateComponent,
    ValidateHigherOdometerUpdateComponent,
    VehicleTagsSectionComponent,
    VehicleAddTagsComponent,
    TagComponent,
    TagDeleteAlertComponent,
    ModifyPasswordComponent,
    ChangePasswordResponseComponent,
    DetailDtcsComponent,
    ExtensionsListComponent,
    ExtensionDetailComponent,
    DriversListExtensionsComponent,
    ExtensionsActivateComponent,
    ExtensionsActivateSuccessComponent,
    TripEventsComponent,
    DashcamDateRangeModalComponent,
  ],
  entryComponents: [
    ProfileComponent,
    GeneralModalComponent,
    ReportsModalComponent,
    FormsComponent,
    AlertSnackBarComponent,
    FeedbackAlertModalComponent,
    WeekFilterComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    SharedDashboardModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxStarsModule,
    TimeagoModule.forRoot(),
    TranslationModule,
    generalFiltersPipe,
    NgCircleProgressModule.forRoot({
      radius: 30,
      space: -5,
      outerStrokeWidth: 5,
      innerStrokeColor: '#e7e8ea',
      innerStrokeWidth: 5,
      animationDuration: 300,
      showSubtitle: false,
      unitsFontSize: '18',
      titleFontSize: '18'
    }),
    // Material modules
    MatToolbarModule, MatIconModule, MatButtonModule, MatSidenavModule, MatMenuModule, MatRadioModule,
    MatSelectModule, MatListModule, MatExpansionModule, MatProgressSpinnerModule, MatSlideToggleModule,
    MatSnackBarModule, MatDialogModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
    MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatTooltipModule,
    ChangePasswordModule, MatGridListModule, MatCardModule,
    MarkdownModule.forRoot(),
  ],
  providers,
  bootstrap: [AppComponent]
})
export class AppModule { }

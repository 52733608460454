import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePasswordComponent } from './change-password.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslationModule } from '../../../pipes/translation/translation.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ ChangePasswordComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    TranslationModule,
    FormsModule, ReactiveFormsModule,
    MatInputModule, MatButtonModule
  ],
  exports: [ ChangePasswordComponent ]
})
export class ChangePasswordModule { }

<div class="vehicle-filter">
  <img src="assets/img/icons/buscar.svg" alt="buscar">
  <input type="text" [(ngModel)]="findText" placeholder="{{ 'GEOFENCE.VEHICLE_FILTER' | translation }}">
  <img *ngIf="findText?.length" class="searchCross" src="assets/img/clearb.png" (click)="findText = ''" 
    alt="clearb">
</div>
<div class="entity-list-addedbox">
  <mat-list>
    <div mat-subheader *ngIf="addedVehicleList.length">{{ 'GEOFENCE.ADDED_VEHICLES' | translation }}</div>
    <mat-list-item class="vehicle-item" *ngFor="let item of addedVehicleList | geoSearch: findText; let i = index;">
      <div class="remove-vehicle" (click)="removeVehicle(item, i)">
        <img src="assets/img/clearb.png" alt="clearb">
      </div>
      <mat-icon mat-list-icon class="picture hasPicture"
        [ngStyle]="{ 'background': item.brand ? item.picture ? 'url('+item.picture+')' : '#ffe09d' : '#a6a6a6'}">
        <span class="brand" [ngStyle]="{'color': item.brand ? '' : '#ffffff'}">
          {{ item.brand ? item.brand.slice(0, 1)[0] : '?' }}
        </span>
        <span class="indicador-driver" [ngClass]="{
            'indicator-driving': item.currentStatus === 'TripDriving',
            'indicator-stopped': item.currentStatus !== 'TripDriving'
          }">
        </span>
        <img *ngIf="sort === 'status'" class="health" [src]="'assets/img/'+item.status+'.svg'" alt="health">
      </mat-icon>

      <h4 class="name activeColor" mat-line>
        <span innerHTML="{{ item?.brand | highlight: findText}}"></span>&nbsp;
        <span innerHTML="{{ item?.model | highlight: findText}}"></span>
      </h4>
      <p class="shift" mat-line>
        <span class="year" innerHTML="{{ item?.year | highlight: findText}}"></span>
        <span *ngIf="item.year"> &nbsp; </span>
        <span *ngIf="item.plate" class="plate" innerHTML="{{ item?.plate | highlight: findText}}"></span>
      </p>
    </mat-list-item>
  </mat-list>

  <mat-list>
    <div mat-subheader class="">{{ 'GEOFENCE.VEHICLES_TO_ADD' | translation }}</div>
    <mat-list-item class="vehicle-item" *ngFor="let vehicle of vehicleList | geoSearch: findText; let j = index;">
      <div class="remove-vehicle" (click)="addVehicle(vehicle, j)">
        <img src="assets/img/group-13.png" alt="vehicle-item">
      </div>
      <mat-icon mat-list-icon class="picture hasPicture"
        [ngStyle]="{ 'background': vehicle.brand ? vehicle.picture ? 'url('+vehicle.picture+')' : '#ffe09d' : '#a6a6a6'}">
        <span class="brand" [ngStyle]="{'color': vehicle.brand ? '' : '#ffffff'}">
          {{ vehicle.brand ? vehicle.brand.slice(0, 1)[0] : '?' }}
        </span>
        <span class="indicador-driver" [ngClass]="{
              'indicator-driving': vehicle.currentStatus === 'TripDriving',
              'indicator-stopped': vehicle.currentStatus !== 'TripDriving'
            }">
        </span>
        <img *ngIf="sort === 'status'" class="health" [src]="'assets/img/'+vehicle.status+'.svg'" alt="health">
      </mat-icon>

      <h4 class="name" mat-line>
        <span innerHTML="{{ vehicle?.brand | highlight: findText}}"></span>&nbsp;
        <span innerHTML="{{ vehicle?.model | highlight: findText}}"></span>
      </h4>

      <p class="shift" mat-line>
        <span class="year" innerHTML="{{ vehicle?.year | highlight: findText}}"></span>
        <span *ngIf="vehicle.year"> &nbsp; </span>
        <span *ngIf="vehicle.plate" class="plate" innerHTML="{{ vehicle?.plate | highlight: findText}}"></span>
      </p>
    </mat-list-item>
  </mat-list>
</div>
import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-driver-advice',
  templateUrl: './driver-advice.component.html',
  styleUrls: ['./driver-advice.component.css']
})
export class DriverAdviceComponent implements OnInit {

  data: any = {};

  constructor(
    private gs: GeneralService
  ) {
    this.gs.getWiData.subscribe(d => this.data = d);
  }

  ngOnInit() {
  }

}

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeneralService } from '../../../services/general.service';
import { DriverService } from '../../../services/driver.service';
import { GeneralModalComponent } from '../../general-modal/general-modal.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-drivers-list-extensions',
  templateUrl: './drivers-list-extensions.component.html',
  styleUrls: ['./drivers-list-extensions.component.css']
})
export class DriversListExtensionsComponent implements OnInit {

  selectedDrivers: Array<any> = [];
  drivers: Array<any> = [];
  findText: string;
  integrationId: any;
  details: any;
  logo: any;
  contexts: any[];
  private subscription: Subscription = new Subscription;

  constructor(
    @Optional()  @Inject(MAT_DIALOG_DATA) public data: {},
    @Optional() public dialogRef: MatDialogRef<DriversListExtensionsComponent>,
    public driverService: DriverService,
    public generalService: GeneralService,
    public dialog: MatDialog,
  ) {
    this.generalService.getWiData.subscribe((data: any) =>  {
      this.integrationId = data.id;
      this.details = data.details;
      this.logo = data.logo;
    } );
    this.subscription.add(this.generalService.getWiContext.subscribe(data => this.contexts = data));
   }

  ngOnInit(): void {
    this.getDrivers();
  }

  async getDrivers() {
    await this.driverService.getAll().toPromise().then((response: Array<any>) => {
      this.drivers = response;
    }).catch(err => this.drivers = []);
  }

  addDriver(driver, index) {
    let rowIndex = this.drivers.findIndex(x => x._id === driver._id);
    if (!this.findText) {
      rowIndex = index;
    }
    this.findText = '';
    this.selectedDrivers.push(driver);
    this.drivers.splice(rowIndex, 1);
  }

  removeDriver(driver, index) {
    this.selectedDrivers.splice(index, 1);
    this.drivers.push(driver);
  }

  calculateCost() {
    let cost: any;
    cost = {currency: this.details.price.currency, cost: this.details.price.value * this.selectedDrivers.length};
    const arrayDrivers = this.selectedDrivers.map(item => item.id);
    const eventName = 'dashcam_result_calculate_view';
    this.generalService.logScreenEvents(eventName, eventName, eventName, 'click');
    this.openGeneralModal(cost, arrayDrivers, 'extensions_activate');
  }

  openGeneralModal(cost, drivers, type) {
    this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '30vw',
      data: { cost, drivers, type, integration: this.integrationId, logo: this.logo }
    });
  }

  openModalEditDriver(driver, isDriverList: boolean, index:number, isAdd?: boolean) {
    const dialogRef = this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '600px',
      data: { ...driver, type: 'edit_driver', fromComponent: 'extensions' }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getDriverAndUpdate(driver._id, isDriverList, index, isAdd);
    });
  }

  
  async getDriverAndUpdate(id: string, isDriverList: boolean, index: number, isAdd?: boolean) {
    await this.driverService.getAll().toPromise().then((response: Array<any>) => {
      const driver = response.find(e => e._id === id);
      if (isAdd) {
        this.addDriver(driver, index);
      } else if (isDriverList) {
        this.drivers[index] = driver;
      } else {
        this.selectedDrivers[index] = driver;
      }
    });
  }

  cancel() {
    const context = this.contexts.filter(c => c.context === 'Add ons');
    this.generalService.setWindow({ state: true, extendedState: false, type: 'extension-detail', contexts: context, data: {id: this.integrationId, details: this.details, logo: this.logo} });
  }
}

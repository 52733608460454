import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../environments/environment';
import { scrollPages } from '../helpers/helpers';
import { HEADER_OPTIONS } from '../helpers/helpers';
import { isFloat } from '../helpers/helpers';
import { translationPipe } from '../pipes/translation/translation.pipe';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TripsService {

  constructor(public http: HttpClient) {
  }

  getAll(query: any, direction: string = 'bw', count: number = scrollPages) {
    return this.http.get(`${env.API_PATH}/api/trip/v1/all/${query.tag}/${query.value}/${direction}/${count}${ query.lastTrip ? `?lastTrip=${query.lastTrip}` : '' }`, HEADER_OPTIONS());
  }

  getTrip(uuid: any, type: string = 'keyframes') {
    return this.http.get(`${env.API_PATH}/api/trip/v1/single/${type}/${uuid}`, HEADER_OPTIONS());
  }

  eventTooltip({ type, value, dashcam }) {
    let template;
    const eventTranslate = translationPipe.transform('EVENT');
    if (type === 'idle' && value) {
      const idleTime = moment.utc(value).format('HH:mm');
      const hours = idleTime.split(':')[0];
      const minutes = idleTime.split(':')[1];
      const eventTag = `TRIP_EVENTS.${type}`;
      const eventName = translationPipe.transform(eventTag);
      template = `${ eventName }: ${ hours }h ${ minutes }m`;
    } else {
      template = `${ translationPipe.transform(`TRIP_EVENTS.${type}`) }`;
    }

    if(dashcam?.images?.length > 0){
      return `
        <div class="trip-range-tooltips">
          <div style="display: flex">
            <div class="icon-dashcam">
              <img src="/assets/img/dashcam-icon.png">
            </div>
            <div style="padding: 6px 4px 6px 3px;"> 
              <span style="color: #FFE09D; font-size: 10px; font-weight:600">${eventTranslate}</span>
              <p style="margin: 0; margin-right: 10px">
                <span style="display: flex;align-items: center;"><img style="margin-right: 4px;" src="/assets/img/icon-play.png"><strong>${template}</strong></span>
              </p>
            </div>
          </div>
        </div>
      `;
    }else{
       return `
      <div style="padding: 10px 20px;" class="trip-range-tooltips">
        <span style="color: #FFE09D; font-size: 10px; font-weight:600">${eventTranslate}</span>
        <span><strong>${template}</strong></span>
      </div>
    `;
    }
  }

  tripTooltipContent(speed, unit) {
    const template = `
      <div style="padding: 10px 20px;" class="trip-range-tooltips">
        <span> <strong>${ translationPipe.transform('VELOCITY') }</strong> <br>
        ${ isFloat(speed) ? speed.toFixed(2) : speed }
          ${ unit }</span>
      </div>
    `;
    return template;
  }

  genRanges(index: number) {
    const ranges = [];
    for (let i = 0; i < 121; i += 5) {
      ranges.push([i, i + 5]);
      if ((i + 1) > 120) { return ranges[index][0] + ' km/h a ' + ranges[index][1] + ' km/h'; }
    }
  }

  getColors(params: any) {
    const colorLists = [
      // City
      [{ color: '#69dceb' }, { color: '#7bd1da' }, { color: '#89c8cc' }, { color: '#99bdbd' },
      { color: '#a7b4ae' }, { color: '#b7aaa0' }, { color: '#c5a091' }, { color: '#d69680' }, { color: '#e48d73' }, { color: '#f38364' }],
      // Highway
      [{ color: '#69dceb' }, { color: '#74d5e0' }, { color: '#7dcfd7' }, { color: '#86c9ce' }, { color: '#90c4c6' }, { color: '#99bdbc' },
      { color: '#a2b8b4' }, { color: '#abb1aa' }, { color: '#b4aca2' }, { color: '#bda699' }, { color: '#c6a090' }, { color: '#cf9b88' },
      { color: '#d8947e' }, { color: '#e18f76' }, { color: '#ea896d' }, { color: '#f38364' }],
      // Freeway
      [{ color: '#69dceb' }, { color: '#71d7e3' }, { color: '#77d3dd' }, { color: '#7dcfd7' }, { color: '#82cbd2' }, { color: '#88c7cc' },
      { color: '#8ec3c6' }, { color: '#95c0c0' }, { color: '#9bbcba' }, { color: '#a0b8b4' }, { color: '#a6b4ae' }, { color: '#acb1a9' },
      { color: '#b2ada3' }, { color: '#b8a99e' }, { color: '#bea598' }, { color: '#c4a192' }, { color: '#ca9e8d' }, { color: '#d09a87' },
      { color: '#d59681' }, { color: '#db927b' }, { color: '#e18e75' }, { color: '#e78a6f' }, { color: '#ed876a' }, { color: '#f38364' }]
    ];
    //
    const list = colorLists[params.track === 'city' ? 0 : params.track === 'highway' ? 1 : 2];
    const index = params.rangeIndex <= 9 ? params.rangeIndex : 9;
    //
    return list[index];
  }
}

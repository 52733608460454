<div class="header" *ngIf="data">
    <div style="display: flex; align-items: center;">
        <div class="logo">
            <img src="{{data.data?.logo}}" alt="" width="35px" height="35px"/>
        </div>
        <div style="display: flex; flex-direction: column; color: white">
            <span>{{'EXTENSION_SUCCESS.SELECTED_FLEET' | translation }}</span>
            <span style="font-size: 20px;">{{data.data?.drivers?.length}}</span>
        </div>
       
    </div>
</div>
<div style="padding: 24px; color: #595959; text-align: center;">
    <span>{{'EXTENSION_SUCCESS.COST_MESSAGE' | translation }}</span>
    <p>
        <span style="font-size: 22px; font-weight: 600;">{{data.data?.cost?.cost}}</span>&nbsp;<span>{{data.data?.cost?.currency}}</span>
    </p>
    <p>{{'EXTENSION_SUCCESS.ACCOUNT_CREATED_MESSAGE' | translation}}</p>
    <p>{{'EXTENSION_SUCCESS.NEXT_STEP_MESSAGE' | translation}}</p>
    <div class="actions">
        <button (click)="closeModal()"> {{ 'CLOSE' | translation }} </button>
    </div> 
</div>
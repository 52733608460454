import { Injectable } from '@angular/core'
import moment from 'moment'
import { BehaviorSubject } from 'rxjs'
import { RTS_status } from '../helpers/helpers'
import { translationPipe } from '../pipes/translation/translation.pipe'
import { DevicesService } from './devices.service'
import { GeneralService } from './general.service'
import { VehicleService } from './vehicle.service'

// LeafLet
declare let L;

const imgBase = '/assets/img/icons/';

@Injectable({
  providedIn: 'root'
})
export class MarkerService {
  constructor(
    private vehicleService: VehicleService,
    private deviceService: DevicesService,
    private gs: GeneralService
  ) {
    this.vehicleService.getCurrentVehicles.subscribe( vehicles => this.vehicles = vehicles );
    this.deviceService.getCurrentDevices.subscribe( devices => this.devices = devices );

    this.translations.lastUpdateLabel = translationPipe.transform('LAST_DATA_RECEIVED');
    this.translations.plate = translationPipe.transform('PLATE');
    this.statusOptions = Object.entries({
        TripDriving: 'DRIVING',
        TripStopped: 'STOPPED',
        Unknown: 'NO_SIGNAL',
        Disconnected: 'DISCONNECTED'
      }).reduce((m, [k, v]) => ({...m, [k]: translationPipe.transform(v)}), {});
    this.gs.getMap.subscribe(map => this.map = map);
  }
  translations: any = {};
  statusOptions: any = {};
  mGroup = new BehaviorSubject<any>(null);
  getMGroup = this.mGroup.asObservable();
  map: any;
  vehicles: any;
  devices: any;
  setMGroup(data: any) { this.mGroup.next(data); }

  setMarkerTemplate(vehicle) {
    vehicle = vehicle || { plate: '', brand: '', model: '', year: '' };
    const abrv = vehicle.current_driver ? `
      ${vehicle.current_driver.name.length ? vehicle.current_driver.name.slice(0, 1)[0] :
      ''} ${vehicle.current_driver.lastName.length ? vehicle.current_driver.lastName.slice(0, 1)[0] :
      ''}
    ` : 'N/S';
    return L.divIcon({
      className: 'markerTemplate', iconAnchor: [29, 65], html: `
      ${ vehicle.current_driver ?
          // If has driver
          `
          <div>
        ${ vehicle.current_driver.picture ?
            `
              <div class="hasAvatar" style="background: url(${vehicle.current_driver.picture});">
                <div></div>
              </div>
              <p ${vehicle.active ? '' : `style="color: #ffffff"`}>${vehicle.plate ? vehicle.plate : vehicle.device.imei.substr(-4)}</p>
              ` :
            // If doesnt have pictures
            `
              <div class="notAvatar" ${vehicle.active ? '' : `style="background: #b8b8b8; color: #717171`};">
                <div ${vehicle.active ? '' : `style="background: #a6a6a6"`}></div>
                ${abrv}
              </div>
              <p ${vehicle.active ? '' : `style="color: #ffffff"`}>${vehicle.plate.length ? vehicle.plate : 'N/S'}</p>
              `}
            ` :
          // If no driver
          `
          <div class="notDriver"></div>
          <p class="notDriverName">${vehicle.plate.length ? vehicle.plate || '' : vehicle.brand || '' + ' ' + vehicle.model || '' + ' ' + vehicle.year || '' }</p>
        </div>
      `}
    `});
  }

  setAssetMarkerTemplate(asset) {
    const { markerIcon }: any = (this.assetStrategy(asset) || {});
    return L.divIcon({
      className: 'markerTemplate marker-type-asset',
      iconAnchor: [29, 65],
      html: `
      <div class="hasAvatar asset-icon ${ markerIcon || '' }">
        <div></div>
      </div>
      <p>${ asset.alias }</p>
    `});
  }

  // tooltip template
  tooltipContent(vehicle) {
    vehicle = vehicle || { plate: '', device: { imei: '' }};
    const name = vehicle.current_driver ? `${vehicle.current_driver.name}` : 'N/S';
    const lastUpdate = moment(vehicle.lastUpdate).format('MMM Do YYYY, h:mm a');

    const timeAgo = moment(vehicle.lastUpdate).fromNow();
    return `
    <div class="tooltip">
        <div class="arrow">
            <div></div>
        </div>
        <div class="head">
            <p class="driver-name">${name}</p>
            <p>
              <span class="vehicle"><strong>Auto: </strong>${vehicle.brand ? vehicle.brand || '' + ' ' + vehicle.model || '' + ' ' + vehicle.year || '' : (vehicle.device.imei || '----')}</span><br>
              ${ vehicle.plate ? `<span class="plate"> <strong>${this.translations.plate}</strong>: ${vehicle.plate}</span><br>` : ''}
              ${!vehicle.plate ? `<span class="imei"> <strong>IMEI</strong>: ${vehicle.device.imei}</span><br>` : ''}
              <span class="status ${ `${vehicle?.currentStatus || '----'}`.toLowerCase() }">${ vehicle?.currentStatus ? this.statusOptions[vehicle.currentStatus] : '----' }</span><br>
            </p>
            <p class="last-update">
              <span class="last-update-label">${ this.translations.lastUpdateLabel }:</span><br>
              <span class="date">${ lastUpdate }, ${ timeAgo }</span>
            </p>
        </div>
    </div>
    `;
  }

  assetTooltipContent(asset) {
    const lastUpdate = moment(asset.time).format('MMM Do YYYY, h:mm a');
    const stablishingSignal = `<span class="badge-nosignal"><img src="/assets/img/icons/time-white.svg" >
    ${ translationPipe.transform('ESTABLISHING_SIGNAL') }`;
    return `
      <div class="tooltip">
          <div class="arrow">
              <div></div>
          </div>
          <div class="asset-container">
            <div class="asset-head">
              <div>${ asset.alias }</div>
              <div class="batterylevel-container">
                <div class="batterylevel batterylevel-${ this.batteryLevels(asset) }">
                  <div class="batterylevel-bar-container">
                      <div style="width: ${ asset.batteryLevel }%"></div>
                  </div>
                </div>
                <span class="batterylevel-color-${ this.batteryLevels(asset)}">
                  ${ asset.batteryLevel }%
                </span>
              </div>
            </div>
            <div class="asset-body">
              <img src="/assets/img/icons/marker-update.svg" >
              ${ translationPipe.transform('LAST_REPORT') }: ${ lastUpdate }<br>
              ${ asset.stablishingSignal ? stablishingSignal : ''} </span>
            </div>
          </div>
      </div>
    `;
  }

  updateAssetTooltip(group, batch) {
    const singleBatch = batch[0] || {};
    const { keys } = singleBatch;
    if ( !keys ) { return; }
    const batchData = keys[ keys.length - 1 ];
    const layer = group.findAssetByImei(singleBatch.imei);
    if ( !layer ) { return; }
    const currentAsset = layer.options.info;
    if ( !this.assetStrategy(currentAsset) || !(keys || []).length ) { return; }
    const newAsset = { ...currentAsset, ...batchData };
    const newLoc = new L.LatLng(newAsset.loc[0], newAsset.loc[1]);
    this.setMGroup(group);
    layer.setLatLng(newLoc);
    layer.setTooltipContent( this.assetTooltipContent(newAsset) );
  }

  updateVehicleTooltip(group, batch, imei: string = null) {
    if ( !batch && imei ) {
      return this.vehicleDriverUpdated(group, imei);
    }
    const singleBatch = batch[0] || {};
    const { keys } = singleBatch;
    if ( !keys ) { return; }
    const batchData = keys[ keys.length - 1 ];
    const layer = group.findByImei(singleBatch.imei);
    if ( !layer ) { return; }
    const currentVehicle = layer.options.info;
    if ( !(keys || []).length ) { return; }
    const newVehicle = { ...currentVehicle, lastUpdate: batchData.time };
    if (newVehicle && singleBatch.type) {
      newVehicle['currentStatus'] = singleBatch.type;
    }
    layer.setTooltipContent( this.tooltipContent(newVehicle) );
    this.setMGroup(group);
  }

  vehicleDriverUpdated(group, imei) {
    const layer = group.findByImei(imei);
    if ( !layer ) { return; }
    const { info } = layer.options;
    layer.setTooltipContent( this.tooltipContent(info) );
    const newMarker = layer;
    this.map.removeLayer(layer);
    layer.options.icon = this.setMarkerTemplate(info);
    newMarker.addTo(this.map);
    return this.setMGroup(group);
  }

  assetStrategy(asset) {
    const types = {
      'AT-Trak4': {
        't4-wired': {
          markerIcon: 'asset-icon-wired'
        },
        't4-wireless': {
          markerIcon: 'asset-icon-wireless'
        }
      }
    };
    return (types[ asset.vendor ] || {})[ asset.model ];
  }

  batteryLevels(asset) {
    if ( asset.batteryLevel <= 10 ) {
      return 'low';
    }
    if ( asset.batteryLevel > 10 && asset.batteryLevel <= 50 ) {
      return 'medium';
    }
    if ( asset.batteryLevel > 50 && asset.batteryLevel <= 100 ) {
      return 'full';
    }
    return 0;
  }

  setMarkerStatus(group) {
    this.setMGroup(group);
    this.vehicles.forEach(v => {
      if ( !v.device ) { return; }
      const layer = group.findByImei(v.device.imei);
      if ( !layer || !layer._icon ) { return; }
      RTS_status.forEach(status => { layer._icon.classList.remove(status); });
      layer._icon.classList.add(v.currentStatus);
      return v;
    });
  }

  abMarker(point) {
    return L.divIcon({
      className: 'abPoint', iconAnchor: [20, 35], html: `
        <img src="${imgBase + point}marker.svg">
      `
    });
  }

  eventMarker(e) {
    return L.divIcon({
      className: `eventMarker ${e.type}`, iconAnchor: [20, 50], html: `
        <img id="${e.start}" src="${imgBase}events/${ e.type }.svg">
      `
    });
  }

}

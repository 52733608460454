import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { flattenDeep } from 'lodash'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { tap } from 'rxjs/operators'
import { HEADER_OPTIONS } from '../helpers/helpers'
import { Driver } from '../models/driver.model'
import { Tag } from '../models/tag.model'
import { Vehicle } from '../models/vehicle.model'
import { environment } from './../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class TagService {
  URL = ''
  private selectedTagsSubject = new BehaviorSubject<Tag.Tag[]>([]);
  private tagResponse: Tag.Response;

  get appliedTag$() {
    return this.selectedTagsSubject.asObservable();
  }

  constructor(private http: HttpClient) {
    this.URL = environment.API_PATH
  }

  getTagList(reload: 'none' | 'force' = 'none'): Observable<Tag.Response> {
    if (this.tagResponse && reload === 'none') {
      return of(this.tagResponse) as Observable<Tag.Response>
    }

    return this.http
      .get(`${this.URL}/api/vehicleTags/v1`, HEADER_OPTIONS())
      .pipe(tap(data =>{ this.tagResponse = data as Tag.Response })) as Observable<Tag.Response>
  }

  getTag(id: string) {
    return this.http.get(`${this.URL}/api/vehicleTags/v1/${id}`, HEADER_OPTIONS())
  }

  addTag(name: string, vehicles?: string[]) {
    return this.http.post(`${this.URL}/api/vehicleTags/v1`, { name, vehicles }, HEADER_OPTIONS())
  }

  editTag(id: string, name: string, payload?: { vehicles?: string[]; devices?: string[] }) {
    return this.http.patch(`${this.URL}/api/vehicleTags/v1/${id}`, { name, ...payload }, HEADER_OPTIONS())
  }

  editVehicleTags(id: string, tags: string[]) {
    return this.http.patch(`${this.URL}/api/vehicleTags/v1/vehicles/${id}`, { tags }, HEADER_OPTIONS())
  }

  deleteTag(id: string): Observable<Object> {
    return this.http.delete(`${this.URL}/api/vehicleTags/v1/${id}`, HEADER_OPTIONS())
  }

  getSelectedTagsCant() {
    return this.selectedTagsSubject.getValue().length
  }

  updateSelectedTags(tags: Tag.Tag[]) {
    this.selectedTagsSubject.next(tags)
  }

  filterVehicles(vehicles: Vehicle.Vehicle[]): Vehicle.Vehicle[] {
    const selectedTags = this.selectedTagsSubject.getValue()

    if (selectedTags.length === 0) {
      return vehicles
    }

    const vehiclesIds = this.tagResponse.vehicleTags
      .filter(tag => selectedTags.find(selectedTag => selectedTag.id === tag.id))
      .map(tag => tag.vehicles)
      .reduce((acc, curr) => acc.concat(curr), [])
      .map(vehicle => vehicle.id);

    const vehiclesIdsUnique = [...new Set(vehiclesIds)];

    return vehicles.filter(vehicle => vehiclesIdsUnique.includes(vehicle.id));
  }

  filterDrivers(drivers: Driver.Driver[]) {
    const selectedTags = this.selectedTagsSubject.getValue()

    if (selectedTags.length === 0) {
      return drivers
    }

    const driversIds = flattenDeep(selectedTags.map(tag => tag.vehicles.map(vehicle => vehicle.drivers))) as string[]
    const driversIdsUnique = [...new Set(driversIds)]

    return drivers.filter(driver => driversIdsUnique.includes(driver.id))
  }

  filterDevices(devices: any[]) {
    const selectedTags = this.selectedTagsSubject.getValue()

    if (selectedTags.length === 0) {
      return devices
    }

    const devicesIds = flattenDeep(selectedTags.map(tag => tag.devices.map(device => device._id))) as string[]
    const deviceUnique = [...new Set(devicesIds)]

    return devices.filter(device => deviceUnique.includes(device._id))
  }

}

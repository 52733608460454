import { environment as env } from "../../environments/environment";
import { scrollPages, toQueryParam } from "./helpers";
export const endpoints = {
  // Menu
  get_menu: (_) => {
    return `${env.API_BASE_URL}/fleetr/navigation/v2/menu`;
  },
  // Dashboard
  dashboard_types: (_) => {
    return `${env.API_BASE_URL}${env.API_PROXY}/api/dashboard/${env.API_VERSION}/types`;
  },
  dashboard_values: (_) => {
    return `${env.API_BASE_URL}${env.API_PROXY}/api/dashboard/${env.API_VERSION}/values`;
  },
  dashboard_charts: (_) => {
    return `${env.API_BASE_URL}${env.API_PROXY}/api/dashboard/${env.API_VERSION}/charts`;
  },
  // maintenance
  dtcs: (_) => {
    return `${env.API_BASE_URL}/fleetr/vehicle/v2/dtcs`;
  },
  maintenance: (_) => {
    return `${env.API_BASE_URL}${env.API_PROXY}/api/vehicle/${env.API_VERSION}/maintenance`;
  },
  // Notifications
  notifications_list: ({ id, notiFilter }) => {
    const idUrl = id ? `/${id}?` : "?";
    return `${env.API_NOTIFICATION_PATH}/api/notifications/${
      env.API_VERSION
    }/list${idUrl}${toQueryParam(notiFilter)}`;
  },
  noti_subscription: (_) => {
    return `${env.API_NOTIFICATION_PATH}/api/contactpoints/v1`;
  },
  noti_groups: (_) => {
    return `${env.API_BASE_URL}/${env.API_VERSION}/core/user_push_settings/me`;
  },
  update_noti_groups: (_) => {
    return `${env.API_BASE_URL}/${env.API_VERSION}/core/user_push_settings`;
  },
  // login flow
  forgot_password: (_) => {
    return `${env.API_BASE_URL}/v1/auth/admin/forgot?access_token=${env.API_TOKEN}`;
  },
  change_password: ({ id }) => {
    return `${env.API_BASE_URL}/v1/core/admins/${id}/password`;
  },
  validate_restore_code: ({ code }) => {
    return `${env.API_BASE_URL}/v1/auth/admin/forgot/reset/${code}?access_token=${env.API_TOKEN}`;
  },
  logout: ({ token }) => {
    return `${env.API_BASE_URL}/v1/auth/admin/local?access_token=${token}`;
  },
  // magic link
  confirm_account: () => {
    return `${env.API_BASE_URL}/fleetr/admins/v1/invitations/actions`;
  },
  get_magic_link: () => {
    return `${env.API_BASE_URL}/v1/auth/admin/magic-link/generate`;
  },
  test_magiclink_token: (_) => {
    return `${env.API_BASE_URL}/v1/auth/admin/magic-link`;
  },
  // Fleet config
  get_set_config: ({ tenantId }) => {
    return `${env.API_BASE_URL}${env.API_PROXY}/api/tenant/v1/${tenantId}/config`;
  },
  get_imeis: ({ lastDigits }) => {
    return `${env.API_BASE_URL}${env.API_PROXY}/api/device/v1/findByImei?imei=${lastDigits}$`;
  },
  get_brands: (_) => {
    return `${env.API_BASE_URL}${env.API_PROXY}/api/brand/v1/all?sort=name`;
  },
  get_models: ({ brandId }) => {
    return `${env.API_BASE_URL}${env.API_PROXY}/api/model/v1/findByBrand?brandId=${brandId}`;
  },
  get_timezones: ({ country }) => {
    return `${env.API_BASE_URL}${env.API_PROXY}/api/timezone/v1/findByCountry?country=${country}`;
  },
  create_entities: ({ tenantId }) => {
    return `${env.API_BASE_URL}${env.API_PROXY}/api/tenant/v1/${tenantId}/entities`;
  },
  // Vehicle
  update_vehicle: () => {
    return `${env.API_BASE_URL}/fleetr/tenant/v2/entities`;
  },
  // Drivers
  update_driver: ({ driverId }) => {
    return `${env.API_BASE_URL}${env.API_PROXY}/api/driver/v1/${driverId}`;
  },
  get_drivers: () => {
    return `${env.API_BASE_URL}${env.API_PROXY}/api/driver/v1/all`;
  },
  // Trips
  get_trips: (
    { query },
    direction: string = "bw",
    count: number = scrollPages
  ) => {
    let url = `${env.API_PATH}/api/trip/v1/all/${query.tag}/${query.value}/${direction}/${count}`;
    const params = new URLSearchParams();

    for (const [key, value] of Object.entries(query)) {
      if (key === "tag" || key === "value") continue;
      params.append(key, value as string);
    }

    if (params.toString() !== '') {
      url += "?" + params.toString();
    }

    return url;
  },
  get_trip_keyframes: ({ id }) => {
    return `${env.API_BASE_URL}/fleetr/trip/v2/${id}?format=keyframes`;
  },
  // Update geofence
  update_active_inactive: ({ id }) => {
    return `${env.API_BASE_URL}/fleetr/geofence/v1/${id}`;
  },
  // Scores
  driver_scores: (_) => {
    return `${`${env.API_BASE_URL}${env.API_PROXY}/api/driver/${env.API_VERSION}/scoring/all`}`;
  },
  get_score_types: () => {
    return `${env.API_BASE_URL}/fleetr/scores/v2`;
  },
  get_score_values: ({ scoreId, filter }) => {
    const hasDateFilter = filter.from && filter.to;
    const dateQuery = `?fromWeek=${filter.from}&toWeek=${filter.to}`;
    return `${env.API_BASE_URL}/fleetr/driver/v2/scores/${scoreId}${
      hasDateFilter ? dateQuery : ""
    }`;
  },
  // Admins
  get_admins: ({}) => {
    return `${env.API_BASE_URL}/fleetr/admins/v1`;
  },
  post_admin: ({}) => {
    return `${env.API_BASE_URL}/fleetr/admins/v1`;
  },
  delete_admin: ({ id }) => {
    return `${env.API_BASE_URL}/fleetr/admins/v1/${id}`;
  },
  patch_admin: ({ id }) => {
    return `${env.API_BASE_URL}/fleetr/admins/v1/${id}`;
  },
  // Roles
  get_roles: ({}) => {
    return `${env.API_BASE_URL}/fleetr/roles/v1`;
  },
  // Reports
  export_report: () => {
    return `${env.API_BASE_URL}/fleetr/reports/v1/requests`;
  },
  // Maintenances
  get_maintenance_types: () => {
    return `${env.API_BASE_URL}/fleetr/maintenance_type/v1`;
  },
  get_vehicle_maintenances: ({ id }) => {
    return `${env.API_BASE_URL}/fleetr/vehicle/v2/${id}/maintenance`;
  },
  update_odometer: ({ id }) => {
    return `${env.API_BASE_URL}/fleetr/vehicle/v2/${id}/odometer`;
  },
  update_maintenance: ({ body }) => {
    body = JSON.parse(body);
    return `${env.API_BASE_URL}/fleetr/vehicle/v2/${body.vehicle}/maintenance/${body.id}`;
  },
  delete_maintenance: ({ vehicleId, maintenanceId }) => {
    return `${env.API_BASE_URL}/fleetr/vehicle/v2/${vehicleId}/maintenance/${maintenanceId}`;
  },
  create_maintenance: ({ body }) => {
    body = JSON.parse(body);
    return `${env.API_BASE_URL}/fleetr/vehicle/v2/${body.vehicle}/maintenance/`;
  },
  events_dashcam: ({tripId}) => {
    return `${env.API_BASE_URL}/dashcam/api/v1/eventImages/${tripId}`
  }
};

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { translationPipe } from '../pipes/translation/translation.pipe';
import { checkForQparam, trimmedLang } from '../helpers/helpers';
import moment from 'moment';

const datePipe = new DatePipe('en');

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  dashboard = new BehaviorSubject<any>(null);
  getDashboard = this.dashboard.asObservable();
  setDashboard(data: any) { this.dashboard.next(data); }

  constructor() {
  }

  dateRange(filter) {
    if (!filter) { return ''; }
    let { from, to } = filter;
    const start = checkForQparam('start');
    const end = checkForQparam('end');
    from = start && end ? moment.utc(Number(start)).locale( trimmedLang() ).format('MMM D, YYYY') :
      datePipe.transform(from, 'mediumDate');
    to = start && end ? moment.utc(Number(end)).locale( trimmedLang() ).format('MMM D, YYYY') :
      datePipe.transform(to, 'mediumDate');
    return `${translationPipe.transform('FROM')} ${from} ${translationPipe.transform('TO')} ${to}`;
  }

  chartTypesStrategies() {
    return {
      dayOfWeek: {
        1: 'MONDAY',
        2: 'TUESDAY',
        3: 'WEDNESDAY',
        4: 'THURSDAY',
        5: 'FRIDAY',
        6: 'SATURDAY',
        7: 'SUNDAY'
      },
      hourOfDay: {
        '0': '00:00',
        '1': '01:00',
        '2': '02:00',
        '3': '03:00',
        '4': '04:00',
        '5': '05:00',
        '6': '06:00',
        '7': '07:00',
        '8': '08:00',
        '9': '09:00',
        '10': '10:00',
        '11': '11:00',
        '12': '12:00',
        '13': '13:00',
        '14': '14:00',
        '15': '15:00',
        '16': '16:00',
        '17': '17:00',
        '18': '18:00',
        '19': '19:00',
        '20': '20:00',
        '21': '21:00',
        '22': '22:00',
        '23': '23:00'
      }
    };
  }

  dashboardFilters() {
    return [
      {
        type: 'LAST_7_DAYS',
        query: {
          from: Date.now() - (1000 * 60 * 60 * 24) * 8,
          to: Date.now() - (1000 * 60 * 60 * 24) * 1
        }
      },
      {
        type: 'LAST_30_DAYS',
        query: {
          from: Date.now() - (1000 * 60 * 60 * 24) * 30,
          to: Date.now() - (1000 * 60 * 60 * 24) * 1
        }
      },
      {
        type: 'MONTH_YEAR'
      },
      {
        type: 'RANGE_FILTER'
      }
    ];
  }

  chartsConfig() {
    return {
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.7,
          opacityTo: 0
        }
      },
      chart: {
        type: 'area',
        height: 300,
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        horizontalAlign: 'left'
      },
      xaxis: {
        labels: {
          formatter: function (value) {
            if (!value) { return value; }
            const isNumber = new RegExp('\\d+').test(value);
            if (!value || isNumber) { return `${value}`; }
            return value.slice(0, 3);
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return `${parseInt(value)} ${translationPipe.transform('TRIPS')}`;
          }
        }
      }
    };
  }

}

const getMonday = d => {
  d = new Date(d);
  const day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1);
  return new Date(d.setDate(diff));
};

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-extensions-activate-success',
  templateUrl: './extensions-activate-success.component.html',
  styleUrls: ['./extensions-activate-success.component.css']
})
export class ExtensionsActivateSuccessComponent implements OnInit {
  @Input("data") data: any;
  @Input("dialogRef") dialogRef: any;

  constructor() {
    //This is intentional
  }

  ngOnInit(): void {
    //This is intentional
  }

  async closeModal() {
    this.dialogRef.close();
  }

}

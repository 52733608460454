import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatAccordion } from "@angular/material/expansion";
import { GeneralService } from "../../services/general.service";

@Component({
  selector: "app-detail-dtcs",
  templateUrl: "./detail-dtcs.component.html",
  styleUrls: ["./detail-dtcs.component.css"],
})
export class DetailDtcsComponent implements OnInit {
  @Input("data") data: any;
  @Input("dialogRef") dialogRef: any;
  @ViewChild("accordionCategory") accordionCategory: MatAccordion;
  componentImg: string;
  errorSize: any;
  categoryStatus: Array<boolean> = [];

  constructor(private gs: GeneralService) {
  }

  ngOnInit(): void {
    this.errorSize = this.data?.vehicle?.dtcs.length > 0 ? 'ERROR_CODE_PLURAL' : 'ERROR_CODE';
    for (let i = 0; i < this.data?.vehicle?.detail?.length; i++) {
      this.categoryStatus.push(false);
    }
  }

  async closeModal() {
    this.dialogRef.close();
  }

  /**
   * Crea la ruta de la imagen del componente seleccionado sobre la imagen del auto.
   * Maneja el estado de las listas de las componentes.
   * @param dtcCategory nombre de la categoria o componente
   * @param isOpen Indica si la sección de esa categoria está abierta o cerrada
   * @param index Indica la posición de la categoria seleccionada
   */
  async setImgComponent(dtcCategory, isOpen: boolean, index: number) {
    this.categoryStatus[index] = isOpen;
    if (isOpen) {
      const url = "assets/img/dtc/" + dtcCategory + ".svg";
      const exists = await this.gs.imageExists(url);
      this.componentImg = exists ? url : "assets/img/dtc/components.svg";
    } else {
      this.componentImg = "";
    }
  }
}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment as env } from '../../environments/environment'
import { HEADER_OPTIONS } from '../helpers/helpers'
import { Driver } from '../models/driver.model'
import { TagService } from './tag.service'


@Injectable({
  providedIn: 'root'
})
export class DriverService {

  drivers = new BehaviorSubject<Driver.Driver[]>([]);
  getCurrentDrivers: Observable<Driver.Driver[]> = this.drivers
    .asObservable()
    .pipe(map(drivers => this.tagService.filterDrivers(drivers)));

  setCurrentDrivers(drivers: Driver.Driver[]) {
    const diff = this.drivers.getValue().filter(driver => drivers.find(d => d.id === driver.id) === undefined);
    this.drivers.next([...diff, ...drivers]);
  }

  constructor(public http: HttpClient, private tagService: TagService) {}

  getAll() {
    return this.http.get(`${ env.API_PATH }/api/driver/v1/all`, HEADER_OPTIONS());
  }

  getScoringDrivers() {
    return this.http.get(`${ env.API_PATH }/api/driver/v1/scoring/all`, HEADER_OPTIONS());
  }

}

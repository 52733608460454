import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltersPipe, HighlightSearch, TrafficLightPipe, TendencyPipe, ScoreMetadata, GeoSearch,
  GrayFontColor } from './filters.pipe';

@NgModule({
  declarations: [ FiltersPipe, HighlightSearch, TrafficLightPipe, TendencyPipe, ScoreMetadata, GeoSearch,
    GrayFontColor],
  imports: [
    CommonModule
  ],
  exports: [ FiltersPipe, HighlightSearch, TrafficLightPipe, TendencyPipe, ScoreMetadata, GeoSearch,
    GrayFontColor ]
})
export class generalFiltersPipe { }

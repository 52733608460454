<div class="create-geofence-wrapper" *ngIf="generalService.checkPermission(['admin.fleetrw', 'admin.fleetmgr'])">
  <form class="geofences" name="form" #f="ngForm" novalidate>
    <div class="listHeight">
      <div class="geofence-name">
        <input type="text" name="name" [(ngModel)]="model.name" class="customSearch" 
          placeholder="{{ 'GEOFENCE.GEOFENCE_NAME_INPUT' | translation }}">
        <img *ngIf="model?.name?.length" (click)="model.name = ''"
          class="searchCross" src="assets/img/clearb.png" alt="clearb">
      </div>
      <br>
      <div>
        <mat-slide-toggle name="active" [(ngModel)]="model.active" >Active</mat-slide-toggle>
        <br *ngIf="!model?.vehicles?.length">
        <br *ngIf="!model?.vehicles?.length">
        <div class="small gray" *ngIf="!model?.vehicles?.length">
          {{ 'GEOFENCE.ACTIVE_WITH_VEHICLES' | translation }}
        </div>
      </div>
      <br>
      <span class="Mask">{{'GEOFENCE.CONFIGURATION' | translation }}</span>
      <section class="example-section">
        <div>
          <mat-checkbox type="checkbox" id="geoIn" name="geoIn" (change)="getGeoInvalue($event)" [checked]="geoIn"
            class="Get-in-the-Geocer">{{'GEOFENCE_ENTER' | translation}} <img
              src="assets/img/icons/entrandosmall-activa.svg" alt="entrandosmall">
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox type="checkbox" id="geoOut" name="geoOut" (change)="getGeoOutvalue($event)" [checked]="geoOut"
            class="Get-out-of-the-Geoce">{{'GEOFENCE_EXIT' | translation}}<img
              src="assets/img/icons/saliendosmall-activa.svg" alt="saliendosmall">
          </mat-checkbox>
        </div>
        <mat-checkbox class="Schedules" (change)="getSchedulesvalue($event)" [checked]="model.isScheduled">
          {{'GEOFENCE_SCHEDULES' | translation}}<img src="assets/img/icons/calendarsmall-activo.svg" alt="calendarsmall">
        </mat-checkbox>
        <section>
          <div class="weekdays" *ngIf="model.isScheduled">
            <div class="days">
              <button class="btn-color" (click)="enableDisableRule(0)" [ngClass]="{'selected' : scheduleDays[0]}">{{'MON' |
                translation}}</button>
              <button class="btn-color" (click)="enableDisableRule(1)" [ngClass]="{'selected' : scheduleDays[1]}">{{'TUE' |
                translation}}</button>
              <button class="btn-color" (click)="enableDisableRule(2)" [ngClass]="{'selected' : scheduleDays[2]}">{{'WED' |
                translation}}</button>
              <button class="btn-color" (click)="enableDisableRule(3)" [ngClass]="{'selected' : scheduleDays[3]}">{{'THU' |
                translation}}</button>
              <button class="btn-color" (click)="enableDisableRule(4)" [ngClass]="{'selected' : scheduleDays[4]}">{{'FRI' |
                translation}}</button>
              <button class="btn-color" (click)="enableDisableRule(5)" [ngClass]="{'selected' : scheduleDays[5]}">{{'SAT' |
                translation}}</button>
              <button class="btn-color" (click)="enableDisableRule(6)" [ngClass]="{'selected' : scheduleDays[6]}">{{'SUN' |
                translation}}</button>
            </div>
            <div class="day-time">
              <div class="from">
                <span>{{ 'FROM' | translation }}</span>
                <input type="time" (change)="getStartvalue($event)" [value]="schedulesModel.from" class="form-field-width setTime">
              </div>
              <div class="to">
                <span>{{ 'TO' | translation }}</span>
                <input type="time" (change)="getEndvalue($event)" [value]="schedulesModel.to" class="form-field-width setTime">
              </div>
            </div>
          </div>
        </section>
      </section>
      <h1 class="Vehicles-2">{{'GEOFENCE_VEHICLES' | translation}} ({{ geofenceVehicles?.length }})</h1>
      <app-geofencingvehicles-template (setVehicleList)="setGeofenceVehicles($event)"> </app-geofencingvehicles-template>
    </div>
    <div class="Rectangle1">
      <div class="d-flex">
        <button mat-button class="Rectangle-2-Copy-2" (click)="cancel()">{{'CANCEL' | translation}}</button>
        <button (click)="saveGeofenceData(f.form)" mat-button class="btn_action saveBtn">{{'SAVE' | translation}}</button>
      </div>
    </div>
  </form>
</div>
<div class="container">
  <div class="filter" *ngFor="let filter of filters | filters: findText;">
    <button [disabled]="isFreemium" [ngClass]="{ 'selected': selected.type === filter.type }" mat-stroked-button
      (click)="genDashboard(filter)">{{ filter.type | translation }}</button>
  </div>
  <div class="date-picker" [ngClass]="{
        'date-picker-open': selected.type === 'RANGE_FILTER' ||
        selected.type === 'MONTH_YEAR'
    }">
    <p>
      <span>{{ 'FROM' | translation }}</span>
      <mat-form-field>
        <input class="date-input" disabled matInput [matDatepicker]="picker1" [(ngModel)]="datePicker.from"
          [min]="rangeFrom.min" [max]="rangeFrom.max" (ngModelChange)="genDashboard(selected)">
        <mat-datepicker-toggle disabled="false" matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker startView="multi-year" disabled="false" touchUi #picker1 (monthSelected)="monthSelected($event, selected.type, picker1, 'from')"></mat-datepicker>
      </mat-form-field>
    </p>
    <p *ngIf="selected.type !== 'MONTH_YEAR'">
      <span>{{ 'TO' | translation }}</span>
      <mat-form-field>
        <input class="date-input" disabled matInput [matDatepicker]="picker2" [(ngModel)]="datePicker.to"
          [min]="rangeTo.min" [max]="rangeTo.max" (ngModelChange)="genDashboard(selected)">
        <mat-datepicker-toggle disabled="false" matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker startView="multi-year" disabled="false" touchUi #picker2 (monthSelected)="monthSelected($event, selected.type, picker2, 'to')"></mat-datepicker>
      </mat-form-field>
    </p>
  </div>

  <div class="filter">
    <h4>{{ "LOOKER_DASHBOARD" | translation }}</h4>
    <button *ngIf="tenantId === '5efc0327b3d0c50479fe7f44'" mat-stroked-button (click)="openLooker('info', tenantId)">{{ "LOOKER_ALERTS" | translation }}</button>
    <ng-container *ngFor="let dash of lookerDashboard; first as isFirst">
      <button mat-stroked-button (click)="openLooker('vip', dash.tenant)">{{ dash.tenantName }}</button>
    </ng-container>
  </div>
</div>

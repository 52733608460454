import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { ExtensionsService } from '../../../services/extensions.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-extension-detail',
  templateUrl: './extension-detail.component.html',
  styleUrls: ['./extension-detail.component.css']
})
export class ExtensionDetailComponent implements OnInit {

  integrationId: any = '';
  detail: any;
  details: any;
  logo: any;
  private subscription: Subscription = new Subscription;
  contexts: any[];

  constructor( public generalService: GeneralService,  public es: ExtensionsService ) {
    this.generalService.getWiData.subscribe((data: any) => { 
      this.integrationId = data.id;
      this.details = data.details;
      this.logo = data.logo;
    } );
    this.subscription.add(this.generalService.getWiContext.subscribe(data => this.contexts = data));
   }

  ngOnInit() {
    if (this.integrationId) {
      this.getExtensionDetail(this.integrationId);
    }
  }

  async getExtensionDetail(id: any) {
    const res = await this.generalService.asyncRequest(this.es.getExtensionById(id));
    this.detail = res;
  }

  selectDrivers() {
    const eventName = 'dashcam_driver_list_view';
    this.generalService.logScreenEvents(eventName, eventName, eventName, 'click');
    const context = this.contexts.filter(c => c.context === 'Add ons');
    this.generalService.setWindow({ state: true, extendedState: false, type: 'drivers-list-extensions', contexts: context, data: {id: this.integrationId, details: this.details, logo: this.logo} });
  }
}

<div class="backdrop" #backdrop>
  <div class="container">
    <div class="title">
      <h3>{{ 'VEHICLE_TAGS.TITLE' | translation }}</h3>
    </div>

    <button class="close" (click)="closeFilter()">
      <img alt="filter by tags" src="/assets/img/icons/cerrar.svg">
    </button>

    <div class="styled-autocomplete">
      <ng-autocomplete
        #autocomplete
        [data]="data"
        searchKeyword="name"
        [placeholder]="'VEHICLE_TAGS.PLACEHOLDER' | translation"
        (selected)="selectEvent($event)"
        (inputChanged)="onChangeSearch($event)"
        [itemTemplate]="itemTemplate"
        [notFoundTemplate]="notFoundTemplate">
      </ng-autocomplete>

      <button class="add" [disabled]="!selectedTag" (click)="addChip()" [class.disabled]="!selectedTag">{{ 'VEHICLE_TAGS.BUTTON_1' | translation }}</button>
    </div>

    <div class="hr"></div>

    <div class="chips" *ngIf="chipList">
      <div class="chip" *ngFor="let tag of chipList">
        <button class="chip-close"><img alt="filter by tags" src="/assets/img/icons/cerrar.svg" (click)="removeTag(tag)"></button>
        <p>{{ tag.name }}</p>
      </div>
    </div>

    <button class="save-filters" (click)="save()">
      {{ 'VEHICLE_TAGS.BUTTON_2' | translation }}
    </button>
  </div>

  <!-- Templates -->

  <ng-template #itemTemplate let-item>
    <a [innerHTML]="item.name" class="option"></a>
  </ng-template>

  <ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="notFound"></div>
  </ng-template>
</div>

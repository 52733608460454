<div class="container">
  <h3>{{ 'ADD_DETAILS' | translation }}</h3><br>
  <!-- VEHICLE -->
  <span class="subtitle">{{ 'VEHICLE' | translation }}</span>
  <div class="form-wrapper">
    <div class="vehicle-form">
      <div>
        <span *ngIf="form.device.imei" class="input-name">IMEI</span>
        <input name="imei" [disabled]="true" class="inputs-text imei-field" type="text" placeholder="IMEI"
          [(ngModel)]="form.device.imei">
      </div>

      <div>
        <span *ngIf="form.vehicle.plate" class="input-name">{{ 'ONBOARDING.PLATE' | translation }}</span>
        <input name="plate" class="inputs-text plate-field" type="text" placeholder="{{ 'ONBOARDING.PLATE' | translation }}"
          [(ngModel)]="form.vehicle.plate">
      </div>
    </div>
    <div class="vehicle-form">
      <div class="select-input">
        <span *ngIf="form.vehicle.brandName" class="input-name">{{ 'ONBOARDING.BRAND' | translation }}</span>
        <select name="brand" class="select-style" [class.custom-input]="form.vehicle.brandName === addValue"
          type="text" [(ngModel)]="form.vehicle.brandName" (ngModelChange)="setData('brand'); getModels()">
          <option [value]="undefined">{{ 'ONBOARDING.SELECT_BRAND' | translation }}</option>
          <option [value]="b.name" *ngFor="let b of brands">{{ b.name }}</option>
          <option [value]="addValue">{{ 'ONBOARDING.ADD_NEW' | translation }}</option>
        </select>
      </div>

      <div class="opt-container" *ngIf="form.vehicle.brandName === addValue">
        <span *ngIf="form.vehicle.brandOptional" class="input-name">{{ 'ONBOARDING.BRAND' | translation }}</span>
        <input name="plate" class="inputs-text" type="text" placeholder="{{ 'ONBOARDING.ADD_BRAND' | translation }}"
          maxlength="25" [(ngModel)]="form.vehicle.brandOptional">
      </div>

      <div class="select-input">
        <span *ngIf="form.vehicle.modelName" class="input-name">{{ 'ONBOARDING.MODEL' | translation }}</span>
        <select name="model" class="select-style" type="text" [class.custom-input]="form.vehicle.modelName === addValue"
          [(ngModel)]="form.vehicle.modelName" (ngModelChange)="setData('model');">
          <option [value]="undefined"><p>{{ 'ONBOARDING.SELECT_MODEL' | translation }}</p></option>
          <option [value]="m.name" *ngFor="let m of models">{{ m.name }}</option>
          <option [value]="addValue">{{ 'ONBOARDING.ADD_NEW' | translation }}</option>
        </select>
      </div>
      <div class="opt-container" *ngIf="form.vehicle.modelName === addValue">
        <span *ngIf="form.vehicle.modelOptional" class="input-name">{{ 'ONBOARDING.MODEL' | translation }}</span>
        <input name="plate" class="inputs-text" type="text" placeholder="{{ 'ONBOARDING.ADD_MODEL' | translation }}"
          maxlength="25" [(ngModel)]="form.vehicle.modelOptional">
      </div>

      <div class="select-input">
        <span *ngIf="form.vehicle.year" class="input-name">{{ 'ONBOARDING.YEAR' | translation }}</span>
        <select name="year" class="select-style" type="text" [(ngModel)]="form.vehicle.year"
          (ngModelChange)="setData('year')">
          <option [value]="undefined">{{ 'ONBOARDING.SELECT_YEAR' | translation }}</option>
          <option [value]="y" *ngFor="let y of years">{{ y }}</option>
        </select>
      </div>
    </div>
  </div>

  <!-- DRIVER -->
  <span class="subtitle">{{ 'DRIVER' | translation }}</span> <br>
  <span class="driver-disclosure">
    {{ 'ONBOARDING.DRIVER_DISCLOSURE' | translation }}
  </span>
  <div class="form-wrapper">
    <div class="driver-form">
      <div>
        <span *ngIf="form.user.firstName" class="input-name">{{ 'ONBOARDING.FIRSTNAME' | translation }}</span>
        <input name="firstName" class="inputs-text" type="text" placeholder="{{ 'ONBOARDING.FIRSTNAME' | translation }}"
          [(ngModel)]="form.user.firstName">
      </div>
      <div>
        <span *ngIf="form.user.lastName" class="input-name">{{ 'ONBOARDING.LASTNAME' | translation }}</span>
        <input name="lastName" class="inputs-text" type="text" placeholder="{{ 'ONBOARDING.LASTNAME' | translation }}"
          [(ngModel)]="form.user.lastName">
      </div>
    </div>
    <div class="driver-form">
      <div>
        <span *ngIf="form.user.phone" class="input-name">{{ 'ONBOARDING.PHONE' | translation }}</span>
        <input name="phone" class="inputs-text" type="text" placeholder="{{ 'ONBOARDING.PHONE' | translation }}"
          [(ngModel)]="form.user.phone">
      </div>
      <div>
        <span *ngIf="form.user.email" class="input-name">{{ 'ONBOARDING.EMAIL' | translation }}</span>
        <input name="email" class="inputs-text" type="text" placeholder="{{ 'ONBOARDING.EMAIL' | translation }}"
          [(ngModel)]="form.user.email">
      </div>
    </div>
  </div>
  <div class="actions">
    <button (click)="backToList()"> {{ 'CANCEL' | translation }} </button>
    <button (click)="saveVehicle()"> {{ 'SAVE' | translation }} </button>
  </div>
</div>

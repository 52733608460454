<button class="closeModalButton" (click)="close()"> x </button>
<div class="profile">
  <div class="header">
    <!-- <img *ngIf="data.picture" [src]="data.picture" [alt]="data.name"> -->
    <h3>{{ 'DASHCAM.INPUT_DATA_MODAL' | translation }}</h3>
    <!-- <h5>{{ data?.email }}</h5> -->
  </div>
  <div class="body">
    <div *ngIf="!selectedPath; else videoDisplay">
    <!-- <div> -->
      <div *ngIf="state === 'inputData'">
        <h4>{{ 'DASHCAM.RANGE_TITLE' | translation }}</h4>
        <!-- <h4 style="color: #676666">{{ vehicles.length }}</h4> -->
        <br>
  
        <div class="date-picker">
          <div class="item">
            <span>{{ 'FROM' | translation }}</span>
            <mat-form-field>
              <input class="date-input" disabled matInput [matDatepicker]="picker1" [(ngModel)]="datePicker.from"
                [min]="rangeFrom.min" [max]="rangeFrom.max" (ngModelChange)="genDashboard(selected)">
              <mat-datepicker-toggle disabled="false" matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker startView="multi-year" disabled="false" touchUi #picker1></mat-datepicker>
            </mat-form-field>
          </div>
  
          <div class="item">
            <span>{{ 'TO' | translation }}</span>
            <mat-form-field>
              <input class="date-input" disabled matInput [matDatepicker]="picker2" [(ngModel)]="datePicker.to"
                [min]="rangeTo.min" [max]="rangeTo.max" (ngModelChange)="genDashboard(selected)">
              <mat-datepicker-toggle disabled="false" matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker startView="multi-year" disabled="false" touchUi #picker2></mat-datepicker>
            </mat-form-field>
          </div>
  
          <div class="buttons-wrapper">
            <button class="outlined-button" mat-stroked-button
              (click)="getRangeVideos()">
              {{ 'DASHCAM.GET_EVENTS' | translation }}
            </button>
          </div>
        </div>
      </div>
  
      <table *ngIf="!loadingEvents && events.length > 0">
        <thead>
          <tr>
            <th>{{ 'DASHCAM.EVENT_DATE' | translation }}</th>
            <th>{{ 'DASHCAM.EVENT_TIME' | translation }}</th>
            <th>{{ 'DASHCAM.EVENT_NAME' | translation }}</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
           <tr *ngFor="let event of events">
              <td>{{event.tripDate}}</td>
              <td>{{event.time}}</td>
              <td>{{event.eventName}}</td>
              <!-- <td><button (click)="getSignedUrl(event.filePath)">more</button></td> -->
              <td class="download-section">
                <button
                  *ngIf="!getLinksSpinner[event.filePath]"
                  class="download-button"
                  (click)="downloadSignedUrl(event.filePath)">
                  {{ 'DASHCAM.GET_LINKS' | translation }}
                </button>

                <div class="get-link-spinner" *ngIf="getLinksSpinner[event.filePath]">
                  <mat-spinner></mat-spinner>
                </div>

                <!-- <button
                  *ngIf="!videoUrls[event.filePath] && !getLinksSpinner[event.filePath]"
                  class="download-button"
                  (click)="downloadSignedUrl(event.filePath)">
                  {{ 'DASHCAM.GET_LINKS' | translation }}
                </button>

                <div class="get-link-spinner" *ngIf="getLinksSpinner[event.filePath]">
                  <mat-spinner></mat-spinner>
                </div>
                <a *ngFor="let url of videoUrls[event.filePath]; let i = index" [href]="url" target="_blank">{{'DASHCAM.CAM' | translation}}-{{i + 1}}</a><br /> -->
              </td>
            </tr>
        </tbody>
      </table>

    </div>
    <div *ngIf="loadingEvents" class="loading-events">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>

<!-- TODO: Add Stream when fix .ts problem -->
<ng-template #videoDisplay>
  <div class="container">
    <div class="video-container">
        <div class="main-video">
          <div class="video-player">
            <video
                class="player"
                preload="auto"
                controls
                [src]="selectedVideo"
              >
            </video>
          </div>
          <div class="button-wrapper">
            <button class="outlined-button" (click)="back()">
              <mat-icon>arrow_back_ios</mat-icon>
              <p>Back</p>
            </button>

            <a class="outlined-button" [href]="selectedVideo" target="_blank"><mat-icon>download</mat-icon>
              <p>Download</p>
            </a>
          </div>
        </div>
        <div class="suggested-videos">
            <div *ngFor="let video of getVideos(selectedPath)" class="suggested-video">
              <button (click)="selection(video)" class="suggested-thumbnail">
              </button>
              <!-- <div class="suggested-info">
                  <h3>Vehicle Maintenance Tips</h3>
                  <p>Fleetr Academy</p>
                  <p>45K views • 2 months ago</p>
              </div> -->
            </div>
        </div>
    </div>
  </div>
</ng-template>

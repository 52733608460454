import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GeneralService } from '../../../../services/general.service';

@Component({
  selector: 'app-vehicle-tags-section',
  templateUrl: './vehicle-tags-section.component.html',
  styleUrls: ['./vehicle-tags-section.component.css']
})
export class VehicleTagsSectionComponent {
  @Input() vehicle: any
  @Output() addOpen: EventEmitter<null> = new EventEmitter(null)

  constructor(public gs: GeneralService) {}
  showOptions() {
    this.addOpen.emit()
  }
}

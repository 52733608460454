import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardTemplateComponent } from '../../components/dashboard/dashboard-template/dashboard-template.component';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslationModule } from '../../pipes/translation/translation.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ComparisonTooltipComponent } from './dashboard-template/comparison-tooltip/comparison-tooltip.component';
import { DashboardComponent } from './dashboard.component';
import { generalFiltersPipe } from '../../pipes/generalFiltersPipe.module';

@NgModule({
  declarations: [ DashboardTemplateComponent, ComparisonTooltipComponent, DashboardComponent ],
  imports: [
    CommonModule,
    FormsModule,
    MatToolbarModule, MatIconModule, MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    TranslationModule,
    NgApexchartsModule,
    MatDatepickerModule,
    generalFiltersPipe
  ],
  exports: [ DashboardTemplateComponent, DashboardComponent ]
})
export class SharedDashboardModule { }

<div class="container">
  <h3>{{ 'EDIT_DRIVER' | translation }}</h3><br>
  <div class="driver-form">
    <!-- FIRSTNAME -->
    <div>
      <span class="input-name">
        {{ 'ONBOARDING.FIRSTNAME' | translation }}
      </span>
      <input name="firstName" class="inputs-text" type="text" placeholder="{{ 'ONBOARDING.FIRSTNAME' | translation }}"
        [(ngModel)]="driver.firstName" [disabled]="driver.fromComponent === 'extensions'">
    </div>
    <!-- LASTNAME -->
    <div>
      <span class="input-name">
        {{ 'ONBOARDING.LASTNAME' | translation }}
      </span>
      <input name="lastName" class="inputs-text" type="text" placeholder="{{ 'ONBOARDING.LASTNAME' | translation }}"
        [(ngModel)]="driver.lastName" [disabled]="driver.fromComponent === 'extensions'">
    </div>
    <!-- PHONE -->
    <div>
      <span class="input-name-2">
        {{ 'ONBOARDING.PHONE' | translation }}
      </span>
      <input name="lastName" class="inputs-text" type="text" placeholder="{{ 'ONBOARDING.PHONE' | translation }}"
        [(ngModel)]="driver.phone" [disabled]="driver.fromComponent === 'extensions'">
    </div>
    <!-- EMAIL -->
    <div>
      <span class="input-name-2">
        {{ 'ONBOARDING.EMAIL' | translation }}
      </span>
      <input name="lastName" class="inputs-text" type="text" placeholder="{{ 'ONBOARDING.EMAIL' | translation }}"
        [(ngModel)]="driver.email">
    </div>
  </div>
  <p *ngIf="driver.fromComponent === 'extensions'" style="color: #b8b8b8; font-size:small">
    {{ 'VERIFY_EMAIL' | translation}}
  </p>
  <div class="actions">
    <button [disabled]="pristineCheck()" [class.disabled]="pristineCheck()" (click)="saveDriver()"> {{ 'SAVE' | translation }} </button>
  </div>
</div>

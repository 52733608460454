<div class="performance-container" [ngClass]="{ 'performance-full-width': !showChartsSideBarOn }">
  <div class="types-spinner" *ngIf="!charts.length">
    <mat-progress-spinner diameter="30" [mode]="'indeterminate'"></mat-progress-spinner>
  </div>
  <div class="side-nav" *ngIf="showChartsSideBarOn" (scroll)="onPerformanceScroll($event)">
    <button [ngStyle]="{
      'bottom': '-'+sideBarScroll+'px'
    }" [ngClass]="{'selected': selectedChart.type === { type: 'reports_button' } }"
      class="bottom-buttom"
      (click)="openReports()" mat-raised-button>
      <mat-icon>download</mat-icon>
      {{ 'PERFORMANCE.SEND_REPORT' | translation }}
    </button>
    <button [ngClass]="{'selected': selectedChart.id === chart.id }"
      *ngFor="let chart of charts" class="list-item"
      (click)="goToChart(chart)" mat-raised-button>
      <mat-icon>{{ chart.icon }}</mat-icon>
      {{ chart.alias | translation }}
    </button>
  </div>
  <div class="performance-content">
    <div class="performance-head">
      <p *ngIf="data?.type !== 'reports'" class="text-center">{{ title | translation }}</p>
      <div class="reports-title" *ngIf="data?.type === 'reports'" >
        <h3>{{ 'TRIPS_REPORT' | translation }}</h3>
        <span>{{ 'SELECT_DAY_TO_DOWNLOAD' | translation }}</span>
        <br *ngFor="let br of ['1', '2']">
      </div>
      <app-filters [type]="data?.type"></app-filters>
    </div>
    <div class="charts">
      <app-kpis *ngIf="data?.type === 'stats'"></app-kpis>
      <app-score *ngIf="data?.type === 'score'" [scoreType]="data?.scoreType"></app-score>
    </div>
  </div>
</div>

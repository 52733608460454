<div class="feedback" *ngIf="!hideFeedback">
    <h3>{{ 'HELLO' | translation }} <span class="text-primary">{{ admin?.name }}</span>!</h3>
    <p> {{ 'FEEDBACK_MSG' | translation }} <a href="mailto:support@fleetr.com">support@fleetr.com</a>
        <span *ngIf="feedbackPhone">
            / <a href="tel:{{ feedbackPhone }}">{{ feedbackPhone }}</a>
        </span>
    </p>
    <p>{{ 'THANKS' | translation }}.</p>
    <form class="form">
        <mat-form-field class="full-width">
            <textarea name="message" [(ngModel)]="message" matInput rows="10"
                [placeholder]="translation.transform('PLACEHOLDER_MSG')"></textarea>
        </mat-form-field>
        <button class="background-primary text-white" mat-raised-button
            (click)="alert()">{{ 'SEND' | translation }}</button>
    </form>
</div>

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { environment as env } from '../../environments/environment'

type LookerReport = {
  automaticReporting: boolean
  dashboard: boolean
  gdriveRootFolder: string
  modif: string
  nAncestors: string[]
  parent: any
  parentFolder: any
  tenant: string
  tenantFolder: string
  tenantName: string
}

type LookerApiResponse = {
  result: 'success' | 'error'
  reporting: LookerReport
}

@Injectable({
  providedIn: 'root'
})
export class LookerService {

  private dashboardSubject: BehaviorSubject<LookerReport[]> = new BehaviorSubject([])

  currentTenants = []

  get dashboard$() {
    return this.dashboardSubject.asObservable()
  }
  constructor(private http: HttpClient) { }

  loadDashboard(tenants: []) {
    const promises = []
    this.currentTenants = tenants
    for (let tenant of tenants) {
      promises.push(this.http.get(`${env.API_PATH}/api/tenant/v1/${tenant}/reportingconfig`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('id')}`
        }),
      }).toPromise())
    }

    Promise.all(promises).then((data: LookerApiResponse[]) => {
      const lookerArr = []
      for (let response of data) {
        if (response.result !== 'error') {
          if (response.reporting && response.reporting.dashboard) {
            lookerArr.push(response.reporting)
          }
        }
      }
      this.dashboardSubject.next(lookerArr)
    })
  }

  getNameByTenantId(tenantId: string): string | undefined {
    const currentDashboards = this.dashboardSubject.getValue()
    const item = currentDashboards.find(i => i.tenant === tenantId)
    return item ? item.tenantName : undefined
  }

}

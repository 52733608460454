import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { trimmedLang } from '../../../helpers/helpers';
import { GeneralService } from '../../../services/general.service';
import { RequestService } from '../../../services/request.service';

const getConfig = (configs, configType) =>
  configs.find( c => c.type === configType) || {};

const parseOverspeedDT = (overspeeed: any = {}) => {
  return {
    toleranceOverSpeedLimit: overspeeed.toleranceOverSpeedLimit,
    durationThreshold: (overspeeed.durationThreshold || 0) / 1000
  };
};

@Component({
  selector: 'app-fleet-configuration',
  templateUrl: './fleet-configuration.component.html',
  styleUrls: ['./fleet-configuration.component.css']
})

export class FleetConfigurationComponent implements OnInit {

  @Output() backToProfile = new EventEmitter<string>();
  @Input('admin') admin;
  timeFormats: any[] = [
    { label: 'FLEET_CONFIGURATION.12_HOURS', type: '12' },
    { label: 'FLEET_CONFIGURATION.24_HOURS', type: '24' }
  ];
  timezones: any[];
  unitSystems: any[] = [ 'metric', 'customaryUS', 'imperialUK'];
  weekDays: any[] = moment.localeData(trimmedLang()).weekdays()
    .map((wd, i) => Object.assign({}, { day: wd.substring(0, 3).toLocaleLowerCase(), number: i }));
  configs: any;
  selectedConfigs: any = {};
  durationOptions: number[] = new Array(56).fill(0).map((_, idx) => idx + 5)
  toleranceOptions: number[] = new Array(50).fill(0).map((_, idx) => idx + 5)

  constructor(
    private rs: RequestService,
    private gs: GeneralService
  ) {
  }

  ngOnInit() {
    this.gs.getFleetConfig.subscribe(configs => {
      this.configs = configs;
      this.selectedConfigs.unitSystem = getConfig(configs, 'admin:unitSystem').configuration.value;
      this.selectedConfigs.timezone = getConfig(configs, 'admin:timezone').configuration.value;
      this.selectedConfigs.schedules = getConfig(configs, 'fleet:workingHours').configuration.schedules;
      this.selectedConfigs.overspeed = parseOverspeedDT(getConfig(configs, 'notification:overspeed').configuration) || {};
    });
    this.getTimeZones();
  }

  async getTimeZones() {
    this.timezones = await this.rs.request({
      method: 'GET',
      endpoint: 'get_timezones',
      country: this.admin.country
    });
  }

  back() {
    this.backToProfile.emit('general');
  }

  checkIfDaySelected(sIndex, wd) {
    return this.selectedConfigs.schedules[ sIndex ].days.some(d => d === wd.number);
  }

  setDay(sIndex, wd) {
    const exists = this.selectedConfigs.schedules[ sIndex ].days.some(d => d === wd.number);
    if ( exists ) {
      this.selectedConfigs.schedules[ sIndex ].days = this.selectedConfigs.schedules[ sIndex ].days
        .filter(d => d !== wd.number);
    } else {
      this.selectedConfigs.schedules[ sIndex ].days.push(wd.number);
    }
    this.prepareConfig('fleet:workingHours');
  }

  removeSchedule(sIndex) {
    this.selectedConfigs.schedules = this.selectedConfigs.schedules.filter((_: any, i: number) => i !== sIndex);
  }

  addSchedule() {
    this.selectedConfigs.schedules.push({
      days: [],
      from: '',
      to: ''
    });
  }

  prepareConfig( configType: any = {} ) {
    const config = getConfig(this.configs, configType);
    const updateStrategy = {
      'admin:unitSystem': _ => config.configuration.value = this.selectedConfigs.unitSystem,
      'admin:timezone': _ => config.configuration.value = this.selectedConfigs.timezone,
      'fleet:workingHours': _ => config.configuration.schedules = this.selectedConfigs.schedules,
      'notification:overspeed': _ => {
        this.selectedConfigs['overspeed']['toleranceOverSpeedLimit'] =
          checkForNegatives(this.selectedConfigs['overspeed']['toleranceOverSpeedLimit']);
        this.selectedConfigs['overspeed']['durationThreshold'] =
          checkForNegatives(this.selectedConfigs['overspeed']['durationThreshold']);
        const { durationThreshold, toleranceOverSpeedLimit } = this.selectedConfigs.overspeed;
        config.configuration = { durationThreshold: (durationThreshold * 1000), toleranceOverSpeedLimit };
      },
    };
    updateStrategy[ configType ]();
    this.delayAndSave(config);
  }

  async delayAndSave(config) {
    if (typeof window['LIT'] !== 'undefined') { clearTimeout(window['LIT']); }
    window['LIT'] = setTimeout(() => this.saveConfig(config), 1500);
  }

  async saveConfig(config) {
    const res = await this.rs
      .request({
        method: 'PUT',
        endpoint: 'get_set_config',
        tenantId: this.admin.tenants[0],
        body: [config]
      });
    if ( res.error ) { return this.gs.openAlert({ msg: 'ERROR', error: true }); }
    this.gs.openAlert({ msg: 'FLEET_CONFIGURATION.FLEET_CONFIGURATION_SAVED', duration: 3000 });
  }

}

const checkForNegatives = number => ( number < 0 ) ? 0 : number;

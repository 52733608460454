<div class="actionpopuup" *ngIf="!data.higherOdometerReading">
  <h3>{{ 'MAINTENANCE.ATTENTION' | translation }}</h3> 
  <span>{{ 'MAINTENANCE.HIGHER_ODOMETER_EDIT_ALERT' | translation }}</span>
</div>

<div class="actionpopuup" *ngIf="data.higherOdometerReading">
  <h3>{{ 'MAINTENANCE.ATTENTION' | translation }}</h3> 
  <span>{{ 'MAINTENANCE.HIGHER_ODOMETER_EREADING' | translation }}</span>
  <div class="actions">
    <button mat-raised-button class="outlined-button" type="button" (click)="dialogRef.close({ confirm: true })"> {{ 'YES' | translation }} </button>
    <button mat-raised-button class="outlined-button" type="button" [mat-dialog-close]="false"> {{ 'NO' | translation }} </button>
  </div>
</div>
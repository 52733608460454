import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-validate-odometer-update',
  templateUrl: './validate-odometer-update.component.html',
  styleUrls: ['./validate-odometer-update.component.css']
})
export class ValidateOdometerUpdateComponent {
  @Input('dialogRef') dialogRef: any;
  @Input('data') data: any;

  constructor() {
    // empty constructor
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-extensions-list',
  templateUrl: './extensions-list.component.html',
  styleUrls: ['./extensions-list.component.css']
})
export class ExtensionsListComponent implements OnInit {

  @Input() isFreemium: boolean;
  @Input() extensions: Array<any>;
  private subscription: Subscription = new Subscription;
  contexts: any[];

  constructor(
    public gs: GeneralService,
  ) {
    this.subscription.add(this.gs.getWiContext.subscribe(data => this.contexts = data));
  }

  ngOnInit(): void {
    //This is intentional
  }

  showDetail(extension) {
    this.gs.logScreenEvents('add_ons_dashcam_view', extension.name, extension._id, 'click');
    const context = this.contexts.filter(c => c.context === 'Add ons');
    this.gs.setWindow({ state: true, extendedState: false, type: 'extension-detail', contexts: context, data: {id: extension._id, details: extension.details, logo: extension.logo} });
  }

}

import { Component, OnDestroy } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import _ from 'lodash'
import moment from 'moment'
import { BehaviorSubject, Subject, Subscription } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { setTimeToMidnight } from '../../../helpers/helpers'
import { KpisService } from '../../../services/kpis.service'
import { PerformanceService } from '../../../services/performance.service'

declare const Plotly;
declare const ApexCharts;

@Component({
  selector: 'app-kpis',
  templateUrl: './kpis.component.html',
  styleUrls: ['./kpis.component.css'],
})
export class KpisComponent implements OnDestroy {
  destroy$: Subject<void> = new Subject();
  subscription$: Subscription = new Subscription()
  years: any;
  chart: any;
  chartSubject: BehaviorSubject<string> = new BehaviorSubject(null)
  drivers: any;
  driverId: string;
  apexStarted: boolean;
  ngUnsubscribe = new Subject<void>();
  maxDate = new Date(new Date().setDate(new Date().getDate() - 1));

  constructor(
    private ps: PerformanceService,
    public dialog: MatDialog,
    public kpisService: KpisService
  ) {
    this.subscription$ = this.ps.getCurrentChart
      .pipe(filter(chart => chart && chart.id ))
      .subscribe((chart: any) => {
        this.destroy$.next();
        this.destroy$.complete();

        this.chartSubject.next(chart);
        this.chart = chart;
        this.getChart(chart, this.years, this.driverId);

        this.ps.getDriverIdFilter.pipe(takeUntil(this.destroy$)).subscribe((driverId: any) => {
          this.driverId = driverId;
          this.getChart(chart, this.years, driverId);
        });

        this.ps.getYears.pipe(takeUntil(this.destroy$)).subscribe((years: any) => {
          this.years = years;
          this.getChart(chart, years, this.driverId);
        });
      }
    );
  }

  ngOnDestroy() {
    this.apexStarted = false;
    this.cleanChart();
    this.destroy$.next();
    this.destroy$.complete();
    this.subscription$.unsubscribe();
  }

  getChart(chart, years, driverId) {
    if (!chart || !driverId || !years.start) { return; }
    const filter = this.ps.encodeQueryParam({
      start: setTimeToMidnight(years.start).getTime(),
      end: setTimeToMidnight(years.end).getTime() + 86400000,
      group: ( driverId || '' ).replace('null', '')
    });

    this.kpisService.getDataChart(chart.id, filter)
      .subscribe(data => {
        this.generateGraph({ chart, data })
      });
  }

  cleanChart() {
    const charEl = document.querySelector('#graph');
    if ( !charEl ) { return; }
    document.querySelector('#graph').innerHTML = '';
  }

  generateGraph(chart) {
    const chartHandlers = {
      apex: () => {
        this.cleanChart();
        this.apexStarted = false;

        this.handleApex(chart);
      },
      plotly: () => {
        this.cleanChart();
        this.handlePlotly(chart);
      }
    };
    return chartHandlers[chart.chart.handler]();
  }

  async handleApex(prop) {
    const chart = _.cloneDeep(prop);

    if (this.apexStarted ) { return; }

    const conf = {...chart.chart.layout, series: chart.data.data};

    conf.tooltip = conf.tooltip || {};
    conf.tooltip.x = conf.tooltip.x || {};

    const tooltipXHandler = _.get(prop, 'chart.layout.tooltip.x.formatter.handler');
    // conf.tooltip.x.formatter = function (val) {
    //   return formatHandlers[tooltipXHandler] ?
    //     formatHandlers[tooltipXHandler](val).format(tooltipXFormatter) : val;
    // };

    conf.tooltip.x.formatter = function (val) {
      return tooltipXHandler === 'moment' ? moment(val).utcOffset(0).format('DD/MM/YYYY') : val;
    };
    while (!document.querySelector('#graph')) {
      await new Promise(r => setTimeout(r, 200));
    }

    const apex = new ApexCharts(document.querySelector('#graph'), conf);
    apex.render();
    this.apexStarted = true;
  }

  handlePlotly(chart) {
    Plotly.newPlot('graph', chart.data.data, chart.chart.layout, Object.assign({}, chart.chart.config, {
      displaylogo: false
    }));
  }

  toggleNav(chart) {
    setTimeout(() => {
      if (!chart) { return; }
      this.generateGraph(chart);
    }, 300);
  }

}

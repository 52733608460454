import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KpisService } from '../../../services/kpis.service';
import { translationPipe } from '../../../pipes/translation/translation.pipe';
import { ActivatedRoute } from '@angular/router';
import { PerformanceService } from '../../../services/performance.service';

declare const ApexCharts;

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.css']
})
export class ScoreComponent implements OnInit, OnDestroy {

  @Input('scoreType') scoreType;
  years: any;
  chart: any;
  drivers: any;
  driverId: string;
  apexStarted: boolean;
  maxDate: any;
  translation: any = translationPipe;
  constructor(
    private ps: PerformanceService,
    public dialog: MatDialog,
    public kpisService: KpisService,
    public route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    const years = {
      start: new Date(Date.now() - 30 * 86400000),
      end: new Date(Date.now() - 86400000)
    };
    this.ps.setYears(years);
    this.ps.getYears.subscribe((y: any) => this.loadChart(this.scoreType, y));
  }

  ngOnDestroy() {
    this.apexStarted = false;
    this.cleanChart();
  }

  loadChart(scoreType, years) {
    const start = new Date(`${ new Date(years.start).toISOString().substr(0, 10)}T00:00:00.000Z`).getTime();
    const end = new Date(`${ new Date (years.end).toISOString().substr(0, 10)}T00:00:00.000Z`).getTime();
    this.kpisService
      .getHistoricScore(start, end, scoreType)
      .subscribe(data => {
        this.generateGraph(data);
        this.ps.setCurrentChart(data);
      });
  }

  cleanChart() {
    const charEl = document.querySelector('#score-graph');
    if ( !charEl ) { return; }
    document.querySelector('#score-graph').innerHTML = '';
  }

  generateGraph(data) {
    this.cleanChart();
    this.apexStarted = false;
    this.handleApex(data);
  }

  handleApex(data) {
    if ( this.apexStarted ) { return; }
    this.apexStarted = true;
    const conf = data.layout;
    conf.series = data.data;
    conf.chart.height = window.innerHeight - 300;
    conf.tooltip = conf.tooltip || {};
    conf.tooltip.x = conf.tooltip.x || {};
    conf.tooltip.y = conf.tooltip.y || {};
    conf.tooltip.y.formatter = val => val + ' pts';
    const apex = new ApexCharts(document.querySelector('#score-graph'), conf);
    apex.render();
  }

}

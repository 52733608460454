import { Component } from '@angular/core';
import { GeneralService } from '../../../services/general.service';

@Component({
  selector: 'app-stats-template',
  templateUrl: './stats-template.component.html',
  styleUrls: ['./stats-template.component.css']
})
export class StatsTemplateComponent {
  data: any;
  constructor(
    private gs: GeneralService
  ) {
    this.gs.getWiData.subscribe( data => {
      this.data = data;
    });
  }
}

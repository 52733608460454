import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { fEventNameConventions } from '../../helpers/helpers'
import { GeneralService } from '../../services/general.service'
import { KpisService } from '../../services/kpis.service'
import { PerformanceService } from '../../services/performance.service'
import { ReportsModalComponent } from '../reports-modal/reports-modal.component'

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.css']
})
export class PerformanceComponent implements OnInit {
  @Input('data') data;
  charts: any = [];
  sideNav: any;
  years: any;
  driverId: string;
  selectedChart: any = {};
  title: string;
  showChartsSideBarOn: boolean;
  reportsString = 'reports';
  sideBarScroll: number;
  constructor(
    private ks: KpisService,
    public gs: GeneralService,
    private ps: PerformanceService,
    public dialog: MatDialog,
  ) {
    this.ks.getCharts().subscribe((c: any) => {
      this.charts = c['charts'].sort((a, b) => a.position - b.position)
        .map((chart: any) => Object.assign({}, chart, { type: 'stats' }));

      if ( this.showChartsSideBarOn ) {
        this.goToChart(this.charts[0]);
      }
    });
  }

  ngOnInit() {
    this.showChartsSideBar();
    this.getTitle();
  }

  goToChart(chart) {
    const eventName = fEventNameConventions['performance'][chart.id] || 'module_performance_chart';
    this.gs.logScreenEvents(eventName, chart.alias, chart.id, 'click');
    this.selectedChart = chart;
    this.data.type = chart.type;
    this.ps.setCurrentChart(chart);
  }

  openReports() {
    this.gs.logScreenEvents('module_performance_report', 'module_performance_report', 'module_performance_report', 'click');
    this.dialog.open(ReportsModalComponent, {
      panelClass: ['general-modal', 'reports-modal'],
      width: '500px',
      data: {}
    });
  }

  showChartsSideBar() {
    const showOn = ['stats'];
    this.showChartsSideBarOn = showOn.some(s => this.data.type === s);
  }

  onPerformanceScroll(event) {
    this.sideBarScroll = event.target.scrollTop || 0;
  }

  getTitle() {
    const titles = {
      stats: _ => {
        return 'STATS';
      },
      score: ({ scoreType }) => {
        return `DRIVER_RANKING_${ scoreType }`;
      },
      reports: () => {
        return 'REPORTS';
      }
    };
    this.title = titles[ this.data.type ](this.data);
  }

}

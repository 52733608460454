export class GeoModel {
    _id: string;
    name: string;
    tenantId: string;
    appId: string;
    geometry: Geometry;
    modif: Date;
    timeConditions: TimeConditions;
    groups: any;
    day: any[];
    vehicles: any[];
    schedules: Schedules;
    when: string;
    active: boolean;
    isScheduled = false;
}

export class Geometry {
    coordinates: any[];
    type: string;
    radius: string;
}

export class TimeConditions {
    from: string;
    to: string;
}

export class Schedules {
    day: any;
    from: string;
    to: string;
}

export class GeoEditModel {
    _id: string;
    name: string;
    tenantId: string;
    appId: string;
    geometry: Geometry;
    modif: Date;
    timeConditions: TimeConditions;
    groups: any;
    day: any[];
    vehicle: any[];
    when: string;
    active: boolean;
    isScheduled: boolean;
}


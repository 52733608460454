import { Component, Input } from '@angular/core';
import { GeneralService } from '../../../../services/general.service';
import { RequestService } from '../../../../services/request.service';

@Component({
  selector: 'app-delete-admin',
  templateUrl: './delete-admin.component.html',
  styleUrls: ['./delete-admin.component.css']
})
export class DeleteAdminComponent {
  @Input('dialogRef') dialogRef: any;
  @Input('data') data: any;
  loading = false;

  constructor(
    private rs: RequestService,
    private gs: GeneralService
  ) { }

  async deleteAdmin() {
    this.loading = true;
    const edit: any = await this.rs.request({
      method: 'DELETE',
      endpoint: 'delete_admin',
      id: this.data.user.id
    });
    if (edit.error) {
      this.gs.openAlert({error: true, msg: 'ADMIN_CONFIGURATION.ERROR_DELETE_ADMINS'});
      this.loading = false;
      return this.close();
    }
    this.gs.openAlert({msg: 'ADMIN_CONFIGURATION.SUCCESS_DELETE_ADMINS'});
    this.loading = false;
    this.close(true);
  }

  close(deleted = false) {
    this.dialogRef.close(deleted);
  }

}

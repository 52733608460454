import { Pipe, PipeTransform } from '@angular/core';
import { timeAgo } from '../helpers/helpers';
import { translationPipe } from './translation/translation.pipe';
// entity filter
@Pipe({ name: 'filters'})
export class FiltersPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return filterPipe.transform(value, args);
  }

}

// entity filter
@Pipe({ name: 'trafficLight'})
export class TrafficLightPipe implements PipeTransform {

  transform(value: any, arg?: any): any {
    try {
      if (value.length && typeof arg  === 'string') {
        return value.filter(n => {
          return n.trafficLight === arg;
        });
      }

      return value;
    } catch (error) {
      console.log('TrafficLightPipe. filter error: ', error);
    }
  }
}

// entity tendency
@Pipe({ name: 'tendency'})
export class TendencyPipe implements PipeTransform {

  transform(value: any): any {
    const base = '/assets/img';
    const extension = 'svg';
    try {
      const tendencies = {
        down: 'tendency-down',
        middle: 'tendency-flat-yellow',
        up: 'tendency-up',
        unknown: 'tendency-flat-gray',
      };
      return `${ base }/${ tendencies[ value ] }.${ extension }`;
    } catch (error) {
      console.log('TendencyPipe. filter error: ', error);
    }
  }
}

// score metadata
@Pipe({ name: 'scoreMetadata'})
export class ScoreMetadata implements PipeTransform {

  transform(value: any, arg): any {
    try {
      const { scoreVariable, scoreType } = value;
      const scoreSelected = scoreVariable || scoreType;
      return (scoreSelected || {})[arg];
    } catch (error) {
      console.log('ScoreMetadata. filter error: ', error);
    }
  }
}

const filterPipe = {
  transform: (value, args) => {
    try {
      if (value && typeof args  === 'string') {
        return value.filter(n => {
          return (n.label && isFunction(n.label) && n.label(n.type).toLowerCase().indexOf(args.toLowerCase()) > -1) ||
            (n.name && n.name.toLowerCase().indexOf(args.toLowerCase()) > -1) ||
            (n.lastName && n.lastName.toLowerCase().indexOf(args.toLowerCase()) > -1) ||
            (n.firstName && n.firstName.toLowerCase().indexOf(args.toLowerCase()) > -1) ||

            (n.title && n.title.toLowerCase().indexOf(args.toLowerCase()) > -1) ||
            (n.description && n.description.toLowerCase().indexOf(args.toLowerCase()) > -1) ||

            (n.brand && n.brand.toLowerCase().indexOf(args.toLowerCase()) > -1) ||
            (n.model && n.model.toLowerCase().indexOf(args.toLowerCase()) > -1) ||
            (n.year && n.year.toString().toLowerCase().indexOf(args.toString().toLowerCase()) > -1) ||
            (n.plate && n.plate.toString().toLowerCase().indexOf(args.toString().toLowerCase()) > -1) ||

            (n.search && n.search.toString().toLowerCase().indexOf(args.toString().toLowerCase()) > -1) ||
            (n.type && n.type.toString().toLowerCase().indexOf(args.toString().toLowerCase()) > -1) ||
            (n.status && n.status.toString().toLowerCase().indexOf(args.toString().toLowerCase()) > -1);
        });
      }

      if (args && args.timeAgo) {
        return timeAgo(args.timeAgo);
      }

      if (args && args.noDecimal) {
        return parseInt(value);
      }

      return value;
    } catch (error) {
      console.log('ScoreMetadata.filterPipe filter error: ', error);
    }
  }
};

const isFunction = fn => fn && {}.toString.call(fn) === '[object Function]';

export {
  filterPipe
};

// highlight filter
@Pipe({ name: 'highlight' })
export class HighlightSearch implements PipeTransform {

  transform(value: any, args: any): any {
    if (!args) { return value; }
    const re = new RegExp(args, 'gi');
    return value ? value.replace(re, '<mark>$&</mark>') : '';
  }
}

@Pipe({ name: 'grayFontColor' })
export class GrayFontColor implements PipeTransform {

  transform(value: any, arg: any): any {
    if (!arg) { return value; }
    const translation = translationPipe.transform(arg);
    const re = new RegExp(translation, 'gi');
    return value ? value.replace(re, '<span class="gray">$&</span>') : '';
  }
}

// geosearch
@Pipe({
  name: 'geoSearch'
})

export class GeoSearch implements PipeTransform {

  transform(value: any, args: any): any {
    if (!value) { return []; }
    if (!args) { return value; }
    return value.filter(item => {
      return Object.keys(item).some(key => {
        return String(item[key]).toLowerCase().includes(args.toLowerCase());
      }) ||
      (item.brand && item.model && (`${ item.brand } ${ item.model }`).toLowerCase().indexOf(args.toLowerCase()) > -1);
    });
	}
}


<div class="form">
  <div class="header">
    <button class="back-to-list" mat-icon-button (click)="back()">
      <mat-icon mat-list-icon>arrow_back_ios</mat-icon>
    </button>
    <div class="titles">
      <div class="title">
        {{ 'FLEET_CONFIGURATION.TITLE' | translation }}
      </div>
    </div>
  </div>
  <div class="configs">
    <br>
    <!-- metric system -->
    <div>
      <span class="label"> {{ 'FLEET_CONFIGURATION.METRIC_SYSTEM' | translation }} </span><br>
      <mat-radio-group [(ngModel)]="selectedConfigs.unitSystem" (ngModelChange)="prepareConfig('admin:unitSystem')">
        <mat-radio-button [ngClass]="{
          'selected': unitSystem === us
        }" *ngFor="let us of unitSystems" [value]="us">{{ us }}</mat-radio-button>
      </mat-radio-group>
      <br>
    </div>
    <!-- timezone -->
    <div>
      <span class="label"> {{ 'FLEET_CONFIGURATION.TIME_ZONE' | translation }} </span><br>
      <div class="select-item">
        <select [(ngModel)]="selectedConfigs.timezone" (ngModelChange)="prepareConfig('admin:timezone')">
          <option [value]="null" disabled selected>{{ 'FLEET_CONFIGURATION.TIME_ZONE_SELECT' | translation }}</option>
          <option *ngFor="let tz of timezones" [value]="tz.id"> {{ tz.name }} </option>
        </select>
      </div>
      <br><br>
    </div>
    <!-- overspeed -->
    <div>
      <span class="label"> {{ 'FLEET_CONFIGURATION.OVERSPEED' | translation }} </span><br>
      <div class="overspeed-input">
        <div>
          <span class="small">{{ 'FLEET_CONFIGURATION.OVERSPEED_DT' | translation }}</span><br>
          <select [(ngModel)]="selectedConfigs.overspeed.durationThreshold" (ngModelChange)="prepareConfig('notification:overspeed')">
            <option [value]="null" disabled selected>{{ 'FLEET_CONFIGURATION.OVERSPEED_DT' | translation }}</option>
            <option *ngFor="let val of durationOptions" [value]="val"> {{ val }} </option>
          </select>
        </div>
        <div class="overspeed-tol">
          <div class="info-overspeed"
            #tooltip="matTooltip"
            matTooltipClass="overspeed-tooltip"
            [matTooltip]="'FLEET_CONFIGURATION.OVERSPEED_TOL_INFO' | translation">
            <mat-icon>info</mat-icon>
          </div>
          <span (click)="tooltip.show()" class="small">{{ 'FLEET_CONFIGURATION.OVERSPEED_TOL' | translation }}</span><br>
          <select [(ngModel)]="selectedConfigs.overspeed.toleranceOverSpeedLimit" (ngModelChange)="prepareConfig('notification:overspeed')">
            <option [value]="null" disabled selected>{{ 'FLEET_CONFIGURATION.OVERSPEED_TOL' | translation }}</option>
            <option *ngFor="let val of toleranceOptions" [value]="val"> {{ val }} </option>
          </select>
        </div>
      </div>
      <br><br>
    </div>
    <!-- schedules -->
    <div>
      <span class="label"> {{ 'FLEET_CONFIGURATION.FLEET_SCHEDULES' | translation }} </span><br>
      <div class="schedule-card" *ngFor="let schedule of selectedConfigs.schedules; let sIndex = index">
        <div class="remove-item" (click)="removeSchedule(sIndex)">
          <img src="assets/img/x.svg" alt="">
        </div>
        <span class="schedule-label"> {{ 'FLEET_CONFIGURATION.CUSTOM_SCHEDULE' | translation }} </span><br>
        <div class="weekDays">
          <div [ngClass]="{
            'day-selected': checkIfDaySelected(sIndex, wd)
          }" *ngFor="let wd of weekDays" (click)="setDay(sIndex, wd)">{{ wd.day }}</div>
        </div>
        <div class="set-hours">
          <div class="hour-box">
            <span>{{ 'FROM' | translation }}</span>
            <input class="hour-picker" type="time"
              [(ngModel)]="selectedConfigs.schedules[ sIndex ].from"
              (ngModelChange)="prepareConfig('fleet:workingHours')">
          </div>
          <div class="hour-box">
            <span>{{ 'TO' | translation }}</span>
            <input class="hour-picker" type="time"
              [(ngModel)]="selectedConfigs.schedules[ sIndex ].to"
              (ngModelChange)="prepareConfig('fleet:workingHours')">
          </div>
        </div>
      </div>
      <div class="add-schedule" (click)="addSchedule()">＋</div>
      <br>
    </div>
  </div>
</div>

import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { Subscription } from 'rxjs';
import { GeofencesService } from '../../../services/geofences.service';
import { MatDialog } from '@angular/material/dialog';
import { GeneralModalComponent } from '../../../components/general-modal/general-modal.component';
import { RequestService } from '../../../services/request.service';
import { translationPipe } from '../../../pipes/translation/translation.pipe';
import { timeOut } from '../../../helpers/helpers';

@Component({
  selector: 'geofence-template',
  templateUrl: './geofence-template.component.html',
  styleUrls: ['./geofence-template.component.css']
})
export class GeofenceTemplateComponent implements OnInit {
  private suscription: Subscription = new Subscription;
  @Input('geofence') geofence: any;
  @Input('isFreemium') isFreemium: boolean;
  contexts: any;
  loadComp: string;
  geofenceClick = false;
  @Output('runEntities') runEntities = new EventEmitter<any>();
  sort: any;
  isVisible: boolean;
  wiType: string;
  constructor(
    public generalService: GeneralService,
    private rs: RequestService,
    public gs: GeofencesService,
    public dialog: MatDialog,
  ) {
    this.generalService.getWiContext.subscribe((data: any[]) => {
      this.contexts = data;
    });
  }

  ngOnInit() {
    localStorage.removeItem('vehicleId');
    localStorage.removeItem('latlong');
    localStorage.removeItem('mapType');
    localStorage.removeItem('geoId');
    localStorage.removeItem('radius');
  }

  ngOnDestroy() {
    this.suscription.unsubscribe();
  }

  openExtension() {
    this.generalService.setWindow({ state: true, extendedState: true, type: 'list', contexts: this.contexts });
  }

  async openGeoDetails(geoId, evt) {
    if ( this.isFreemium ) { return; }
    const { className } = evt.target;
    const avoidClickOn = [ 'mat-slide-toggle-thumb', 'action-button' ];
    const notDetail = avoidClickOn.some( a => className.indexOf(a) > -1);
    if ( notDetail ) { return; }
    localStorage.setItem('geoId', geoId);
    const context = this.contexts.filter((c) =>
      /geofences/.test(c.context)
    );
    this.setWindow(context, 'list');
    await this.generalService.timeOut(500);
    this.setWindow(context, 'create_geofences');
    this.generalService.logScreenEvents('geofence_click_edit', 'geofence_click_edit', 'geofence_click_edit', 'click');
  }

  setWindow(context, type) {
    this.generalService.setWindow({
      state: true,
      extendedState: false,
      type,
      contexts: context,
      data: null,
    });
  }

  async markStatus(id, event) {
    this.geofence.active = event.checked;
    if ( !this.geofence.vehicles.length && this.geofence.active ) {
      this.generalService.openAlert({
        msg: translationPipe.transform('GEOFENCE.NO_VEHICLES_ERROR'),
        error: true
      });
      await timeOut(100);
      this.geofence.active = false;
      return;
    }
    const update = await this.rs.request({
      method: 'PATCH',
      endpoint: 'update_active_inactive',
      id,
      body: { active: this.geofence.active }
    });
    if (update.error) {
      return this.generalService.openAlert({
        msg: update.error,
        error: true
      });
    }
    this.generalService.openAlert({
      msg: translationPipe.transform('GEOFENCE.STATUS_UPDATED')
    });
  }

  openEditMode(id) {
    this.openGeneralModal('delete_geofence', id);
  }

  openGeneralModal(type, id) {
    const gid = id;
    const dialogRef = this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '350px',
      data: { gid, type }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.runEntities.emit(true);
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment as env } from '../../environments/environment';
import { HEADER_OPTIONS } from '../helpers/helpers';

const notificationGranted = 'Notification' in window ? Notification.permission === 'granted' : false;

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(public http: HttpClient) {
  }

  unread = new BehaviorSubject<number>(null);
  getUnreadNotifications = this.unread.asObservable();

  notificationsEnabled = new BehaviorSubject<any>(notificationGranted);
  getNotificationsEnabled = this.notificationsEnabled.asObservable();

  silenceAlerts = new BehaviorSubject<any>(notificationGranted);
  getSilenceAlerts = this.silenceAlerts.asObservable();

  notificationFilter = new BehaviorSubject<any>({});
  getNotificationFilter = this.notificationFilter.asObservable();
  setUnreadNotifications(data: number) { this.unread.next(data); }
  setNotificationsEnabled(data: boolean) { this.notificationsEnabled.next(data); }
  setSilenceAlerts(data: boolean) { this.silenceAlerts.next(data); }
  setNotificationFilter(data: boolean) { this.notificationFilter.next(data); }

  categoryTypes() {
    return {
      alert: ['HIGH_SPEED', 'HIGH_SPEED_CONTEXTUAL', 'MECANICAL_ALERTS', 'OUT_SCHEDULE', 'DRIVING_LIMIT',
        'ON_OFF_ALERTS', 'GEOFENCES', 'VEHICLE_MAINTENANCE', 'POSSIBLE_CRASH', 'IDDLE_TIME', 'DX_TIME', 'DRIVING_TIME',
        'LONG_TRIPS'],
      notification: ['NEW_FEATURES', 'SUGGESTIONS', 'EXPIRING_RENOVATION']
    };
  }

  categoryTabs() {
    return [
      { name: 'NOTICATEGORIES.ALL', category: 'all' },
      { name: 'NOTICATEGORIES.NOTIFICATIONS', category: 'notification' },
      { name: 'NOTICATEGORIES.ALERTS', category: 'alert' }
    ];
  }

  readNotification({ bucketId, id, interactionToken }) {
    return this.http.get(`${env.API_NOTIFICATION_PATH}/api/action/${env.API_VERSION}/${bucketId}/${id}/${interactionToken}`);
  }

  modifyAction({ bucketId, id, interactionToken, actionId }) {
    return this.http.get(`${env.API_NOTIFICATION_PATH}/api/action/${env.API_VERSION}/${bucketId}/${id}/${actionId}/${interactionToken}`);
  }

  markAsViewed() {
    return this.http.get(`${env.API_NOTIFICATION_PATH}/api/notifications/${env.API_VERSION}/markAsViewed`, HEADER_OPTIONS());
  }
}

<div class="container">
  <!-- header -->
  <div class="header"> {{ 'MAINTENANCE.TITLE' | translation }} </div>
  <!-- odometer detail -->
  <div class="odometer-detail">
    <div class="icon">
      <img src="assets/img/maintenance/odometer-icon.svg" alt="odometer-icon">
    </div>
    <div class="details">
      <div class="odometer-title">{{ 'MAINTENANCE.ODOMETER' | translation }}</div>
      <div class="odometer-value-wrapper">
        <div class="odometer-value">
          <div *ngFor="let o of odometerDisplay">
            <span>{{ o.number }}</span>
            <div class="mask" [ngClass]="{
              'mask-off': o.maskOff
            }"></div>
          </div>
        </div>
        <div class="odometer-unit">
          {{ odometerUnit }}
        </div>
        <div class="item-edit" (click)="openMaintenance({ odometerEdit: true })">
          <img src="assets/img/more-navbar.svg" alt="more-navbar">
        </div>
      </div>
    </div>
  </div>
  <!-- maintenance list -->
  <div class="maintenance-list">
    <div class="empty-maintenances" *ngIf="!maintenances.length">
      {{ 'MAINTENANCE.EMPTY_MAINTENANCES' | translation }}
    </div>
    <div class="maintenance" *ngFor="let maintenance of maintenances">
      <div class="maintenance-name">{{ maintenance.maintenanceType }}</div>
      <div class="maintenance-detail">
        <div class="maintenance-bar">
          <div class="percentage-container">
            <div class="current-percentage"
            [ngStyle]="{
              'background': statusColors[maintenance.status],
              'width': (maintenance.currentPecentage < 0 ? 0 : maintenance.currentPecentage > 100 ? 100 : maintenance.currentPecentage ) + '%'
            }"></div>
          </div>
          <div class="circles" [ngStyle]="{ 'border-color': statusColors[maintenance.status] }"></div>
          <div class="circles circles-right" [ngStyle]="{ 'border-color': maintenance.currentPecentage === 100 ? statusColors[maintenance.status] : inherit }"></div>
          <div class="maintenance-value">{{ numberFormater(maintenance.start) }}</div>
          <div class="maintenance-value">{{ numberFormater(maintenance.end) }}</div>
        </div>
        <div class="item-edit" (click)="openMaintenance(maintenance)">
          <img src="assets/img/more-navbar.svg" alt="more-navbar">
        </div>
        <div class="item-edit" (click)="deleteMaintenance(maintenance)">
          <img src="assets/img/maintenance/delete.svg" alt="more-navbar">
        </div>
      </div>
    </div>
  </div>
  <!-- add maintenance -->
  <div class="add-maintenance" *ngIf="odometerValue">
    <button mat-raised-button (click)="openMaintenance({})">
      {{ 'MAINTENANCE.ADDMAINTENANCE' | translation }}
    </button>
  </div>
  <!-- Maintenances needed -->
  <div class="maintenances-needed" *ngIf="maintenancesNeeded">
    {{ 'MAINTENANCE.NEEDS' | translation }} {{ maintenancesNeeded }} {{ 'MAINTENANCE.MAINTENANCES' | translation }}
  </div>
</div>

'use strict';

declare let L;
interface VAHConfig {
  MAX_DISTANCE?: number;
  MAX_TIME?: number;
  ANIMATION_TIME?: number;
  MIN_DURATION?: number;
  MIN_SPEED?: number;
  FIXED_DURATION?: number;
}

/** Handles the logic
 * for a vehicle animation
 * while listening to new frames
 **/
export class VehicleAnimationService {
  handler: (
    frame: VehicleFrame, // the next frame
    duration: number, // the animation duration in miliseconds
    animated: boolean, // if it should be animated or not
    engineStatus: boolean, // engineStatus
    framesLeft: number
  ) => Promise<void>; /** It should be resolved once the animation is complete! **/

  frames: VehicleFrame[]; // This could be replaced if possible with a FIFO list/queue

  // Options
  MAX_DISTANCE: number;
  MAX_TIME: number;
  ANIMATION_TIME: number;
  MIN_DURATION: number;
  MIN_SPEED: number;
  FIXED_DURATION: number;

  id: string;
  speed: number;
  status: boolean;
  lastFrame: VehicleFrame;
  lastTime: number;

  constructor(
    id: string,
    frames: VehicleFrame[] = [],
    handler: any,
    {
      MAX_DISTANCE = 1000, // Optional, default 500 m
      MAX_TIME = 30 * 60 * 1000, // Optional, default 30 minutes
      ANIMATION_TIME = 1000, // Time for updating the location
      MIN_DURATION = 200, // Under this time, animations will be dropped
      MIN_SPEED = 0.3, // Factor to allow faster animations
      FIXED_DURATION = 6000
    }: VAHConfig = {}) {
    // Main args
    this.handler = handler;
    this.frames = []; // This could be replaced if possible with a FIFO list/queue

    // Options
    this.MAX_DISTANCE = MAX_DISTANCE;
    this.MAX_TIME = MAX_TIME;
    this.ANIMATION_TIME = ANIMATION_TIME;
    this.MIN_DURATION = MIN_DURATION;
    this.MIN_SPEED = MIN_SPEED;
    this.FIXED_DURATION = FIXED_DURATION;

    // Initial state
    this.id = id;
    this.speed = 0;
    this.status = false;
    this.lastFrame = null;
    this.lastTime = 0;

    this.addFrames(frames);
  }

  // Just a function to elapse time
  static sleep(time: number = 0): Promise<void> {
    return new Promise(r => setTimeout(r, time));
  }

  // Add new frames to the handler
  addFrames(frames: VehicleFrame[], engineOn: boolean = true): void {
    this.status = engineOn;

    if (this.lastFrame) { // Filter frames with time older than last state's time
      frames = frames.filter(_ => _.time > this.lastFrame.time);
    }

    this.frames.push(...frames);
    // limit to a size of 15
    if (this.frames.length > 15) {
      this.frames = this.frames.slice(-15);
    }
  }

  /** This is the suscription (if not using a stream, try to make it tail-recursive) **/
  async listen(): Promise<void> {
    while (this.frames.length > 0) {
      let next = this.frames.shift();

      if (this.lastFrame) {
        const last = this.frames.slice(-1)[0] || next; // pick the last of list or the recently picked one
        const isFar = VehicleFrame.distance(this.lastFrame, next) > this.MAX_DISTANCE;
        const isOld = last.time > this.lastFrame.time + this.MAX_TIME;

        if (isOld) { // Remove all pending animations and move to last frame
          this.frames = [];
          next = last;
        }

        if (isFar || isOld) {
          this.lastFrame = next;
          if (next.time > this.lastTime) {
            this.lastTime = next.time;
            await this.handler(next, 0, false, this.status, this.frames.length); // move without duration
          }
          continue;
        }
      }

      let duration = this.getDuration();
      this.lastFrame = next;
      if (next.time >= this.lastTime) {
        this.lastTime = next.time;
        await this.handler(next, duration, true, this.status, this.frames.length);
      }
    }

    await VehicleAnimationService.sleep(500);
    await this.listen();
  }

  /** This is where the important thing happens!! */
  getDuration(): number {
    return this.frames.length
      ? Math.round(this.FIXED_DURATION / Math.max(10, this.frames.length))
      : 0;
  }
}

/**
 * This class is only a structure
 * for store location and time
 **/
export class VehicleFrame {
  lat: number;
  lon: number;
  time: number;

  constructor(lon, lat, time) {
    this.lon = lon;
    this.lat = lat;
    this.time = time;
  }

  get loc() {
    return [this.lon, this.lat];
  }

  get latLng() {
    return L.latLng(this.lat, this.lon);
  }

  get frame() {
    return { loc: [this.lon, this.lat], time: this.time };
  }

  /** Get an itermediate frame between two others */
  static between(a: VehicleFrame, b: VehicleFrame, at: number = 0.5) {
    const atP = 1 - at;
    return new VehicleFrame(
      a.lon * at + b.lon * atP,
      a.lat * at + b.lat * atP,
      a.time * at + b.time * atP
    );
  }

  /** distance using haversine formula */
  static distance(f1: VehicleFrame, f2: VehicleFrame) {
    if (!f1 || !f2) { return Infinity; }
    const [rlat1, rlat2, rlon1, rlon2] =
      [f1.lat, f2.lat, f1.lon, f2.lon].map(_ => _ * Math.PI / 180);
    return 6371 * 2 * Math.asin(
      Math.sqrt(
        Math.pow(Math.sin((rlat2 - rlat1) / 2), 2) +
        Math.pow(Math.sin((rlon2 - rlon1) / 2), 2) * Math.cos(rlat1) * Math.cos(rlat2)
      )
    );
  }
}

<div>
  <div class="geoList">
    <div class="driver-text-gray">
      <div class="driver">
        <h2 class="driver__subtitle">{{ 'SELECT_DRIVE' | translation }}</h2>
        <p class="driver__description">{{ 'SELECT_DRIVE_DESCRIPTION' | translation }}</p>
      </div>
      <div class="driver-filter">
        <div>
          <h1 class="driver-filter__title">{{'DRIVERS' | translation}} ({{ selectedDrivers.length }})</h1>
        </div>
        <div class="driver-filter__search">
          <img src="assets/img/icons/buscar.svg" alt="buscar">
          <input type="text" [(ngModel)]="findText" placeholder="{{ 'DRIVER_FILTER' | translation }}">
          <img *ngIf="findText?.length" class="searchCross" src="assets/img/clearb.png" (click)="findText = ''"
            alt="clearb">
        </div>
      </div>
      <div class="driver-list">
        <mat-list class="driver-list__added">
          <div mat-subheader class="driver-list__title">{{ 'ADDED_DRIVERS' | translation }}</div>
          <div class="noSelected" *ngIf="selectedDrivers.length === 0; else selectedDriversList">
            {{'SELECTED_DRIVES' | translation}}
          </div>
          <ng-template #selectedDriversList>
            <mat-list-item class="driver-list__item" *ngFor="let driverSel of selectedDrivers | geoSearch: findText; let i = index;">
              <div class="driver-list__item-content__img">
                <mat-icon mat-list-icon class="picture"
                  [ngStyle]="{ 'background': driverSel.picture ? 'url('+driverSel.picture+')' : '#ffe09d'}">
                  <span class="noPicture"  *ngIf="!driverSel.picture">
                    {{ driverSel.firstName && driverSel.firstName.length ? driverSel.firstName.slice(0, 1)[0] : ''}}
                    {{ driverSel.lastName && driverSel.lastName.length ? driverSel.lastName.slice(0, 1)[0] : '' }}
                  </span>
                  <span class="indicador-driver"></span>
                </mat-icon>
              </div>
              <div class="driver-list__item-content__description driver-text-gray">
                <div class="driver-list__item-content__description-text">
                  <h4>
                    <span innerHTML="{{ driverSel?.firstName | highlight: findText}}"></span>&nbsp;
                    <span innerHTML="{{ driverSel?.lastName | highlight: findText}}"></span>
                  </h4>
                  <div class="driver-list__item-content__description-text__email"
                  [ngClass]="{'email-black': driverSel.email, 'email-gray': !driverSel.email}">
                    <mat-icon>mail_outline</mat-icon>
                    <span *ngIf="driverSel.email">{{driverSel.email}}</span>
                    <span *ngIf="!driverSel.email">{{'ENTER_EMAIL' | translation}}</span>
                  </div>
                  <div class="driver-list__item-content__description-text__email"
                  [ngClass]="{'email-valid': driverSel.email, 'no-email': !driverSel.email}">
                    <mat-icon>{{driverSel.email ? 'check' : 'warning'}}</mat-icon>
                    <span *ngIf="driverSel.email">{{'EMAIL_VALID' | translation}}</span>
                    <span *ngIf="!driverSel.email">{{'NO_EMAIL' | translation}}</span>
                  </div>
                </div>
              </div>
              <div class="driver-list__item-content__edit">
                <img class="img-click" src="assets/img/more-navbar.svg" alt="more-navbar" (click)="openModalEditDriver(driverSel, false, i)">
              </div>
              <div class="driver-list__item-content__remove">
                <img class="img-click" src="assets/img/clearb.png" alt="clear button" (click)="removeDriver(driverSel, i)">
              </div>
            </mat-list-item>
          </ng-template>
        </mat-list>
        <mat-list class="driver-list__all">
          <div mat-subheader class="driver-list__title">{{ 'DRIVER' | translation }}</div>
          <mat-list-item class="driver-list__item" *ngFor="let driver of drivers | geoSearch: findText; let i = index;">
            <div class="driver-list__item-content__img">
              <mat-icon mat-list-icon class="picture"
                [ngStyle]="{ 'background': driver.picture ? 'url('+driver.picture+')' : '#ffe09d'}">
                <span class="noPicture"  *ngIf="!driver.picture">
                  {{ driver.firstName && driver.firstName.length ? driver.firstName.slice(0, 1)[0] : ''}}
                  {{ driver.lastName && driver.lastName.length ? driver.lastName.slice(0, 1)[0] : '' }}
                </span>
                <span class="indicador-driver"></span>
              </mat-icon>
            </div>
            <div class="driver-list__item-content__description driver-text-gray">
              <div class="driver-list__item-content__description-text">
                <h4>
                  <span innerHTML="{{ driver?.firstName | highlight: findText}}"></span>&nbsp;
                  <span innerHTML="{{ driver?.lastName | highlight: findText}}"></span>
                </h4>
                <div class="driver-list__item-content__description-text__email"
                [ngClass]="{'email-black': driver.email, 'email-gray': !driver.email}">
                  <mat-icon>mail_outline</mat-icon>
                  <span *ngIf="driver.email">{{driver.email}}</span>
                  <span *ngIf="!driver.email">{{'ENTER_EMAIL' | translation}}</span>
                </div>
                <div class="driver-list__item-content__description-text__email"
                [ngClass]="{'email-valid': driver.email, 'no-email': !driver.email}">
                  <mat-icon>{{driver.email ? 'check' : 'warning'}}</mat-icon>
                  <span *ngIf="driver.email">{{'EMAIL_VALID' | translation}}</span>
                  <span *ngIf="!driver.email">{{'NO_EMAIL' | translation}}</span>
                </div>
              </div>
            </div>
            <div class="driver-list__item-content__edit">
              <img class="img-click" src="assets/img/more-navbar.svg" alt="more-navbar" (click)="openModalEditDriver(driver, true, i)">
            </div>
            <div class="driver-list__item-content__add">
              <img [ngClass]="{'img-click': driver.email}" src="assets/img/group-13.png" alt="add button" (click)="driver.email ? openModalEditDriver(driver, true, i, true) : false">
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
    <div class="settings">
      <button mat-button class="Rectangle-2-Copy-2" (click)="cancel()">{{'CANCEL' | translation}}</button>
      <button mat-button [disabled]="selectedDrivers.length === 0" [class.disabled]="selectedDrivers.length === 0" class="btn_action saveBtn" (click)="calculateCost()">{{'CALCULATE_COST' | translation}}</button>
    </div>
  </div>

</div>
import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { filter } from "rxjs/operators"
import io from "socket.io-client"
import { environment as env } from "../../environments/environment"
import { timeAgo } from "../helpers/helpers"
import { Tag } from "../models/tag.model"
import { Vehicle } from "../models/vehicle.model"
import { WebSocket } from "../models/web-socket.model"
import { DevicesService } from "./devices.service"
import { DriverService } from "./driver.service"
import { GeneralService } from "./general.service"
import { TagService } from "./tag.service"
import { VehicleService } from "./vehicle.service"

@Injectable({
  providedIn: "root",
})
export class WebSocketService {
  private authSubject = new BehaviorSubject(false);
  private dirty = false;
  authSocket: any = new BehaviorSubject(false);
  version: string = `${env.BUILD_COMMIT}:${env.BUILD_TIME}`;
  collection: any = {};
  ws: any;
  vehicles: Vehicle.Vehicle[];
  devices: any;
  drivers: any;
  ranges: any = [];
  lastTimes: any = {};
  trackers: any = {};
  tripDrivingDelay: number = 5 * 60 * 1000;
  connected = false;
  activeImeis: any = {};
  totalImeis: number = 0;
  imeis = new BehaviorSubject<WebSocket.Imei[]>([]);
  refreshConnectionTimeout;
  getKeyFrames = this.imeis
    .asObservable()
    .pipe(filter((item) => this.checkImeis(item)));
  currentTags: any = [];

  get auth$() {
    return this.authSubject.asObservable();
  }

  get authenticated() {
    return this.authSubject.getValue();
  }

  set authenticated(value: boolean) {
    if (this.authenticated !== value) {
      this.authSubject.next(value);
    }
  }

  constructor(
    private driverService: DriverService,
    private vehicleService: VehicleService,
    private devicesService: DevicesService,
    private gs: GeneralService,
    private tagService: TagService
  ) {
    this.vehicleService.getCurrentVehicles.subscribe((vehicles) => {
      this.vehicles = vehicles;
    });

    this.driverService.getCurrentDrivers.subscribe((drivers) => {
      this.drivers = drivers;
    });

    this.devicesService.getCurrentDevices.subscribe((devices) => {
      this.devices = devices.filter((device) => device.type === "asset");
    });

    this.tagService.appliedTag$.subscribe((tags) => {
      if (this.dirty) {
        this.disconnect();
        this.initConnection();
      }

      this.applyFilter(tags);
      this.dirty = true;
      this.currentTags = tags;
    });

    this.ranges = this.genRanges();
    window["newBatch"] = this;

    this.auth$.subscribe((auth) => {
      if (auth) {
        this.applyFilter([]);
      }
    });
  }

  setKeyFrames(data: any) {
    this.imeis.next(data);
  }

  initConnection(
    config: any = {
      host: env.RTS_URL,
      port: env.RTS_PORT,
      protocol: env.RTS_PROTOCOL,
      endpoint: env.RTS_ENDPOINT,
    }
  ) {
    if (this.connected) {
      return;
    }
    this.ws = null;
    this.ws = io(`${config.protocol}://${config.host}:${config.port}`, {
      path: config.endpoint,
      transports: ["websocket"],
    });

    this.ws.on("connect", async () => {
      this.connected = true;
      this.authenticated = false;

      this.ws.on("authenticated", (data) => {
        this.authenticated = true;
        data = JSON.parse(data);
        const now = Date.now();
        this.activeImeis = data.imeis.reduce(
          (m, v) => ({ ...m, [v]: now }),
          {}
        );
        this.gs.log.info("WS auth", data);
        this.totalImeis = data.imeis.length;
        this.authSocket.next(true);
      });

      this.ws.on("upd", (msg) => {
        msg = JSON.parse(msg);
        let track: any = {};
        if (msg.track) {
          track = { ...msg.track };
          track.now = Date.now();
          track.initTime = track.pipe.reduce(
            (a, b) => a + b.split(":")[1] * 1000,
            0
          );
          track.pipe.push(`frnt:${Math.round(track.now / 1000)}`);
          this.trackers[msg.id] = this.trackers[msg.id] || [];
          this.trackers[msg.id].push(track);
        }
        this.processKeyframes(msg);
      });

      while (this.ws && !this.authenticated) {
        this.gs.log.info("WS emit-auth");
        this.ws.emit("auth", { token: localStorage.getItem("id") });
        await this.gs.timeOut(20000 + Math.random() * 1000);
      }
    });

    this.ws.on("close", async () => {
      this.gs.log.info("WS closed");
      this.activeImeis = {};
      this.trackers = {};
      this.connected = false;
      while (!this.connected) {
        await this.gs.timeOut(5000);
        this.initConnection();
      }
    });

    this.refreshConnectionTimeout = setTimeout(() => {
      if (this.refreshConnectionTimeout) {
        clearTimeout(this.refreshConnectionTimeout);
      }

      this.disconnect();
      this.initConnection();
    }, 300000);
  }

  sendTrackMessage(imei: string) {
    if (!this.connected || !this.trackers[imei]) {
      return;
    }
    while (this.trackers[imei].length) {
      const track = this.trackers[imei].shift();
      if (!track || !track.pipe) return;
      track.pipe.push(`anim:${Math.round(Date.now() / 1000)}`);
      delete track.now;
      delete track.initTime;
      track.version = "" + this.version;
      this.gs.log.info("WS track", imei, this.trackers[imei].length);
      this.ws.emit("track", track);
    }
  }

  applyFilter(tags: Tag.Tag[]) {
    if (this.connected) {
      const tagIds = tags.map((t) => t.id);
      this.ws.emit("filter", { tags: tagIds || [] });
    }
  }

  disconnect() {
    this.gs.log.info("WS disconnect");
    this.connected = false;
    this.authenticated = false;
    this.activeImeis = {};
    this.trackers = {};
    this.imeis.next([]);
    if (this.ws) {
      this.ws.close();
    }
    this.ws = null;
  }

  processKeyframes(batch: any) {
    const batchType = batch.response.type;
    let { data, status }: any = batch.response.data || {};
    if (
      status === "TripDriving" &&
      data.last_time < Date.now() - this.tripDrivingDelay
    ) {
      status = "Unknown";
      batch.response.data.status = status;
    }

    if (batchType === "Summary") {
      let keys = [];
      // Set keys
      if (["TripStopped", "Unknown"].includes(status)) {
        const isAsset = (_) => !isNaN(_);
        const { battery_level, time, location }: any =
          (data.last_keyframes || []).find((k) => isAsset(k.battery_level)) ||
          {};
        if (data.last_pos) {
          keys = [
            {
              stopped: true,
              speed: 0,
              time: isAsset(battery_level) ? time : data.last_time,
              loc: [
                isAsset(battery_level) ? location.lat : data.last_pos.lat,
                isAsset(battery_level) ? location.lon : data.last_pos.lon,
              ],
              batteryLevel: battery_level,
              stablishingSignal: hasLatency(data.last_time, time, 1),
            },
          ].reduce((res, current) => res.concat([current, current]), []);
        }
      } else if (status === "TripDriving") {
        let keyframes = data.last_keyframes;
        if (!keyframes.length) {
          keyframes = [
            { location: data.last_pos, time: data.last_time },
            { location: data.last_pos, time: data.last_time },
          ];
        }

        keys = keyframes.reduce(
          (m, k) => {
            if (!k.location && !k.speed) {
              return m;
            }
            if ("speed" in k) {
              m.lastSpeed = k.speed;
            }
            if (!k.location) {
              return m;
            }
            if (k.time < (this.lastTimes[batch.id] || 0)) {
              return m;
            }
            m.keys = m.keys.concat([
              {
                track: "city",
                time: k.time,
                loc: k.location,
                speed: m.lastSpeed,
                range: this.ranges.reduce((acc, range, i) => {
                  if (
                    acc === null &&
                    m.lastSpeed >= range[0] &&
                    m.lastSpeed <= range[1]
                  ) {
                    acc = i;
                  }
                  return acc;
                }, null),
              },
            ]);
            return m;
          },
          { lastSpeed: 0, keys: [] }
        ).keys;

        if (!keys.length && data.last_pos) {
          keys = [
            {
              speed: 0,
              time: data.last_time,
              loc: [data.last_pos.lat, data.last_pos.lon],
            },
          ].reduce((res, current) => res.concat([current, current]), []);
        }
      }

      this.lastTimes[batch.id] = data.last_time;
      this.collection[batch.id] = this.collection[batch.id] || {
        imei: batch.id,
        keys: [],
      };
      this.collection[batch.id].keys = keys;
      this.collection[batch.id].type = status;

      if (keys.length) {
        this.setKeyFrames([this.collection[batch.id]]);
      }
    }
    this.updateVehicleActivity(batch);
  }

  sendTestMessage(imei, message) {
    this.ws.emit("send-test", { imei, message });
  }

  locToArray(loc) {
    return [loc.lat, loc.lon];
  }

  updateVehicleActivity(batch) {
    if (!this.vehicles.length || !this.drivers.length) {
      return;
    }
    const imei = batch.id;
    const last_time = batch.response.data.data.last_time;
    const updateEntity = (arr) =>
      arr.map((e) =>
        e.device && e.device.imei === imei
          ? Object.assign({}, e, {
              movingTime: last_time ? timeAgo(last_time) : null,
              currentStatus: batch.response.data.status,
              lastUpdate: last_time,
            })
          : e
      );

    const vehicleToUpdate = updateEntity(this.vehicles);
    this.vehicleService.setCurrentVehicles(vehicleToUpdate);

    const driverToUpdate = updateEntity(this.drivers);
    this.driverService.setCurrentDrivers(driverToUpdate);
  }

  setRanges(keyframes: any) {
    return keyframes.map((k) =>
      Object.assign({}, k, {
        range: this.genRanges()
          .map((range, rangeIndex) => {
            return k.speed >= range[0] && k.speed <= range[1]
              ? rangeIndex
              : null;
          })
          .filter((r) => r || r == 0)[0],
        track: "city",
      })
    );
  }

  genRanges() {
    const ranges = [];
    for (let i = 0; i < 121; i += 5) {
      ranges.push([i, i + 5]);
      if (i + 1 > 120) {
        return ranges;
      }
    }
  }

  private checkImeis(imeis: WebSocket.Imei[] = []) {
    if (this.tagService.getSelectedTagsCant() === 0) {
      return true;
    }

    const vehiclesImeis = this.vehicles
      .filter((v) => v.device && v.device.imei)
      .map((v) => v.device.imei);
    const driversImeis = this.drivers
      .filter((v) => v.device)
      .map((v) => v.device.imei);
    const assetsImeis = this.devices
      .filter(
        (d) =>
          !this.devicesService.deviceToRemove.map((d) => d._id).includes(d._id)
      )
      .map((d) => d.imei);

    const filtered = imeis.filter(
      (i) =>
        vehiclesImeis.includes(i.imei) ||
        driversImeis.includes(i.imei) ||
        assetsImeis.includes(i.imei)
    );

    return filtered.length > 0;
  }
}

const hasLatency = (last_time, prevTime, latencyLimit) => {
  latencyLimit = latencyLimit * 1000 * 3600;
  return last_time - prevTime > latencyLimit;
};

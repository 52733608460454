import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
@Component({
  selector: 'app-week-filter',
  templateUrl: './week-filter.component.html',
  styleUrls: ['./week-filter.component.css']
})


export class WeekFilterComponent implements OnInit {

  @Input('dialogRef') dialogRef: any;
  @Input('data') data: any;
  filter: any = { from: {}, to: {} };
  maxDate: any = new Date(new Date().setDate(new Date().getDate() - 1));

  constructor() { }

  ngOnInit() {
    const { from: { end: fromDate }, to: { end: toDate } } = this.data;
    this.setWeekYear(moment(new Date(fromDate)), 'from');
    this.setWeekYear(moment(new Date(toDate)), 'to');
  }

  close() {
    this.dialogRef.close();
  }

  applyFilter() {
    this.dialogRef.close({...this.filter, prevData: this.data.prevData});
  }

  setWeekYear(date, range) {
    this.filter[range].date = date;
    this.filter[range].year = date.year();
    this.filter[range].week = date.isoWeek();
  }

}

<mat-expansion-panel class="driver-collapse"
  [id]="'driver_'+selected?._id" [expanded]="(selected?.id === driver?.id) && !isFreemium"
  (closed)="selectedDriver(undefined); expanded = false" (afterExpand)="expanded = true">
  <mat-expansion-panel-header #panelH
    [ngClass]="{
      'no-click': expanded,
      'selected-driver-freemium': (selected?.id === driver?.id) && isFreemium && !expanded
    }"
    collapsedHeight="80px"
    expandedHeight="80px"
    (click)="selectedDriver(driver, panelH);">
    <mat-list class="entity-list">
      <mat-list-item>
        <div class="current-car">
          <img src="/assets/img/icons/autoconductor-listas.svg">
          <span *ngIf="driver.current_vehicle?.plate" class="plate">{{ driver.current_vehicle.plate }}</span>
        </div>
        <mat-icon mat-list-icon class="picture hasPicture"
          [ngStyle]="{ 'background': driver.picture ? 'url('+driver.picture+')' : '#ffe09d'}">
          <span class="noPicture" *ngIf="!driver.picture"> {{ getNameFirstLetter(driver.firstName || driver.name, driver.lastName) }}
          </span>
          <span class="indicador-driver" [ngClass]="{
              'indicator-driving': driver.currentStatus === 'TripDriving',
              'indicator-stopped': driver.currentStatus !== 'TripDriving'
            }">
          </span>
        </mat-icon>
        <h4 class="name" matLine>
          <span innerHTML="{{ fullName(driver.name, driver.lastName) | highlight: findText }}"></span>
          <img *ngIf="expanded" class="tendency"
            [src]="'assets/img/'+tendencyIcon(driver.scores[0]?.previousScore, driver.scores[0]?.score)+'.svg'"
            alt="tendency">
        </h4>
        <p mat-line>
          <span class="state-driver" [ngStyle]="{ 'color': driver.currentStatus === 'TripDriving' ? '#38b6d1' : '' }">
            <span *ngIf="driver.currentStatus === 'TripDriving'" class="driving"></span>
            <span *ngIf="driver.currentStatus === 'TripDriving'" class="active-driver">{{ 'DRIVING' | translation }}
              {{ driver.movingTime }}</span>
            <span *ngIf="driver.currentStatus === 'TripStopped'" style="color:#b3b3b3;">{{ 'STOPPED' | translation }}</span>
            <span *ngIf="driver.currentStatus === 'Unknown' || driver.currentStatus === 'Disconnected'" style="color:#b3b3b3;">
              {{ 'NO_SIGNAL' | translation }}
            </span>
            <span *ngIf="!driver.currentStatus"
              style="color:#b3b3b3;">
              {{ (driver.device?.status ? 'DEVICE_STATUS.'+ driver.device?.status : 'NO_DEVICE') | translation }}
            </span>
          </span>
        </p>
        <p class="line" *ngIf="!driver.device" mat-line>{{ 'NO_DEVICE' | translation }}</p>
      </mat-list-item>
    </mat-list>
    <div class="phone" *ngIf="driver.phone" (click)="callPhone(panelH, driver?.phone)">
      <img src="assets/img/icons/phone.svg" alt="phone">
      {{ driver.phone }}
    </div>
    <div *ngIf="expanded && gs.checkPermission(['admin.fleetrw', 'admin.fleetmgr'])"
      class="edit-action" (click)="openEditMode(panelH, driver)" >
      <img src="assets/img/more-navbar.svg" alt="more-navbar">
    </div>

  </mat-expansion-panel-header>
  <div class="container">
    <div class="list-section">
      <div>
        <div class="outlined-button hide-show-text" (click)="goToRanking()" *ngIf="driver.scores[0]?.trafficLight">
          <span [ngStyle]="{
              'color': genTrafficLight(driver.scores[0]?.trafficLight).color
            }">
            <span class="icon-wrapper">
              <img [src]="genTrafficLight(driver.scores[0]?.trafficLight).icon" alt="trafficlight">
            </span>
            <span class="button-name">
              {{ 'TRAFFICLIGHT_SAFETY.'+driver.scores[0]?.trafficLight | translation }}
            </span>
          </span>
        </div>
        <div class="outlined-button hide-show-text" (click)="openGeneralModal(driver, 'stats')">
          <span>
            <span class="icon-wrapper">
              <img src="assets/img/indicator-driver.svg" alt="indicator">
            </span>
            <span class="button-name">
              {{ 'STATS' | translation }}
            </span>
          </span>
        </div>
      </div>
      <div>
        <div class="outlined-button" (click)="openTrips()"> {{'TRIPS' | translation}} </div>
      </div>
      <div *ngIf="driver.dashcam">
        <div class="outlined-button" (click)="getDashCam(driver.dashcam)">Dashcam</div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <mat-list class="template-list">
      <h5 *ngIf="!driver.current_vehicle" class="noshift-user"> {{ 'NO_VEHICLE_FOR_DRIVER' | translation }}</h5>
      <mat-list-item class="current-vehicle list-hover" *ngIf="driver.current_vehicle"
        (click)="homeService.actions({id: driver.current_vehicle.id}, 'vehicles')">
        <mat-icon mat-list-icon style="border: none !important" class="picture hasPicture"
          [ngStyle]="{ 'background': driver.current_vehicle?.picture ? 'url('+driver.current_vehicle?.picture+')' : '#ffe09d'}">
          <span class="noPicture" *ngIf="!driver.current_vehicle?.picture"> {{driver.current_vehicle ?
            getNameFirstLetter(driver.current_vehicle?.brand, driver.current_vehicle?.model) : '' }}
          </span>
        </mat-icon>
        <h4 class="name" mat-line>{{driver.current_vehicle?.brand}} {{driver.current_vehicle?.model}}
          {{ driver.current_vehicle?.year ? '- '+driver.current_vehicle?.year : ''}}</h4>
        <p mat-line>
        </p>
      </mat-list-item>
    </mat-list>
    <div class="close-panel" (click)="closePanel()">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </div>
  </div>
</mat-expansion-panel>

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { HEADER_OPTIONS } from '../helpers/helpers'

@Injectable({
  providedIn: 'root'
})
export class  DashcamService {

  constructor(private http: HttpClient) {}

  getDashcam(vehicleId: string) {
    return this.http.get(`${environment.API_BASE_URL}/v1/core/vehicles/${vehicleId}/dashcam`, HEADER_OPTIONS());
  }

  getDashCode(camImei: string, payload: any) {
    return this.http.post(`${environment.API_BASE_URL}/v1/core/matracks/${camImei}/dashcanAlert`, payload, HEADER_OPTIONS());
  }

  getSignedUrl(camImei: string, filePath: string) {
    return this.http.post(`${environment.API_BASE_URL}/v1/core/matracks/${camImei}/dashcanAlertSignedMediaFiles`, {filePath}, HEADER_OPTIONS());
  }
}

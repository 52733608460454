<div class="header">
  <h3>{{ 'TAGS_MANAGER.TITLE' | translation }}</h3>
  <h4>{{ 'TAGS_MANAGER.SUBTITLE' | translation }}</h4>
</div>
<div class="body">
  <h5>{{ 'TAGS_MANAGER.BODY_STRONG' | translation }}</h5>
  <p>{{ 'TAGS_MANAGER.BODY_DESCRIPTION' | translation }}</p>

  <div class="inputs">
    <div class="add-tag-name">
      <img src="/assets/img/icons/filter-icon.svg" alt="filter">
      <input type="tag-name" [(ngModel)]="addTagInput" [placeholder]="'TAGS_MANAGER.INPUT_PLACEHOLDER' | translation">

      <button (click)="addNewTag()">+ {{ 'TAGS_MANAGER.BUTTON_1' | translation }}</button>
    </div>
  </div>

  <div class="tags-container">
    <ul class="tag-list">
      <li *ngFor="let tag of (tag$ | async); index as idx; let last = last" class="tag-item border-bottom"
        [class.active]="selectedTag && selectedTag.id === tag.id">
        <p *ngIf="!editTagName || editTagName.id !== tag.id" class="tag-name" (click)="editTag(tag)" role="button">{{ tag.name }}</p>
        <input *ngIf="editTagName && editTagName.id === tag.id" (keyup)="keyEvent($event)" [(ngModel)]="tempTagname">
        <div class="tag-options" role="button" (click)="openOptions(idx)">
          <img src="/assets/img/icons/more-gray.svg" alt="more">
          <ul *ngIf="selectedOption === idx" #options [class.last-option]="last">
            <li role="button" (click)="showEditTagNameInput(tag)">{{ 'TAGS_MANAGER.EDIT_OPTION' | translation }}</li>
            <li role="button" (click)="showDeleteAlert(tag)">{{ 'TAGS_MANAGER.DELETE_OPTION' | translation }}</li>
          </ul>
        </div>
      </li>
    </ul>

    <div class="car-list" *ngIf="selectedTag">
      <div class="tag-name" role="text">
        {{selectedTag?.name}}
      </div>
      <ul>
        <li *ngFor="let vehicle of selectedTag?.vehicles" class="border-bottom">
          <img src="/assets/img/icons/indicador-vehiculo-white.svg" alt="car">
          <p class="plate">{{vehicle?.plate}}</p>
          <p class="model">{{vehicle?.model}}</p>
          <p>-</p>
          <p class="year">{{vehicle?.year}}</p>
          <button (click)="showRemoveVehicleModal(vehicle?.id)"><img src="/assets/img/icons/trash.svg" alt="delete"></button>
        </li>
        <li *ngFor="let asset of selectedTag?.devices" class="border-bottom">
          <img src="/assets/img/icons/indicador-vehiculo-white.svg" alt="car">
          <p class="plate">{{asset?.serial}}</p>
          <p class="model">{{asset?.modelN}}</p>
          <p>-</p>
          <p class="year">{{asset?.type}}</p>
          <button (click)="showRemoveAssetModal(asset?._id)"><img src="/assets/img/icons/trash.svg" alt="delete"></button>
        </li>
        <li *ngIf="showAddVehicle && vehiclesFiltered?.length" class="styled-autocomplete">
          <img src="/assets/img/icons/indicador-vehiculo-white.svg" alt="car">
          <ng-autocomplete [data]="vehiclesFiltered" searchKeyword="keyword" placeholder="{{ 'TAGS_MANAGER.BUTTON_2' | translation }}"
            (selected)='vehicleEvent($event)' [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
        </li>
        <li *ngIf="showAddAsset && assetFiltered?.length" class="styled-autocomplete">
          <img src="/assets/img/icons/indicador-vehiculo-white.svg" alt="car">
          <ng-autocomplete [data]="assetFiltered" searchKeyword="keyword" placeholder="{{ 'TAGS_MANAGER.BUTTON_3' | translation }}"
            (selected)='assetEvent($event)' [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
        </li>
      </ul>

      <button class="add-car" [disabled]="showAddVehicle || !vehiclesFiltered?.length" (click)="addVehicle()"
        [class.disabled]="showAddVehicle || !vehiclesFiltered?.length">+ {{ 'TAGS_MANAGER.BUTTON_2' | translation }}
      </button>

      <button class="add-device" [disabled]="showAddAsset || !assetFiltered?.length" (click)="addAsset()"
        [class.disabled]="showAddAsset || !assetFiltered?.length">+ {{ 'TAGS_MANAGER.BUTTON_3' | translation }}
      </button>
    </div>
  </div>
</div>


<ng-template #itemTemplate let-item>
  <a class="option">
    <p class="plate">{{item.value.plate}}</p>
    <p class="model">{{item.value.model}}</p>
    <p>-</p>
    <p class="year">{{item.value.year}}</p>
  </a>
</ng-template>

<ng-template #notFoundTemplate let-notFound>
  <div [innerHTML]="notFound"></div>
</ng-template>

<app-tag-delete-alert *ngIf="showDeleteAlertModal" [title]="'TAGS_MANAGER.DELETE_TITLE' | translation"
  [subtitle]="'TAGS_MANAGER.DELETE_SUBTITLE' | translation" [button]="'TAGS_MANAGER.DELETE_BUTTON' | translation"
  [content]="'TAGS_MANAGER.DELETE_BODY' | translation" (close)="closeDeleteAlert()" (delete)="deleteTag()">
</app-tag-delete-alert>

<app-tag-delete-alert *ngIf="showDeleteVehicleAlertModal" [title]="'TAGS_MANAGER.REMOVE_VEHICLE' | translation"
  [content]="'TAGS_MANAGER.REMOVE_VEHICLE_BODY' | translation"
  [button]="'TAGS_MANAGER.REMOVE_VEHICLE_BUTTON' | translation" (close)="closeDeleteVehicleAlert()"
  (delete)="deleteVehicle()">
</app-tag-delete-alert>

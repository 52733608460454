<div class="extension-text-gray">
  <div class="extension">
    <h2 class="extension__subtitle">{{ 'EXTENSION_SUBTITLE' | translation }}</h2>
    <p class="extension__description" innerHTML="{{ 'EXTENSION_DESCRIPTION' | translation }}"></p>
  </div>
  <div class="extension-list">
    <div class="extensions-list__item" *ngFor="let ext of extensions" (click)="showDetail(ext)">
      <div class="extensions-list__item-content">
        <div class="extensions-list__item-content__logos">
          <img src="/assets/img/icons/dashcam.svg" alt="logo dashcam">
          <hr class="extensions-list__item-content__logos-line">
          <img src="{{ext.logo}}" alt="logo">
        </div>
        <div class="extensions-list__item-content__text">
          <span class="extensions-list__item-content__text-title">{{ ext.name }}</span>
          <p class="extensions-list__item-content__text-description">{{ ext.shortDescription }}</p>
        </div>
        <mat-icon class="extension-list__item-content__icon">arrow_forward_ios</mat-icon>
      </div>
    </div>
  </div>
</div>
import Rollbar from 'rollbar';
import {
  Injectable,
  Inject,
  InjectionToken,
  ErrorHandler
} from '@angular/core';
import { environment as env } from '../environments/environment';
console = window.console;

const rollbarConfig = {
  accessToken: env.ROLLBAR_TOKEN,
  captureUncaught: env.production,
  captureUnhandledRejections: env.production,
  payload: {
    environment: env.production ? 'production' : 'development',
    client: {
      javascript: {
        source_map_enabled: true, // true by default

        // -- Add this into your configuration ---
        code_version: env.production ? 'production' : 'development',
        // ---------------------------------------

        // Optionally have Rollbar guess which frames the error was
        // thrown from when the browser does not provide line
        // and column numbers.
        guess_uncaught_frames: true
      }
    }
  }
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

import { Injectable } from '@angular/core';
import { GeneralService } from './general.service';
import { VehicleService } from './vehicle.service';
import { DriverService } from './driver.service';
import { TripsService } from './trips.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  vehicles: any;
  drivers: any;
  contexts: any[];

  constructor(
    public gs: GeneralService,
    public vehicleService: VehicleService,
    public driverService: DriverService,
    public tripsService: TripsService,
    public location: Location) {
    this.contexts = this.gs.contextOptions();
  }

  async actions(params: any, type: string) {
    const vehicles: any = await this.vehicleService.getAll().toPromise();
    const drivers: any = await this.driverService.getAll().toPromise();
    const contexts = this.contexts.filter(c => new RegExp(type).test(c.context));

    const typeStrategy = {
      vehicles: ({ id }: any = {}) => {
        const options = { state: true, type: 'list', contexts };
        if (id) {
          options['data'] = id ? vehicles.find(v => v.id === id) : undefined;
        }
        this.gs.setWindow(options);
        this.location.go('/home');
      },
      drivers: ({ id }: any = {}) => {
        const options = { state: true, type: 'list', contexts };
        if (id) {
          options['data'] = id ? drivers.find(v => v.id === id) : undefined;
        }
        this.gs.setWindow(options);
        this.location.go('/home');
      },
      trips: async ({ id }: any = {}) => {
        if (!id) { return; }
        const trip: any = await this.gs.asyncRequest(this.tripsService.getTrip(id, 'full'));
        this.gs.setWindow({
          state: true, type: 'trips', contexts: this.contexts,
          data: Object.assign({}, trip, {
            type: 'trip',
            driver: drivers.find(d => d.id === trip.driver)
          })
        });
        this.location.go('/home');
      },
      ranking: () => {
        this.gs.setWindow({
          state: true, type: 'ranking', contexts: [{
            icon: '/assets/img/icons/Rank.svg',
            name: 'Ranking',
            context: 'ranking',
            type: 'ranking',
            menu: true
          }]
        });
      },
      notifications: () => {
        this.gs.setWindow({
          state: true, type: 'list', contexts: [this.contexts.find(c => c.context === type)]
          });
      }
    };

    typeStrategy[type](params);
    this.gs.unfocusPanel();
  }

}

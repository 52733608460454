import { Component, HostListener } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { NavigationEnd, Router } from '@angular/router'
import { SwUpdate } from '@angular/service-worker'
import { filter } from 'rxjs/operators'
import { checkForQparam, countrySpecifics } from '../app/helpers/helpers'
import { environment as env } from '../environments/environment'
import { GeneralModalComponent } from './components/general-modal/general-modal.component'
import { translationPipe } from './pipes/translation/translation.pipe'
import { VehicleService } from './services//vehicle.service'
import { AuthService } from './services/auth.service'
import { GeneralService } from './services/general.service'
import { GeofencesService } from './services/geofences.service'
import { LogoutService } from './services/logout.service'
import { LookerService } from './services/looker.service'
import { NotificationsService } from './services/notifications.service'
import { RequestService } from './services/request.service'
declare var firebase;

const checkForZendeskRemoval = zendeskSelector => {
  const zendeskEl = document.querySelector(zendeskSelector);
  if (!zendeskEl) { return zendeskEl; }
  zendeskEl.remove();
  return !document.querySelector(zendeskSelector);
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  isEnableFinderNotifications = true;
  isResetPassword = false;
  isShowFilter = true;

  constructor(
    public gs: GeneralService,
    public vehicleService: VehicleService,
    private router: Router,
    private authService: AuthService,
    public swUpdate: SwUpdate,
    private geofencesService: GeofencesService,
    private ns: NotificationsService,
    private logout: LogoutService,
    private rs: RequestService,
    private lookerService: LookerService,
    public dialog: MatDialog
  ) {

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm(translationPipe.transform('UPDATE_APPLICATION'))) {
          this.swUpdate.activateUpdate().then(() => {
            window.location.reload();
          }).catch(_err => {
            window.location.reload();
          });
        }
      });
    }

    this.gs.getFleetConfig.subscribe(configs => this.configs = configs);
    this.gs.getMap.subscribe(m => this.map = m);
    this.gs.getWiState.subscribe((data: boolean) => this.wiState = data);
    this.gs.getWiExtendedState.subscribe((data: boolean) => this.wiExtendedState = data);
    this.gs.getWiExtendedType.subscribe((data: string) => this.wiExtendedType = data);

    this.gs.getWiContext.subscribe((data: any[]) => {
      this.contexts = data;
      if (this.contexts) {
        this.isShowFilter = !['dashboard', "ranking", 'health', 'Add ons'].includes(data[0]?.context);
        return;
      }

      const url = window.location.pathname;
      if (url.includes('dashboard')) {
        this.isShowFilter = false;
        return;
      }

      this.isShowFilter = true;
    });

    this.gs.getWiType.subscribe((data: string) => {
      this.type = data
    });
    this.gs.getWiData.subscribe((data: any) => this.wiData = data);
    this.gs.getLoggedUser.subscribe((data: any) => this.admin = data);
    this.ns.getNotificationsEnabled.subscribe(n => this.notificationsEnabled = n);
    this.initFirebase(env);

    this.router.events.pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const url = event.urlAfterRedirects;
        const routeStrategy = {
          externalRoute: _ => {
            this.isExternalView = true;
            // checkForZendeskRemoval('iframe#launcher');
            localStorage.getItem('id') ? this.router.navigate(['home']) : this.initiateSidebars = false;
            return;
          },
          internalRoute: () => {
            this.isExternalView = false;
            const token = checkForQparam('token');
            if (token) { localStorage.setItem('id', token); }
            if (!localStorage.getItem('id')) {
              if (url.includes('reset-password')) {
                return this.router.navigate([url]);
              } else {
                return this.router.navigate(['login']);
              }
            } else {
              this.isEnableFinderNotifications = !url.includes('reset-password');
            }
            this.authService.getAdmin().subscribe((admin: any) => {
              if (!admin) { return this.logout.logoutActionFn(); }
              if (admin['tenants']) { this.lookerService.loadDashboard(admin['tenants']) }
              this.admin = admin;
              this.gs.setLoggedUser(admin);
              localStorage.setItem('LANG', admin['lang'] || 'en_US');
              this.checkInitialRedirectinon(url);
              this.getUnreadNotifications();
              const fAnalytics = firebase.analytics();
              fAnalytics.setUserId(admin.id);
              const scriptForCountry = countrySpecifics[admin.country];

              // if (scriptForCountry && scriptForCountry.zendeskScript && !token) {
              //   scriptForCountry.zendeskScript();
              // } else {
              //   checkForZendeskRemoval('iframe#launcher');
              // }

              if (scriptForCountry && scriptForCountry.zohoScript && !token) {
                scriptForCountry.zohoScript();
              } else {
              }

            }, () => {
              this.logout.logoutActionFn();
              // checkForZendeskRemoval('iframe#launcher');
            });
            return;
          }
        };
        routeStrategy[this.externalRoutes(url) ? 'externalRoute' :
          'internalRoute'](null);
      });
    this.ns.getUnreadNotifications.subscribe(unreadNotifications => this.unreadNotifications = unreadNotifications);
  }

  wiState: boolean;
  wiExtendedState: boolean;
  toolBarHovered = true;
  contexts: any;
  type: string;
  initiateSidebars: boolean;
  map: any;
  isMobile: boolean;
  wiData: any;
  wiExtendedType: string;
  notificationsEnabled: boolean;
  unreadNotifications: number;
  admin: any;
  configs: any;
  isExternalView: boolean;
  editingGeofence = localStorage.getItem('geoId');
  private externalRoutes = (url: string) => [
    'login',
    // 'reset-password',
    'magicLink'
  ].some(r => RegExp(r).test(url))

  async checkInitialRedirectinon(url) {
    if (!this.admin) { return; }
    const res = await this.rs.request({
      method: 'GET',
      endpoint: 'get_set_config',
      tenantId: this.admin.tenants[0]
    });
    if (res.error || !res.find(c => c.type === 'fleet:status')) {
      return this.gs.openAlert({ msg: 'ERROR', error: true });
    }
    const { welcoming, onboarding } = res.find(c => c.type === 'fleet:status').configuration;
    this.gs.setFleetConfig(res);
    const initialScreens = ['welcoming', 'onboarding']
      .some(s => url.indexOf(s) > -1);
    if (!welcoming) {
      this.initiateSidebars = false;
      return this.router.navigate(['welcoming']);
    }
    if (!onboarding) {
      this.initiateSidebars = false;
      return this.router.navigate(['onboarding']);
    }
    this.initiateSidebars = true;
    if (initialScreens) {
      return this.router.navigate(['home']);
    }
  }

  initFirebase({ FIREBASE_CONFIG }) {
    firebase.initializeApp(FIREBASE_CONFIG);
    return firebase.analytics();
  }

  async getUnreadNotifications() {
    const notifications = await this.rs.request({
      method: 'GET',
      endpoint: 'notifications_list'
    });
    this.ns.setUnreadNotifications(notifications.unread);
  }

  @HostListener('document:keydown', ['$event'])
  keypress(e: KeyboardEvent) {
    if (e.key === 'Escape' && this.gs.checkPermission(['admin.fleetrw', 'admin.fleetmgr'])) {
      this.geofencesService.mapContext.subscribe(x => {
        if (x !== 'create_geofences') {
          this.close();
        }
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(_) {
    this.isResetPassword = this.router.url.includes('reset-password');
    this.isMobile = window.innerWidth < 450;
  }

  backToList() {
    this.gs.setShowLiveViewRoute(false);
    this.gs.addSelectedClass(undefined);
    let contexts = this.gs.contextOptions();
    let type = 'list';
    if (this.type === 'create_geofences') {
      contexts = contexts.filter(c => c.context === 'geofences');
    } else if (this.type === 'drivers-list-extensions') {
      type = 'extension-detail';
      contexts = contexts.filter(c => c.context === 'Add ons');
    } else if (this.type === 'extension-detail') {
      contexts = contexts.filter(c => c.context === 'Add ons');
    }
    const state = contexts.length < 2 && this.gs.checkPermission(['admin.fleetrw', 'admin.fleetmgr']);
    this.gs.setWindow({ state, extendedState: false, type, contexts, data: this.wiData });
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }

  goToNotifications() {
    this.gs.setWindow({ state: true, extendedState: false, type: 'list', contexts: this.gs.contextOptions().filter(c => c.context === 'notifications') });
  }

  close() {
    this.gs.setShowLiveViewRoute(false);
    if (this.wiExtendedState) {
      if (this.type === 'trips') {
        this.gs.setWindow({
          state: true,
          extendedState: false,
          type: this.type,
          contexts: this.contexts,
          data: this.wiData
        });
      } else {
        this.gs.setWindow({ state: true, extendedState: false, type: this.type, contexts: this.contexts });
      }
    } else {
      this.gs.addSelectedClass(undefined);
      this.gs.setWindow({ state: false });
    }
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 600);
  }

  toolBarHover(state: boolean) {
    this.toolBarHovered = state;
  }

  openGeneralModal() {
    this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '600px',
      data: { type: 'app_down' }
    });
  }
}

import { Component, OnInit, OnDestroy } from "@angular/core";
import { GeneralService } from "../../../services/general.service";
import { MatDialog } from "@angular/material/dialog";
import { RequestService } from "../../../services/request.service";
import { Subscription } from "rxjs";
import { GeneralModalComponent } from "../../general-modal/general-modal.component";
import { HealthDetail, HealthState, VehicleHealth } from '../../../models/dtc.model';

@Component({
  selector: "app-health-detail",
  templateUrl: "./health-detail.component.html",
  styleUrls: ["./health-detail.component.css"],
})
export class HealthDetailComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  data: HealthDetail;
  statusTypes: HealthState[];

  constructor(
    private gs: GeneralService,
    public dialog: MatDialog,
    private rs: RequestService
  ) {
    this.subscription = this.gs.getWiData.subscribe((_) => {
      if (!_ || _.type !== "health_general") {
        return;
      }
      this.data = _;
      this.statusTypes = this.statusTypesStrategy(
        this.data.selected.filter,
        this.data.status
      );
    });
  }

  ngOnInit() {
    // This is intentional
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getNameFirstLetter(name, lastName) {
    return `${name.length ? name.slice(0, 1)[0] : ""} ${
      lastName.length ? lastName.slice(0, 1)[0] : ""
    }`;
  }

  statusTypesStrategy(filter, statusList) {
    return statusList
      .filter((_) => _.type !== "all")
      .filter((_) => filter === "all" || _.type === filter);
  }

  /**
   * Abre el modal con el detalle del vehículo seleccionado
   * @param type Tipo de contenido a mostrar en el modal
   * @param vehicle Vehículo seleccionado
   */
   showVehicleDetail(vehicle: VehicleHealth) {
    this.dialog.open(GeneralModalComponent, {
      panelClass: "general-modal",
      width: "45vw",
      data: { vehicle, type: 'detail_dtcs' }
    });
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { TranslationPipe } from '../../../pipes/translation/translation.pipe';
import { entities } from '../../../helpers/helpers';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css'],
  providers: [ TranslationPipe ]
})
export class FormsComponent implements OnInit {

  emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  admin: any;
  createError: string;
  codeAreas: any = {
    US: '+1',
    CL: '+56'
  };

  constructor(
    public dialogRef: MatDialogRef<FormsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private gs: GeneralService,
    private router: Router,
    private tp: TranslationPipe
  ) {
    this.gs.getLoggedUser.subscribe(a => this.admin = a);
    this.data['singularType'] = this.data.type.slice(0, -1);
  }

  ngOnInit() {
  }

  create(type) {
    this.data.emailregex = this.emailregex;
    const validate = entities[type].validator(this.data);
    if ( !validate ) { return this.createError = `${ this.tp.transform('CREATE_ENTITY_ERROR_EMPTY_FIELDS') }`; }
    this.close(entities[type].amount);
  }

  uploadXls() {
    this.close();
  }

  close(amount: number = 0): void {
    const result = {};
    result['added'] = amount;
    this.dialogRef.close(result);
  }

}

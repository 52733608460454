import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KpisService {

  constructor(private http: HttpClient) { }

  getCharts() {
    return this.http.get(`${env.API_PATH}/api/stats/v1/charts`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('id')}`
      }),
    });
  }

  getDataChart(id, filter: any = null) {
    return this.http.get(`${env.API_PATH}/api/stats/v1/charts/${id}/data?${filter}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('id')}`
      }),
    });
  }

  getDrivers() {
    return this.http.get(`${env.API_PATH}/api/driver/v1/all`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('id')}`
      }),
    });
  }

  getHistoricScore(start, end, scoreType) {
    start = new Date(`${ new Date(start).toISOString().substr(0, 10)}T00:00:00.000Z`).getTime();
    end = new Date(`${ new Date (end).toISOString().substr(0, 10)}T00:00:00.000Z`).getTime();
    return this.http.get(`${env.API_PATH}/api/stats/v1/charts/score/${scoreType}?start=${start}&end=${end}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('id')}`
      }),
    });
  }
}

import { HttpHeaders } from '@angular/common/http'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import es from 'javascript-time-ago/locale/es'
import { version } from '../../../package.json'
import { environment as env } from '../../environments/environment'
declare let platform;
declare let window;

const emailregex: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const successStatus = status => status >= 200 && status < 300;

const countrySpecifics = {
    US: {
        feedbackPhone: env.US.FEEDBACK_PHONE,
        zendeskScript: _ => {
            const head = document.getElementsByTagName('head')[0];
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.id = 'ze-snippet';
            script.src = `https://static.zdassets.com/ekr/snippet.js?key=${env.US.ZENDESK_KEY}`;
            head.append(script);
        },
        zohoScript: _ => {
          const head = document.getElementsByTagName('head')[0];
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.id = 'zsiqchat';
          script.innerHTML = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siqf0f0ca1303ae15b015d5378ddbb8220f5c014e6c692cf4b5eadf7a2bdb6dd353",
                        values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;
                        s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);`;
          head.append(script);

          window.$zoho.salesiq.ready = function() {
            window.$zoho.salesiq.floatbutton.visible("hide");
          }
          return window.$zoho;
        }
    }
};

const trimmedLang = (lang: string = localStorage.getItem('LANG') || 'es_CL') => {
    return lang.split('_')[0];
};

const appDateConfig = key => {
    const lang = trimmedLang();
    const obj = {
        'es': {
            locale: es,
            format: 'dd-MM-yyyy',
            tripHeaderDateFormat: 'DD MMM YYYY'
        },
        'en': {
            locale: en,
            format: 'MM-dd-yyyy',
            tripHeaderDateFormat: 'MMM DD YYYY'
        }
    };
    return obj[lang][key];
};

const timeAgo = date => {
    TimeAgo.addLocale(appDateConfig('locale'));
    return new TimeAgo(trimmedLang()).format(date);
};

const timeOut = time => new Promise(r => setTimeout(r, time));

const momentLang = () => {
    return trimmedLang();
};

const HEADER_OPTIONS = (token: string = localStorage.getItem('id')) => {
    const lang = localStorage.getItem('LANG') ? localStorage.getItem('LANG').replace('_', '-') : 'en';
    return {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Accept-Language': lang
        })
    };
};

const appStores = [{
    type: 'ios',
    asset: 'assets/img/appstore-badge.svg',
    url: 'https://apps.apple.com/us/app/fleetr/id1449248347'
},
{
    type: 'android',
    asset: 'assets/img/google-play-badge.png',
    url: 'https://play.google.com/store/apps/details?id=com.jooycar.Fleetr&hl=en'
}
];

const RTS_status = ['TripDriving', 'TripStopped', 'Disconnected', 'Unknown'];

const platformOptions = {
    appV: version,
    os: platform.os.family,
    osV: platform.os.version,
    devModel: platform.product,
    res: `${window.screen.availWidth}x${window.screen.availHeight}`,
    locale: navigator.language
};

const MsToTime = duration => {
    const minutes: any = Math.floor((duration / (1000 * 60)) % 60);
    const hours: any = Math.floor((duration / (1000 * 60 * 60)) % 24);
    return `${hours ? `${hours}h` : ''} ${minutes ? `${minutes}m` : ''}`;
};

const scrollPages = 10;

const routerPrefix = 'fleetr://fleetr/';

const calcDistance = (lat1, lng1, lat2, lng2, index) => {
    return {
        distance: Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1)) * 6371,
        index
    };
};

const parsedDashboardData = (types, values, filter) => {
    const transformed = types.map(t => {
        const value = values.find(v => t.id === v.type);
        if (value.value) {
            value.value = isFloat(value.value) ? value.value.toFixed(2) : value.value;
        }
        return value.value ? Object.assign({}, t, { filter }, {
            value: Object.assign({}, value, {
                percentDifference: value.prevPeriod && value.prevPeriod.comparisons ?
                    Number(value.prevPeriod.comparisons.find(_ => _.type === 'percentDifference').value) :
                    0
            }
            )
        }) : null;
    });
    return transformed;
};

const isFloat = n => Number(n) === n && n % 1 !== 0;

const checkAlertSettings = (settings, notifications) => {
    if (!settings || !settings.silenceAlerts) { return notifications; }
    return notifications.filter(_ => !settings[_.type]);
};

const addCatAndType = (notifications, categoryTypes) => {
    const categories = Object.keys(categoryTypes);
    return notifications.map(_ => {
        const notification = Object.assign({}, _, {
            category: categories[Math.round(Math.random() * 1)]
        });
        const types = categoryTypes[notification.category];
        notification['type'] = types[Math.round(Math.random() * types.length)];
        return notification;
    });
};

const vehicleSortTypes = ['plate', 'status', 'model', 'brand', 'year'];
const sortVehicles = (sort, vehicles) => vehicles
    .sort((a, b) => a[sort] < b[sort] ? -1 : (a[sort] > b[sort] ? 1 : 0));

const maintenanceStatus = (maintenance, style, maintenanceType) => {
    const statusStyles = {
        background: _ => {
            const status = {
                critical: '#ef6b47',
                bad: '#ceac5e',
                normal: '#73cc4a',
                none: 'inherit'
            };
            const getMaintenance = maintenance.find(m => m.type.type === maintenanceType);
            return status[getMaintenance.status || 'none'];
        },
        width: _ => {
            const percentageKey = { battery: 'lifePercent' };
            const percentage = maintenance
                .find(m => m.type.type === maintenanceType)
                .info[percentageKey[maintenanceType] || 'distancePercent'];
            return `${percentage > 100 ? 100 : percentage}%`;
        }
    };
    return statusStyles[style](maintenance);
};

const categoryTabs = (groups) => {
    return Object.keys(groups.configGroups).map(cg => {
        const labels = groups.configGroups[cg].labels;
        const name = labels[localStorage.getItem('LANG')] || labels['es_CL'];
        const children = groups.configGroups[cg].children;
        const types = Object.keys(children).map(id => Object.assign({}, children[id], { id }));
        return Object.assign({}, {
            name,
            category: groups.configGroups[cg].type,
            types,
            id: cg
        });
    }).map(c => Object.assign({}, c, {
        types: c.types.map(t => Object.assign({}, t, {
            label: t.labels[localStorage.getItem('LANG')] || t.labels['es_CL']
        }))
    }));
};

const toQueryParam = obj => {
    const str = [];
    for (const p in obj) {
        if (obj && !!obj[p] && obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
    }
    return str.join('&');
};

const healthTypes = {
    type: 'engine_error_codes',
    icon: 'heart',
    values: [
        { type: 'all', color: '' },
        { type: 'critical', color: '#DB4343' },
        { type: 'bad', color: '#FFBE32' },
        { type: 'normal', color: '#83DB83' }
    ]
};

const checkForQparam = (key) => {
    const search = location.search.substring(1);
    if (!search.length) { return undefined; }
    const params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","')
        .replace(/=/g, '":"') + '"}');
    return params[key];
};
const geofenceSortTypes = ['Name', 'Polygon', 'Radius'];
const sortGeofence = (sort, geofences) => geofences
    .sort((a, b) =>
        sort == 'name' ? a[sort] < b[sort] ? -1 : (a[sort] > b[sort] ? 1 : 0) :
            sort == 'radius' ? a['geometry']['type'] < b['geometry']['type'] ? -1 : (a['geometry']['type'] > b['geometry']['type'] ? 1 : 0) :
                sort == 'polygon' ? a['geometry']['type'] > b['geometry']['type'] ? -1 : (a['geometry']['type'] < b['geometry']['type'] ? 1 : 0) : 0);


const fEventNameConventions = {
    toolbar: {
        liveview: 'module_liveview',
        dashboard: 'module_dashboard',
        notifications: 'module_notification',
        ranking: 'module_score',
        vehicles: 'module_vehicles',
        drivers: 'module_driver',
        health: 'module_health',
        stats: 'module_performance',
        geofences: 'module_geofence',
        tag: 'module_tag',
        'Add ons': 'add_ons_view'
    },
    profile: {
        fleetConfiguration: 'admin_click_fleetcon',
        adminConfiguration: 'module_admin'
    },
    score: {
        safety: 'module_score_safety',
        efficiency: 'module_score_efficiency',
        security_history: 'module_score_safety_history',
        efficiency_history: 'module_score_efficiency_history'
    },
    performance: {
        "60db1b48ff0c160289c0a328": 'module_performance_QTrips',
        "60db1b30ff0c160289c0a327": 'module_performance_distance',
        "60db1aeaff0c160289c0a323": 'module_performance_time',
        "60db1b57ff0c160289c0a329": 'module_performance_maxspeed',
        "60db1b0aff0c160289c0a325": 'module_performance_speedxmile',
        "60db1b71ff0c160289c0a32b": 'module_performance_HBreakxmile'
    }

};

const roleNames = {
    'admin.fleetrw': 'ADMIN_CONFIGURATION.ROLE_ADMIN',
    'admin.fleetro': 'ADMIN_CONFIGURATION.ROLE_VIEW_ONLY'
};

const satelliteTiles = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';
const mapTilerUrl = `https://api.maptiler.com/maps/basic/style.json?key=${env.MAPTILER_TOKEN}`;

const markerStates = [
    {
        name: 'MARKER_STATUS.DRIVEN_VEHICLE',
        state: 'DRIVEN_VEHICLE',
        plateColor: '#38B6D1'
    },
    {
        name: 'MARKER_STATUS.STOPED_VEHICLE',
        state: 'STOPED_VEHICLE'
    },
    {
        name: 'MARKER_STATUS.OFFLINE_VEHICLE',
        state: 'OFFLINE_VEHICLE'
    }
];

const mainMapConfig = {
    zoomControl: false,
    center: [0, 0],
    zoom: 2,
    maxZoom: 18,
    minZoom: 2
  };

const odometerReadingDigits = 7;

const entities = {
    drivers: {
      validator: _ => _.name && _.email && _.phone && _.emailregex.test(_.email),
      amount: 1
    },
    vehicles: {
      validator: _ => _,
      amount: 1
    }
  };

const setTimeToMidnight = (date: string | number | Date) => {
    let newDate = new Date(date);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    // newDate.setUTCHours(0,0,0,0)
    return newDate;
}


export {
    HEADER_OPTIONS,
    MsToTime,
    RTS_status,
    addCatAndType,
    appDateConfig,
    appStores,
    calcDistance,
    categoryTabs,
    checkAlertSettings,
    checkForQparam,
    countrySpecifics,
    emailregex,
    entities,
    fEventNameConventions,
    geofenceSortTypes,
    healthTypes,
    isFloat,
    mainMapConfig,
    maintenanceStatus,
    mapTilerUrl,
    markerStates,
    momentLang,
    odometerReadingDigits,
    parsedDashboardData,
    platformOptions,
    roleNames,
    routerPrefix,
    satelliteTiles,
    scrollPages, setTimeToMidnight, sortGeofence,
    sortVehicles,
    successStatus,
    timeAgo,
    timeOut,
    toQueryParam,
    trimmedLang,
    vehicleSortTypes
}


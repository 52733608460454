import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { platformOptions } from '../helpers/helpers';
import { HttpClient } from '@angular/common/http';
import { SwPush } from '@angular/service-worker';
import { RequestService } from './request.service';

declare const safari;

@Injectable({
  providedIn: 'root'
})
export class NotiSubscriptionService {
  constructor(
    public http: HttpClient,
    public swPush: SwPush,
    private rs: RequestService
  ) { }

  async subscribe() {
    const isSafari = /constructor/i.test((window as any).HTMLElement)
      || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

    if (!isSafari && 'Notification' in window) {
      this.swPush.requestSubscription({
        serverPublicKey: env.VAPID_KEY
      }).then(async sub => {
        const subscription = JSON.parse(JSON.stringify(sub));
        const fields = Object.assign({
          endpoint: subscription.endpoint,
          uid: subscription.keys.p256dh,
          notifToken: subscription.keys.p256dh,
          auth: subscription.keys.auth,
          type: 'webpush'
        }, platformOptions);

        await this.rs.request({
          method: 'POST',
          endpoint: 'noti_subscription',
          body: fields
        });
      });
    }
  }
}

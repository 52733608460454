<div class="container">
  <div class="setting-icon"></div>
  <h3>{{ 'WEB_NOTIFICATIONS' | translation }}</h3>
  <p *ngIf="!settings.notificationsEnabled">{{ 'WEB_NOTIFICATIONS_INFO' | translation }} {{ 'HERE' | translation }}:</p>
  <button [ngClass]="{ 'disabled-button': !settings?.notificationsEnabled }" class="enable-notifications outlined-button" mat-stroked-button
    (click)="enableNotifications()">
    {{ settings?.label_notificationEnabled | translation }}
  </button> 

  <h3>{{ 'NOTIFICATION_PREFERENCES' | translation }}</h3>
  <button [ngClass]="{ 'disabled-button': !settings?.silenceAlerts }"
    class="outlined-button"
    mat-stroked-button
    (click)="silenceAlerts()">
    {{ settings?.label_silenceAlerts | translation }}
  </button>
  <br>
  <div class="categories">
    <div *ngFor="let c of categoryTabs">
       <h4>{{ c.name | translation }}</h4>
       <mat-checkbox
          *ngFor="let type of c.types"
          [(ngModel)]="settings[type.id]"
          [(indeterminate)]="indeterminate"
          [labelPosition]="'after'"
          (ngModelChange)="setAlerts(settings)"
          >
        {{ type.label }}
      </mat-checkbox>
    </div>
  </div>
</div>
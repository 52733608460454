import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { MatDialog } from '@angular/material/dialog';
import { GeneralModalComponent } from '../../general-modal/general-modal.component';
import { RequestService } from '../../../services/request.service';

@Component({
  selector: 'app-ranking-template',
  templateUrl: './ranking-template.component.html',
  styleUrls: ['./ranking-template.component.css']
})
export class RankingListComponent implements OnInit {

  drivers = [];
  selectedDriver: any;
  trafficLights: any;
  scoreTitle: string;
  data: any;
  rawData: any;
  scoreOptions = [];
  wiState: any;
  contexts: any;

  constructor(
    private gs: GeneralService,
    public dialog: MatDialog,
    private rs: RequestService
  ) {
    this.gs.wiData.subscribe(_ => {
      this.data = _ || {};
      this.rawData = this.data;
      this.trafficLightTransform(this.data);
      // allows click for only scoreTypes (security & effiency)
      if ((this.data || {}).scoreVariable) { this.selectedDriver = null; }
    });
    this.scoreOptions = this.gs.getDriverTrafficLights();
    this.gs.wiState.subscribe(contexts => this.contexts = contexts);
  }

  ngOnInit() {
  }

  async getScores() {
    const res = await this.rs.request({
      method: 'GET',
      endpoint: 'driver_scores'
    });
    this.formatDrivers(res.drivers, res.metadata.title);
  }

  formatDrivers(drivers, title: string = null) {
    this.drivers = drivers.sort((a, b) => {
      const aScore = a.scores.find(s => s.type === this.data.type);
      const bScore = b.scores.find(s => s.type === this.data.type);
      if (!aScore || !bScore) { return null; }
      return aScore.score - bScore.score;
    }).map(_ => {
      const scoreType = _.scores.find(s => s.type === this.data.type) || {};
      return Object.assign({}, _, {
        score: scoreType.score || 0,
        previousScore: scoreType.previousScore || 0,
        trafficLight: scoreType.trafficLight || 'unknown',
      });
    });

    if (!!title) {
      this.scoreTitle = title;
    }
  }

  trafficLightLabel(type) {
    return `TRAFFICLIGHT_${this.data.type}.${type}`;
  }

  goToDriver() {
    this.gs.logScreenEvents('score_click_driver', 'score_click_driver', 'score_click_driver', 'click');
    const driverContext = this.gs.contextOptions().filter(d => d.context === 'drivers');
    this.gs.setWindow({ state: true, extendedState: false, type: 'list', contexts: driverContext, data: this.selectedDriver });
  }

  backToGeneralScore() {
    this.rawData.dataBasedAction = 'generalScore';
    this.gs.setWindowData(this.rawData);
  }

  trafficLightTransform(data) {
    if (!data) { return; }
    this.trafficLights = this.gs.getDriverTrafficLights();
    this.trafficLights = !data.scoreVariable ? this.trafficLights
      .map(tl => {
        return {
          ...tl, drivers: (data.drivers || [])
            .filter(d => d.trafficLight === tl.type)
        };
      }) : [{ drivers: data.drivers.filter(d => d.scoreInfo) }];
  }

  history(type: string = 'score', scoreType) {
    this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '80vw',
      data: { type, scoreType }
    });
  }

  orderDrivers(drivers) {
    return drivers.sort((x, y) => x.rank - y.rank);
  }

  selectDriver(driver) {
    if (this.data.scoreVariable) { return; }
    if ((this.selectedDriver && this.selectedDriver === driver) || !driver) {
      this.selectedDriver = null;
    } else {
      this.selectedDriver = driver;
    }
  }

  openWeekFilter() {
    const { weeksRange: { from, to } } = this.data;
    this.selectedDriver = null;
    const dialogRef = this.dialog.open(GeneralModalComponent, {
      panelClass: ['general-modal', 'week-filter-scores'],
      data: {
        type: 'week_filter',
        from, to,
        prevData: this.data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result || !result.from || !result.to) { return; }
      this.gs.setScoreWeekFilter(result);
    });
  }

}

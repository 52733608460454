'use strict';

import { Injectable } from '@angular/core';
import { GeneralService } from './general.service';
import { WebSocketService } from './web-socket.service';
import { VehicleFrame } from './vehicle-animation.service';

@Injectable({
  providedIn: 'root'
})
export class TripTesterService {
  frames: any = [];
  imei: string = null;
  lastTime = 0;
  delta = 0;
  inProgress = false;

  constructor(
    public gs: GeneralService,
    public webSocketService: WebSocketService
  ) {
    window['replayTrip'] = this.replayTrip.bind(this);
  }

  async sleep(t) {
    return new Promise(r => setTimeout(r, t));
  }

  setTrip(imei, trip) {
    this.imei = imei;
    this.frames = trip.keyframes
      .filter(_ => _.loc)
      .map(k => new VehicleFrame(k.loc[1], k.loc[0], k.time))
      .sort((a, b) => a.time - b.time);
    this.lastTime = Math.max(this.lastTime, Date.now());
    this.delta = this.lastTime - this.frames[0].time;
  }

  sendFrames(locs, status, time) {
    this.webSocketService.sendTestMessage(this.imei, {
      imei: this.imei,
      stt: status,
      t: time / 1000,
      locs: locs.map( _ => [
        Math.round(( _.time + this.delta ) / 1000),
        _.loc[0],
        _.loc[1], 10
      ])
    });
  }

  async replayTrip(size: number = 15, time: number = 60) {
    let isActive = true;
    if (!this.frames.length) {
      console.log( 'No frames available to replay! Please select another trip' );
      return;
    }

    if (this.inProgress) {
      console.log( 'Replay still in progress, pending:', this.frames.length );
      return;
    }

    this.inProgress = true;

    this.sendFrames([ this.frames[ 0 ]], 1, this.frames[ 0 ].time );
    await this.sleep( 5000 );

    let lastTime: number = this.frames.slice(-1)[ 0 ].time;
    while (this.frames.length > 0 || isActive) {
      if ( this.frames.length === 0 ) {
        isActive = false;
      }
      const pulled = this.frames.splice( 0, size );
      lastTime = this.frames.length ? this.frames.slice(-1)[ 0 ].time : lastTime + 1000;
      this.sendFrames( pulled, this.frames.length ? 1 : 0, lastTime );
      await this.sleep( time * 1000 );
    }
    this.lastTime = lastTime;
    console.log( 'Replay finished! :)' );
    this.inProgress = false;
  }
}


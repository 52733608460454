import { Component, Output, EventEmitter } from '@angular/core';
import { GeneralService } from '../../../../services/general.service';
import { HomeService } from '../../../../services/home.service';
import { MarkerService } from '../../../../services/marker.service';
import { VehicleService } from '../../../../services/vehicle.service';
import { GeofencesService } from '../../../../services/geofences.service';

@Component({
  selector: 'app-geofencingvehicles-template',
  templateUrl: './geofencingvehicles-template.component.html',
  styleUrls: ['./geofencingvehicles-template.component.css']
})
export class GeofencingTemplateComponent {
  @Output() setVehicleList = new EventEmitter<any>();
  vehicleHealth = [
    { name: 'Buena', type: 'good', icon: 'salud-buena.svg' },
    { name: 'Mala', type: 'bad', icon: 'salud-mala.svg' },
    { name: 'Estado critico', type: 'critical', icon: 'saludmuy-mala.svg' }
  ];
  vehicleIds: any;
  wiData: any;
  contexts: any;
  map: any;
  shwoShifts: boolean;
  shifts: any[] = [];
  selected: any;
  weekDays: any = [
    { day: 'Dom' },
    { day: 'Lun' },
    { day: 'Mar' },
    { day: 'Mié' },
    { day: 'Jue' },
    { day: 'Vie' },
    { day: 'Sáb' }
  ];

  showingRoute: boolean;
  mGroup: any;
  findText: string;
  sort: any;
  addedVehicleList = [];
  temp: any;
  vehicleList: any;
  wiState: boolean;
  wiType: any;
  filterList: any;

  constructor(
    public gs: GeneralService,
    public homeService: HomeService,
    public markerService: MarkerService,
    public geofencesService: GeofencesService,
    public vs: VehicleService
  ) {
    this.gs.getVehicleSort.subscribe(_ => this.sort = _);
    this.vs.getCurrentVehicles.subscribe(vehicles => this.vehicleList = vehicles);
  }

  addVehicle(vehicle, index) {
    let rowIndex = this.vehicleList.findIndex(x => x.brand === vehicle.brand);
    if (!this.findText) {
      rowIndex = index;
    }
    this.findText = '';
    this.addedVehicleList.push(vehicle);
    this.vehicleList.splice(rowIndex, 1);
    this.setVehicleList.emit(this.addedVehicleList);
  }

  removeVehicle(vehicle, index) {
    this.addedVehicleList.splice(index, 1);
    this.vehicleList.push(vehicle);
    this.setVehicleList.emit(this.addedVehicleList);
  }

  async setAddedVehicals(vehicleIds = []) {
    this.addedVehicleList = [];
    vehicleIds.forEach(element => {
      const vehicle = (this.vehicleList || []).find(x => x.id == element);
      if (!vehicle) { return; }
      this.addedVehicleList.push(vehicle);
      this.vehicleList.splice(this.vehicleList.findIndex(v => v.id === vehicle.id), 1);
    });
  }
}

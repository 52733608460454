import { Component } from '@angular/core';
import { GeneralService } from '../../services/general.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'side-info-window',
  templateUrl: './side-info-window.component.html',
  styleUrls: ['./side-info-window.component.css']
})

export class SideInfoWindowComponent {
  private suscription: Subscription = new Subscription;
  type: string;
  data: any;
  contexts: any[];
  wiState: boolean;
  opening: boolean;
  geofenceListRO: boolean;
  constructor(public gs: GeneralService) {
    // Window state observable
    this.suscription.add(this.gs.getWiState.subscribe((data: boolean) => this.wiState = data ));
    // Window type observable
    this.suscription.add(this.gs.getWiType.subscribe((data: string) =>  {
      this.type = data;
      this.geofenceListRO = this.type === 'create_geofences' && this.gs.checkPermission(['admin.fleetro']);
    }));
    // Window context observable
    this.suscription.add(this.gs.getWiContext.subscribe((data: any) => this.contexts = data ));
  }

  ngOnDestroy() {
    this.suscription.unsubscribe();
  }

  cancelCreation() {
    this.gs.setWindow({ state: true, extendedState: false, type: 'list', contexts: this.gs.contextOptions() });
  }

}

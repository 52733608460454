<button class="sort" mat-button [matMenuTriggerFor]="sort">
  <mat-icon> <img src="assets/img/filterorder.svg" alt=""> </mat-icon>
  <span *ngIf="selected">{{ 'ORDER_BY' | translation }} - {{ selected | translation }}</span>
  <span *ngIf="!selected">{{ 'ORDER_BY' | translation }}:</span>
</button>
<mat-menu #sort="matMenu" xPosition="after">
  <button [ngClass]="{ 'selected': selected === sort }" *ngFor="let sort of geofenceSortTypes"
    mat-menu-item (click)="selectSort(sort)">
      {{ sort | translation }}
  </button>
</mat-menu>
import { Component, Input, OnInit } from '@angular/core'
import { MatEndDate, MatStartDate } from '@angular/material/datepicker'
import { SwPush, SwUpdate } from '@angular/service-worker'
import { Moment } from 'moment'
import { categoryTabs } from '../../helpers/helpers'
import { DateRageFilter } from '../../models/filters.model'
import { FiltersPipe } from '../../pipes/filters.pipe'
import { TranslationPipe } from '../../pipes/translation/translation.pipe'
import { GeneralService } from '../../services/general.service'
import { NotificationsService } from '../../services/notifications.service'
import { RequestService } from '../../services/request.service'

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
  providers: [FiltersPipe, TranslationPipe]
})
export class NotificationsComponent implements OnInit {
  @Input('isFreemium') isFreemium: boolean;
  findText: string;
  notifications: any = [];
  notiPrev: any;
  notiNext: any;
  categoryTabs: any;
  selectedCategory: string;
  allNotifications: any = [];
  contexts: any;
  wiData: any;
  categoryTypes: any;
  settings: any;
  dateFilter: any = {};
  groups: any;
  notificationFilter: any;
  notiFilter: DateRageFilter = {};
  rangeDate: any = {
    min: new Date(new Date().setFullYear((new Date().getFullYear() - 10))),
    max: new Date(),
  };
  unreadNotifications: number;
  notificationSpinner: boolean;
  constructor(
    public gs: GeneralService,
    public filter: FiltersPipe,
    public swPush: SwPush, public swUpdate: SwUpdate,
    public ns: NotificationsService,
    public rs: RequestService,
    private ts: TranslationPipe
  ) { }

  ngOnInit() {
    this.gs.getWiFindText.subscribe(_ => this.findText = _);
    this.gs.getWiContext.subscribe((data: any[]) => this.contexts = data );
    this.gs.getWiData.subscribe((data: any) => this.wiData = data || {});
    if (this.swUpdate.isEnabled) {
      this.swPush.messages.subscribe((n: any) => {
        this.notifications.unshift(n.notification);
        this.ns.setUnreadNotifications(this.unreadNotifications + 1);
      });
    }

    this.getNotifications({ refresh: true });
    this.getNotificationGroups();
    this.gs.logScreenEvents('notification_view','notification_view','notification_view','view');
  }

  setDateFilter(evt) {
    if (evt.target instanceof MatStartDate) {
      this.notiFilter['from'] = evt.value;
    }

    if (evt.target instanceof MatEndDate) {
      this.notiFilter['to'] = evt.value;
      this.notiFilter = { ...this.notiFilter, group: this.notiFilter.group };
      this.getNotifications({ refresh: true });
    }
  }

  setGroup(group) {

    if (group.category === 'all') {
      this.notiFilter.group = undefined;
    } else {
      this.notiFilter.group = group.id;
    }

    this.getNotifications({ refresh: true });
  }

  clearDate() {
    this.notiFilter = { group: this.notiFilter.group };
    this.getNotifications({ refresh: true });
  }

  async getNotificationGroups() {

    this.groups = await this.rs.request({
      method: 'GET',
      endpoint: 'noti_groups'
    });
    this.categoryTabs = categoryTabs(this.groups);
    this.categoryTabs.unshift({ name: this.ts.transform('ALL'), category: 'all' });
    this.selectedCategory = this.categoryTabs[0].category;
  }

  async getNotifications(opts: any = {}) {

    this.notificationSpinner = true;
    const id = this.notiPrev ? this.notiPrev : null;
    const notiFilter = Object.assign({}, this.getTimeFromTo(this.notiFilter), { group: this.notiFilter.group });

    const notifications = await this.rs.request({
      method: 'GET',
      endpoint: 'notifications_list',
      id: opts.refresh ? undefined : id,
      notiFilter,
    });

    if (notifications.error) {
      this.gs.openAlert({ msg: 'NOTI_EMPTY', error: true });
      this.notificationSpinner = false;
      return;
    }
    if (!this.notifications.length || opts.refresh) {
      this.notifications = notifications['messages'].sort((a, b) => b.created - a.created);
    } else {
      this.notifications = this.notifications.concat(notifications['messages'].sort((a, b) => b.created - a.created));
    }

    this.notifications = this.setDateHeaders(this.notifications);

    if (!!this.notifications.length) { this.notifications[0].first = true; }

    this.notiPrev = notifications['prev'];
    this.notiNext = notifications['next'];
    this.unreadNotifications = notifications.unread;
    this.notificationSpinner = false;
    this.ns.setUnreadNotifications(this.unreadNotifications);
  }

  setDateHeaders(notifications) {

    const headers = [];
    notifications.reduce((acc, curr, i) => {
      if (i === 0) { headers.push(i); }
      if (flatDate(curr.created) !== flatDate(acc.created)) { headers.push(i); }
      return curr;
    }, { created: Date.now() });
    headers.map(n => {
      return notifications[n].dateHeader = true;
    });
    return notifications;
  }

  selectCategory(category, notifications) {

    this.selectedCategory = category;
    if (category === 'all') { return notifications; }
    return notifications.filter(_ => _.category === category);
  }

  openSettings() {
    this.gs.logScreenEvents('notification_click_setup', 'notification_click_setup', 'notification_click_setup', 'click');
    if (this.wiData && this.wiData.type === 'notificationSettings') {
      this.gs.setWindow({
        state: true, extendedState: false, type: 'list', contexts: this.contexts,
        data: undefined
      });
    } else {
      this.gs.setWindow({
        state: true, extendedState: true, type: 'list', contexts: this.contexts,
        data: Object.assign({}, this.wiData, {
          type: 'notificationSettings'
        })
      });
    }
  }

  trackByFn(_, item) { return item.id; }

  // implement read all notification functionality
  async clearNotification() {
    this.notificationSpinner = true;
    await this.ns.markAsViewed().
      subscribe(async (res: any) => {
        if (res.status === 200) {
          this.notifications.forEach(i => {
            if (!i.viewed) {
              i['viewed'] = Date.now();
            }
          });

          this.ns.setUnreadNotifications(0);
          this.notificationSpinner = false;
          this.unreadNotifications = 0;
        }
      });
  }

  // implement enabled and desabled functionality for read all notification
  checkNotification(): boolean {
    if (this.unreadNotifications > 0) {
      return false;
    } else {
      return true;
    }
  }

  private getTimeFromTo({ from, to }: { from?: Moment, to?: Moment }) {
    if (!from || !to) {
      return {}
    }

    return {
      from: from.valueOf(),
      to: to.endOf('day').valueOf()
    }
  }
}

const flatDate = _ => {
  const day = new Date(_).getDay();
  const month = new Date(_).getMonth();
  const year = new Date(_).getFullYear();
  const date = `${year}-${month}-${day}`;
  return date;
};

<div class="backdrop">
  <div class="tag-delete-alert">
    <button (click)="close()">
      <img src="/assets/img/icons/cerrar.svg" alt="close">
    </button>
    <p class="tag-delete-alert-title">{{ title }}</p>
    <p class="tag-delete-alert-subtitle">{{ subtitle }}</p>
    <p class="tag-delete-alert-content">{{ content }}</p>
    <div class="buttons">
      <button class="delete" (click)="delete()">{{ button }}</button>
    </div>
  </div>
</div>

<div class="d-flex setBtnAlign subheader-grid ">
  <button mat-button (click)="clearNotification()"
    class="mat-button pr-0 disableIcon" [disabled]="checkNotification()">
    <div class="notifications-shortcut">
      <div *ngIf="unreadNotifications && contexts?.length === 1">
        <img src="assets/img/checked.png" class="checked" alt="checked">
        <span class="Mark-all-as-you">{{ 'MARK_ALLASREAD' | translation }}</span>
      </div>
      <div *ngIf="!unreadNotifications  && contexts?.length === 1">
        <img src="assets/img/checked-disabled.png" class="checked" alt="checked"><span class="Mark-all-as-you">{{ 'MARK_ALLASREAD' |
          translation }}</span>
      </div>
    </div>
  </button>
  <div class="filters" *ngIf="contexts.length === 1">
    <div [ngClass]="{'no-date-filter': !notiFilter?.from || !notiFilter?.to }" (click)="picker.open()">

      <img src="assets/img/noti-icons/noti-datesearch.svg" alt="silenceAlerts">
      <mat-form-field class="hidden">
        <mat-date-range-input [rangePicker]="picker" [min]="rangeDate.min" [max]="rangeDate.max">
          <input matStartDate [value]="notiFilter.from" (dateChange)="setDateFilter($event)"/>
          <input matEndDate [value]="notiFilter.to" (dateChange)="setDateFilter($event)"/>
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker touchUi #picker></mat-date-range-picker>
      </mat-form-field>


    </div>
    <div class="clear-date" (click)="clearDate()" *ngIf="notiFilter && notiFilter?.from && notiFilter?.to"> x </div>
  </div>
</div>
<div class="notification-spinner" *ngIf="notificationSpinner">
  <mat-progress-spinner diameter="30" [mode]="'indeterminate'"></mat-progress-spinner>
</div>
<mat-accordion>
  <p class="small text-center" *ngIf="!notificationSpinner && !notifications.length">
    {{ 'NOTI_EMPTY' | translation }}
  </p>
  <notification-template *ngFor="let notification of notifications | filters: findText; trackBy: trackByFn"
    [notification]="notification" [isFreemium]="isFreemium"></notification-template>
</mat-accordion>

<button [disabled]="!notiPrev" [ngClass]="{ 'button-disabled': !notiPrev }"
  *ngIf="contexts.length === 1 && notifications.length" class="outlined-button fixed-bottom" mat-stroked-button
  (click)="getNotifications()">
  {{ 'LOAD_MORE' | translation }}
</button>

<div class="settings" (click)="openSettings()" *ngIf="contexts.length === 1">
  <img src="assets/img/noti-icons/settings.svg" alt="settings">
  {{ 'EDIT_SETTINGS' | translation }}
</div>

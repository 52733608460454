<div class="container" class="container" [@listAnimation]="messages?.length"> 

  <div class="msgcontainer {{ m.type }}" *ngFor="let m of messages">

    <div *ngIf="m.bubble" class="bubblecontainer {{ m.from }}">
      <button mat-button *ngIf="m.edit"> {{ 'CHANGE' | translation }} </button>
      <div class="bubble">
        <span *ngIf="m.text">{{ m.text }}</span>
        <span *ngIf="!m.text">{{ 'ADDING_PROCESS.'+m.type | translation }}</span>
      </div>
    </div>

    <div *ngIf="m.type === 'adding_type'" class="adding-type">
      <button mat-raised-button *ngFor="let t of addTypes"
      (click)="addType(t)">
        {{ 'ADDING_PROCESS.'+t | translation }}
      </button>
    </div>

  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../services/general.service';
import { RequestService } from '../../services/request.service';
import { MatDialog } from '@angular/material/dialog';
import { GeneralModalComponent } from '../general-modal/general-modal.component';
import { translationPipe } from '../../pipes/translation/translation.pipe';
import { VehicleService } from '../../services/vehicle.service';
import {odometerReadingDigits} from '../../helpers/helpers';
@Component({
  selector: 'app-vehicle-maintenance',
  templateUrl: './vehicle-maintenance.component.html',
  styleUrls: ['./vehicle-maintenance.component.css']
})

export class VehicleMaintenanceComponent implements OnInit {
  vehicle: any = {};
  criticalMaintenances: number;
  maintenanceTypes = [];
  // mocked data
  odometerValue: number;
  odometerUnit: string;
  odometerDisplay: any;
  maintenances = [];
  statusColors = { ok: '#83DB83', warn: '#FFBE32', critical: '#DB4343' };
  maintenancesNeeded: number;
  vehicleOdometer: number;
  vehicles: any = [];

  constructor(
    private gs: GeneralService,
    private rs: RequestService,
    public dialog: MatDialog,
    private vs: VehicleService
  ) {
    this.vs.getCurrentVehicles.subscribe(vehicles => {
      this.vehicles = vehicles;
    });
  }

  ngOnInit() {
    this.gs.getWiData.subscribe(data => {
      this.vehicle = data || {};
      if (!this.vehicle.odometer) { return; }
      this.odometerUnit = this.vehicle.odometer.unit;
      this.setOdometerValues();
    });
    this.getMaintenanceTypes();
  }

  numberFormater(x) {
    return Number(x).toLocaleString((localStorage.getItem('LANG') || 'es_CL').replace('_', '-'));
  }

  setOdometerValues() {
    this.odometerValue = this.vehicle.odometer.reading || 0;
    this.odometerDisplay = (this.vehicle.odometer.reading ? parseFloat(String(this.vehicle.odometer.reading)).toFixed(0) : '-------')
      .split('')
      .filter(c => c !== '.')
      .map(o => ({number: o}));

    let remainingZeros: any = odometerReadingDigits - this.odometerDisplay.length;
    if (remainingZeros) {
      remainingZeros = Array.from(Array(remainingZeros).keys()).map(_ => {
        return {number: 0};
      });
      this.odometerDisplay = [...remainingZeros, ...this.odometerDisplay];
    }
    this.odometerDisplayAnimation();
    this.odometerUnit = this.vehicle.odometer.unit;
  }

  odometerDisplayAnimation() {
    Promise.all(this.odometerDisplay.map(async (o, i) => {
      await this.gs.timeOut(100 * i);
      o.maskOff = true;
    }));
  }

  async getMaintenances() {
    try {
      const { maintenances, error } = await this.rs.request({
        method: 'GET',
        endpoint: 'get_vehicle_maintenances',
        id: this.vehicle.id
      });
      if (error) {
        return this.gs.openAlert({
          msg: translationPipe.transform('MAINTENANCE.ERROR_EMPTY_ODOMETER'),
          error: true,
          duration: 5000
        });
      }
      this.maintenances = (maintenances || []).map(m => {
        return {
          ...m,
          metrics: {
            startOdometer: Math.round(m.metrics.startOdometer),
            endOdometer: Math.round(m.metrics.endOdometer)
          },
          maintenanceType: (this.maintenanceTypes.find(mt => mt.id === m.maintenanceType) || {}).name,
          maintenanceId: (this.maintenanceTypes.find(mt => mt.id === m.maintenanceType) || {}).id,
          start: Math.round(m.metrics.startOdometer),
          end: Math.round(m.metrics.endOdometer),
          current: m.progress,
          currentPecentage: m.progress
        };
      });
      this.maintenancesNeeded = this.maintenances.filter((m: any) => m.currentPecentage >= 100).length;
    } catch (error) {
      this.gs.openAlert({
        msg: translationPipe.transform('MAINTENANCE.ERROR_EMPTY_ODOMETER'),
        error: true,
        duration: 5000
      });
    }
  }

  async getMaintenanceTypes() {
    const { maintenanceTypes } = await this.rs.request({
      method: 'GET',
      endpoint: 'get_maintenance_types'
    });
    if ( !maintenanceTypes ) {
      return this.gs.openAlert({
        msg: translationPipe.transform('MAINTENANCE.ERROR_TYPES'),
        error: true,
        duration: 3000
      });
    }
    this.maintenanceTypes = maintenanceTypes;
    this.getMaintenances();
  }

  deleteMaintenance(maintenance) {
    const dialogRef = this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '350px',
      data: { type: 'delete_maintenance', maintenance, vehicle: this.vehicle }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.refreshMaintenances) {
        this.getMaintenances();
      }
    });
  }

  openMaintenance(maintenance) {
    const data = {
      maintenanceTypes: this.maintenanceTypes,
      maintenances: this.maintenances,
      maintenance,
      type: 'edit_maintenance',
      odometer: this.odometerValue,
      vehicle: this.vehicle,
      odometerUnit: this.odometerUnit
    };
    const dialogRef = this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '50vw',
      data
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.reading) {
        this.vehicle.odometer.reading = result.reading;
        this.setOdometerValues();
        const vehicles = this.vehicles.map(v => {
          return v.id === this.vehicle.id ? this.vehicle : v;
        });
        this.vs.setCurrentVehicles(vehicles);
      }
      this.getMaintenances();
    });
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { translationPipe } from '../../../pipes/translation/translation.pipe';
import { GeneralService } from '../../../services/general.service';
import { RequestService } from '../../../services/request.service';
import { GeneralModalComponent } from '../../general-modal/general-modal.component';

@Component({
  selector: 'app-edit-maintenance',
  templateUrl: './edit-maintenance.component.html',
  styleUrls: ['./edit-maintenance.component.css']
})
export class EditMaintenanceComponent implements OnInit {

  @Input() dialogRef: any;
  @Input() data: any;
  odometerEdit: boolean;
  disableTypeEdit: boolean;
  oldOdometer: number;

  constructor(
    private gs: GeneralService,
    private rs: RequestService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.odometerEdit = this.data.maintenance.odometerEdit;
    this.disableTypeEdit = this.data.maintenance && this.data.maintenance.status;
    const { odometer: oldOdometer } = this.data;
    this.oldOdometer = oldOdometer;
  }

  async validateOdometerUpdate() {
    const { odometer } = this.data;
    const heighest = Math.max.apply({}, this.data.maintenances.map(m => m.start));
    if (Number(odometer) < heighest) {
      this.openHigherOdometerValidatorModal();
    } else {
      this.openOdometerValidatorModal();
    }
  }

  async saveOdometer() {
    try {
      const { odometer } = this.data;
      const data = { reading: Number(odometer), unit: this.data.odometerUnit };
      if (!data.reading) {
        this.dialogRef.close();
        return;
      }
      const req = await this.rs.request({
        method: 'PUT',
        endpoint: 'update_odometer',
        id: this.data.vehicle.id,
        body: data
      });
      if (req.error) {
        return this.gs.openAlert({
          msg: translationPipe.transform('MAINTENANCE.ODOMETER_SAVED_ERROR'),
          duration: 3000,
          error: true
        });
      }
      this.gs.openAlert({
        msg: translationPipe.transform('MAINTENANCE.ODOMETER_SAVED'),
        duration: 3000
      });
      this.dialogRef.close(data);
    } catch (error) {
      console.log('EditMaintenanceComponent.saveOdometer', error);
      this.gs.openAlert({
        msg: JSON.stringify(error),
        duration: 3000,
        error: true
      });
    }
  }

  openOdometerValidatorModal(wrongMetrics = false) {
    const dialogRef = this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '350px',
      data: { type: 'validate_odometer_edit', wrongMetrics }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirm) {
        this.saveOdometer();
      }
    });
  }

  openHigherOdometerValidatorModal() {
    this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '350px',
      data: { type: 'validate_higher_odometer_edit' }
    });
  }

  maintenanceAction() {
    if (this.data.maintenance && this.data.maintenance.status) {
      this.saveMaintenance();
    } else {
      this.createMaintenance();
    }
  }

  validateOdometerReading(skip = null) {
    this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '350px',
      data: { type: 'validate_higher_odometer_edit', higherOdometerReading: true }
    }).afterClosed().subscribe(result => {
      if (result && result.confirm) {
        this.createMaintenance(skip);
      }
    });
  }

  maintenanceBody(skip) {
    const { maintenance } = this.data;
    const body = {
      id: maintenance.id,
      maintenanceType: maintenance.maintenanceId,
      vehicle: this.data.vehicle.id,
      metrics: {
        startOdometer: maintenance.start,
        endOdometer: maintenance.end
      }
    };

    if (body.metrics.startOdometer >= body.metrics.endOdometer) {
      this.openOdometerValidatorModal(true);
      return null;
    }

    if (body.metrics.startOdometer > this.oldOdometer && !skip) {
      this.validateOdometerReading(true);
      return null;
    }
    return body;
  }

  async saveMaintenance(skip = null) {
    try {
      const body =  this.maintenanceBody(skip);
      if (!body) { return; }
      const { error } = await this.rs.request({
        method: 'PATCH',
        endpoint: 'update_maintenance',
        body
      });
      if (error) {
        this.maintenanceError('MAINTENANCE.MAINTENANCE_SAVE_ERROR', true);
        return;
      }
      const reading = body.metrics.startOdometer > this.oldOdometer ? body.metrics.startOdometer : undefined;
      this.maintenanceError('MAINTENANCE.MAINTENANCE_SAVED');
      this.dialogRef.close({ refreshMaintenances: true, reading });
    } catch (error) {
      this.maintenanceError(JSON.stringify(error), true);
    }
  }

  async createMaintenance(skip = null) {
    try {
      const body =  this.maintenanceBody(skip);
      if (!body) { return; }
      if (!body.maintenanceType || body.maintenanceType === '') {
        this.maintenanceError('MAINTENANCE.MAINTENANCE_CREATE_MISSING_TYPE', true);
        return;
      }

      if (!body.metrics.startOdometer) {
        this.maintenanceError('MAINTENANCE.MAINTENANCE_CREATE_MISSING_CURRENT_ODOMETER', true);
        return;
      }

      if (!body.metrics.endOdometer) {
        this.maintenanceError('MAINTENANCE.MAINTENANCE_CREATE_MISSING_THRESHOLD', true);
        return;
      }

      const { error } = await this.rs.request({
        method: 'POST',
        endpoint: 'create_maintenance',
        body
      });

      if (error) {
        this.maintenanceError('MAINTENANCE.MAINTENANCE_CREATE_ERROR', true);
        return;
      }
      const reading = body.metrics.startOdometer > this.oldOdometer ? body.metrics.startOdometer : undefined;
      this.maintenanceError('MAINTENANCE.MAINTENANCE_CREATED');
      this.dialogRef.close({ refreshMaintenances: true, reading });
    } catch (error) {
      this.maintenanceError(JSON.stringify(error), true);
    }
  }

  maintenanceError(msg, error = false) {
    this.gs.openAlert({
      msg: translationPipe.transform(msg),
      duration: 3000,
      error
    });
  }

}

<div class="container" [ngClass]="{ 'trips_opened': type == 'trips', 'single_trip': entity?.type == 'trip' }">
  <!--Header -->
  <mat-list class="header">
    <mat-list-item>
      <!-- back -->
      <button mat-icon-button (click)="closeTrips()">
        <mat-icon mat-list-icon>arrow_back_ios</mat-icon>
      </button>
      <!-- avatar -->
      <mat-icon *ngIf="entity?.type === 'vehicle'"> <img src="/assets/img/icons/autoconductor-listas.svg"> </mat-icon>
      <mat-icon *ngIf="entity?.type === 'driver'" class="driver-picture"
        [ngStyle]="{ 'background': 'url('+entity?.picture+')'}">
      </mat-icon>
      <!-- name -->
      <span class="name" *ngIf="entity?.type === 'vehicle'"> {{ entity?.brand +' '+ entity?.model }} </span>
      <span class="name" *ngIf="entity?.type === 'driver'"> {{ fullName(entity.name, entity.lastName) }} </span>

      <!-- filter -->

      <div class="filters" *ngIf="contexts.length === 1">
        <div [ngClass]="{'no-date-filter': !dateFilter?.from || !dateFilter?.to }" (click)="picker.open()">
          <img src="assets/img/noti-icons/noti-datesearch.svg" alt="silenceAlerts">
          <mat-date-range-input [rangePicker]="picker" [min]="rangeDate.min" [max]="rangeDate.max" [formGroup]="dateForm">
            <input matStartDate formControlName="from" />
            <input matEndDate formControlName="to" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker touchUi #picker></mat-date-range-picker>
        </div>
        <div class="clear-date" (click)="clearDate()" *ngIf="dateFilter && dateFilter?.from && dateFilter?.to"> x </div>
      </div>

    </mat-list-item>
  </mat-list>
  <!-- Trips -->
  <div #tripsContainer class="trip-list" (scroll)="tripsScroll($event)">
    <div *ngFor="let trip of tripsData"
      (click)="getTripFn(trip.uuid); selectedTrip = trip">
      <span *ngIf="trip.showDate" class="trip-date"> {{ headerDate(trip.startDate) }} </span>
      <ng-container *ngTemplateOutlet="tripTemplate; context: { trip: trip }"></ng-container>
    </div>
  </div>

  <div *ngIf="!tripsData.length" class="no-trips">
    <img src="assets/img/no-trips.svg" alt="">
    <p>{{ 'NO_TRIPS' | translation }}</p>
  </div>

  <span *ngIf="tripsData.length" class="fixed-bottom" [ngClass]="{ 'no-more-trips': disableLoadMore }">
    <mat-spinner *ngIf="requestingTrips" class="finder-spinner"></mat-spinner>
    <span *ngIf="disableLoadMore">{{ 'NO_MORE_TRIPS' | translation }} </span>
  </span>
</div>

<!-- Admin list item -->
<ng-template #tripTemplate let-trip="trip">
  <div class="trip-item"
    [ngClass]="{ 'selected-trip': selectedTrip.uuid === trip.uuid }"  >
    <div class="trip-print">
      <img [src]="getTripThumb(trip)" onerror="this.src='/assets/img/trip_placeholder.png'" alt="trip-static-picture">
    </div>
    <div class="trip-details">
      <div class="trip-header">
        <mat-icon class="picture" [ngStyle]="{ 'background': 'url('+ trip.icon +')'}"></mat-icon>
        <span>{{ trip.alias }}</span>
      </div>
      <div class="trip-info">
        <div class="direction-point">
          <div>B</div>
          <div>{{ trip.endAddress }}</div>
          <div>{{ parseDate(trip.endDate) }}</div>
        </div>
        <div class="direction-point">
          <div class="trip-km-arrow"></div>
          <div>{{ trip.distance.distance }} {{ trip.distance.units }}</div>
          <div>{{ msToTimeHelper(trip.duration) }}</div>
        </div>
        <div class="direction-point">
          <div>A</div>
          <div>{{ trip.startAddress }}</div>
          <div>{{ parseDate(trip.startDate) }}</div>
        </div>
      </div>
      <div class="extra-details" *ngIf="selectedTrip.uuid === trip.uuid && trip.maxVelocity">
        <div><strong>{{ 'MAX_VELOCITY' | translation }}: </strong> {{ trip.maxVelocity | number : '1.2-2' }} {{ 'ABBREVS.VELOCITY_UNIT' | translation }}</div>
      </div>
    </div>
  </div>
</ng-template>

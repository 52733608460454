<div class="header">
  <button mat-icon-button (click)="openProfile()">
    <mat-icon *ngIf="loggedUser?.picture"><img [src]="loggedUser.picture" alt="."></mat-icon>
    <mat-icon *ngIf="!loggedUser?.picture">
      <img class="button-img-filter" src="assets/img/cuenta.svg" alt="account">
    </mat-icon>
    <span>{{ 'ADMIN' | translation }}</span>

  </button>
</div>
<!--  -->
<div class="buttons">
  <div *ngFor="let b of buttons; let index = index">
    <div class="unread" *ngIf="unreadNotifications && b.context === 'notifications'">
      {{ unreadNotifications }}
    </div>
    <button [ngClass]="{
      'selected': contexts.length === 1 && contexts[0].context === b.context
    }" mat-icon-button (click)="infoWindow(b)" *ngIf="b.menu">
      <mat-icon *ngIf="!b.material_icon"> <img class="button-img-filter" [src]="b.icon" [alt]="b.context"></mat-icon>
      <mat-icon *ngIf="b.material_icon"> {{ b.material_icon }} </mat-icon>
      <span>{{ b.context | translation }}</span>
    </button>
  </div>
</div>
<div class="header">
</div>
<iframe *ngIf="removeInitialIframe" class="hidden" [src]="preloadStats"></iframe>

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tag-delete-alert',
  templateUrl: './tag-delete-alert.component.html',
  styleUrls: ['./tag-delete-alert.component.css']
})
export class TagDeleteAlertComponent {

  @Input() title: string
  @Input() subtitle: string
  @Input() content: string
  @Input() button: string
  @Output('delete') deleteEvent = new EventEmitter()
  @Output('close') closeEvent = new EventEmitter()

  delete() {
    this.deleteEvent.emit()
  }

  close() {
    this.closeEvent.emit()
  }
}

<div class="container"> 
  <mat-icon class="logo" *ngIf="!opening">
    <img src="/assets/img/icons/fleetrface.svg" alt="">
  </mat-icon> 

  <mat-icon class="logo" *ngIf="opening">
      <mat-spinner class="finder-spinner"></mat-spinner>
  </mat-icon>

  <input class="finder"
    [(ngModel)]="findText"
    [placeholder]="entityPlaceHolder"
    (click)="openWindowBar()"
    (ngModelChange)="finderEvent()"> 

    
  <mat-form-field>
    <div class="pointer-none">
    <mat-select #contextSelector [(ngModel)]="selectedContext" (ngModelChange)="finderEvent()" multiple>
      <mat-label class="filter-menu">{{ 'FINDER.FILTER_PLACEHOLDER' | translation }}</mat-label>
      <mat-option *ngFor="let co of contextOptions" [value]="co">
        <mat-icon style="margin: 0 0 0 10px"> <img [src]="'assets/img/icons/'+co.icon_blue+'.svg'"> </mat-icon>
        {{co.context | translation}}
      </mat-option>
    </mat-select> 
  </div>
  </mat-form-field> 


  <button *ngIf="findText && findText.length" mat-icon-button class="context-menu clear" (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>

</div>
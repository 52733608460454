<div class="container">
  <h4 class="title" *ngIf="data.addingType === 'add_manually'">{{ 'NEW' | translation }}
    {{ data.singularType | translation }} </h4>
  <h4 class="title" *ngIf="data.addingType === 'add_csv'">{{ 'NEW_MANY' | translation }} {{ data.type | translation }}
  </h4>

  <!-- manually - drivers -->
  <div class="form" *ngIf="data.addingType === 'add_manually' && data.type === 'drivers'">
    <div class="picture" (click)="uploadPicture()" [ngStyle]="{
      'background-image': data.picture ? 'url('+data.picture+')' : 'url(assets/img/camera.svg)'
      }" [ngClass]="{
        'has-picture': !!data.picture,
        'no-picture': !data.picture
      }"></div>
    <mat-form-field>
      <mat-label>{{ 'NAME' | translation }}</mat-label>
      <input matInput type="text" [(ngModel)]="data.name">
      <button mat-button *ngIf="data.name" matSuffix mat-icon-button aria-label="Clear" (click)="data.name=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'PHONE' | translation }}</mat-label>
      <span matPrefix>{{ codeAreas[admin.country] || '+56' }} &nbsp;</span>
      <input type="tel" [(ngModel)]="data.phone" matInput>
      <button mat-button *ngIf="data.phone" matSuffix mat-icon-button aria-label="Clear" (click)="data.phone=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'EMAIL' | translation }}</mat-label>
      <input matInput type="text" [(ngModel)]="data.email">
      <button mat-button *ngIf="data.email" matSuffix mat-icon-button aria-label="Clear" (click)="data.email=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <p class="danger text-center" *ngIf="!!createError">{{ createError }}</p>
    <div class="actions">
      <button mat-raised-button (click)="close(0)"> <mat-icon>clear</mat-icon> </button>
      <button mat-raised-button (click)="create(data.type)"> <mat-icon>done</mat-icon> </button>
    </div>
  </div>
  <!-- CSV - drivers -->
  <div class="form" *ngIf="data.addingType === 'add_csv' && data.type === 'drivers'">
    <p>{{ 'ADDING_PROCESS.ADD_CSV_INFO' | translation }}</p>
    <button class="xls" mat-button (click)="downloadXls()">
      <mat-icon> <img src="assets/img/xls.svg" alt="xls"> </mat-icon>
      {{ 'ADDING_PROCESS.SAMPLE_SHEET' | translation }}
    </button>
    <br *ngFor="let br of [1, 2, 3, 4]">
    <button class="upload" mat-raised-button (click)="uploadXls()">
      {{ 'UPLOAD_FILE' | translation }}
    </button>
  </div>
</div>



import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment as env } from '../../environments/environment';
import { HEADER_OPTIONS } from '../helpers/helpers';
import { Vehicle } from '../models/vehicle.model';
import { TagService } from './tag.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  vehicles = new BehaviorSubject<Vehicle.Vehicle[]>([]);

  get filtreredVehicles(): Observable<Vehicle.Vehicle[]> {
    return this.vehicles
      .asObservable()
      .pipe(map(vehicles => this.tagService.filterVehicles(vehicles)));
  }

  getCurrentVehicles: Observable<Vehicle.Vehicle[]> = this.vehicles.asObservable()

  get updateVehicleFromMap$() {
    return this.vehicles.asObservable().pipe(map(vehicles => {
      const toAdd = this.tagService.filterVehicles(vehicles)
      const toRemove = vehicles.filter(vehicle => toAdd.find(v => v.id === vehicle.id) === undefined);
      return { toAdd, toRemove };
    }));
  }

  get vehiclesToRemove() {
    return this.vehicles.getValue().filter(vehicle => this.tagService.filterVehicles(this.vehicles.getValue()).find(v => v.id === vehicle.id) === undefined);
  }

  constructor(public http: HttpClient, private tagService: TagService) {
    this.tagService.appliedTag$.subscribe(() => {
      // Force to reload map when tags change
      const curr = this.vehicles.getValue()
      this.vehicles.next(curr)
    })
  }

  setCurrentVehicles(vehicles: Vehicle.Vehicle[]) {
    const diff = this.vehicles.getValue().filter(vehicle => vehicles.find(v => v.id === vehicle.id) === undefined);
    this.vehicles.next([...diff, ...vehicles]);
  }

  getAll(): Observable<Vehicle.Vehicle[]> {
    return this.http
      .get(`${env.API_PATH}/api/vehicle/v1/all`, HEADER_OPTIONS()) as Observable<Vehicle.Vehicle[]>;
  }

  getDtc() {
    return {
      compatibility: 1,
      mil: true,
      dtcs: [
        {
          type: `oxygen_sensor`,
          title: `Sensor de Oxígeno`,
          description: `Para poder controlar la mezcla de combustible es necesario un sensor que indique el porcentaje en que cada uno entra en el motor.`,
          descriptionLong: `<p>Los motores de los autos queman combustible, gasolina o diesel, en la presencia de oxígeno. Existe una proporción tanto de aire como de combustible para que la mezcla de ambos sea perfecta.</p><p>Si llegara a haber poco aire en la combinación, se tiene un excedente de combustible después de la ignición a lo que se llama mezcla rica, que es muy contaminante. Si por el contrario hay más aire y menos combustible se denomina mezcla pobre y tiende a generar más contaminantes de óxido de nitrógeno y en algunos casos, causar un bajo desempeño incluso un daño al motor.</p><p>El sensor de oxígeno está colocado en el tubo de escape y sirve para detectar mezclas ricas o pobres. El mecanismo de sus sensores involucra una reacción química que genera un voltaje que es monitoreado por la computadora del motor para determinar el tipo de mezcla y así ajustar la cantidad de combustible que debe entrar al motor.</p><p>Cuando el sensor de oxígeno falla, la computadora ya no puede detectar el rango de aire y combustible, por lo que no regula la cantidad de combustible que deja pasar al motor, con esto el desempeño del auto no es óptimo y consume más combustible del que requiere.`,
          dtcs: [
            {
              cause: `<p>Un código DTC P0141 Genérico puede tener uno a me1s de los siguientes problemas:</p><p><br/></p><ul><li><span id="selectionBoundary_1533655807058_9459055966640688" class="rangySelectionBoundary">&#65279;</span>El sistema de cables del circuito del calentador del O2 (Oxygen Sensor) puede estar en cortocircuito.</li><li>La resistencia del elemento calentador del sensor de oxigeno (O2) quizás es alta.</li><li>Puede que exista un cortocircuito interno en el elemento calentador del sensor de oxígeno.<br/></li></ul>`,
              code: `P0141`,
              cost_max: ``,
              cost_min: ``,
              description: `<p><span></span></p><p>Los sensores de oxígenos (O2S) deben tener una temperatura mínima de 750° F para que de esta forma su funcionamiento sea eficiente y la señal de tensión que produzca sea precisa. Mientras menos sea el tiempo en que el sensor de oxigeno alcance dicha temperatura, más rápido será enviada la señal al ECM (Engine Control module).</p><p><br/></p><p><br/></p><p>Con el objetivo de que lo sensores alcancen la temperatura que necesitan, cuentan con un elemento calentador dentro de este, el cual es controlado por el Módulo del control del motor (ECM) sobre la base de las señales de la temperatura del refrigerante del motor y la carga del motor. De igual forma controla el circuito de este elemento calentador y permite el flujo de corriente a tierra. </p><div><br/></div><div>También maneja la señal de tensión que recibe el circuito del elemento calentador y establece el estado del circuito a través de comparaciones que hacen entre la tensión detectada y las especificaciones de fábrica.<p><br/></p></div>`,
              fix_hr: ``,
              services_cat: [],
              severity: 2,
              suggestion: `<p>¿Por qué sustituir?<br/><br/>La falla de estos sensores, tanto por el envejecimiento normal, la utilización de combustibles con plomo, o debido a la contaminación con combustible por ejemplo. siliconas o silicatos, puede provocar daños en el convertidor catalítico de un automóvil y reparaciones costosas. Un efecto secundario de los sensores de oxígeno es que pueden impedir las tecnologías de ahorro de combustible, que crean una mezcla de aire combustible magra de trabajo.<br/><br/>Los sensores de oxígeno se recomiendan para ser reemplazados después de CADA 50,000 MILLAS - 80.000 KM<br/></p>`,
              suppliers_cat: [],
              symptom: `<ul><li>La luz del Check Engine está encendida<br/></li><li>Puede que el consumo de combustible sea más del habitual.<br/></li></ul>`,
              title: `Sensor de O2 calentador circuito Avería Banco 1 Sensor 2`,
              type: `oxygen_sensor`,
              uuid: `555741 a93b41faac13639df`
            }
          ]
        },
        {
          type: `evaporation_system`,
          title: `Sistema de Evaporación`,
          description: `El sistema de evaporación, también llamado EVAP, atrapa y captura vapores de hidrocarburos en el sistema de combustible, que está relacionado con el control de emisiones.`,
          descriptionLong: `<p>Este sistema recupera los vapores del tanque de gas ventilado, junto con el exceso de vapores expulsados ​​durante el funcionamiento del motor y la carga de combustible. Si los vapores de hidrocarburos no se mantienen contenidos y reciclados, pueden tener fugas a la atmósfera, contribuyendo a la contaminación de ozono.</p>`,
          dtcs: []
        },
        {
          type: `catalyst`,
          title: `Catalizador`,
          description: `El catalizador produce modificaciones químicas en los gases de escape de los automóviles antes de liberarlos a la atmósfera. Estas modificaciones tienen como fin reducir la proporción de algunos gases nocivos que se forman en el proceso de combustión.`,
          descriptionLong: `<p>El catalizador, junto a la gasolina sin plomo, es una de las principales modificaciones introducidas en el funcionamiento de los nuevos automóviles, destinadas a reducir el impacto ambiental de las emisiones contaminantes nocivas de los vehículos.</p>`,
          dtcs: []
        },
        {
          type: `components`,
          title: `Componentes Globales`,
          description: `Algunos de los componentes globales (CCM) o sistemas de un vehículo se comprueban continuamente por el sistema OBDII del vehículo.`,
          descriptionLong: ``,
          dtcs: []
        },
        {
          type: `egr_system`,
          title: `Sistema EGR`,
          description: `La finalidad del sistema de recirculación de gases de escape (EGR) es disminuir las emisiones de gases nocivos al medio ambiente.`,
          descriptionLong: `<p>Debido a la necesidad de promover políticas anticontaminantes y medidas que ayudasen a proteger el medio ambiente, desde los 60 en EEUU y los 90 en Europa, se decidió instalar en los vehículos un sistema de recirculación de gases de escape, al que conocemos como EGR.</p>`,
          dtcs: []
        },
        {
          type: `fuel_system`,
          title: `Sistema de Combustible`,
          description: `El sistema de combustible suministra al motor el combustible que necesita para funcionar. Si alguna de las partes del sistema de combustible falla, el motor no funcionará.`,
          descriptionLong: `<p>EI combustible bombeado desde el tanque de combustible por la bomba de combustible pasa a través de la línea de presión tubería de alta presión y es filtrado en el filtro de combustible. Este es luego distribuido a los inyectores a través de la tubería de entrega. Los inyectores inyectan el combustible dentro del múltiple de admisión.</p>`,
          dtcs: []
        },
        {
          type: `oxygen_heater`,
          title: `Calentador del Sensor de Oxígeno`,
          description: `Cuando falla este calentador, el sensor de oxígeno demora en llegar a su temperatura operacional y no se mantiene trabajando a través de las diferentes condiciones de funcionamiento del motor.`,
          descriptionLong: `<p>Para que el sensor de oxígeno funcione adecuadamente y genere señales de voltaje precisas necesita estar a una alta temperatura (300 °C – 400 °C). El problema se presenta en los primeros minutos de funcionamiento del motor en los cuales el sensor no ha llegado a su temperatura de operación, por lo cual se hace necesario colocarle un calentador que le permita llegar a su temperatura rápidamente. Un elemento PTC (Coeficiente térmico positivo) en el interior del sensor de oxígeno genera calor a medida que pasa corriente a través de el, calentando al sensor.</p>`,
          dtcs: []
        },
        {
          type: `misfire`,
          title: `Fallas de Ignición (misfires)`,
          description: `Se definen como una pérdida de combustión en un cilindro debido a la ausencia de chispa, una medición pobre del combustible, una compresión pobre o cualquier otra causa.`,
          descriptionLong: ``,
          dtcs: []
        },
        //
        {
          type: `canbuscomm`,
          title: `Falla comunicación Can Bus`,
          description: `Se definen como una falla de comunicación entre módulos como ECM (motor), TCM (transmisión), ABS (frenos) EAM (dirección electrónica), etc.`,
          descriptionLong: ``,
          dtcs: []
        },
        {
          type: `tcm`,
          title: `Falla en transmisión automática`,
          description: `Falla TCM (módulo de Transmisión automática).`,
          descriptionLong: ``,
          dtcs: []
        },
        {
          type: `dpf`,
          title: `Filtro de partículas Diesel`,
          description: `El filtro de partículas Diesel es un dispositivo diseñado para eliminar partículas u hollín del gas de escape de un motor Diesel.`,
          descriptionLong: ``,
          dtcs: []
        },
        {
          type: `turbocharger`,
          title: `Turbocompresor`,
          description: `Un turbocompresor o también llamado turbo es un sistema de sobrealimentación que usa una turbina centrífuga para accionar mediante un eje coaxial con ella, un compresor centrífugo para comprimir gases. Este tipo de sistemas se suele utilizar en motores de combustión interna alternativos, especialmente en los motores diésel.`,
          descriptionLong: ``,
          dtcs: []
        },
        {
          type: `airbag`,
          title: `Airbag`,
          description: `Sistema de Airbag.`,
          descriptionLong: ``,
          dtcs: []
        }
      ],
      updated: 1571507402661
    };
  }
}


import { Component } from '@angular/core';
import { GeneralService } from '../../services/general.service';
import { geofenceSortTypes } from '../../helpers/helpers';

@Component({
  selector: 'app-geofence-sorting',
  templateUrl: './geofence-sorting.component.html',
  styleUrls: ['./geofence-sorting.component.css']
})
export class GeofenceSortingComponent {

  selected: any;
  geofenceSortTypes: any = geofenceSortTypes;

  constructor(
    private gs: GeneralService
  ) {
  }

  selectSort(sort) {
    this.selected = sort;
    this.gs.setGeofenceSort(sort);
  }

}

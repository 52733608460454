import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FeedbackService } from '../../services/feedback.service';
import { TranslationPipe } from '../../pipes/translation/translation.pipe';
import { AlertSnackBarComponent } from '../alert-snack-bar/alert-snack-bar.component';
import { GeneralService } from '../../services/general.service';
import { countrySpecifics } from '../../helpers/helpers';
import _ from 'lodash';

// Alert modal class
@Component({
  template: `
  <p> {{ 'ALERT_SEND' | translation }}</p>
  <button style="margin-right: 20px" mat-raised-button (click)="answer(true)">{{ 'ACCEPT' | translation }}</button>
  <button mat-raised-button (click)="answer(false)">{{ 'CANCEL' | translation }}</button>
  `,
})
export class FeedbackAlertModalComponent {
  constructor(public dialogRef: MatDialogRef<FeedbackAlertModalComponent>) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  answer(answer) {
    this.dialogRef.close(answer);
  }
}

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css'],
  providers: [ TranslationPipe ]
})
export class FeedbackComponent {

  feedbackPhone: string;
  admin: any;
  navState: boolean;
  message: string;
  hideFeedback: boolean;

  constructor(
    private feedbackService: FeedbackService,
    public translation: TranslationPipe,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private gs: GeneralService
    ) {
      this.gs.getLoggedUser.subscribe(admin => {
        this.admin = admin;
        const adminCountry = _.get(admin, ['country']);
        this.feedbackPhone = countrySpecifics[ adminCountry ] ?
          countrySpecifics[ adminCountry ].feedbackPhone : null;
      });
  }

  submitMsg() {
    this.feedbackService.sendFeedback(this.message).subscribe( res => {
      this.hideFeedback = true;
      this.openSnackBar(true, this.translation.transform('MSG_SENT'), 5000);
    }, e => {
      this.openSnackBar(false, e.error.error.message, 3000);
    });
  }

  openSnackBar(sent, msg, duration) {
    this._snackBar.openFromComponent(AlertSnackBarComponent, {
      duration, data: msg,
      panelClass: sent ? 'panel-success' : 'panel-error',
      verticalPosition: 'top'
    });
  }

  alert(): void {
    const dialogRef = this.dialog.open(FeedbackAlertModalComponent, {
      width: '90%',
      panelClass: 'feedback-panel',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.submitMsg();
      }
    });
  }

}

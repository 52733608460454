import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { routerPrefix } from '../../../helpers/helpers';
import { GeneralService } from '../../../services/general.service';
import { HomeService } from '../../../services/home.service';
import { NotificationsService } from '../../../services/notifications.service';

@Component({
  selector: 'notification-template',
  templateUrl: './notification-template.component.html',
  styleUrls: ['./notification-template.component.css']
})
export class NotificationTemplateComponent {
  private suscription: Subscription = new Subscription;
  @Input('notification') notification: any;
  @Input('isFreemium') isFreemium: any;
  contexts: any;
  externalRoute: string;
  unreadNotifications: number;
  findText: string;
  settings: any = {};
  dateFilter: any = {};
  wiData: any;

  constructor(
    public gs: GeneralService,
    public route: Router,
    public location: Location,
    private notiService: NotificationsService,
    private homeService: HomeService
    ) {
      this.suscription.add(this.gs.getWiContext.subscribe((data: any[]) => {
      this.contexts = data;
    }));
    this.gs.getWiData.subscribe((data: any) => this.wiData = data || {});
    this.suscription.add(this.notiService.getUnreadNotifications.subscribe( u => this.unreadNotifications = u ));
    this.suscription.add(this.gs.getWiFindText.subscribe(findText => this.findText = findText));
  }

  ngOnDestroy() {
    this.suscription.unsubscribe();
  }

  goToAction(a) {
    this.externalRoute = null;
    let path;
    if ( a.path.indexOf(routerPrefix) > -1 ) {
      path = a.path.split(routerPrefix).join('').split('/');
      this.homeService.actions(path[1] ? { id: path[1] } : undefined, path[0]);
    }
    if ( a.path.match(/(tel|mailto)/) ) {
      this.externalRoute = a.path;
      setTimeout(() => document.getElementById('external-route').click(), 100);
    }
  }

  actionModification(n, a) {
    this.suscription.add(this.notiService.modifyAction({
      bucketId: n.bucketId,
      id: n.id,
      interactionToken: n.interactionToken,
      actionId: a.id
    }).subscribe({
      error: error => {
        if ( error.status !== 200 ) {
          return console.log('NotificationTemplateComponent.actionModification', error.message);
        }
      }
    }));
  }

  callReadNotification(n) {
    if ( n.viewed ) { return; }
    this.suscription.add(this.notiService.readNotification({
      bucketId: n.bucketId,
      id: n.id,
      interactionToken: n.interactionToken
    }).subscribe(() => {
      this.notification.viewed = Date.now();
      this.notiService.setUnreadNotifications(this.unreadNotifications - 1);
    }, error => {
      if ( error.status !== 200 ) {
        return console.log('NotificationTemplateComponent.callReadNotification', error.message);
      }
      this.notification.viewed = Date.now();
      this.notiService.setUnreadNotifications(this.unreadNotifications - 1);
    }));
  }

  getYear(year) {
    if (year === new Date().getFullYear() + '') { return ''; } else { return year; }
  }

  getDate(attrs: any) {
    const time = new Date(attrs.time).getTime();
    const hasOffset = 'offset' in attrs;

    if (!hasOffset) return time;

    const realOffset = attrs.offset + new Date().getTimezoneOffset() * 60000;
    return time + realOffset;
  }

}

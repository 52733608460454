import { Component, Input, OnInit } from '@angular/core'
import { KpisService } from '../../../services/kpis.service'

import { ActivatedRoute } from '@angular/router'
import { TranslationPipe } from '../../../pipes/translation/translation.pipe'
import { PerformanceService } from '../../../services/performance.service'

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css'],
  providers: [ TranslationPipe ]
})
export class FiltersComponent implements OnInit {
  @Input('type') type;
  years: any = {};
  chart: any;
  drivers: any;
  selectedDriverId: string = null;
  maxDate: any = new Date(new Date().setDate(new Date().getDate() - 1));
  minReportDate: any = new Date(new Date(this.maxDate).setMonth(this.maxDate.getMonth() - 2));

  constructor(private ps: PerformanceService, private kpisService: KpisService,
    public translation: TranslationPipe,
    private route: ActivatedRoute
  ) {
      this.ps.getCurrentChart.subscribe(c => {this.chart = c;});
      this.ps.getYears.subscribe(y => this.years = y);
      this.ps.getDriverIdFilter.subscribe(d => this.selectedDriverId = d);
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const driver = params && params.driver ? params.driver : null;
      this.getDrivers(driver);
    });
  }

  setMin(type) {
    return type === 'reports' ? this.minReportDate : undefined;
  }

  async getDrivers(driver: string = null) {
    this.drivers = await this.ps.asyncReq(this.kpisService.getDrivers());
    if (!this.drivers.length) { return; }
    if (this.selectedDriverId) { return; }
    this.selectedDriverId = driver || this.drivers[0].id;
    this.sendDriverId(this.selectedDriverId);
  }

  sendStartyear() {
    const years = {
      start: new Date(this.years.start),
      end: this.years.end
    };
    this.ps.setYears(years);
  }

  sendEndYear() {
    const years = {
      start: this.years.start,
      end: new Date(this.years.end)
    };
    this.ps.setYears(years);
  }

  sendDriverId(selectedDriverId) {
    this.selectedDriverId = selectedDriverId;
    this.ps.setDriverIdFilter(selectedDriverId);
  }

}

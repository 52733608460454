<div class="filter-container">
    <div class="pickers" [ngClass]="{ 'pickers-reports': type === 'reports' }">
        <mat-form-field *ngIf="type !== 'reports'">
            <input disabled="true" matInput [matDatepicker]="pickerFrom" [placeholder]="translation.transform('FROM')"
                [(ngModel)]="years.start" [max]="maxDate" (ngModelChange)="sendStartyear()">
            <mat-datepicker-toggle [disabled]="!chart" matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker [disabled]="!chart" touchUi #pickerFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <input disabled="true" matInput [matDatepicker]="pickerTo" [placeholder]="translation.transform('TO')"
                [(ngModel)]="years.end" [max]="maxDate" (ngModelChange)="sendEndYear()"
                [min]="setMin(type)">
            <mat-datepicker-toggle [disabled]="!chart" matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker [disabled]="!chart" touchUi #pickerTo></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="drivers" *ngIf="type === 'stats'">
        <div class="label">{{ 'FOR' | translation }}</div>
        <select [disabled]="!chart" [(ngModel)]="selectedDriverId" (ngModelChange)="sendDriverId(selectedDriverId)">
            <option *ngFor="let driver of drivers" [value]="driver.id">{{ driver.name }}</option>
        </select>
    </div>
    <br>
</div>
<div class="date-header" *ngIf="notification.dateHeader">
  {{ getDate(notification.attrs) | date:'dd'}} {{ getDate(notification.attrs) | date:'MMMM' | titlecase }}
  {{ getYear(getDate(notification.attrs) | date:'yyyy') }}
</div>
<mat-expansion-panel
  (afterExpand)="callReadNotification(notification)"
  [disabled]="isFreemium"
  (opened)="notification.hideDescription = true"
  (closed)="notification.hideDescription = false">
  <mat-expansion-panel-header collapsedHeight="100px" expandedHeight="100px">
    <mat-list class="entity-list">
      <mat-list-item class="notification">
        <div class="time"> {{ getDate(notification.attrs) | date:'h:mm a' }}</div>
        <mat-icon mat-list-icon class="picture">
          <span *ngIf="!notification?.viewed" class="not_viewed"></span>
          <img style="width: 100%;"
            [src]="notification.icon ? 'assets/img/noti-icons/'+notification.icon+'.svg' : ''"
            alt="">
        </mat-icon>
        <h4 class="name" [ngClass]="{'viewed': notification.viewed }" mat-line
          innerHTML="{{ notification?.title | highlight: findText }}"></h4>
        <p class="description"
          [ngClass]="{ 'viewed': notification.viewed }" mat-line
          innerHTML="{{ notification.hideDescription ? '' : notification?.body | highlight: findText
        }}"></p>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel-header>
  <!-- Collapse content -->
  <div class="container">
    <div class="time innerTime">
      {{ 'NOTI_RECEIVED' | translation }}: {{ getDate(notification.attrs) | date:'mediumDate' }} {{ getDate(notification.attrs) | date:'h:mm a' }}
      <span *ngIf="notification?.viewed"> | {{ 'NOTI_READ' | translation }}:
        {{ notification.viewed | date:'mediumDate' }} {{ notification.viewed | date:'h:mm a' }}</span>
    </div>
    <p>{{notification.body}}</p>
    <hr style="border: none;">
    <a *ngIf="externalRoute" id="external-route" [href]="externalRoute"></a>
    <div class="actions">
      <span *ngFor="let action of notification.actions;">
        <button mat-stroked-button mat-button
          (click)="goToAction(action); actionModification(notification, action)">
          {{ action.title }}
        </button>
        <br>
        <br>
      </span>
    </div>
  </div>
</mat-expansion-panel>

import { Component, OnInit } from '@angular/core';
import { NotiSubscriptionService } from '../../../services/noti-subscription.service';
import { RequestService } from '../../../services/request.service';
import { categoryTabs } from '../../../helpers/helpers';
import { TranslationPipe } from '../../../pipes/translation/translation.pipe';
import { NotificationsService } from '../../../services/notifications.service';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.css'],
  providers: [ TranslationPipe ]
})
export class NotificationSettingsComponent implements OnInit {

  categoryTabs: any;
  categoryTypes: any;
  settings: any = {};
  wiData: any;
  groups: any;

  constructor(
    private nsub: NotiSubscriptionService,
    private rs: RequestService,
    private ts: TranslationPipe,
    private ns: NotificationsService
  ) { }

  ngOnInit() {
    this.getNotificationGroups();
    if ( Notification.permission === 'granted') {
      this.enableNotifications();
    }
  }

  translateLabel(string) {
    return this.ts.transform(string);
  }

  async getNotificationGroups() {
    this.groups = await this.rs.request({
      method: 'GET',
      endpoint: 'noti_groups'
    });
    Object.keys(this.groups.config).map(c => this.settings[c] = this.groups.config[c].value);
    this.categoryTabs = categoryTabs(this.groups);
    const allSilenced = Object.keys(this.groups.config).some(g => !!this.settings[g]);
    this.settings.silenceAlerts = !allSilenced;
    this.ns.setSilenceAlerts(!allSilenced);
    this.setLabels();
  }

  setLabels() {
    this.settings.label_notificationEnabled = this.settings.notificationsEnabled ? 'DISABLE_NOTIFICATIONS' : 'ENABLED_NOTIFICATIONS';
    this.settings.label_silenceAlerts = this.settings.silenceAlerts ? 'SILENCED_ALERTS' : 'SILENCE_ALERTS';
  }

  silenceAlerts() {
    Object.keys(this.groups.config).map(g => this.settings[g] = false);
    this.ns.setSilenceAlerts(true);
    this.setAlerts(this.settings);
  }

  async setAlerts(config) {
    const obj = {};
    Object.keys(config).map(c => obj[c] = { value: config[c] });
    const update = await this.rs.request({
      method: 'PATCH',
      endpoint: 'update_noti_groups',
      body: { config: obj }
    });
    if (update.error) { alert(JSON.stringify(update.error)); }
    this.getNotificationGroups();
  }

  async enableNotifications() {
    if (!('Notification' in window)) { alert('This browser does not support desktop notification'); }
    let permission = Notification.permission;
    if ( permission === 'granted' ) {
      this.settings.notificationsEnabled = true;
      this.nsub.subscribe();
    } else if ( permission === 'default' ) {
      permission = await Notification.requestPermission();
      if ( permission === 'denied' ) { return this.settings.notificationsEnabled = false; } else if ( permission === 'granted' ) {
        this.settings.notificationsEnabled = true;
        this.nsub.subscribe();
      }
    }
    this.setLabels();
  }

}

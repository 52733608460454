<div class="container">
    <div *ngIf="!data?.hasError" class="maintenance-container">
        <div class="maintenance-list">
            <div class="type engine_error_codes" *ngIf="data?.selected?.type === 'engine_error_codes'">
                <h4 *ngIf="data?.selected.filter === 'all'">{{ data?.selected.filter | translation }}</h4>
                <div class="types" *ngFor="let st of statusTypes">
                    <div class="flag">
                        <div class="flag-name" [ngStyle]="{ 'color': st.color }">
                            {{ st.type | translation }} <span class="total-cars">({{data.vehicles[st.type].length}})</span>
                        </div>
                        <div class="flag-line" [ngStyle]="{ 'background': st.color }"></div>
                    </div>
                    
                    <div class="no-safesty-data" *ngIf="data.vehicles[st.type].length === 0 &&
                        data.selected.filter !== 'all'">
                        <div>
                            <img src="assets/img/dtc/no-dtc.svg" alt="{{ 'NO_VEHICLE_SAFESTY' | translation }}">
                            <p>{{ 'NO_VEHICLE_SAFESTY' | translation }}</p>
                        </div>
                    </div>
                    <div class="vehicle no-vehicle" *ngIf="data.vehicles[st.type].length === 0 && data.selected.filter === 'all'">
                        <div class="picture">
                            <img class="picture-no-vehicle" src="assets/img/dtc/no-dtc.svg" alt="{{ 'NO_VEHICLE_SAFESTY' | translation }}">
                        </div>
                        <div>
                            <p>{{ 'NO_VEHICLE_SAFESTY' | translation }}</p>
                        </div>
                    </div>
                    <div class="vehicle" *ngFor="let vehicle of data.vehicles[st.type]" (click)="showVehicleDetail(vehicle)">
                        <div class="picture">
                            <div class="health"><img [src]="'assets/img/'+st.type+'.svg'" alt="st.type"></div>
                            <span class="no-picture"> {{ getNameFirstLetter(vehicle.brand, vehicle.model) }} </span>
                        </div>
                        <div class="vehicle-name">
                            {{ vehicle.brand }} {{ vehicle.model }}
                            <br>
                            <span *ngIf="vehicle.year" class="year">{{ vehicle.year }}</span>
                            <span *ngIf="vehicle.plate" class="plate">{{ vehicle.plate }}</span>
                        </div>
                        <div *ngIf="vehicle.status !== 'normal'" class="vehicle-errors">
                            <div [ngStyle]="{ 'background': st.color, 'margin-right': '16px' }">{{ vehicle.dtcs.length }}
                                {{ 'ERRORS' | translation }}
                            </div>
                        </div>
                    </div>
                    <br *ngFor="let br of [1, 2]">
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="data?.hasError">
        <h6>Ha ocurrido un error al consultar los datos</h6>
    </div>
</div>
const API_BASE_URL = 'https://api.fleetr.app/api'
const API_PROXY = '/v1/fleetr/proxy'

export const environment = {
  production: true,
  BUILD_COMMIT: '{BUILD_COMMIT}',
  BUILD_TIME: '{BUILD_TIME}',
  API_BASE_URL,
  API_PROXY,
  API_PATH: `${API_BASE_URL}${API_PROXY}`,
  API_COMMUNICATIONS_PATH: `${API_BASE_URL}/communications/v1`,
  API_NOTIFICATION_PATH: `${API_BASE_URL}/v1/notifications`,
  API_GEOFENCE_PATH: `${API_BASE_URL}/fleetr/geofence`,
  API_VERSION: 'v1',
  API_TOKEN: 'lRwGGABMT7uVXYb5wNSfWUiYy0EDy0JP34ZeHYxd',
  RTS_URL: 'liveview-bridge.fleetr.app',
  RTS_PORT: '443',
  RTS_PROTOCOL: 'wss',
  RTS_ENDPOINT: '',
  GA: 'G-X4TCVL9VG7',
  VAPID_KEY: 'BCkD2_tK7SqJQNnj92GXsK7NzMd2t19xqx-6ty1o4xIL7fsI12YaB7DIZPognrdUdCT20-QqwHHg_bhdRFk-mio',
  NOTI_TOKEN: '6bIar1vwBX1bbVf0ep4ViAH1lCyR9CCPRzELUpnG',
  MAPTILER_TOKEN: 'CtnrAsm6sHzZrT5CHWne',
  SCORING_HISTORY_URL: 'https://stats.fleetr.com/scoring_history',
  ROLLBAR_TOKEN: '33da2e9ab365405fb931b197b69a6684',
  CHARGEBEE_SOURCE: 'https://js.chargebee.com/v2/chargebee.js',
  CHARGEBEE_SITE: 'fleetr',
  FLEETR_URL: 'https://app.fleetr.app',
  FLEETR_APP_SCHEME: 'fleetr://fleetr',
  FLEETR_BASIC_TIER: 'fleetr:billingTier:freemium',
  US: {
    ZENDESK_KEY: '6a587fd2-8ecc-4865-b5fd-7abb9e9aea46',
    FEEDBACK_PHONE: '+1(240)647-9414',
  },
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyDpo-D3-JM2akEDawWnjO4lqEE11rC-keU",
    authDomain: "fleetr-16eda.firebaseapp.com",
    projectId: "fleetr-16eda",
    storageBucket: "fleetr-16eda.appspot.com",
    messagingSenderId: "944738108321",
    appId: "1:944738108321:web:df477989e8c61644e841e0",
    measurementId: "G-X4TCVL9VG7"
  },
  GOOGLE_MAPS_TOKEN: 'AIzaSyCBmWj-JnDBXut1mHyHDF_iwoLpfE3sqMM',
};

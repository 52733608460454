<div class="container">
  <div class="pickers">
    <!-- FROM -->
    <div>
      <div class="date-toggle">
        <div>{{ 'FROM' | translation }}</div>
        <button mat-raised-button (click)="pickerFrom.open()">
          {{ 'WEEK' | translation }} {{ filter.from.week }} - {{ filter.from.year }}
        </button>
      </div>
      <mat-form-field appearance="fill"> 
        <input matInput [max]="maxDate" [matDatepicker]="pickerFrom" [(ngModel)]="filter.from.date"
          (ngModelChange)="setWeekYear(filter.from.date, 'from')">
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>
    </div>
    <!-- TO -->
    <div>
      <div class="date-toggle">
        <div>{{ 'TO' | translation }}</div>
        <button mat-raised-button (click)="pickerTo.open()">
          {{ 'WEEK' | translation }} {{ filter.to.week }} - {{ filter.to.year }}
        </button>
      </div>
      <mat-form-field appearance="fill">
        <input matInput [max]="maxDate" [min]="filter.from.date" [matDatepicker]="pickerTo" [(ngModel)]="filter.to.date"
          (ngModelChange)="setWeekYear(filter.to.date, 'to')">
        <mat-datepicker #pickerTo></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="actions">
    <button mat-raised-button (click)="applyFilter()"> {{ 'APPLY' | translation }} </button>
  </div>
</div>
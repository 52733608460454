import { Component, Input} from '@angular/core';
import { translationPipe } from '../../../pipes/translation/translation.pipe';
import { GeneralService } from '../../../services/general.service';
import { RequestService } from '../../../services/request.service';

@Component({
  selector: 'app-delete-maintenance',
  templateUrl: './delete-maintenance.component.html',
  styleUrls: ['./delete-maintenance.component.css']
})
export class DeleteMaintenanceComponent {
  @Input('dialogRef') dialogRef: any;
  @Input('data') data: any;

  constructor(public gs: GeneralService,
    private rs: RequestService) {
  }

  async deletMaintenance() {
    try {
      const { maintenance, vehicle } = this.data;
      const { error } = await this.rs.request({
        method: 'DELETE',
        endpoint: 'delete_maintenance',
        vehicleId: vehicle.id,
        maintenanceId: maintenance.id,
      });
      if (error) {
        return this.gs.openAlert({
          msg: translationPipe.transform('MAINTENANCE.MAINTENANCE_DELETE_ERROR'),
          duration: 3000,
          error: true
        });
      }
      this.gs.openAlert({
        msg: translationPipe.transform('MAINTENANCE.MAINTENANCE_DELETED'),
        duration: 3000
      });
      this.dialogRef.close({ refreshMaintenances: true });
    } catch (error) {
      this.gs.openAlert({
        msg: JSON.stringify(error),
        duration: 3000,
        error: true
      });
    }
  }
}

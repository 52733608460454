<div class="header" *ngIf="data">
    <div class="info-vehicle">
        <mat-icon mat-list-icon class="picture hasPicture"
            [ngStyle]="{ 'background': data.vehicle.brand ? data.vehicle.picture ? 'url('+data.vehicle.picture+')' : '#ffe09d' : '#a6a6a6'}">
            <span class="brand" [ngStyle]="{'color': data.vehicle.brand ? '' : '#ffffff'}">
                {{ data.vehicle.brand ? data.vehicle.brand.slice(0, 1)[0] : '?' }}
            </span>

            <span class="indicador-driver" [ngClass]="{
    'indicator-driving': data.vehicle.currentStatus === 'TripDriving',
    'indicator-stopped': data.vehicle.currentStatus !== 'TripDriving'
     }">
            </span>
        </mat-icon>
        <div [ngStyle]="{'margin-left': '10px'}">
            <span class="name" mat-line>
                <span *ngIf="data.vehicle.plate" class="plate" innerHTML="{{ data.vehicle?.plate}}"></span>
            </span>

            <p class="shift" mat-line>
                <span innerHTML="{{ data.vehicle?.brand}}"></span>&nbsp;
                <span innerHTML="{{ data.vehicle?.model}}"></span>
                <span *ngIf="data.vehicle.year"> - </span>
                <span class="year" innerHTML="{{ data.vehicle?.year}}"></span>
            </p>
        </div>
    </div>
</div>

<div class="container" *ngIf="data">
    <div class="container-img">
        <div [ngStyle]="{'display': 'flex', 'flex-direction': 'column', 'align-items': 'flex-start'}">
            <span [ngStyle]="{'font-size': '14px', 'color': '#8B8B8B'}">{{ 'I_DETECTED' | translation }}</span>
            <span
                [ngStyle]="{'font-size': '22px', 'font-weight': 'bold', 'color': '#DB4343'}">{{this.data?.vehicle?.dtcs.length}} {{ errorSize | translation }}</span>
        </div>
        <div class="dtc-components">
            <img src="assets/img/dtc/vehicle-parts-base.svg" width="328" height="152" alt="">
            <img *ngIf="componentImg" [src]="componentImg" alt="Vehicle base" width="328" height="152">
        </div>
    </div>
    <div [ngStyle]="{'height': '442px', 'overflow': 'auto'}">
        <mat-accordion #accordionCategory>
             <mat-expansion-panel hideToggle *ngFor="let vehicle of data.vehicle.detail; let i = index" (closed)="setImgComponent(vehicle.code, false, i)"
            (opened)="setImgComponent(vehicle.code, true, i)">
            <mat-expansion-panel-header>
                <mat-panel-title [ngClass]="{
                    'title-component-dtc-open': categoryStatus[i],
                    'title-component-dtc-close': !categoryStatus[i]
                 }">
                    {{vehicle.title}}
                </mat-panel-title>
                <mat-panel-description>
                    <span [ngClass]="{
                    'dot-red': vehicle?.dtcs.length > 0,
                    'dot-green': vehicle?.dtcs.length === 0
                  }"></span>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <p class="description">{{vehicle.description}}</p>
            <div *ngIf="vehicle?.dtcs.length > 0" class="codes-details">
                <p [ngStyle]="{'color': '#595959', 'font-size': '10px', 'font-weight': '600'}">{{'ERROR_CODE_DTC' | translation}}</p>
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let dtc of vehicle.dtcs; let i = index" class="expansion-panel-detail-dtc">
                        <mat-expansion-panel-header class="expansion-code-detail" [ngClass]="{
                                'bg-bad': dtc?.status !== 'critical',
                                'bg-critical': dtc?.status === 'critical'
                             }">
                            <mat-panel-title class="title-dtc">
                                <div [ngStyle]="{'display': 'flex', 'padding': '5px'}">
                                    <div class="icon-status"><img [src]="dtc.status === 'critical' ? 'assets/img/critical.svg' : 'assets/img/bad.svg'"
                                            alt="dtc.status">
                                    </div>
                                    <span>{{dtc.code}} | {{dtc.title}}</span>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div [ngStyle]="{'padding': '0px 16px 0px 16px'}">
                            <span class="dtc-description" [innerHTML]="dtc?.description"></span>
                            <span [ngStyle]="{'color': '#595959', 'font-size': '14px', 'font-weight': '600'}">{{'CAUSE' | translation}}</span>
                            <span class="dtc-description" [innerHTML]="dtc?.cause"></span>
                            <span [ngStyle]="{'color': '#595959', 'font-size': '14px', 'font-weight': '600'}">{{'SUGGESTION' | translation}}</span>
                            <span class="dtc-description" [innerHTML]="dtc?.suggestion"></span>
                            <hr *ngIf="i !== vehicle.dtcs.length - 1" style="border: 0; border-top: 1px solid #B9B9B9;">
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </mat-expansion-panel>
        </mat-accordion>
       

    </div>

</div>
import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { countrySpecifics, fEventNameConventions } from '../../helpers/helpers'
import { AuthService } from '../../services/auth.service'
import { GeneralService } from '../../services/general.service'
import { NotificationsService } from '../../services/notifications.service'
import { RequestService } from '../../services/request.service'
import { GeneralModalComponent } from '../general-modal/general-modal.component'
import { ProfileComponent } from '../profile/profile.component'

@Component({
  selector: 'app-side-tool-bar',
  templateUrl: './side-tool-bar.component.html',
  styleUrls: ['./side-tool-bar.component.css']
})
export class SideToolBarComponent implements OnInit, OnDestroy {
  private suscription: Subscription = new Subscription;
  buttons: any[];
  loggedUser: any;
  unreadNotifications: number;
  preloadStats: any;
  removeInitialIframe: boolean;
  contexts: any[] = [];

  constructor(
    public gs: GeneralService,
    public dialog: MatDialog,
    private notiService: NotificationsService,
    private rs: RequestService,
    private router: Router,
    private auth: AuthService,
  ) {}

  ngOnInit() {
    this.suscription.add(this.gs.getWiContext.subscribe(contexts => { this.contexts = contexts || []; }));
    this.suscription.add(this.notiService.getUnreadNotifications.subscribe(u => this.unreadNotifications = u));
    this.suscription.add(this.gs.getLoggedUser.subscribe(u => {
      this.loggedUser = u;
      if (!u) { return; }
      this.getMenu(u.roles);
    }));
  }

  ngOnDestroy() {
    this.suscription.unsubscribe();
  }

  async getMenu(roles: string[]) {
    const { items } = await this.rs.request({
      method: 'GET',
      endpoint: 'get_menu'
    });

    if (!items) {
      return this.gs.openAlert({ msg: 'Error fetching menu', error: true });
    }

    this.buttons = items
      .map(i => {
        return {
          ...i,
          icon: (this.gs.contextOptions().find((c: any) => c.context === i.context) || {})['icon']
        };
      })
      .filter(i => i.context !== 'notifications')
      .filter(i => i.context !== 'tag' || roles.some(e => ['admin.fleetmgr'].includes(e)))

    this.gs.setToolbarButtons(this.buttons);
    this.checkIfRemoveFeedback();
  }

  checkIfRemoveFeedback() {
    if (!this.loggedUser) { return; }
    const zeScriptForCountry = countrySpecifics[this.loggedUser.country];
    if (!zeScriptForCountry) { return; }
    this.buttons = this.buttons.filter(b => b.context !== 'feedback');
    this.gs.setToolbarButtons(this.buttons);
  }

  suggestionIcon(i) {
    if (i.router.indexOf('stats') > -1) {
      return '/assets/img/suggestions-icons/indicadores-suggestions.svg';
    } else if (i.router.indexOf('feedback') > -1) {
      return '/assets/img/suggestions-icons/feedback-suggestions.svg';
    }
  }

  infoWindow(button) {
    const extraActions = {
      stats: _ => this.openGeneralModal(button.context),
      feedback: _ => this.openGeneralModal(button.context),
      tag: _ => this.openTagsModal(button.context),
      liveview: _ => {
        this.gs.setShowLiveViewRoute(false);
        this.gs.setWindow({ state: false, type: 'list', findText: undefined, contexts: [] });
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 1000);
      },
    };

    const eventName = fEventNameConventions['toolbar'][button.context] || 'undefined_toolbar_button';

    this.gs.logScreenEvents(eventName, eventName, eventName, 'click');

    const windowObj = { state: true, type: button.type || 'list', contexts: [button] };

    if (button.context !== 'dashboard') {
      this.router.navigate(['home']);
    }

    return extraActions[button.context] ? extraActions[button.context]() : this.gs
      .setWindow(windowObj);
  }

  openProfile() {
    this.dialog.open(ProfileComponent, {
      panelClass: 'profile-modal',
      data: this.loggedUser,
      width: '600px'
    });
    this.gs.logScreenEvents('admin_view', 'admin_view', 'admin_view', 'click');
  }

  openGeneralModal(type: string) {
    this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '80vw',
      data: { type }
    });
  }

  openTagsModal(type: string) {
    this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '820px',
      data: { type }
    });
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { request } from '../services/request.service';
import { successStatus } from '../helpers/helpers';
import { GeneralService } from './general.service';
import { WebSocketService } from './web-socket.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(
    private gs: GeneralService,
    private router: Router,
    private wss: WebSocketService
  ) { }
  async logoutActionFn(_params = {}, modal = null) {
    const req = await request({
      method: 'DELETE',
      endpoint: 'logout',
      token: localStorage.getItem('id'),
      fullRes: true
    });
    if ( !successStatus(req.status) ) {
      this.closeAll(modal);
      return false;
    }
    this.closeAll(modal);
    return true;
  }

  closeAll(modal) {
    localStorage.removeItem('id');
    this.gs.setWindow({ state: false });
    this.wss.disconnect();
    if ( modal ) { modal.close(); }
    this.gs.setLoggedUser(null);
    this.router.navigate(['login'])
    .then(() => {
      window.location.reload();
    });
  }
}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { environment as env } from '../../environments/environment'
import { HEADER_OPTIONS } from '../helpers/helpers'
import { TagService } from './tag.service'

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  devices = new BehaviorSubject<any>([]);
  getCurrentDevices = this.devices.asObservable();
  setCurrentDevices(devices: any) { this.devices.next(devices); }

  get deviceToRemove() {
    const devices = this.devices.getValue().filter(device => device.type === 'asset')
    const filteredDevices = this.tagService.filterDevices(devices)
    return devices.filter(device => filteredDevices.find(d => d._id === device._id) === undefined)
  }

  constructor(public http: HttpClient, public tagService: TagService) {
  }

  getAll() {
    return this.http.get(`${ env.API_PATH }/api/device/v1/all`, HEADER_OPTIONS());
  }

  getImeis() {
    return this.http.get(`${ env.API_PATH }/api/device/v1/imeis`, HEADER_OPTIONS());
  }
}

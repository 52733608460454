<spinner></spinner>
<div *ngIf="!isMobile || isExternalView || isResetPassword">
    <!--  -->
    <div *ngIf="isShowFilter">
        <div class="finder"
            *ngIf="initiateSidebars &&
                (type !== 'trips' &&
                type !== 'create_entity' &&
                (type === 'create_geofences' && gs.checkPermission(['admin.fleetro']) ) ||
                    type !== 'create_geofences')"
                [ngClass]="{
                    'finder-partially-moved': toolBarHovered,
                    'notification-not-enabled': !notificationsEnabled,
                    'finder-down': wiState
                }">
            <div *ngIf="isEnableFinderNotifications">
                <app-finder></app-finder>
            </div>
            <div *ngIf="isEnableFinderNotifications &&  !wiState" class="notifications-shortcut" (click)="goToNotifications()">
                <div class="unread" *ngIf="unreadNotifications">
                    {{ unreadNotifications || '' }}
                </div>
                <mat-icon>
                    <img src="assets/img/noti-icons/notifications-blue.svg" alt="">
                </mat-icon>
            </div>
        </div>
    </div>
    <!--  -->
    <div class="app-wrapper" [ngClass]="{ 'app-opened': wiState }">
        <router-outlet></router-outlet>
    </div>
    <!--  -->
    <div class="side-tool-bar tool-bar-hovered tool-bar-visible"  *ngIf="initiateSidebars && isEnableFinderNotifications">
        <app-side-tool-bar></app-side-tool-bar>
    </div>
    <!--  -->
    <div *ngIf="initiateSidebars" class="side-info-window" [ngClass]="{
            'window-opened': wiState && admin,
            'window-partially-opened': wiState && toolBarHovered && admin,
            'window-expanded': wiExtendedState && admin,
            'maintenance-size': wiData?.type === 'vehicle_maintenance',
            'trip-events-size': wiExtendedType === 'trip_events'
        }">
        <div class="closeInfoWindow" (click)="close()">
            <mat-icon>{{ wiExtendedState ? 'arrow_back_ios' : 'close'}}</mat-icon>
        </div>
        <!--  -->
        <h2 class="current-single-context" *ngIf="contexts?.length == 1" [ngClass]="{
            'context-style': type !== 'create_entity',
            'text-center': type === 'create_entity'
        }">
            <span *ngIf="(type === 'create_entity' || type ==='create_geofences') && gs.checkPermission(['admin.fleetrw', 'admin.fleetmgr'])">
                {{ editingGeofence ? 'Editing' : 'CREATING' | translation }}
            </span>
            <span *ngIf="type === 'drivers-list-extensions'">
                {{ 'DRIVER' | translation }}
            </span>
            <span *ngIf="(type !== 'create_entity' || type !== 'create_geofences') && type !== 'drivers-list-extensions'">
                {{ contexts[0]?.context | translation }}
            </span>
        </h2>
        <button class="back-to-list" *ngIf="contexts?.length == 1 && type !== 'trips'"
            mat-icon-button (click)="backToList()">
            <mat-icon mat-list-icon>arrow_back_ios</mat-icon>
        </button>
        <div id="scroll-window" class="scroll-window" *ngIf="initiateSidebars">
            <side-info-window></side-info-window>
        </div>
        <div *ngIf="admin" class="expanded-content" [ngClass]="{
                'no-padding': ['stats', 'dashboard', 'trips'].includes(type),
                'create-entity': type === 'create_entity'
            }">
            <app-ranking-template *ngIf="type === 'ranking'"></app-ranking-template>
            <app-stats-template *ngIf="type === 'stats'"></app-stats-template>
            <app-dashboard-template *ngIf="type === 'dashboard'"></app-dashboard-template>
            <app-health-detail *ngIf="wiData?.type === 'health_general'"></app-health-detail>
            <app-vehicle-maintenance *ngIf="wiData?.type === 'vehicle_maintenance'"></app-vehicle-maintenance>
            <app-driver-advice *ngIf="wiData?.type === 'driver_advice'"></app-driver-advice>
            <app-notification-settings *ngIf="wiData?.type === 'notificationSettings'"></app-notification-settings>
            <app-creating-entity *ngIf="type === 'create_entity'"></app-creating-entity>
            <app-trip-events *ngIf="wiExtendedType === 'trip_events'"></app-trip-events>
        </div>
    </div>
</div>
<app-mobile-view *ngIf="isMobile && !isExternalView && !isResetPassword"></app-mobile-view>

<div class="form">
  <div [ngClass]="{
    'header': comingFrom === 'profile',
    'text-center': comingFrom !== 'profile'
    }" class="header">
    <button class="back-to-list" *ngIf="comingFrom === 'profile'"
        mat-icon-button (click)="back()">
      <mat-icon mat-list-icon>arrow_back_ios</mat-icon>
    </button>
    <div class="titles">
      <div class="title" *ngIf="comingFrom === 'profile'">
        {{ 'CHANGE_PASS_TITLE' | translation }}
      </div>
      <div class="subtitle">
        {{ 'CHANGE_PASS_SUBTITLE' | translation }}
      </div>
    </div>
  </div>
  
  <form [formGroup]="changePassForm" (ngSubmit)="changePassword()">
    <mat-form-field class="full-width" *ngIf="comingFrom === 'profile'">
      <input type="password" matInput placeholder="{{ 'CURRENT_PASS' | translation }}"
        formControlName="currentPassword">
      <mat-error *ngIf="cpForm.newPass.errors && cpForm.newPass.errors.required">
        {{ 'MANDATORY_FIELD' | translation }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <input type="password" matInput placeholder="{{ 'NEW_PASS' | translation }}" formControlName="newPass">
      <mat-error *ngIf="cpForm.newPass.errors &&
        cpForm.newPass.errors.required">
        {{ 'MANDATORY_FIELD' | translation }}
      </mat-error>
      <mat-error *ngIf="cpForm.newPass.errors && !cpForm.newPass.errors.required &&
         cpForm.newPass.errors.pattern">
        {{ 'WEAK_PASSWORD' | translation }}
      </mat-error>
      <mat-error *ngIf="cpForm.newPass.errors && !cpForm.newPass.errors.required &&
        !cpForm.newPass.errors.pattern && cpForm.newPass.errors.wrongLegnth">
        {{ 'SHORT_PASSWORD' | translation }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <input type="password" matInput placeholder="{{ 'REPEAT_PASS' | translation }}" formControlName="repeatPass">
      <mat-error *ngIf="cpForm.repeatPass.errors &&
        cpForm.repeatPass.errors.required">
        {{ 'MANDATORY_FIELD' | translation }}
      </mat-error>
      <mat-error *ngIf="cpForm.repeatPass.errors &&
        !cpForm.repeatPass.errors.required && cpForm.repeatPass.errors.notEqual">
        {{ 'PASSWORD_DOESNT_MATCH' | translation }}
      </mat-error>
    </mat-form-field>
    <ul class="recomendations">
      <li *ngFor="let r of recomendations"> 
        <span [ngClass]="{ 'check-shown': r.showCheck(cpForm) }" class="check">
          <mat-icon>check</mat-icon>
        </span>
        {{ r.name | translation }}
      </li>
    </ul>
    <button [ngClass]="{ 'gray': disableButton }" [disabled]="disableButton"
      class="outlined-button full-width submit-form" mat-stroked-button>
      {{ 'CHANGE_PASSWORD' | translation }}
    </button>
  </form>
</div>
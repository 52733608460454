<h5 class="text-center gold" *ngIf="!filterArray(suggestions)?.length &&
  !filterArray(vehicles)?.length &&
  !filterArray(drivers)?.length &&
  !filterArray(notifications)?.length &&
  !filterArray(geofences)?.length">
  {{ 'NO_SEARCH_RESULT' | translation }} "{{ findText }}"
</h5>

<span *ngIf="findText?.length > 0 || contexts?.length === 1">
  <span *ngFor="let c of contexts; trackBy: trackByFn" class="entity-list">
    <!-- Regular entities -->
    <!-- vehicles template -->
    <div class="entity" *ngIf="c.context === 'vehicles' ">
      <h3 class="subheader" *ngIf="contexts?.length > 1 && filterArray(vehicles)?.length">{{c.context | translation}}
      </h3>
      <app-vehicle-sorting *ngIf="contexts && contexts.length === 1 "></app-vehicle-sorting>
      <mat-accordion>
        <vehicle-template *ngFor="let vehicle of filterArray(vehicles); trackBy: trackByFn"
          (runEntities)="getEntities()"
          [isFreemium]="isFreemium"
          [vehicle]="vehicle">
        </vehicle-template>
      </mat-accordion>
    </div>

    <!-- drivers template -->
    <div class="entity" *ngIf="c.context === 'drivers' ">
      <h3 class="subheader" *ngIf="contexts?.length > 1 && filterArray(drivers)?.length">{{c.context | translation}}
      </h3>
      <mat-accordion>
        <driver-template *ngFor="let driver of filterArray(drivers); trackBy: trackByFn"
          (runEntities)="getEntities()"
          [isFreemium]="isFreemium"
          [driver]="driver">
        </driver-template>
      </mat-accordion>
    </div>
    <!-- notifications template -->
    <div class="entity entity-notification" [ngClass]="{
        'entity-notification-search': findText && findText.length && contexts.length > 0
      }" *ngIf="c.context === 'notifications' ">
      <h3 class="subheader" *ngIf="contexts && contexts?.length > 1 && filterArray(notifications)?.length">{{c.context |
        translation}}</h3>
      <notifications [isFreemium]="isFreemium"></notifications>
    </div>
    <!-- Scores template -->
    <div class="entity" *ngIf="c.context === 'ranking' && contexts.length === 1">
      <div class="ranking-header" *ngIf="scoreType">{{ 'TYPE' | translation }}</div>
      <div class="ranking-header" *ngIf="scoreType">
        <img src="assets/img/info.svg" alt="info" (click)="openScoreHelp(scoreType)" >
      </div>
      <mat-accordion>
        <ranking *ngFor="let score of scoreOptions | filters: findText; trackBy: trackByFn"
          [score]="score">
        </ranking>
      </mat-accordion>
    </div>
    <!-- dashboard template -->
    <div class="entity" *ngIf="c.context === 'dashboard' ">
      <app-dashboard [isFreemium]="isFreemium"></app-dashboard>
    </div>
    <!-- health template -->
    <div class="entity" *ngIf="c.context === 'health' && contexts.length === 1">
      <health-list [isFreemium]="isFreemium"></health-list>
    </div>
    <!-- geofencing tamplate -->
    <div class="entity" *ngIf="c.context === 'geofences'">
      <h3 class="subheader" *ngIf="contexts?.length > 1 && filterArray(geofences)?.length">
        {{c.context | translation}}
      </h3>
      <div>
        <div class="geoList" (scroll)="onGEoScroll($event, geoList)" #geoList>
          <app-geofence-sorting *ngIf="contexts && contexts.length === 1 "></app-geofence-sorting>
          <div class="geo-spinner" *ngIf="geoSpinner">
            <mat-progress-spinner diameter="30" [mode]="'indeterminate'"></mat-progress-spinner>
          </div>
          <p class="small text-center gray" *ngIf="!filterArray(geofences).length">
            {{ 'GEOFENCE.NO_GEOFENCES_YET' | translation }}
          </p>
          <mat-list>
            <geofence-template (click)="selectedGeo = geofence" [ngClass]="{
              'geo-selected': geofence.id === selectedGeo.id
            }" [isFreemium]="isFreemium" *ngFor="let geofence of filterArray(geofences); trackBy: trackByFn"
              [geofence]="geofence">
            </geofence-template>
          </mat-list>
        </div>
        <!-- Load more geofences -->
        <button *ngIf="contexts.length === 1 && geofences.length && loadMore && scrolledToEnd" class="outlined-button fixed-bottom"
          mat-stroked-button (click)="getGeofences({ pageNo: pageNo, trigger: 'loadMore' })">
          {{ 'LOAD_MORE' | translation }}
        </button>
        <!-- Create geofence -->
        <div *ngIf="!createGeofenceClick && generalService.checkPermission(['admin.fleetrw', 'admin.fleetmgr'])" (click)="onClickCreateGeofence()"
          class="settings create-geofence">
          <img src="assets/img/addgeofence.svg" alt="addgeofence">
          <span>{{ 'CREATE_GEOFENCE' | translation }}</span>
        </div>
      </div>
    </div>
    <!-- extensions template -->
    <div class="entity" *ngIf="c.context === 'Add ons' && contexts.length === 1">
      <app-extensions-list [isFreemium]="isFreemium" [extensions]="extensions"></app-extensions-list>
    </div>
  </span>
</span>

<mat-expansion-panel (afterExpand)="genScore(score)" (afterCollapse)="closeWindow()"
    collapsedHeight="80px" expandedHeight="80px">
  <mat-expansion-panel-header class="score-type-header score-type-padding">
    <mat-icon> <img [src]="'assets/img/icons/'+ score.icon +'_icon.svg'" alt=""></mat-icon>
    {{ score.name }}
  </mat-expansion-panel-header>
  
  <div class="container score-type-padding"
    *ngIf="score.scoreVariables && score.scoreVariables.length">
      <div class="score-variables"
        *ngFor="let sv of score.scoreVariables">
        <button mat-button class="outlined-button"
          [ngClass]="{
            'selected-variable': sv  === generatedScore.scoreVariable
          }" (click)="genScoreByVariable(score, sv)">
            {{ sv.name }}
        </button>
    </div>
  </div>
</mat-expansion-panel>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HEADER_OPTIONS } from '../helpers/helpers';
import { environment as env } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExtensionsService {

  constructor(public http: HttpClient) { }

  getAll() {
    return this.http.get(`${env.API_BASE_URL}/integrations/api/v1/`, HEADER_OPTIONS());
  }

  getExtensionById(id) {
    return this.http.get(`${env.API_BASE_URL}/integrations/api/v1/${id}`, HEADER_OPTIONS());
  }

  activateExtension(data: any): Observable<any> {
    return this.http.post<any>(`${env.API_BASE_URL}/integrations/api/driverTechnologies/v1`, data, HEADER_OPTIONS());
  }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeLast, takeUntil } from 'rxjs/operators';
import { Tag } from '../../../../models/tag.model';
import { TagService } from '../../../../services/tag.service';

@Component({
  selector: 'app-vehicle-add-tags',
  templateUrl: './vehicle-add-tags.component.html',
  styleUrls: ['./vehicle-add-tags.component.css'],
})
export class VehicleAddTagsComponent implements OnInit {

  private unsubscribe$ = new Subject()
  private tagListSubject: BehaviorSubject<Tag.Tag[]> = new BehaviorSubject([])

  @Input() chipList: Tag.Tag[] = []
  @Input() vehicle: any
  @Output() close: EventEmitter<null> = new EventEmitter()
  @Output() updated: EventEmitter<null> = new EventEmitter()
  @ViewChild('backdrop') backdrop: ElementRef
  @ViewChild('autocomplete') autocomplete: AutocompleteComponent

  selectedTag: Tag.Tag = undefined
  keyword = 'name';

  get data() {
    return this.tagListSubject
      .getValue()
      .filter(tag => !this.chipList.find(chip => chip.id === tag.id))
  }

  constructor(
    private tagService: TagService,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.renderer.listen(document, 'click', (element: PointerEvent) => {
      if (element.target === this.backdrop.nativeElement) {
        this.close.emit()
      }
    })

    this.tagService.getTagList()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(response => {
        this.tagListSubject.next(response.vehicleTags || []);
        const currentTagList = this.vehicle.tags.map(tag => tag.id)
        this.chipList = (response.vehicleTags || []).filter(tag => currentTagList.includes(tag.id))
      })
  }

  closeFilter() {
    this.close.emit()
  }

  selectEvent(item: Tag.Tag) {
    this.selectedTag = item
  }

  onChangeSearch(_val: string) {
    this.selectedTag = undefined
  }

  addChip() {
    this.chipList = [...this.chipList, this.selectedTag]
    this.selectedTag = undefined
    this.autocomplete.clear()
    this.autocomplete.close()
  }

  removeTag(tag: Tag.Tag) {
    this.chipList = this.chipList.filter(chip => chip.id !== tag.id)
  }

  save() {
    const vehicleId = this.vehicle.id
    const tags = this.chipList.map(tag => tag.id)
    this.tagService.editVehicleTags(vehicleId, tags).pipe(takeLast(1)).subscribe((_data) => {
      this.updated.emit()
      this.tagService.getTagList('force').toPromise().then()
    })
  }
}

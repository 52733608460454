import { Pipe, PipeTransform } from '@angular/core';

import en_translations from './en_translations.json';
import es_translations from './es_translations.json';
const defaultLang = 'en_US';

const translations = {
  es: es_translations,
  en: en_translations
};

const translationPipe = {
  transform: value => {
    try {
      if ( !value ) { return ''; }
      const browserLang = navigator.language.replace('-', '_');
      const lang = (localStorage.getItem('LANG') || browserLang || defaultLang).split('_')[0];
      const translator = translations[lang] || translations[defaultLang.split('_')[0]];
      let result;
      const text = value.split('.').map(t => t.toUpperCase());
      if (text.length === 1) {
        result = translator[text[0]] ? translator[text[0]] : value;
      } else {
        result = translator[text[0]] ? translator[text[0]][text[1]] : value;
      }
      return result || value;
    } catch (error) {
      console.log('TranslationPipe.translationPipe. Translation error: ', error);
    }
  }
};

@Pipe({
  name: 'translation'
})
export class TranslationPipe implements PipeTransform {
  constructor() {
  }

  transform(value: any): any {
    return translationPipe.transform(value);
  }
}

export {
  translationPipe
};

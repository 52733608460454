import { Component, Input, OnInit } from "@angular/core";
import { RequestService } from "../../services/request.service";
import { GeneralService } from "../../services/general.service";
import { healthTypes } from "../../helpers/helpers";
import { VehicleHealth, HealthDetail, SelectedFilterHealth } from '../../models/dtc.model';

@Component({
  selector: "health-list",
  templateUrl: "./health-list.component.html",
  styleUrls: ["./health-list.component.css"],
})
export class HealthListComponent implements OnInit {
  @Input("isFreemium") isFreemium: boolean;
  healthTypes = healthTypes;
  selected: SelectedFilterHealth;
  contexts: any;
  data: Array<VehicleHealth> = [];
  normalStatus: Array<VehicleHealth> = [];
  criticalStatus: Array<VehicleHealth> = [];
  badStatus: Array<VehicleHealth> = [];
  hasError = false; // Bandera que indica si hubo un error al consultar el servicio de los dtcs

  constructor(
    private rs: RequestService,
    private gs: GeneralService
  ) {}

  ngOnInit() {
    this.selected = {filter: null, type: null};
    this.gs.getWiContext.subscribe((_) => (this.contexts = _));
    this.getDtcs();
  }

  /**
   * Consulta el servicio que trae la información de DTCS y setea los arrays de los 3 estados: normal, critical, bad.
   */
  async getDtcs() {
    const req = await this.rs.request({
      method: 'GET',
      endpoint: 'dtcs'
    });
    if (req.error) {
      this.data = [];
      this.hasError = true;
    } else {
      this.data = req;
      this.hasError = false;
    }
    
    this.data.forEach((item) => {
      item.status === 'normal' && this.normalStatus.push(item);
      item.status === 'critical' && this.criticalStatus.push(item);
      item.status === 'bad' && this.badStatus.push(item);
    });
  }

  /**
   * Construye los datos del estado de los vehículos de acuerdo al filtro que seleccionó el usuario 
   * @param selected Filtro seleccionado
   * @returns 
   */
  buildData(selected) {
    const filterSelected = {
      all: {
        critical: this.criticalStatus,
        bad: this.badStatus,
        normal: this.normalStatus
      },
      critical: {
        critical: this.criticalStatus,
        bad: [],
        normal: []
      },
      bad: {
        critical: [],
        bad: this.badStatus,
        normal: []
      },
      normal: {
        critical: [],
        bad: [],
        normal: this.normalStatus
      }
    };
    return <HealthDetail>{
      type: 'health_general',
      selected,
      status: healthTypes.values,
      vehicles: filterSelected[selected.filter],
      hasError: this.hasError
    };
  }

  /**
   *
   * Construye y setea la que se usa en componente health-detail
   * @param type Tipo de health
   * @param filter Tipo de filtro seleccionado
   */
  async getDataFromType(type: string, filter: string) {
    const eventName = filter;
    this.gs.logScreenEvents('module_vehicles_health', eventName, 'module_vehicles_health', 'click');
    this.selected = { type, filter };
    const data = this.buildData(this.selected);
    this.gs.setWindow({
      state: true,
      extendedState: true,
      type: 'list',
      contexts: this.contexts,
      data
    });
  }
}

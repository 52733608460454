import { Component, OnInit, Output, Input } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RequestService } from '../../../services/request.service';
import { AlertSnackBarComponent } from '../../alert-snack-bar/alert-snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationPipe } from '../../../pipes/translation/translation.pipe';
import { successStatus } from '../../../helpers/helpers';
import {Router} from '@angular/router';

const errorMsgs = {
  '401': 'PASSWORD_CHANGED_ERROR'
};

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
  providers: [TranslationPipe]
})
export class ChangePasswordComponent implements OnInit {

  @Output() backToProfile = new EventEmitter<string>();
  @Output() backToLogin = new EventEmitter<boolean>();
  @Input('comingFrom') comingFrom;
  @Input('code') code;
  @Input('admin') admin;

  changePassForm: FormGroup;
  get cpForm() { return this.changePassForm.controls; }
  cpFormError: string;
  disableButton: boolean;
  recomendations = [{
      name: 'PASS_UPC',
      showCheck: form => /(.*[A-Z].*)/.test(form.newPass.value)
    },
    {
      name: 'PASS_LWC',
      showCheck: form => form.newPass.value && /(.*[a-z]{1,}.*)/.test(form.newPass.value)
    },
    {
      name: 'PASS_NUMBER',
      showCheck: form => /(.*\d.*)/.test(form.newPass.value)
    },
    {
      name: 'PASS_SPECIAL',
      showCheck: form => form.newPass.value && /(?=.*[!@#$%^&*()<>_+,.\\\/;':"-])/.test(form.newPass.value)
    },
    {
      name: 'PASS_8CHAR',
      showCheck: form => form.newPass.value && form.newPass.value.length > 7
    }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private rs: RequestService,
    private _snackBar: MatSnackBar,
    private router: Router,
    public translation: TranslationPipe
  ) {
  }

  ngOnInit() {
    const passwordPattern: RegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+,.·=?¿¡{}\\\/;':"-]).{8,}$/;
    const currentPasswordValidators = { profile: [null, [Validators.required]] };
    this.changePassForm = this.formBuilder.group({
      currentPassword: currentPasswordValidators[this.comingFrom] || [null],
      newPass: [null, [Validators.required, Validators.pattern(passwordPattern),
      this.customValidators('checkLength')]],
      repeatPass: [null, [Validators.required, Validators.pattern(passwordPattern),
      this.customValidators('checkEqual')]]
    });
  }

  back() {
    this._snackBar.dismiss();
    this.backToProfile.emit('general');
  }

  async changePassword() {
    if (!this.changePassForm || this.changePassForm.status !== 'VALID' || this.disableButton) { return; }
    this.disableButton = true;
    const id = this.admin ? this.admin.id : undefined;
    const requestTypes = this.requestTypes(id);
    const req = await this.rs.request(requestTypes[this.comingFrom].reqOptions);
    this.openAlert({
      panelClass: successStatus(req.status) ? 'panel-success' : 'panel-error',
      msg: successStatus(req.status) ? 'PASSWORD_CHANGED_SUCCESSFULLY' :
        (errorMsgs[String(req.status)] || 'ERROR')
    });
    if (successStatus(req.status) && localStorage.getItem('id')) {
      await this.router.navigate(['home']);
    }
    requestTypes[this.comingFrom].afterSuccessAction();
  }

  openAlert({ panelClass, msg }) {
    this._snackBar.openFromComponent(AlertSnackBarComponent, {
      duration: 5000,
      data: this.translation.transform(msg),
      panelClass,
      verticalPosition: 'top'
    });
  }

  customValidators(validator: string) {
    const validators = {
      checkEqual: () => {
        if (!this.changePassForm) { return null; }
        const newPass = this.cpForm.newPass.value;
        const repeatPass = this.cpForm.repeatPass.value;
        return newPass !== repeatPass ? { 'notEqual': true } : null;
      },
      checkLength: (input) => {
        if (!this.changePassForm) { return null; }
        return !input.value || input.value.length < 8 ? { 'wrongLegnth': true } : null;
      }
    };
    return validators[validator];
  }

  requestTypes(id) {
    return {
      profile: {
        reqOptions: {
          method: 'PUT',
          endpoint: 'change_password',
          id,
          body: {
            oldPassword: this.changePassForm.controls.currentPassword.value,
            newPassword: this.changePassForm.controls.newPass.value,
          },
          fullRes: true
        },
        afterSuccessAction: _ => this.backToProfile.emit('general')
      },
      resetPassword: {
        reqOptions: {
          method: 'POST',
          code: this.code,
          endpoint: 'validate_restore_code',
          body: { password: this.changePassForm.controls.newPass.value },
          fullRes: true
        },
        afterSuccessAction: _ => this.backToLogin.emit(true)
      }
    };
  }
}

<mat-list-item class="geofence-item" (click)="openGeoDetails(geofence.id, $event)">
  <mat-icon mat-list-icon class="geofence-icon">
    <img src="assets/img/icons/geofence-icon.svg" alt="geofence-icon">
  </mat-icon>
  <div class="gioTitle" mat-line
    [ngStyle]="{ 'color': !geofence.active ? '#b8b8b8' : '#848484' }">
    {{ geofence?.name }}
  </div>
  <div mat-line>
    <!-- vehicles -->
    <div class="vehicles">
      <img *ngIf="geofence.active" src="assets/img/carsmall-copy-4.png" alt="carsmall">
      <img *ngIf="!geofence.active" src="assets/img/icons/carsmall-inactivo.svg" alt="carsmall">
      <span [ngStyle]="{ 'color': !geofence.active ? '#b8b8b8' : '#848484' }">{{ geofence.vehicles?.length }}</span>
    </div>
    <div class="features">
      <!-- Active -->
      <span *ngIf="geofence.active" [ngStyle]="{ 'display': geofence.isScheduled ? '' : 'none'}"></span>
      <span *ngIf="geofence.active"
        [ngStyle]="{ 'display': geofence.when=='inside' || geofence.when === 'both' ? '' : 'none'}"></span>
      <span *ngIf="geofence.active"
        [ngStyle]="{ 'display': geofence.when=='outside'|| geofence.when === 'both'  ? '' : 'none'}"></span>
      <!-- Unactive -->
      <span *ngIf="!geofence.active" class="geofence-unactive"
        [ngStyle]="{ 'display': geofence.isScheduled ? '' : 'none'}"></span>
      <span *ngIf="!geofence.active" class="geofence-unactive"
        [ngStyle]="{ 'display': geofence.when=='inside' || geofence.when === 'both' ? '' : 'none'}"></span>
      <span *ngIf="!geofence.active" class="geofence-unactive"
        [ngStyle]="{ 'display': geofence.when=='outside'|| geofence.when === 'both'  ? '' : 'none'}"></span>
    </div>
    <span class="spacer"></span>
    <!-- status -->
    <div class="active-status" *ngIf="!isFreemium && generalService.checkPermission(['admin.fleetrw', 'admin.fleetmgr'])">
      <mat-slide-toggle name="geofenceActive" [(checked)]="geofence.active" 
        (change)="markStatus(geofence.id, $event)"></mat-slide-toggle>
    </div>
    <!-- action menu -->
    <div class="dropdownmenu-action" *ngIf="!isFreemium && generalService.checkPermission(['admin.fleetrw', 'admin.fleetmgr'])">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon class="action-button">more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openEditMode(geofence.id); isVisible = false">
          <span>{{ 'GEOFENCE.DELETE' | translation }}</span>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-list-item>
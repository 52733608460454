import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GeneralModalComponent} from '../../general-modal/general-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from '../../../services/auth.service';
import {GeneralService} from '../../../services/general.service';

@Component({
  selector: 'app-modify-password',
  templateUrl: './modify-password.component.html',
  styleUrls: ['./modify-password.component.css']
})
export class ModifyPasswordComponent implements OnInit {

  @Input('data') data: any;
  @Input('dialogRef') dialogRef: any;
  @Output() runEntities = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private gs: GeneralService
  ) {
  }

  ngOnInit(): void {
    // This is intentional
  }

  async closeModal() {
    this.dialogRef.close();
  }

  /**
   * Realiza la solicitud de cambio de contraseña
   */
  sendPasswordRequest() {
    this.authService.forgotPassword(this.data.email).subscribe(
      (_res) => {
        this.openGeneralModal('change_password_response', true);
      },
      (_error) => {
        this.openGeneralModal('change_password_response', false);
      }
    );
    this.gs.logScreenEvents('change_password_click_request', 'change_password_click_request', 'change_password_click_request', 'click');
    this.dialogRef.close();
  }

  openGeneralModal(type: string, success: boolean) {
    this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '30vw',
      data: {type, success}
    });
  }

}

import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { checkForQparam, parsedDashboardData } from '../../helpers/helpers'
import { TranslationPipe } from '../../pipes/translation/translation.pipe'
import { DashboardService } from '../../services/dashboard.service'
import { GeneralService } from '../../services/general.service'
import { LookerService } from '../../services/looker.service'
import { RequestService } from '../../services/request.service'

const resetTime = (t: number) => new Date(`${ new Date(t).toISOString().substr(0, 10)}T00:00:00.000Z`)
  .getTime();
const setHours = time => {
  const start = checkForQparam('start');
  const end = checkForQparam('end');
  const to = time.to + 1000 * 60 * 60 * 24;
  time = {
    gte: start ? Number(start) : resetTime(time.from),
    lte: end ? Number(end) : resetTime(to)
  };
  return time;
};
const commonMin = new Date(new Date().setFullYear((new Date().getFullYear() - 10)));
const commonMax = new Date(new Date().setDate((new Date().getDate() - 1)));

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [ TranslationPipe ]
})
export class DashboardComponent implements OnInit, OnDestroy {

  @Input('isFreemium') isFreemium: boolean;

  private unsuscriber = new Subject()

  rangeFrom: any = {
    min: commonMin,
    max: commonMax,
  };
  rangeTo: any = {
    min: commonMin,
    max: commonMax,
  };
  filters: any;
  selected: any = {};
  datePicker: any = {};
  mock: any;
  contexts: any;
  wiState: any;
  findText: string;
  lookerDashboard = []
  tenantId = ''

  constructor(
    private ds: DashboardService,
    private gs: GeneralService,
    private rs: RequestService,
    private tp: TranslationPipe,
    private lookerService: LookerService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.gs.getWiContext.pipe(takeUntil(this.unsuscriber)).subscribe((data: any[]) => this.contexts = data);
    this.gs.getWiFindText.pipe(takeUntil(this.unsuscriber)).subscribe(text => this.findText = text);
    this.gs.getWiState.pipe(takeUntil(this.unsuscriber)).subscribe(state => this.wiState = state);
    this.lookerService.dashboard$.pipe(takeUntil(this.unsuscriber)).subscribe(data => {
      // TODO: remove filter when go to clients
      // this.lookerDashboard = data.filter(report => report.tenant === '5efc0327b3d0c50479fe7f44') || [];
      this.lookerDashboard = data || [];
    })
    this.tenantId = this.lookerService.currentTenants[0]
    this.filters = this.ds.dashboardFilters();
  }

  ngOnDestroy(): void {
    this.unsuscriber.next()
    this.unsuscriber.complete()
  }

  genDashboard(filter) {
    this.selected = filter;
    this.sendTrackerFilter(filter);
    this.checkRanges();
    const rangeFilter = (this.selected.type === 'RANGE_FILTER' || this.selected.type === 'MONTH_YEAR');
    if (rangeFilter && (!this.datePicker.from || !this.datePicker.to)) { return; }
    if (rangeFilter) {
      const datePicker = {};
      Object.keys(this.datePicker).forEach( _ => datePicker[_] = new Date(this.datePicker[_]).getTime());
      this.selected.query = datePicker;
    }
    this.getTypes();
  }

  sendTrackerFilter(filter) {
    if (filter.type === 'LAST_7_DAYS') {
      this.gs.logScreenEvents('module_dashboard_7days','module_dashboard_7days','module_dashboard_7days','click');
    } else if (filter.type === 'LAST_30_DAYS') {
      this.gs.logScreenEvents('module_dashboard_last30days','module_dashboard_last30days','module_dashboard_last30days','click');
    }
  }

  async getTypes() {
    const req = await this.rs.request({
      method: 'GET',
      endpoint: 'dashboard_types'
    });
    if ( req.error ) { return alert(this.tp.transform('NO_DASHBOARD_INFO')); }
    this.getValues( req, this.selected.query );
  }

  async getValues(types: any, time: any) {
    const data = await this.rs.request({
      method: 'POST',
      endpoint: 'dashboard_values',
      body: { time: setHours(time), types: types.map( _ => _.id) }
    });
    const values = parsedDashboardData(types, data, this.selected).filter(Boolean);
    if ( !values.length ) {
      this.ds.setDashboard({ filter: this.selected.query, type: this.selected.type });
      return this.gs.setWindow({ state: this.wiState, extendedState: true, type: 'dashboard', contexts: this.contexts, data: {
        type: 'NO_DASHBOARD_DATA'
      }});
    }
    const { from, to } = this.selected.query;
    const start = checkForQparam('start');
    const end = checkForQparam('end');
    this.ds.setDashboard({ values, charts: [], filter: {
      from: Number(start) || from,
      to: Number(end) || to
    }, type: this.selected.type });
    this.gs.setWindow({ state: this.wiState, extendedState: true, type: 'dashboard', contexts: this.contexts });
  }

  monthSelected(event, rangeType, picker, type) {
    if (rangeType !== 'MONTH_YEAR' ) { return; }
    const dateSelected = new Date(event);
    this.datePicker[type] = dateSelected;
    this.datePicker['to'] = new Date(new Date(event).setDate(31));
    this.genDashboard({ type: 'MONTH_YEAR' });
    picker.close();
  }

  checkRanges() {
    this.rangeTo.min = this.datePicker.from;
  }

  openLooker(module: string, tenant: string) {
    this.router.navigate([`home/dashboard/${module}/${tenant}`]);
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import _ from 'lodash';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { environment as env } from '../../environments/environment';
import { AlertSnackBarComponent } from '../components/alert-snack-bar/alert-snack-bar.component';
import { HEADER_OPTIONS, timeOut } from '../helpers/helpers';
import { translationPipe } from '../pipes/translation/translation.pipe';
declare var firebase;

// LeafLet
declare let L;

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(
    public http: HttpClient,
    private _snackBar: MatSnackBar,
  ) {
    // panToOffset function property
    L.Map.prototype.panToOffset = function (latlng, offset, _options) {
      const x = this.latLngToContainerPoint(latlng).x - offset[0];
      const y = this.latLngToContainerPoint(latlng).y - offset[1];
      const point = this.containerPointToLatLng([x, y]);
      return this.setView(point, this._zoom);
    };

    // finds layer by imei£
    L.LayerGroup.include({
      findByImei: function (id) {
        for (const i in this._layers) {
          if (this._layers[i].options.info && this._layers[i].options.info.device &&
            this._layers[i].options.info.device.imei === id) {
            return this._layers[i];
          }
        }
      },
      findAssetByImei: function (id) {
        for (const i in this._layers) {
          if (this._layers[i].options.info && this._layers[i].options.info.imei === id) {
            return this._layers[i];
          }
        }
      }
    });

    this.getWiState.subscribe(s => this.localWiState = s);
    if (localStorage.getItem('debug')) {
      this.log = console;
    }
    this.getLoggedUser.subscribe(admin => {
      this.admin = admin;
    });
  }
  log: any = new Proxy({}, { get: () => () => null });
  admin: any;

  fleetConfig = new BehaviorSubject<string>(null);
  getFleetConfig = this.fleetConfig.asObservable();

  scoreWeekFilter = new BehaviorSubject<string>(null);
  getScoreWeekFilter = this.scoreWeekFilter.asObservable();

  reloadEntities = new BehaviorSubject<boolean>(false);
  getReloadEntities = this.reloadEntities.asObservable();

  map = new BehaviorSubject<any>(null);
  getMap = this.map.asObservable();
  // Spinner state
  showSpinner = new BehaviorSubject<any>({ show: false, progress: false });
  getSpinnerState = this.showSpinner.asObservable();
  // Toolbar type
  toolBar = new BehaviorSubject<string>(null);
  getToolBar = this.toolBar.asObservable();

  toolbarButtons = new BehaviorSubject<string>(null);
  getToolbarButtons = this.toolbarButtons.asObservable();

  scoreType = new BehaviorSubject<string>(null);
  getCurrentScoreType = this.scoreType.asObservable();

  loggedUser = new BehaviorSubject<any>(null);
  getLoggedUser = this.loggedUser.asObservable();
  RTSState = new BehaviorSubject<boolean>(false);
  getRTSState = this.RTSState.asObservable();
  // Window state
  wiState = new BehaviorSubject<boolean>(false);
  getWiState = this.wiState.asObservable();
  localWiState: boolean;
  // Window extended state
  wiExtendedState = new BehaviorSubject<boolean>(false);
  getWiExtendedState = this.wiExtendedState.asObservable();
  // Window extended type
  wiExtendedType = new BehaviorSubject<string>(null);
  getWiExtendedType = this.wiExtendedType.asObservable();
  // Window info bar type
  wiType = new BehaviorSubject<string>(null);
  getWiType = this.wiType.asObservable();
  // Window info bar data
  wiData = new BehaviorSubject<any>({});
  getWiData = this.wiData.asObservable();
  // Window context
  wiContext = new BehaviorSubject<any>(this.contextOptions());
  getWiContext = this.wiContext.asObservable();
  // Window findText
  wiFindText = new BehaviorSubject<string>('');
  getWiFindText = this.wiFindText.asObservable();
  // Finder spinner
  finderSpinner = new BehaviorSubject<boolean>(false);
  getFinderSpinner = this.finderSpinner.asObservable();
  // Expanded
  expanded = new BehaviorSubject<any>({});
  getExpanded = this.expanded.asObservable();
  // Ahow live view route
  showLiveViewRoute = new BehaviorSubject<boolean>(false);
  getShowLiveViewRoute = this.showLiveViewRoute.asObservable();
  // Score info url
  scoreInfoUrl = new BehaviorSubject<any>(null);
  getScoreInfoUrl = this.scoreInfoUrl.asObservable();
  // Settings
  settings = new BehaviorSubject<any>({});
  getSettings = this.settings.asObservable();
  // Vehicle Sort
  vehicleSort = new BehaviorSubject<string>(null);
  getVehicleSort = this.vehicleSort.asObservable();
  // Geofence Sort
  geofenceSort = new BehaviorSubject<string>(null);
  getGeofenceSort = this.geofenceSort.asObservable();
  // Driver to open in indicators
  openIndicatorForDriver = new BehaviorSubject<string>(null);
  getOpenIndicatorForDriver = this.openIndicatorForDriver.asObservable();

  // Dashcam Events Trip
  eventsTrip = new BehaviorSubject<any>(null);
  getEvetsTrip = this.eventsTrip.asObservable();

  // Single trip
  singleTrip = new BehaviorSubject<any>(null);
  getSingleTrip = this.singleTrip.asObservable();
  setFleetConfig(data: any) { this.fleetConfig.next(data); }
  setScoreWeekFilter(data: any) { this.scoreWeekFilter.next(data); }
  setReloadEntities(data: boolean) { this.reloadEntities.next(data); }
  setToolbarButtons(data: any) { this.toolbarButtons.next(data); }
  setCurrentScoreType(data: any) { this.scoreType.next(data); }
  setLoggedUser(data: any) { this.loggedUser.next(data); }
  setExpanded(data: any) { this.expanded.next(data); }
  setShowLiveViewRoute(data: any) { this.showLiveViewRoute.next(data); }
  setScoreInfoUrl(data: any) { this.scoreInfoUrl.next(data); }
  setSettings(data: any) { this.settings.next(data); }
  setVehicleSort(data: string) { this.vehicleSort.next(data); }
  setGeofenceSort(data: string) { this.geofenceSort.next(data); }
  setOpenIndicatorForDriver(data: string) { this.openIndicatorForDriver.next(data); }
  setMap(map: any) { this.map.next(map); }
  setSpinnerState(state: any) { this.showSpinner.next(state); }
  setToolBar(type: string) { this.toolBar.next(type); }
  setRTSState(state: boolean) { this.RTSState.next(state); }
  setSingleTrip(trip: any, eventImages: any) { this.singleTrip.next({ trip, eventImages }); }

  // Get all entities
  getEntitites(entities) {
    const observableBatch = [];
    entities.map(e => observableBatch.push(this.http.get(`${env.API_PATH}/api/${e}/v1/all`, HEADER_OPTIONS())));
    return forkJoin(observableBatch);
  }

  checkPermission(permissions) {
    if (!this.admin) { return false; }
    return this.admin.roles.some(r => permissions.includes(r));
  }

  setWindow(data: any) {
    if (data.state) {
      this.finderSpinner.next(true);
      const stop = setTimeout(() => {
        this.finderSpinner.next(false);
        this.wiState.next(data.state);
        clearTimeout(stop);
        window.dispatchEvent(new Event('resize'));
      }, 800);
    } else {
      this.wiState.next(data.state);
    }
    this.wiType.next(data.type);
    this.wiData.next(data.data);
    this.wiFindText.next(data.findText);
    this.wiContext.next(data.contexts);
    this.wiExtendedState.next(data.extendedState);
    this.wiExtendedType.next(data.extendedType);
  }

  setWindowData(data: any) { this.wiData.next(data); }

  async unfocusPanel() {
    await this.timeOut(this.localWiState ? 100 : 600);
    const panels = document.querySelectorAll('mat-expansion-panel');
    const unfocused = [];
    panels.forEach(m => {
      m.classList.remove('unfocused');
      if (!m.classList.contains('mat-expanded')) {
        unfocused.push(m);
      }
    });
    if (panels.length === unfocused.length) {
      panels.forEach(m => m.classList.remove('unfocused'));
    } else {
      panels.forEach(m => m.classList.contains('mat-expanded') ? false : m.classList.add('unfocused'));
    }
  }

  addSelectedClass(marker) {
    this.unfocusPanel();
    const el = document.getElementsByClassName('markerTemplate');
    for (let i = 0; i < el.length; i++) {
      el[i].classList.remove('markerTemplate_selected');
      if (el.length === (i + 1) && _.get(marker, ['_icon'])) {
        marker._icon.classList.add('markerTemplate_selected');
      }
    }
  }

  goToMarker(marker, data, map, contexts) {
    const lastLoc = marker.getLatLng();
    map.flyTo([lastLoc.lat, lastLoc.lng], 16, {
      animate: true,
      duration: 1
    });
    if (data.type !== 'notification') { this.addSelectedClass(marker); }
    setTimeout(() => { this.setWindow({ state: true, type: 'list', contexts, data }); }, 500);
  }

  goToAssetMarker(marker, map) {
    const lastLoc = marker.getLatLng();
    map.flyTo([lastLoc.lat, lastLoc.lng], 16, {
      animate: true,
      duration: 1
    });
  }

  imageExists(url) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = function () { resolve(true); };
      img.onerror = function () { resolve(false); };
      img.src = url;
    });
  }

  handleDecimals(value: number, decimals: number) {
    return value.toFixed(decimals);
  }

  getMenu() {
    return this.http.get(`${env.API_PATH}/api/navigation/v1/menu`, HEADER_OPTIONS());
  }

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  asyncRequest(service) {
    return new Promise((resolve, reject) => {
      service.subscribe(data => resolve(data), error => reject(error));
    });
  }

  timeOut(time) {
    return timeOut(time);
  }

  strArr(arr) {
    return JSON.stringify((arr || []).sort((a, b) => a - b));
  }

  openAlert({ msg, duration = 7000, error = false }) {
    this._snackBar.openFromComponent(AlertSnackBarComponent, {
      duration,
      data: translationPipe.transform(msg),
      panelClass: error ? 'panel-error' : 'panel-success',
      verticalPosition: 'top'
    });
    return this._snackBar;
  }

  statsOptions() {
    return [
      {
        id: 1,
        type: 'stats',
        name: 'kpis'
      }
    ];
  }

  getDriverTrafficLights() {
    return [
      {
        type: 'safe',
        icon: 'assets/img/icons/seguridad-alta.svg',
        color: '#73cc4a'
      },
      {
        type: 'medium',
        icon: 'assets/img/icons/seguridad-media.svg',
        color: '#feca59'
      },
      {
        type: 'risky',
        icon: 'assets/img/icons/seguridad-baja.svg',
        color: '#ef6a47'
      }
    ];
  }

  contextOptions() {
    return [
      {
        icon: '/assets/img/icons/liveview-w.svg',
        icon_blue: 'liveview',
        icon_add: '',
        icon_suggestions: '',
        context: 'liveview',
        menu: true,
        search: 'liveview',
        analytics: 'module_live'
      },
      {
        icon: '/assets/img/icons/dashboard.svg',
        icon_blue: 'dashboard',
        icon_add: 'addnotification',
        icon_suggestions: '/assets/img/suggestions-icons/dashboard-suggestions.svg',
        context: 'dashboard',
        menu: true,
        search: 'dashboard'
      },
      {
        icon: '/assets/img/icons/notifications.svg',
        icon_blue: 'notifications_blue',
        icon_add: 'addnotification',
        icon_suggestions: '/assets/img/suggestions-icons/notifications-suggestions.svg',
        context: 'notifications',
        menu: true,
        search: 'notificaciones notifications'
      },
      {
        icon: '/assets/img/icons/Rank.svg',
        icon_suggestions: '/assets/img/suggestions-icons/ranking-suggestions.svg',
        context: 'ranking',
        search: 'scores score ranking',
        menu: true
      },
      {
        icon: '/assets/img/icons/vehicles.svg',
        icon_blue: 'vehicles_blue',
        icon_add: 'addvehicle',
        icon_suggestions: '/assets/img/suggestions-icons/vehicles-suggestions.svg',
        context: 'vehicles',
        menu: true,
        search: 'vehiculos vehículos vehicles'
      },
      {
        icon: '/assets/img/icons/drivers.svg',
        icon_blue: 'drivers_blue',
        icon_add: 'adddriver',
        icon_suggestions: '/assets/img/suggestions-icons/drivers-suggestions.svg',
        context: 'drivers',
        menu: true,
        search: 'conductores drivers'
      },
      {
        icon: '/assets/img/icons/health-outline.svg',
        icon_blue: 'health_blue',
        icon_add: '',
        material_icon: 'add_circle_outline',
        icon_suggestions: '/assets/img/suggestions-icons/health-suggestions.svg',
        context: 'health',
        menu: true,
        search: 'dts health salud vehicular'
      },
      {
        icon: '/assets/img/icons/geocercas.png',
        icon_blue: 'vehicles_blue',
        icon_add: '',
        icon_suggestions: '/assets/img/suggestions-icons/health-geocercas.png',
        context: 'geofences',
        menu: true,
        search: 'vehiculos vehículos vehicles'
      },
      {
        icon: '/assets/img/icons/stats.svg',
        icon_blue: 'stats',
        icon_add: '',
        icon_suggestions: '',
        context: 'stats',
        menu: true,
        search: 'fleet stats stadistics'
      },
      {
        icon: '/assets/img/icons/feedback.svg',
        icon_blue: 'feedback',
        icon_add: '',
        icon_suggestions: '',
        context: 'feedback',
        menu: true,
        search: 'fleet feedback'
      },
      {
        icon: '/assets/img/icons/tags.svg',
        icon_blue: 'tag',
        icon_add: '',
        icon_suggestions: '',
        context: 'tag',
        menu: true,
        search: ''
      },
      {
        icon: '/assets/img/icons/extensions.svg',
        icon_blue: 'extensions',
        icon_add: '',
        icon_suggestions: '',
        material_icon: '',
        context: 'Add ons',
        menu: true,
        search: 'extensions integrations'
      }
    ];
  }

  logScreenEvents(eventName = 'fleetr_unknown_screen', itemName, itemId, contentType) {
    firebase.analytics().logEvent(eventName, { eventName, itemName, itemId, contentType });
  }

  setEventsTrip(eventsTrip) {
    this.eventsTrip.next(eventsTrip);
  }
}

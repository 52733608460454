import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { timeAgo } from '../../../helpers/helpers'
import { GeneralService } from '../../../services/general.service'
import { HomeService } from '../../../services/home.service'
import { MarkerService } from '../../../services/marker.service'
import { GeneralModalComponent } from '../../general-modal/general-modal.component'

@Component({
  selector: 'vehicle-template',
  templateUrl: './vehicle-template.component.html',
  styleUrls: ['./vehicle-template.component.css', '../device-template/device-template.component.css']
})
export class VehicleTemplateComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  @Input() vehicle: any;
  @Input() isFreemium: boolean;
  @Output() runEntities = new EventEmitter<any>();

  vehicleHealth = [
    { name: 'Buena', type: 'good', icon: 'salud-buena.svg' },
    { name: 'Mala', type: 'bad', icon: 'salud-mala.svg' },
    { name: 'Estado critico', type: 'critical', icon: 'saludmuy-mala.svg' }
  ];

  menu: any = [];
  wiData: any;
  contexts: any;
  map: any;
  showingRoute: boolean;
  mGroup: any;
  findText: string;
  sort: any;
  showAddVehicleTags = false;
  selected: any;

  // Combine weekdays and shift data into a single array for easier template handling
  weekdays: { day: string, today?: boolean, active?: boolean, shifts?: any[] }[] = [
    { day: 'Dom' }, { day: 'Lun' }, { day: 'Mar' }, { day: 'Mié' }, { day: 'Jue' }, { day: 'Vie' }, { day: 'Sáb' }
  ];

  constructor(
    public gs: GeneralService,
    public homeService: HomeService, // Unused, consider removing
    public markerService: MarkerService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.gs.logScreenEvents('vehicle_view', 'vehicle_view', 'vehicle_view', 'view');
    this.initializeSubscriptions();
    this.updateWeekdays();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeSubscriptions() {
    this.gs.getWiFindText.pipe(takeUntil(this.destroy$)).subscribe(findText => this.findText = findText);
    this.gs.getWiContext.pipe(takeUntil(this.destroy$)).subscribe((data: any) => this.contexts = data);
    this.gs.getMap.pipe(takeUntil(this.destroy$)).subscribe((data: any) => this.map = data);
    this.gs.getShowLiveViewRoute.pipe(takeUntil(this.destroy$)).subscribe(show => this.showingRoute = show);
    this.markerService.getMGroup.pipe(takeUntil(this.destroy$)).subscribe(g => this.mGroup = g);
    this.gs.getWiData.pipe(takeUntil(this.destroy$)).subscribe(data => this.wiData = data);
    this.gs.getToolbarButtons.pipe(takeUntil(this.destroy$)).subscribe(buttons => this.menu = buttons);
    this.gs.getWiData.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      if (data && data.type === 'vehicle') {
        this.selected = data;
      }
    });
    this.gs.getVehicleSort.pipe(takeUntil(this.destroy$)).subscribe(_ => this.sort = _);
  }

  private updateWeekdays() {
    const today = this.weekdays[new Date().getDay()];
    this.weekdays.forEach((wd, wi) => {
      wd.today = wd.day === today.day;
      wd.active = this.vehicle?.shifts?.some(vs => this.vehicle.current_driver?.shifts?.some(ds => vs.day === wd.day && ds.day === wd.day));
       // Pre-calculate shifts for each day for better performance
      wd.shifts = this.vehicle?.shifts?.filter(vs => this.vehicle.current_driver?.shifts?.some(ds => vs.uid === ds.uid && wd.day === ds.day));
    });
  }


  getHealth(type: string) {
    return this.vehicleHealth.find(vh => vh.type === type);
  }

  fullName(name: string, lastName: string) {
    return `${name.split(' ')[0]} ${lastName.split(' ')[0]}`;
  }

  closePanel() {
    this.selected = undefined;
    this.gs.setWindow({ state: true, extendedState: false, type: 'list', contexts: this.contexts });
  }

  getNameFirstLetter(name: string, lastName: string) {
    return `${name?.[0] ?? ''} ${lastName?.[0] ?? ''}`; // Simplified and safer
  }

  openTrips() {
    const context = this.contexts.filter(c => new RegExp(this.vehicle.type).test(c.context));
    this.gs.setWindow({ state: true, extendedState: false, type: 'trips', contexts: context, data: this.vehicle });
  }

  timeAgo(time: number | string) {
    return timeAgo(time);
  }


  openExtension() {
    this.gs.setWindow({ state: true, extendedState: true, type: 'list', contexts: this.contexts, data: this.vehicle });
  }

  selectedVehicle(vehicle: any, panel: any) {
    if (this.isFreemium && panel) { panel._toggle(); }
    this.gs.setShowLiveViewRoute(false);
    this.gs.setWindow({ state: true, extendedState: false, type: 'list', contexts: this.contexts, data: null });
    if (!vehicle || !vehicle.device) { return this.gs.addSelectedClass(false); }
    const marker = this.mGroup.findByImei(vehicle.device.imei);
    marker ? this.gs.goToMarker(marker, vehicle, this.map, this.contexts) : this.gs.addSelectedClass(false); // Simplified conditional
  }

  openHealth() {
    this.gs.setWindow({
      state: true,
      extendedState: false,
      type: 'list',
      contexts: [this.menu.find(i => i.context === 'health')],
      data: Object.assign({}, { type: 'health' })
    });
  }

  openMaintenance() {
    this.gs.setWindow({
      state: true,
      extendedState: true,
      type: 'list',
      contexts: this.contexts,
      data: Object.assign({}, this.vehicle, { type: 'vehicle_maintenance' })
    });
  }

  editVehicle(vehicle) {
    this.openGeneralModal(vehicle, 'edit_vehicle');
    this.gs.logScreenEvents('vehicle_click_edit', 'vehicle_click_edit', 'vehicle_click_edit', 'click');
  }

  openGeneralModal(vehicle, type) {
    let width = '80vw';
    if (type === 'edit_vehicle') {
      width = '600px';
    }
    const dialogRef = this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width,
      data: { ...vehicle, type }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.runEntities.emit(true);
    });
  }

  showOptions() {
    this.showAddVehicleTags = true;
  }

  addTagsClose() {
    this.showAddVehicleTags = false;
  }

  addTagsUpdated() {
    this.runEntities.emit(true);
    this.showAddVehicleTags = false;
  }

}

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { translationPipe } from '../../../../pipes/translation/translation.pipe';
import { GeneralService } from '../../../../services/general.service';

@Component({
  selector: 'app-trip-events',
  templateUrl: './trip-events.component.html',
  styleUrls: ['./trip-events.component.css']
})
export class TripEventsComponent implements OnInit {

  contexts: any;
  wiData: any;
  eventsTrip: any;
  eventTypeFormated = '';
  recordedAt: any;
  icons = {
    hardAcceleration: '/assets/img/icons/aceleracionbrusca.svg',
    hardBreak: '/assets/img/icons/frenadobrusco.svg',
    hardTurn: '/assets/img/icons/girobrusco.svg',
    idle: '/assets/img/icons/tiempoinactivo.svg'
  }

  isDisabledPlay = true;
  imageSelected: string;
  posicionActual = 0;
  images: Array<string> = [];
  intervalo: null | ReturnType<typeof setTimeout>;
  private suscription: Subscription = new Subscription;

  constructor(public gs: GeneralService) {
    this.suscription.add(this.gs.getWiContext.subscribe((data: any[]) => this.contexts = data));
    this.suscription.add(this.gs.getWiData.subscribe((data: string) => {
      this.wiData = data;
    }));
    this.suscription.add(this.gs.getEvetsTrip.subscribe((data: string) => {
      this.eventsTrip = data;
      const eventString = translationPipe.transform(`TRIP_EVENTS.${this.eventsTrip.type}`);
      this.eventTypeFormated = eventString;
      this.images = [];
      this.stop();
      if (this.eventsTrip.eventType === 'dashcam' && this.eventsTrip.images.length > 0) {
        const eventName = 'dashcam_video_view';
        this.gs.logScreenEvents(eventName, eventName, eventName, 'view');
        this.eventsTrip.images.forEach(element => {
          this.images.push(element.url);
        });
        this.renderImage();
        this.play();
      }
      this.recordedAt = this.secondsToString(this.eventsTrip.recordedAt);
    }));
  }

  ngOnInit(): void {
    // This is intentional
  }

  ngOnDestroy() {
    this.suscription.unsubscribe();
    clearInterval(this.intervalo);
  }

  secondsToString(seconds) {
    if(seconds === null) return "--";
    let hour: any = Math.floor(seconds / 3600);
    hour = (hour < 10)? '0' + hour : hour;
    let minute: any = Math.floor((seconds / 60) % 60);
    minute = (minute < 10)? '0' + minute : minute;
    let second: any = seconds % 60;
    second = (second < 10)? '0' + second : second;
    if(parseInt(hour[1]) === 0){
      return minute + ':' + second;
    } else {
      return hour + ':' + minute + ':' + second;
    }
  }

  /**
   * Change the position of the image to display and call the renderImage function
   */
  nextImage() {
    if(this.posicionActual >= this.images.length - 1) {
      this.posicionActual = 0;
    } else {
      this.posicionActual++;
    }
    this.renderImage();
  }

  /**
   * Change the image being displayed
   */
  renderImage() {
    this.imageSelected = this.images[this.posicionActual];
  }

  /**
   * Play images automatically
   */
  play() {
    const eventName = 'dashcam_video_button';
    this.gs.logScreenEvents(eventName, eventName, eventName, 'click');
    this.intervalo = setInterval( () => {this.nextImage()}, 500);
    this.isDisabledPlay = true;
  }

  /**
   * Stops image playback
   */
  stop() {
    clearInterval(this.intervalo);
    this.isDisabledPlay = false;
  }

}

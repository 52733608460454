<div class="form">
  <div class="header">
    <button class="back-to-list" mat-icon-button (click)="back()">
      <mat-icon mat-list-icon>arrow_back_ios</mat-icon>
    </button>
    <div class="titles">
      <div class="title" *ngIf="currentView === 'adminsList' ">
        {{ 'ADMIN_CONFIGURATION.TITLE' | translation }}
      </div>
      <div class="title" *ngIf="currentView === 'adminEdit' && !creating">
        {{ 'ADMIN_CONFIGURATION.EDIT' | translation }}
      </div>
      <div class="title" *ngIf="currentView === 'adminEdit' && creating">
        {{ 'ADMIN_CONFIGURATION.CREATE' | translation }}
      </div>
    </div>
  </div>
  <!-- Empty admins view -->
  <div class="empty-admins" *ngIf="!loading && !admins.length && currentView === 'adminsList'">
    <div>
      <img src="assets/img/addadmin.svg" alt="add-admin">
      <p>{{ 'ADMIN_CONFIGURATION.ADDADMIN_DESCRIPTION' | translation }}</p>
      <button mat-raised-button (click)="setCurrentView('adminEdit', {}, true)">{{ 'ADMIN_CONFIGURATION.ADDADMIN' | translation
        }}</button>
    </div>
  </div>
  <!-- Admins view -->
  <div class="admins-list" *ngIf="admins.length && currentView === 'adminsList' ">
    <div class="subheader">
      <h3 class="subtitle">{{ 'ADMIN_CONFIGURATION.ADMINS' | translation }}</h3>
      <button mat-raised-button (click)="setCurrentView('adminEdit', {}, true)">
        <mat-icon> <img src="assets/img/new-admin.svg" alt="new-admin"> </mat-icon>
      </button>
    </div>
    <div class="role-rows" *ngFor="let roleRow of roleRows">
      <div class="row-divider" *ngIf="roleRow.admins.length">
        <span>{{ roleNames[roleRow.id] | translation }} ({{roleRow.admins.length}})</span>
        <span class="bar"></span>
      </div>
      <div class="admin" *ngFor="let admin of roleRow.admins">
        <ng-container *ngTemplateOutlet="adminTemplate; context: { admin: admin }"></ng-container>
      </div>
    </div>
    <div *ngIf="statusPendingRows.length" class="role-rows">
      <div class="row-divider">
        <span>{{ 'ADMIN_CONFIGURATION.PENDING' | translation }} ({{statusPendingRows.length}})</span>
        <span class="bar"></span>
      </div>
      <div class="admin" *ngFor="let admin of statusPendingRows">
        <ng-container *ngTemplateOutlet="adminTemplate; context: { admin: admin }"></ng-container>
      </div>
    </div>
  </div>
  <!-- Admin Edit view -->
  <div class="admin-edit" *ngIf="currentView === 'adminEdit'">
    <div class="admin-form">
      <br>
      <div>
        <span *ngIf="currentAdmin.name" class="input-name">{{ 'ADMIN_CONFIGURATION.NAME' | translation }}</span>
        <input [disabled]="loading" name="name" class="inputs-text" type="text"
          placeholder="{{ 'ADMIN_CONFIGURATION.NAME' | translation }}"
          [(ngModel)]="currentAdmin.name">
        <div *ngIf="currentAdmin.name" class="clear-input" (click)="currentAdmin.name = ''">x</div>
      </div>
      <div>
        <span *ngIf="currentAdmin.lastName" class="input-name">{{ 'ADMIN_CONFIGURATION.LASTNAME' | translation }}</span>
        <input [disabled]="loading" name="lastName" class="inputs-text" type="text"
          placeholder="{{ 'ADMIN_CONFIGURATION.LASTNAME' | translation }}"
          [(ngModel)]="currentAdmin.lastName">
        <div *ngIf="currentAdmin.lastName" class="clear-input" (click)="currentAdmin.lastName = ''">x</div>
      </div>
      <div>
        <span *ngIf="currentAdmin.email" class="input-name">{{ 'ADMIN_CONFIGURATION.EMAIL' | translation }}</span>
        <input [disabled]="loading || currentAdmin.status === 'active'" name="email" [disabled]="!creating" class="inputs-text" type="text"
          placeholder="{{ 'ADMIN_CONFIGURATION.EMAIL' | translation }}"
          [(ngModel)]="currentAdmin.email">
        <div *ngIf="currentAdmin.email && currentAdmin.status !== 'active'" class="clear-input" (click)="currentAdmin.email = ''">x</div>
      </div>
      <h3 class="subtitle">{{ 'ADMIN_CONFIGURATION.PERMISSIONS' | translation }}</h3>
      <div class="select-input">
        <span *ngIf="currentAdmin.role" class="input-name">{{ 'ADMIN_CONFIGURATION.ROLE' | translation }}</span>
        <select [disabled]="loading" name="role" class="select-style" type="text" [(ngModel)]="currentAdmin.role">
          <option [value]="undefined">{{ 'ADMIN_CONFIGURATION.SELECT_ROLE' | translation }}</option>
          <option [value]="role.id" *ngFor="let role of roles">{{ roleNames[role.id] | translation }}</option>
        </select>
      </div>
      <h3 class="subtitle fleet-subtitle">{{ 'ADMIN_CONFIGURATION.SUB_FLEETS_TITLE' | translation }}</h3>
      <p class="fleet-description">{{ 'ADMIN_CONFIGURATION.SUB_FLEETS_DESCRIPTION' | translation }}</p>
      <div class="tag-selector-container">
        <div class="fleetr-autocomplete">
          <ng-autocomplete
            #autocomplete
            [data]="filteredTags"
            searchKeyword="name"
            [placeholder]="'Tags'"
            (selected)="selectEvent($event)"
            [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name" class="option"></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>
        <button class="add" [disabled]="!selectedTag" (click)="addChip()" [class.disabled]="!selectedTag">+ {{ 'ADMIN_CONFIGURATION.ADD' | translation }}</button>
      </div>

      <div class="tag-chips">
        <div class="chip" *ngFor="let tag of chipList">
          <span [innerHTML]="tag.name"></span>
          <button class="close" (click)="removeChip(tag)">
            <img src="assets/img/icons/close-gray.svg" alt="close">
          </button>
        </div>
      </div>

      <div class="actions">
        <button (click)="back()"> {{ 'CANCEL' | translation }} </button>
        <button [disabled]="loading" (click)="adminAction()">
          {{ (currentAdmin.status === 'active' ? 'ADMIN_CONFIGURATION.SAVE' : 'ADMIN_CONFIGURATION.SEND_INVITE') | translation }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Admin list item -->
<ng-template #adminTemplate let-admin="admin">
  <div class="user-data-container" (click)="setCurrentView('adminEdit', admin)">
    <div>
      <div class="picture">
        <img *ngIf="admin.picture" [src]="admin.picture" alt="admin-photo">
        <span *ngIf="!admin.picture">{{ admin.name.charAt(0) }}</span>
      </div>
      <div class="details">
        <span>{{ admin.name }} {{ admin.lastName }}</span> <br>
        <span>{{ admin.email }}
        <img *ngIf="admin.status === 'pending'" src="assets/img/clock.svg" alt="clock">
        <span *ngIf="admin.status === 'pending'" style="color: #b8b8b8">{{ admin.email }}</span> </span>
      </div>
    </div>
    <div>
      <div class="action-button">
        <img src="assets\img\icons\pencil-blue.svg" alt="edit">
      </div>

      <div class="horizontal-divider"></div>

      <div class="action-button" (click)="alertDelete(admin); $event.stopPropagation();">
        <img src="assets\img\icons\trash-blue.svg" alt="delete">
      </div>
    </div>
  </div>
  <div class="user-tags-container" *ngIf="admin.vehicleTags.length > 0">
    <div class="tag-title">{{ 'ADMIN_CONFIGURATION.TAGS' | translation }}:</div>
    <div class="detail-chip-container">
      <ng-container *ngFor="let item of admin.vehicleTags; index as i">
        <div class="tag-chip" *ngIf="i <= 2 && !showMore[admin.id]">{{ item.name }}</div>
        <div class="tag-chip" *ngIf="showMore[admin.id]">{{ item.name }}</div>
      </ng-container>
      <div
        *ngIf="admin.vehicleTags.length > 3 && !showMore[admin.id]"
        (click)="showMoreToggle(admin.id)"
        role="button"
        class="more-tags">
        {{ admin.vehicleTags.length - 3 }} more...
      </div>
    </div>
  </div>
</ng-template>

<div class="container">
  <h4 class="date-head">{{ ds.dateRange(dashboard.filter) }}</h4>
  <div class="no-dashboard-data" *ngIf="wiData?.type === 'NO_DASHBOARD_DATA'">
    <div>
      <img src="assets/img/dashboard/no-dashboard-data.svg" alt="">
      <p>{{ 'NO_DASHBOARD_INFO' | translation }}</p>
    </div>
  </div>
  <div class="dashboard" *ngIf="dashboard">
    <div class="totals">
      <div class="box {{ total.id }}" *ngFor="let total of dashboard.values" (mouseout)="totalComparison = null"
        (mouseover)="showTotalComparison(total)">
        <mat-icon>
          <img [src]="getTotalStyles(total.value.status, total.display.icon).img">
        </mat-icon>
        <span class="label"> {{ total.description }} </span>
        <span class="value" [ngStyle]="{'color': getTotalStyles(total.value.status, total.display.icon).color}">
          {{ setDecimals(total.value?.value, total.precision) }}
          <span class="units">{{ total.unit }} </span>
        </span>
        <span class="comparison" *ngIf="showComparison(total.value.percentDifference)">
          <img [src]="getTendencyStyles(total.value.prevPeriod, total.value.percentDifference, total.id)"
            alt="tendency">
          {{ roundUp(total.value.percentDifference) }}%
        </span>
      </div>
    </div>
    <br *ngFor="let a of [1, 2, 3]">
  </div>
  <div class="back-to-fleetr" (click)="goTo('home')" *ngIf="showOnView()">
    {{ 'BACK' | translation }}
  </div>
</div>

<div class="comparison-tooltip" [ngStyle]="{
    'opacity': !!totalComparison ? 1 : 0
  }">
  <app-comparison-tooltip *ngIf="!!totalComparison" [totalComparison]="totalComparison"></app-comparison-tooltip>
</div>

<div class="container">
  <div class="header" *ngIf="wiData">
    <h2 *ngIf="wiData?.type === 'vehicle'">{{wiData.plate}}</h2>
    <h2 *ngIf="wiData?.type === 'driver' && wiData.current_vehicle">{{wiData.current_vehicle.plate}}</h2>
  </div>
  <div class="trip-event__description" *ngIf="eventsTrip">
    <div class="subheader">
      <img [src]="icons[eventsTrip.type]" alt="">
      <h2>{{eventTypeFormated}}</h2>
    </div>
    <div class="trip-event__description-items">
      <mat-icon class="trip-event__description-items__icon">speed</mat-icon>
      <p *ngIf="eventsTrip.eventType === 'general'"><span>{{'EVENT_SPEED' | translation}}: </span>{{eventsTrip.value}} {{eventsTrip.unit}}
      </p>
      <p *ngIf="eventsTrip.eventType === 'dashcam'"><span>{{'EVENT_SPEED' | translation}}: </span>{{eventsTrip.speed.value}}
        {{eventsTrip.speed.unit}}</p>
    </div>
    <div  *ngIf="eventsTrip.eventType === 'dashcam'" class="trip-event__description-items">
      <mat-icon class="trip-event__description-items__icon">schedule</mat-icon>
      <p><span>{{'RECORDING_TIME' | translation}}: </span>{{recordedAt}}</p>
    </div>
  </div>
  <div class="trip-event__images" *ngIf="eventsTrip.eventType === 'dashcam'">
    <div *ngIf="eventsTrip.images.length > 0 && imageSelected" class="trip-event__images-carousel">
      <div class="trip-event__images-selected">
        <img [src]="imageSelected" alt="">
        <mat-icon *ngIf="!isDisabledPlay" (click)="play()" class="control-icon">play_circle</mat-icon>
        <mat-icon *ngIf="isDisabledPlay" (click)="stop()" class="control-icon">stop_circle</mat-icon>
      </div>
    </div>
  </div>
</div>
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { appDateConfig } from '../../helpers/helpers';
import { translationPipe } from '../../pipes/translation/translation.pipe';
import { GeneralService } from '../../services/general.service';
import { PerformanceService } from '../../services/performance.service';
import { RequestService } from '../../services/request.service';



@Component({
  selector: 'app-reports-modal',
  templateUrl: './reports-modal.component.html',
  styleUrls: ['./reports-modal.component.css']
})
export class ReportsModalComponent implements OnInit, OnDestroy {

  // Enum 'report_prepare', 'report_requested'
  subscriptions: Subject<void> = new Subject<void>();
  currentView = 'report_prepare';
  @ViewChild('pickerFrom', {static: false}) pickerFrom: ElementRef;
  @ViewChild('pickerTo', {static: false}) pickerTo: ElementRef;
  maxDate: any = new Date(new Date().setDate(new Date().getDate() - 1));
  minDate: any;
  ranges: any;
  reportTypes: any = [
    {
      type: 'trips',
      name: 'PERFORMANCE.TRIPS_REPORT'
    }
  ];
  filter: any;
  reportType: any;
  cc = ""
  userData: any;

  constructor(
    public dialogRef: MatDialogRef<ReportsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ps: PerformanceService,
    private gs: GeneralService,
    private rs: RequestService
  ) {
  }

  ngOnDestroy() {
    this.currentView = 'report_prepare';
  }

  ngOnInit() {
    this.ps.getYears.pipe(takeUntil(this.subscriptions)).subscribe((ranges: any) => {
      this.ranges = ranges;
      this.formatDates(this.ranges);
    });

    this.gs.getLoggedUser.pipe(takeUntil(this.subscriptions)).subscribe(data => {
      this.userData = data;
    })
  }

  formatDates(ranges) {
    this.minDate = ranges.start;
    this.filter = {
      from: moment(ranges.start).format(appDateConfig('format').toUpperCase()),
      to: moment(ranges.end).format(appDateConfig('format').toUpperCase()),
    };
  }

  openPicker(type) {
    const picker: any = document.querySelector(`.pickers-conatiner .${type} button.mat-icon-button`);
    picker.click();
  }

  async exportReport() {
    this.reportType = this.reportTypes[0].type;

    const body = {
      type: this.reportType,
      filter: {
        startDate: this.ranges.start.getTime ? this.ranges.start.getTime() : this.ranges.start.valueOf(),
        endDate: this.ranges.end.getTime ? this.ranges.end.getTime() : this.ranges.end.valueOf(),
      }
    };
    const res = await this.rs.request({
      method: 'POST',
      endpoint: 'export_report',
      body
    });
    if (res.error || res.status !== 'requested') {
      return this.gs.openAlert({
        msg: translationPipe.transform('PERFORMANCE.REPORT_EXPORT_ERROR'),
        error: true
      });
    }
    this.currentView = 'report_requested';
  }
}

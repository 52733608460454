import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-validate-higher-odometer-update',
  templateUrl: './validate-higher-odometer-update.component.html',
  styleUrls: ['./validate-higher-odometer-update.component.css']
})
export class ValidateHigherOdometerUpdateComponent {
  @Input('dialogRef') dialogRef: any;
  @Input('data') data: any;

  constructor() {
    // empty constructor
  }

}

<div class="ranking">
  <div>
    <ng-template [ngTemplateOutlet]="header"></ng-template>
    <ng-template [ngTemplateOutlet]="filters"></ng-template>
    <ng-template [ngTemplateOutlet]="columnsHeader"></ng-template>
    <ng-template [ngTemplateOutlet]="driverScores"></ng-template>
  </div>
  <div>
    <ng-template *ngIf="selectedDriver && !data.scoreVariable" [ngTemplateOutlet]="scoreDetails"></ng-template>
  </div>
</div>

<!-- Header -->
<ng-template #header>
  <div class="header">
    <div>
      <h3 class="date-head">
        <button *ngIf="data.scoreVariable" class="back-to-list" mat-icon-button (click)="backToGeneralScore()">
          <mat-icon mat-list-icon>arrow_back_ios</mat-icon>
        </button>
        {{ data | scoreMetadata: 'description' }}
      </h3>
    </div>
  </div>
</ng-template>

<!-- Filters -->
<ng-template #filters>
  <div class="filters-header" [ngClass]="{ 'not-selected-driver': selectedDriver }">
    <div class="week-filter" (click)="openWeekFilter()">
      <div class="week-detail">
        {{ data.weeksRange?.from?.week }} <br>
        <span [innerHTML]="data.weeksRange?.from?.date | grayFontColor: 'to'"></span>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>
      <div class="to">
        {{ 'TO' | translation }}
      </div>
      <div class="week-detail">
        {{ data.weeksRange?.to?.week }} <br>
        <span [innerHTML]="data.weeksRange?.to?.date | grayFontColor: 'to'"></span>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>
    </div>
  </div>
</ng-template>

<!-- Column header -->
<ng-template #columnsHeader>
  <div class="column-header table-header"> 
    <div></div>
    <div></div>
    <div></div>
    <div class="time-columns">
      <mat-icon>schedule</mat-icon>
    </div>
    <div class="distance-columns">
      <mat-icon>multiple_stop</mat-icon>
    </div>
    <div class="score-columns">
      {{ data | scoreMetadata: 'name' }}
    </div>
  </div>
</ng-template>

<!-- Driver scores  -->
<ng-template #driverScores>
  <div class="traffic-lights" *ngFor="let tl of trafficLights">
    <div class="light" *ngIf="!data.scoreVariable && tl.drivers && tl.drivers.length" [ngClass]="{ 'not-selected-driver': selectedDriver &&
        selectedDriver.trafficLight !== tl.type }">
      <span [ngStyle]="{ 'color': tl.color }">
        {{ 'TRAFFICLIGHT_'+data.scoreType.type+'.'+tl.type | translation }}
      </span>
      <span class="bar" [ngStyle]="{ 'background': tl.color }"></span>
    </div>
    <div class="drivers">
      <div class="driver-row" *ngFor="let driver of orderDrivers(tl.drivers) | trafficLight: tl.type"
        (click)="selectDriver(driver)"
        [ngClass]="{
          'selected-driver': selectedDriver === driver,
          'not-selected-driver': selectedDriver && selectedDriver !== driver
        }">
        <div class="score-position">{{ driver.rank }}</div>
        <div class="driver-picture">
          <div class="tendency">
            <img [src]="driver.tendency | tendency" alt="">
          </div>
          <mat-icon>
            <img [src]="driver.picture" alt="" (error)="driver.picture = 'assets/img/icons/sinfoto.svg'">
          </mat-icon>
        </div>
        <div class="driver-name bold-gray">{{ driver.name }}</div>
        <div class="score-time bold-gray">{{ driver.scoreInfo?.time?.defaultLabel }}</div>
        <div class="score-distance bold-gray">{{ driver.scoreInfo?.distance?.value | number: '1.0-0' }}
          {{ driver.scoreInfo?.distance?.unit }}</div>
        <div class="points" *ngIf="!data.scoreVariable">
          <div class="value">
            {{ driver.score | number: '1.0-0' }} pts
          </div>
        </div>
        <div class="stars-container" *ngIf="data.scoreVariable">
          <div class="stars-empty">
            <div class="stars" [ngStyle]="{ 'width': (driver.stars*20)+'%' }">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #scoreDetails>
  <div class="driver-details">
    <div class="driver-picture">
      <button class="back-to-list" mat-icon-button (click)="selectDriver(null)">
        <mat-icon mat-list-icon>arrow_back_ios</mat-icon>
      </button>
      <mat-icon>
        <img [src]="selectedDriver.picture" alt="" (error)="selectedDriver.picture = 'assets/img/icons/sinfoto.svg'">
      </mat-icon>
    </div>
    <div class="name bold-gray">
      <div>{{ selectedDriver.name }}</div>
      <div>{{ selectedDriver.score | number: '1.0-0' }} pts</div>
    </div>
    <div class="score-info">
      <div>
        <mat-icon>schedule</mat-icon>
        {{ selectedDriver.scoreInfo.time.defaultLabel }}
      </div>
      <div>
        <mat-icon>multiple_stop</mat-icon>
        {{ selectedDriver.scoreInfo.distance.value | number: '1.0-0' }}
        {{ selectedDriver.scoreInfo.distance.unit }}
      </div>
    </div>
    <div class="driver-score-variables" *ngFor="let sv of selectedDriver.scoreVariables">
      <div class="sv-type">
        <img [src]="'assets/img/icons/events/score-variable-'+ sv.type +'.svg'" alt="">
      </div>
      <div class="sv-type-label"> {{ 'SCORE.'+sv.type | translation }} </div>
      <div class="driver-tendency"> <img [src]="sv.tendency | tendency" alt=""> </div>
      <div class="stars-container">
        <div class="stars-empty">
          <div class="stars" [ngStyle]="{ 'width': (sv.stars*20)+'%' }">
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<div class="mobile-container">
  <header>
    <img src="assets/img/logo-mobileView.svg" alt="">
  </header>
  <main>
    <img src="assets/img/fleetrface.svg" alt="">
    <h1>¡{{ 'HELLO' | translation }}!</h1>
    <p>{{ 'MOBILE_MSG' | translation }}</p>
  </main>
  <footer>
    <a [href]="s.url" *ngFor="let s of stores" target="_blank"> <img [src]="s.asset" alt=""> </a>
  </footer>
</div>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../environments/environment';
import { HEADER_OPTIONS } from '../helpers/helpers';

import { GeoModel } from '../components/finder-lists/geofencing-model';
import { BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class GeofencesService {
  public mapContext = new BehaviorSubject<string>('list');
  public functonInit = new BehaviorSubject<any>([]);
  geofences = new BehaviorSubject<any>([]);
  getGeofences = this.geofences.asObservable();
  setGeofences(geofences: any) { this.geofences.next(geofences); }

  constructor(public http: HttpClient) {
  }

  getAll() {
    return this.http.get(`${env.API_PATH}/api/geofence/v1/all`, HEADER_OPTIONS());
  }

  getList(page = 0, perPage = 15) {
    const pageQuery = `?page=${page}&perPage=${perPage}`;
    return this.http.get(`${env.API_GEOFENCE_PATH}/v1${ pageQuery }`, HEADER_OPTIONS());
  }

  saveAll(gm: GeoModel) {
    return this.http.post(`${env.API_GEOFENCE_PATH}/v1/geofences`, gm, HEADER_OPTIONS());
  }

  editAll(id) {
    return this.http.get(`${env.API_GEOFENCE_PATH}/v1/${id}`, HEADER_OPTIONS());
  }
  updateGeofence(gm: GeoModel, id) {
    return this.http.put(`${env.API_GEOFENCE_PATH}/v1/${id}`, gm, HEADER_OPTIONS());
  }
  markStatus(id, status) {
    return this.http.put(`${env.API_GEOFENCE_PATH}/v1/${id}`, { 'active': status }, HEADER_OPTIONS());
  }
  deleteField(id) {
    return this.http.delete(`${env.API_GEOFENCE_PATH}/v1/delete/${id}`, HEADER_OPTIONS());
  }

}

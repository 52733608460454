import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { map } from 'rxjs/operators';

import { GeneralService } from '../../../services/general.service';
import { roleNames, emailregex } from '../../../helpers/helpers';
import { GeneralModalComponent } from '../../general-modal/general-modal.component';
import { RequestService } from '../../../services/request.service';
import { TagService } from '../../../services/tag.service';
import { Tag } from '../../../models/tag.model';
import { AdminData, AdminItem } from '../../../models/admin.model';
import { TranslationPipe } from '../../../pipes/translation/translation.pipe';

type CurrentView = 'adminsList' | 'adminEdit'

@Component({
  selector: 'app-admin-configuration',
  templateUrl: './admin-configuration.component.html',
  styleUrls: ['./admin-configuration.component.css'],
  providers: [ TranslationPipe ]
})
export class AdminConfigurationComponent implements OnInit {

  @Input() admin: AdminData;
  @Output() backToProfile = new EventEmitter<string>();
  @ViewChild('autocomplete') autocomplete: AutocompleteComponent;

  roleNames = roleNames;
  currentView: CurrentView = 'adminsList';
  currentAdmin: AdminItem = null;
  admins: AdminItem[] = [];
  roles = [];
  roleRows = [];
  statusPendingRows = [];
  creating: boolean;
  loading = false;
  tagList: Tag.Tag[];
  selectedTag: Tag.Tag;
  chipList: { id: string, name: string}[] = [];
  showMore: {}

  get filteredTags(): Tag.Tag[] {
    return this.tagList.filter(tag => !this.chipList.find(t => t.id === tag.id));
  }

  constructor(
    private gs: GeneralService,
    public dialog: MatDialog,
    private rs: RequestService,
    private tagService: TagService,
    private tp: TranslationPipe,
  ) { }

  ngOnInit() {
    this.initData();
    this.tagService.getTagList()
      .pipe(map(response => response.vehicleTags))
      .subscribe(tags => {
        this.tagList = tags;
      }, error => {this.tagList = []});

    }

  async initData() {
    await this.getAdmins();
    await this.getRoles();
    this.formatRows();
  }

  async getAdmins() {
    this.loading = true;
    const { admins }: any = await this.rs.request({
      method: 'GET',
      endpoint: 'get_admins'
    });
    if (!admins) {
      this.loading = false;
      return this.gs.openAlert({error: true, msg: 'ADMIN_CONFIGURATION.ERROR_GET_ADMINS'});
    }
    this.loading = false;
    [...admins].forEach(admin => {
      this.showMore = { [admin.id]: true };
    });

    this.admins = admins;
  }

  async getRoles() {
    const { roles }: any = await this.rs.request({
      method: 'GET',
      endpoint: 'get_roles'
    });
    if (!roles) {
      return this.gs.openAlert({error: true, msg: 'ADMIN_CONFIGURATION.ERROR_GET_ROLES'});
    }
    this.roles = roles;
  }

  formatRows(): void {
    this.roleRows = this.roles.map(role => {
      const admins = this.admins
        .filter(admin => ((admin.role.id || admin.role) === (role.id || role)) && admin.status !== 'pending');
      return {
        id: role.id,
        ...role.info,
        admins
      };
    });
    this.statusPendingRows = this.admins.filter(a => a.status === 'pending');
  }

  setCurrentView(currentView: CurrentView, admin, create = false) {
    this.currentAdmin = admin;
    this.chipList = [];

    if ( !create && admin && admin.role ) {
      this.chipList = admin.vehicleTags;
      this.currentAdmin.role = admin.role.id || admin.role;
    }
    this.currentView = currentView;
    this.creating = create;
  }

  back(view = null) {
    if ( (view || this.currentView) === 'adminEdit') {
      this.setCurrentView('adminsList', {}, false);
      return;
    }
    this.backToProfile.emit('general');
  }

  async adminAction() {
    const err = this.formHasErrors(this.currentAdmin);

    if ( err ) {
      return this.gs.openAlert({ msg: err, error: true });
    }

    const { id: role }: any = (this.roles.find(r => r.id === this.currentAdmin.role) || {});
    const vehicleTags = this.chipList.map(t => t.id);
    const admin = { ...this.currentAdmin, role, vehicleTags };

    if (!admin.role) { return; }

    if (this.creating) {
      this.gs.logScreenEvents('admin_type_permission', this.tp.transform(roleNames[role]), 'admin_type_permission', 'click');
      this.createAdmin(admin);
    } else {
      this.updateAdmin(admin);
    }
  }

  async createAdmin(admin) {
    this.loading = true;
    const create: any = await this.rs.request({
      method: 'POST',
      endpoint: 'post_admin',
      body: admin
    });
    if (create.error) {
      this.loading = false;
      return this.gs.openAlert({error: true, msg: 'ADMIN_CONFIGURATION.ERROR_POST_ADMINS'});
    }
    await this.initData();
    this.gs.openAlert({ msg: 'ADMIN_CONFIGURATION.ADMIN_CREATED' });
    this.back();
    this.loading = false;
  }

  async updateAdmin(admin) {
    this.loading = true;
    const edit: any = await this.rs.request({
      method: 'PATCH',
      endpoint: 'patch_admin',
      body: admin,
      id: admin._id || admin.id
    });
    if (edit.error) {
      this.loading = false;
      return this.gs.openAlert({error: true, msg: 'ADMIN_CONFIGURATION.ERROR_PUT_ADMINS'});
    }
    this.gs.openAlert({ msg: 'ADMIN_CONFIGURATION.ADMIN_UPDATED' });
    await this.initData();
    this.back();
    this.loading = false;
  }

  alertDelete(id) {
    this.openGeneralModal('delete_admin', id);
  }

  openGeneralModal(type, user) {
    const dialogRef = this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '350px',
      data: { user, type }
    });

    dialogRef.afterClosed().subscribe(async deleted => {
      if (!deleted) { return this.back('adminEdit'); }
      await this.initData();
      this.back('adminEdit');
    });
  }

  selectEvent(tag: Tag.Tag) {
    this.selectedTag = tag;
  }

  addChip() {
    this.chipList =[{ id: this.selectedTag.id, name: this.selectedTag.name }, ...this.chipList];
    this.selectedTag = null;
    this.autocomplete.clear();
    this.autocomplete.close();
  }

  removeChip(tag: Tag.Tag) {
    this.chipList = this.chipList.filter(t => t.id !== tag.id);
  }

  formHasErrors(form) {
    const filled = ['name', 'lastName', 'email', 'role'].some( f => !form[f] );
    if ( filled ) { return 'ADMIN_CONFIGURATION.FORM_ERROR'; }
    const validEmail = emailregex.test(form.email);
    if ( !validEmail ) { return 'ADMIN_CONFIGURATION.EMAIL_ERROR'; }
    return false;
  };

  showMoreToggle(id: string) {
    this.showMore[id] = !this.showMore[id];
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { GeneralService } from '../../services/general.service';
import { VehicleService } from '../../services/vehicle.service';
import { TranslationPipe } from '../../pipes/translation/translation.pipe';
import { NotificationsService } from '../../services/notifications.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-finder',
  templateUrl: './finder.component.html',
  styleUrls: ['./finder.component.css'],
  providers: [ TranslationPipe ]
})
export class FinderComponent implements OnInit {
  private suscription: Subscription = new Subscription;
  @ViewChild('contextSelector', {static: false}) contextSelector;
  contextOptions: any[];
  selectedContext: any[];
  findText: string;
  wiState: boolean;
  contexts: any[];
  clearFilter: boolean;
  opening: boolean;
  vehicles: any;
  entityPlaceHolder: string;
  lang: any;

  windowsData: any;
  wiType: any;
  extendedState: boolean;

  constructor(
    public gs: GeneralService,
    public vehicleService: VehicleService,
    public translate: TranslationPipe,
    public notiService: NotificationsService
    ) {
    this.contextOptions = this.gs.contextOptions()
      .filter(c => {
        const hideOnFinderSelector = [ 'health', 'dashboard' ];
        return !hideOnFinderSelector.some(h => c.context === h);
      });
    this.gs.getWiState.subscribe((data: boolean) => this.wiState = data );
    this.gs.getWiType.subscribe((data: string) => this.wiType = data );
    this.gs.getWiExtendedState.subscribe((data: boolean) => this.extendedState = data );
    this.gs.getWiData.subscribe((data: boolean) => this.windowsData = data );

    this.suscription.add(this.gs.getFinderSpinner.subscribe((data: boolean) => this.opening = data ));
    this.suscription.add(this.gs.getWiFindText.subscribe( text => this.findText = text ));

    this.suscription.add(this.gs.getWiContext.subscribe((data: any[]) => {
      this.contexts = data;
      if ( this.contexts ) {
        this.selectedContext = this.contexts.map(c => this.contextOptions.find(co => c.context === co.context) );
      }

      if ( this.contexts && data.some(s => s.context &&
        (s.context === 'stats' || s.context === 'ranking' || s.context === 'dashboard') )) {
        this.selectedContext = this.contexts;
      }

      if ( this.contexts && this.contexts.length === 1 ) {
        this.entityPlaceHolder = `${this.translate.transform('FINDER.SEARCHING_IN')} ${this.translate.transform(this.contexts[0].context).toLowerCase()}`;
      } else {
        this.entityPlaceHolder = `${this.translate.transform('FINDER.SEARCHING')}...`;
      }

    }));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.suscription.unsubscribe();
  }

  openWindowBar() {
    this.gs.logScreenEvents('search_button', 'search_button', 'search_button', 'click');
    if (this.wiState === true) { return; }
    this.gs.setWindow({ state: true, type: 'list', contexts: this.contextOptions, findText: this.findText });
  }

  openContext() {
    this.contextSelector.open();
    this.openWindowBar();
  }

  clear() {
    this.findText = '';
    this.finderEvent();
  }

  finderEvent() {
    const opts = {
      state: this.wiState,
      type: this.wiType || 'list',
      contexts: this.selectedContext,
      findText: this.findText
    };

    if ( this.windowsData ) {
      opts['data'] = this.windowsData;
    }

    opts['extendedState'] = this.extendedState ? true : false;

    this.gs.setWindow(opts);
  }

  allContexts() {
    this.clearFilter = false;
    this.gs.setWindow({ state: true, type: 'list', contexts: this.contextOptions, findText: this.findText });
  }

}

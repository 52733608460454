import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { environment as env } from '../../environments/environment'
import { HEADER_OPTIONS } from '../helpers/helpers'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${env.API_TOKEN}` })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject(null);

  get token$() {
    return this.tokenSubject.asObservable();
  }

  constructor(public http: HttpClient) {
    const token = localStorage.getItem('id');
    if (token) {
      this.tokenSubject.next(token);
    }
  }

  getAdmin() {
    return this.http.get(`${env.API_BASE_URL}/${env.API_VERSION}/core/admins/me`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('id')}` })
    });
  }

  login(user) {
    return this.http.post(`${env.API_BASE_URL}/${env.API_VERSION}/auth/admin/local`, user, httpOptions)
      .pipe(
        tap((data: any) => {
          if (data && data.token) {
            this.tokenSubject.next(data.token);
            localStorage.setItem('id', data.token);
          }
        })
      );
  }

  getConfig() {
    return {
      welcoming: true,
      onboarding: false
    };
  }

  /**
   * Hace la solicitud para cambiar la contraseña
   * @param email Correo electrónico
   * @returns
   */
  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(`${env.API_BASE_URL}/${env.API_VERSION}/auth/admin/forgot`, {email: email}, HEADER_OPTIONS());
  }
}

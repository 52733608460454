import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class PerformanceService {

  constructor() {
  }

  showSpinner = new BehaviorSubject<boolean>(false);
  getSpinnerState = this.showSpinner.asObservable();

  navState = new BehaviorSubject<boolean>(false);
  getNavState = this.navState.asObservable();

  years = new BehaviorSubject<any>({
    start: new Date(Date.now() - 7 * 86400000),
    end: new Date(Date.now() - 86400000)
  });

  getYears = this.years.asObservable();

  driverIdFilter = new BehaviorSubject<string>(null);
  getDriverIdFilter = this.driverIdFilter.asObservable();

  currentChart = new BehaviorSubject<any>(null);
  getCurrentChart = this.currentChart.asObservable();
  setSpinnerState(state: boolean) { this.showSpinner.next(state); }
  setNavState(state: boolean) { this.navState.next(state); }
  setYears(years: any) { this.years.next(years); }
  setDriverIdFilter(id: string) { this.driverIdFilter.next(id); }
  setCurrentChart(chart: any) {
    this.currentChart.next(chart);
  }

  asyncReq(service) {
    return new Promise((resolve, reject) => {
      service.subscribe(data => resolve(data), error => reject(error));
    });
  }

  encodeQueryParam(obj) {
    return (new URLSearchParams(obj)).toString();
  }

  isMobile() {
    const deviceWidth = screen.width;
    return deviceWidth < 768;
  }
}

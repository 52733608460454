import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../services/general.service';
import { vehicleSortTypes } from '../../helpers/helpers';

@Component({
  selector: 'app-vehicle-sorting',
  templateUrl: './vehicle-sorting.component.html',
  styleUrls: ['./vehicle-sorting.component.css']
})
export class VehicleSortingComponent implements OnInit {

  selected: any;
  vehicleSortTypes: any = vehicleSortTypes;

  constructor(
    private gs: GeneralService
  ) {
  }

  ngOnInit() {
  }

  selectSort(sort) {
    this.selected = sort;
    this.gs.setVehicleSort(sort);
  }

}

import { Component, OnInit } from '@angular/core';
import { trigger, style, transition, animate, keyframes, query, stagger } from '@angular/animations';
import { GeneralService } from '../../services/general.service';
import { TranslationPipe } from '../../pipes/translation/translation.pipe';
import { MatDialog } from '@angular/material/dialog';
import { FormsComponent } from './forms/forms.component';

const staggerAnimation = [
  trigger('listAnimation', [
    transition('* => *', [
      query(':enter', style({ opacity: 0 }), { optional: true }),
      query(':enter', stagger('300ms', [
        animate('1s ease-in', keyframes([
          style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
          style({ opacity: .5, transform: 'translateY(25px)', offset: 0.2 }),
          style({ opacity: 1, transform: 'translateY(0)', offset: 0.5 }),
        ]))]), { optional: true })
    ])
  ])
];

@Component({
  selector: 'app-creating-entity',
  templateUrl: './creating-entity.component.html',
  styleUrls: ['./creating-entity.component.css'],
  animations: staggerAnimation,
  providers: [TranslationPipe]
})
export class CreatingEntityComponent implements OnInit {

  messages: any = [];
  data: any;
  addTypes: any = ['add_manually', 'add_csv'];
  formsOpen: boolean;

  constructor(
    private gs: GeneralService,
    private tp: TranslationPipe,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.gs.getWiData.subscribe(d => { this.data = d; this.initMessages(); });
  }

  async initMessages() {
    if (!this.data) { return; }
    this.pushMsg(this.defaultMessages('init'));
    await this.gs.timeOut(1000);
    this.pushMsg(this.defaultMessages('add'));
    await this.gs.timeOut(1000);
    this.pushMsg(this.defaultMessages('adding_type'));
  }

  defaultMessages(type) {
    const msgs = [
      { type: 'init', from: 'bot', bubble: true },
      { type: 'add', from: 'bot', bubble: true, text: `${ this.tp.transform(`ADDING_PROCESS.${type}`)} ${this.tp.transform(this.data.type) }` },
      { type: 'adding_type' },
      { type: 'add_manually', from: 'user', bubble: true },
      { type: 'add_csv', from: 'user', bubble: true },
    ];
    return msgs.find(m => m.type === type);
  }

  pushMsg(msg) {
    this.messages.push(msg);
  }

  async addType(type) {
    this.pushMsg(this.defaultMessages(type));
    await this.gs.timeOut(1000);
    if ( this.formsOpen ) { return; }
    this.addFrom(type);
  }

  addFrom (addingType) {
    this.formsOpen = true;
    const dialogRef = this.dialog.open(FormsComponent, {
      panelClass: `modal-creating-entity`,
      data: {
        type: this.data.type,
        addingType
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.formsOpen = false;
      if ( !result ) { return; }
      this.pushMsg({ from: 'user', bubble: true, text: `${ result.added} ${this.tp.transform(this.data.type) }  ${this.tp.transform('ADDED') }`  });
    });
  }

}

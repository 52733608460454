import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-change-password-response',
  templateUrl: './change-password-response.component.html',
  styleUrls: ['./change-password-response.component.css']
})
export class ChangePasswordResponseComponent implements OnInit {

  @Input('dialogRef') dialogRef: any;
  @Input() success: boolean;

  constructor() {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

  async closeModal() {
    this.dialogRef.close();
  }

}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import moment from 'moment'
import { Subscription } from 'rxjs'
import { MsToTime, appDateConfig, scrollPages } from '../../helpers/helpers'
import { translationPipe } from '../../pipes/translation/translation.pipe'
import { DriverService } from '../../services/driver.service'
import { GeneralService } from '../../services/general.service'
import { HomeService } from '../../services/home.service'
import { RequestService } from '../../services/request.service'
import { TripTesterService } from '../../services/trip-tester.service'
import { TripsService } from '../../services/trips.service'
import { VehicleService } from '../../services/vehicle.service'
import { WebSocketService } from '../../services/web-socket.service'

@Component({
  selector: 'app-trips-list',
  templateUrl: './trips-list.component.html',
  styleUrls: ['./trips-list.component.css']
})
export class TripsListComponent implements OnInit {
  @ViewChild('tripsContainer', {static: false}) tripsContainer: ElementRef;
  private suscription: Subscription = new Subscription;
  contexts: any;
  type: string;
  entity: any;
  tripsData: any = [];
  drivers: any = [];
  vehicles: any = [];
  showTrips: boolean;
  map: any;
  msToTimeHelper: any = MsToTime;
  disableLoadMore: boolean;
  currentDate: any;
  imei: string = null;
  requestingTrips: boolean;
  admin: any;
  selectedTrip: any = {};
  dateFilter: any = {};
  rangeDate: any = {
    min: new Date(new Date().setFullYear((new Date().getFullYear() - 10))),
    max: new Date(),
  };

  dateForm = new FormGroup({
    from: new FormControl(),
    to: new FormControl(),
  })

  constructor(public gs: GeneralService,
    public tripsService: TripsService,
    public driverService: DriverService,
    public vehicleService: VehicleService,
    public webSocketService: WebSocketService,
    public homeService: HomeService,
    public tripTesterService: TripTesterService,
    public rs: RequestService) {

    this.suscription.add(this.gs.getWiContext.subscribe((data: any[]) => this.contexts = data));
    this.suscription.add(this.gs.getWiType.subscribe((data: string) => this.type = data));
    this.suscription.add(this.gs.getMap.subscribe((map: any) => this.map = map));
    this.gs.setShowLiveViewRoute(false);
    this.gs.getLoggedUser.subscribe(admin => this.admin = admin || {});

    this.dateForm.valueChanges.subscribe(value => {
      if (this.dateForm.pristine) {
        this.initTripList();
      } else {
        const from = moment(value.from).startOf('day').valueOf();
        const to = moment(value.to).endOf('day').valueOf();
        this.dateFilter = { from, to };

        if (from && to) {
          this.getTrips(false);
        }
      }
    })

    this.initTripList();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.suscription.unsubscribe();
  }

  async tripsScroll({ target: { scrollTop } }: any) {
    const pos = Math.ceil(scrollTop + this.tripsContainer.nativeElement.offsetHeight);
    const max = this.tripsContainer.nativeElement.scrollHeight;
    if (pos >= max && !this.requestingTrips && !this.disableLoadMore) {
      await this.getTrips(true);
    }
  }

  async initTripList() {
    this.drivers = await this.gs.asyncRequest(this.driverService.getCurrentDrivers);
    this.vehicles = await this.gs.asyncRequest(this.vehicleService.getCurrentVehicles);
    this.suscription.add(this.gs.getWiData.subscribe((data: any = {}) => {
      this.entity = data;
      if (data.type === 'trip') {
        this.showTrips = true;
        this.getTripFn(this.entity.id);
        return this.tripsData = [data];
      } else if( this.selectedTrip.uuid !== null && this.selectedTrip.uuid !== undefined ) {
        this.showTrips = true;
        this.getTripFn(this.selectedTrip.uuid);
        return;
      }
      this.getTrips(false);
    }));
  }

  async getTrips(more) {
    if (!this.drivers.length || !this.vehicles.length) { return; }
    const query = {
      tag: /driver/.test(this.entity.type) ? 'user' : this.entity.type,
      value: this.entity.uuid
    };

    if (more) {
      query['lastTrip'] = this.tripsData[this.tripsData.length - 1].uuid;
    }

    if (this.dateFilter.from && this.dateFilter.to) {
      query['from'] = this.dateFilter.from;
      query['to'] = this.dateFilter.to;
    }

    this.requestingTrips = true;
    let req;

    if (query.tag && query.value) {
      req = await this.rs.request({
        method: 'GET',
        endpoint: 'get_trips',
        query
      });
    } else {
      req = [];
    }

    if ( req.error ) {
      this.gs.openAlert({ msg: translationPipe.transform('TRIPS_ERROR'), error: true });
      return;
    }

    this.disableLoadMore = req.length < scrollPages;

    let trips;
    if (query['lastTrip']) {
      trips = this.tripsData.concat(req);
    } else {
      trips = req;
    }

    this.tripsData = formatTrip(trips, this.vehicles, this.entity);
    this.dateHeaders();
    this.showTrips = true;
  }

  fullName(name, lastName) {
    return `${name.split(' ')[0]} ${lastName.split(' ')[0]}`;
  }

  getPicture(trip, type) {
    const types = {
      vehicle: 'driver',
      driver: 'vehicle',
      trip: 'driver'
    };
    const picture = (trip[types[type]] || {}).picture;
    const placeholder = 'url(/assets/img/icons/autoconductor-listas.svg)';
    return picture ? `url('${picture}')` : placeholder;
  }

  closeTrips() {
    if (this.entity.type == 'trip') {
      this.gs.setWindow({
        state: false,
        type: 'list',
        contexts: this.contexts,
        data: undefined
      });
    } else {
      this.homeService.actions({
        id: this.entity.id
      }, `${this.entity.type}s`);
    }

    this.gs.setSingleTrip(null, null);
  }

  removeTag(uuid, tags, index) {
    this.tripsData.trips[index].tags = tags.filter((t) => t.uuid !== uuid);
  }

  async getTripFn(uuid) {
    if ( this.selectedTrip && this.selectedTrip.uuid === uuid ) { return; }
    this.gs.setSpinnerState({ show: true });
    const trip = await this.rs.request({
      method: 'GET',
      endpoint: 'get_trip_keyframes',
      id: uuid
    });

    if (trip.error) {
      this.gs.setSpinnerState({ show: false });
      return;
    }

    // Disabled dashcam
    // const eventImagesResponse = await this.rs.request({
    //   method: 'GET',
    //   endpoint: 'events_dashcam',
    //   tripId: uuid
    // })

    let eventImages = {data: []};

    // if(eventImagesResponse?.data?.length){
    //   eventImages = eventImagesResponse;
    // }

    trip.keyframes = trip.keyframes.map(k => ({...k, loc: [ k.loc.lat, k.loc.lon] }));
    this.tripTesterService.setTrip(this.entity?.device?.imei, { keyframes: trip.keyframes });
    const tripIndex = this.tripsData.findIndex(t => t.uuid === uuid);
    this.tripsData[ tripIndex ].maxVelocity = Math.max.apply(Math, trip.keyframes.map(k => k.speed));
    this.gs.setSingleTrip(trip, eventImages);
    this.gs.setSpinnerState({ show: false });
  }

  parseDate(timeStamp) {
    return moment(timeStamp).utc().add(this.admin.tzo).format('hh:mm a');
  }

  dateHeaders() {
    if (!this.tripsData) { return; }
    this.requestingTrips = false;
    const includeDates = this.tripsData.map(t => {
      return Object.assign({}, t, {
        date: moment(t.startDate).format(appDateConfig('tripHeaderDateFormat'))
      });
    });
    Array.from(new Set(includeDates.map(a => a.date)))
      .map(date => includeDates.find(a => a.date === date))
      .map(d => this.tripsData[this.tripsData.findIndex(t => t.id === d.id)].showDate = true);
  }

  getYear(year) {
    if (year + '' === new Date().getFullYear() + '') { return ''; } else { return year; }
  }

  headerDate(date) {
    return moment(date).format(appDateConfig('tripHeaderDateFormat'));
  }

  getTripThumb(trip) {
    return (!trip.print || trip.print === 'null') ? '/assets/img/trip_placeholder.png' : trip.print;
  }

  clearDate() {
    this.dateForm.reset(moment());
    this.dateFilter = {};
  }
}

const formatTrip = (trips, vehicles, entity) => {
  if (!entity.type) { return; }
  const placeholder = '/assets/img/icons/autoconductor-listas.svg';
  return trips.map((t) => {
    t.vehicle = t.vehicle.uuid ? t.vehicle : vehicles.find(v => v.uuid === t.vehicle);
    return Object.assign({}, t, {
      print: `${t.print}`,
      icon: (entity.type === 'driver' ? entity.current_vehicle?.picture : entity.current_driver?.picture) || placeholder,
      alias: entity.type === 'driver' ? entity.current_vehicle.plate : entity.current_driver.name,
      distance: {
        distance: Number(t.distance.distance).toFixed(1),
        units: t.distance.units
      }
    });
  });
};

const createTime = (time, h, m, s) => {
  const hours = new Date(time).setHours(h);
  const minutes = new Date(hours).setMinutes(m);
  const final = new Date(minutes).setSeconds(s);
  return final;
};

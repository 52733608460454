import { Component, OnInit, Input } from '@angular/core';
import { isFloat, appDateConfig } from '../../../../helpers/helpers';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-comparison-tooltip',
  templateUrl: './comparison-tooltip.component.html',
  styleUrls: ['./comparison-tooltip.component.css'],
  providers: [ DatePipe ]
})
export class ComparisonTooltipComponent implements OnInit {

  @Input('totalComparison') totalComparison;
  comparisons: any = [];
  constructor(
    private dp: DatePipe
  ) { }

  ngOnInit() {
    const fullWidth = '100%';
    const dateFormat = appDateConfig('format');
    const from = this.totalComparison.filter.query.from;
    const to = this.totalComparison.filter.query.to;
    const values = this.totalComparison.value;
    this.comparisons = [
      {
        from: prevFrom({ from, to }),
        to: from,
        value: this.totalComparison.value.prevPeriod.value,
        barWidth: values.prevPeriod.value > Number(values.value) ? fullWidth : `${100 * values.prevPeriod.value / Number(values.value)}%`,
        color: '#ffd16f'
      },
      {
        from,
        to, value: this.totalComparison.value.value,
        barWidth: Number(values.value) > values.prevPeriod.value  ? fullWidth : `${100 * Number(values.value) / values.prevPeriod.value}%`,
        color: '#63a4ff'
      }
    ].map(p => Object.assign({}, p, {
      date: `${ this.dp.transform(p.from, dateFormat) } - ${ this.dp.transform(p.to, dateFormat) }`,
    }));
  }

  setPrecision(value, precision) {
    value = value ? Number(value) : 0;
    return this.totalComparison.precision ? isFloat(value) ? value.toFixed(precision) : parseInt(value) : parseInt(value);
  }

}

const prevFrom = _ => {
  const diff = _.to - _.from;
  return _.from - diff;
};


<div class="container" [ngStyle]="{ 'padding-top': contexts?.length == 1 ? '130px' : ''}">
    <!-- Lista de entidades -->
    <app-finder-lists
        *ngIf="type == 'list' || type == 'ranking' || type == 'stats' || type == 'dashboard' ||
        geofenceListRO">
    </app-finder-lists>
    <!-- lista de trips segun usuario o vehiculo -->
    <app-trips-list *ngIf="type == 'trips'"></app-trips-list> 
    <app-geofencing-create *ngIf="type == 'create_geofences'"></app-geofencing-create>   
    <div *ngIf="type === 'create_entity'" class="creating-entity" >
        <button mat-button (click)="cancelCreation()">{{ 'BACK' | translation }}</button>
    </div>
    <app-extension-detail *ngIf="type === 'extension-detail'"></app-extension-detail>
    <app-drivers-list-extensions *ngIf="type === 'drivers-list-extensions'"></app-drivers-list-extensions>
</div> 
<div class="container">
  <div *ngIf="!errorMessage">
    <p style="font-size: 20px; font-weight: 600;">{{ 'ACTIVATE_SUBSCRIPTION' | translation }}</p>
    <p>{{ 'COST_SUBSCRIPTION' | translation }}</p>
    <p>
        <span style="font-weight: 600; font-size: 22px;">{{data?.cost?.cost}}</span>
    </p>
    <p>{{ 'QUESTION_EMAILS_EXTENSIONS' | translation }}</p>
    <div class="actions">
      <button (click)="closeModal()"> {{ 'EDIT_DRIVER' | translation }} </button>
      <button (click)="sendDrivers()"> {{ 'ACCEPT' | translation }} </button>
    </div> 
  </div>
  <div *ngIf="errorMessage">
    <p>
      <span>{{ 'SOMETHING_WENT_WRONG' | translation }},</span>&nbsp; <span>{{ errorMessage }}</span>
    </p>
    <div class="actions">
      <button (click)="closeModal()"> {{ 'CANCEL' | translation }} </button>
    </div> 
  </div>
</div>

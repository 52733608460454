import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GeneralService } from '../../../../services/general.service';
import { VehicleService } from '../../../../services/vehicle.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GeoModel, Geometry, TimeConditions, Schedules } from '../../geofencing-model';
import { GeofencesService } from '../../../../services/geofences.service';
import { MatDialog } from '@angular/material/dialog';
import { RequestService } from '../../../../services/request.service';
import { GeofencingTemplateComponent } from '../geofencingvehicles-template/geofencingvehicles-template.component';
import { translationPipe } from '../../../../pipes/translation/translation.pipe';

@Component({
  selector: 'app-geofencing-create',
  templateUrl: './geofencing-create.component.html',
  styleUrls: ['./geofencing-create.component.css'],

})
export class GeofencingCreateComponent implements OnInit {
  @Input('data') geo: any;
  @ViewChild(GeofencingTemplateComponent, {static: false}) child: GeofencingTemplateComponent;
  private suscription: Subscription = new Subscription;
  public model: GeoModel;
  public schedulesModel: Schedules;
  public timeModel: TimeConditions;
  public geometryModel: Geometry;
  vehicles: any[];
  findText: string;
  wiState: boolean;
  wiType: any;
  selectedContext: any[];
  contextOptions: any[];
  time = `${new Date().getHours()}:${(new Date().getMinutes() < 10 ? '0' : '') + new Date().getMinutes()}`;
  date = new Date();
  clearFilter: boolean;
  windowsData: any;
  extendedState: boolean;
  contexts: any;
  hide = false;
  geoIn = false;
  geoOut = false;
  scheduleDays: boolean[] = [];
  expanded: boolean;
  @Output('runEntities') runEntities = new EventEmitter<any>();

  geoUnchecked = false;
  isMapNotDraw = false;
  geofenceVehicles: any;
  constructor(
    public generalService: GeneralService,
    public vehiclesService: VehicleService,
    public router: Router,
    public activeRout: ActivatedRoute,
    public gs: GeofencesService,
    public dialog: MatDialog,
    private rs: RequestService,
  ) {
    this.gs.mapContext.next('create_geofences');
    this.suscription.add(this.vehiclesService.getCurrentVehicles.subscribe(vehicles => this.vehicles = vehicles));
    this.suscription.add(this.generalService.getWiFindText.subscribe(text => this.findText = text));
    this.generalService.getWiExtendedState.subscribe((data: boolean) => this.extendedState = data);
    this.generalService.getWiData.subscribe((data: boolean) => this.windowsData = data);
    this.model = new GeoModel();
    this.timeModel = new TimeConditions();
    this.schedulesModel = new Schedules();
    this.schedulesModel.from = this.time;
    this.schedulesModel.to = this.time;
    this.geometryModel = new Geometry();
    this.suscription.add(this.generalService.getWiContext.subscribe((data: any[]) => {
      this.contexts = data;
    }));
  }

  ngOnInit() {
    const geoId = localStorage.getItem('geoId');
    if (geoId) {
      this.editAllGeofence(geoId);
    }
  }
  ngAfterViewInit() {
    const container = document.getElementsByClassName('container');
    if (container[2] && this.generalService.checkPermission(['admin.fleetrw', 'admin.fleetmgr'])) {
      container[2].classList.add('removespace');
    }

  }
  ngOnDestroy() {
    const container = document.getElementsByClassName('container');
    if (container[2]) {
      container[2].classList.remove('removespace');
    }
  }

  openExtension() {
    this.generalService.setWindow({ state: true, extendedState: true, type: 'geofences', contexts: this.contexts });
  }

  openWindowBar() {
    if (this.wiState === true) { return; }
    this.generalService.setWindow({ state: true, type: 'list', contexts: this.contextOptions, findText: this.findText });
  }

  finderEvent() {
    const opts = {
      state: this.wiState,
      type: this.wiType || 'list',
      contexts: this.selectedContext,
      findText: this.findText
    };

    if (this.windowsData) {
      opts['data'] = this.windowsData;
    }

    opts['extendedState'] = this.extendedState ? true : false;

    this.generalService.setWindow(opts);
  }

  clear() {
    this.findText = '';
    this.finderEvent();
  }


  enableDisableRule(day: number) {
    this.scheduleDays[day] = !this.scheduleDays[day];
  }

  setGeofenceVehicles(selectedVehicles) {
    this.geofenceVehicles = selectedVehicles;
    this.model.vehicles = selectedVehicles;
  }

  /*** save geofence data */
  async saveGeofenceData(form: any) {
    if (this.validateFields(form)) {
      const request: any = await this.getParameters();
      if (request.schedules && request.isScheduled) {
        request.schedules = [request.schedules];
      } else { request.schedules = []; }
      const geoId = localStorage.getItem('geoId');
      if (request.geometry.type === 'polygon') { delete request.geometry.radius; }
      if (request.geometry.type === 'circle') { request.geometry.radius = Number(request.geometry.radius); }
      if (geoId) {
        await this.generalService.asyncRequest(this.gs.updateGeofence(request, geoId));
        this.generalService.openAlert({ msg: 'Geofence updated successfully.' });
      } else {
        await this.generalService.asyncRequest(this.gs.saveAll(request));
        this.generalService.openAlert({ msg: 'Geofence saved successfully.' });
      }
      this.geoUnchecked = false;
      this.isMapNotDraw = false;
      localStorage.removeItem('latlong');
      localStorage.removeItem('mapType');
      localStorage.removeItem('geoId');
      localStorage.removeItem('radius');
      this.generalService.logScreenEvents('geofence_click_save', 'geofence_click_save', 'geofence_click_save', 'click');
      this.cancel();
    }
  }

  async getParameters() {
    this.model.vehicles = this.child.addedVehicleList;
    if (!this.model.vehicles.length) { this.model.active = false; }
    this.geometryModel.coordinates = JSON.parse(localStorage.getItem('latlong'))[0];
    this.geometryModel.type = localStorage.getItem('mapType');
    this.geometryModel.radius = localStorage.getItem('radius');
    this.model.geometry = this.geometryModel;
    this.model.isScheduled = !!this.model.isScheduled;
    if (this.geoIn && this.geoOut) {
      this.model.when = 'both';
    } else if (this.geoOut) {
      this.model.when = 'outside';
    } else if (this.geoIn) {
      this.model.when = 'inside';
    }
    if (this.model.isScheduled) {
      this.schedulesModel.day = {
        'MON': !!this.scheduleDays[0],
        'TUE': !!this.scheduleDays[1],
        'WED': !!this.scheduleDays[2],
        'THU': !!this.scheduleDays[3],
        'FRI': !!this.scheduleDays[4],
        'SAT': !!this.scheduleDays[5],
        'SUN': !!this.scheduleDays[6],
      };
      this.model.schedules = this.schedulesModel;
    }
    return this.model;
  }

  validateFields(form) {
    this.geoUnchecked = false;
    this.isMapNotDraw = false;
    if (!this.geoIn && !this.geoOut) {
      this.generalService.openAlert({
        msg: translationPipe.transform('GEOFENCE.WHEN_CONFIGURATION'),
        error: true
      });
      return false;
    }
    if (!JSON.parse(localStorage.getItem('latlong'))) {
      this.isMapNotDraw = true;
      this.generalService.openAlert({
        msg: translationPipe.transform('GEOFENCE.NO_GEOFENCE_ERROR'),
        error: true
      });
      return false;
    }
    if (!form.value.name || !form.value.name.length) {
      this.generalService.openAlert({
        msg: translationPipe.transform('GEOFENCE.GEOFENCE_NAME_INPUT_ERROR'),
        error: true
      });
      return false;
    }
    return true;
  }

  getGeoInvalue(e: any) {
    this.geoUnchecked = false;
    this.geoIn = e.source.checked;
    this.normalizeWhenValues();
  }
  getGeoOutvalue(e: any) {
    this.geoUnchecked = false;
    this.geoOut = e.source.checked;
    this.normalizeWhenValues();
  }
  normalizeWhenValues() {
    if (this.geoIn && this.geoOut) {
      this.model.when = 'both';
    } else if (this.geoOut && !this.geoIn) {
      this.model.when = 'outside';
    } else if (!this.geoOut && this.geoIn) {
      this.model.when = 'inside';
    }
  }

  getSchedulesvalue(e: any) {
    this.model.isScheduled = e.source.checked;
  }
  getStartvalue(e: any) {
    this.schedulesModel.from = e.target.value;
  }
  getEndvalue(e: any) {
    this.schedulesModel.to = e.target.value;
  }

  cancel() {
    const context = this.contexts.filter((c) =>
      /geofences/.test(c.context)
    );
    this.generalService.setWindow({
      state: true,
      extendedState: false,
      type: 'list',
      contexts: context,
      data: null,
    });
  }

  /*** edit geofence data */
  async editAllGeofence(id: any) {
    const res = await this.generalService.asyncRequest(this.gs.editAll(id));
    this.setParameter(res);
  }

  setParameter(response: any) {
    if (response) {
      this.model.name = response.name;
      this.model.active = response.active;
      this.model.isScheduled = response.isScheduled;
      this.model._id = response.id;
      this.model.when = response.when;
      if (response.when == 'both') {
        this.geoIn = true;
        this.geoOut = true;
      }
      if (response.when == 'inside') {
        this.geoIn = true;
        this.geoOut = false;
      }
      if (response.when == 'outside') {
        this.geoIn = false;
        this.geoOut = true;
      }
      response.schedules = response.schedules[0];
      if (response.schedules) {
        this.schedulesModel.from = response.schedules.from;
        this.schedulesModel.to = response.schedules.to;
        this.schedulesModel.day = {
          'MON': response.schedules.day['MON'],
          'TUE': response.schedules.day['TUE'],
          'WED': response.schedules.day['WED'],
          'THU': response.schedules.day['THU'],
          'FRI': response.schedules.day['FRI'],
          'SAT': response.schedules.day['SAT'],
          'SUN': response.schedules.day['SUN']
        };
        this.scheduleDays = [false, false, false, false, false, false, false];
        this.scheduleDays[0] = this.schedulesModel.day['MON'];
        this.scheduleDays[1] = this.schedulesModel.day['TUE'];
        this.scheduleDays[2] = this.schedulesModel.day['WED'];
        this.scheduleDays[3] = this.schedulesModel.day['THU'];
        this.scheduleDays[4] = this.schedulesModel.day['FRI'];
        this.scheduleDays[5] = this.schedulesModel.day['SAT'];
        this.scheduleDays[6] = this.schedulesModel.day['SUN'];
        this.model.schedules = this.schedulesModel;
      }
      if (response.geometry.coordinates) {
        const latLng = [response.geometry.coordinates];
        localStorage.setItem('latlong', JSON.stringify(latLng));
      }
      localStorage.setItem('mapType', response.geometry.type);
      localStorage.setItem('radius', response.geometry.radius);
      if (response.vehicles) {
        this.geofenceVehicles = response.vehicles;
        if (this.child) {
          this.child.setAddedVehicals(response.vehicles);
        }
      }
    }
  }

  allContexts() {
    this.clearFilter = false;
    this.generalService.setWindow({ state: true, type: 'list', contexts: this.contextOptions, findText: this.findText });
  }

  onClickCreateGeofence() {
    const context = this.contexts.filter((c) =>
      /geofences/.test(c.context)
    );
    this.generalService.setWindow({
      state: true,
      extendedState: false,
      type: 'create_geofences',
      contexts: context,
      data: null,
    });
  }

}

import { Component, Input} from '@angular/core';
import { Subscription } from 'rxjs';
import { GeneralService } from '../../../../services/general.service';
import { GeofencesService } from '../../../../services/geofences.service';
import { translationPipe } from '../../../../pipes/translation/translation.pipe';

@Component({
  selector: 'app-delete-geofence',
  templateUrl: './delete-geofence.component.html',
  styleUrls: ['./delete-geofence.component.css']
})
export class DeleteGeofenceComponent {
  @Input('dialogRef') dialogRef: any;
  @Input('data') id: any;
  wiData: any;
  contexts: any;
  private suscription: Subscription = new Subscription;

  constructor(public gs: GeofencesService, public generalService: GeneralService) {
    this.generalService.getWiData.subscribe((data: any) => this.wiData = data);
    this.suscription.add(this.generalService.getWiContext.subscribe((data: any[]) => {
      this.contexts = data;
    }));
  }

  async deletGeofence(id) {
    await this.generalService.asyncRequest(this.gs.deleteField(id));
    this.gs.functonInit.next({ pageNo: 0, trigger: 'init', triggered : 'Delete' });
    this.generalService.openAlert({ msg: translationPipe.transform('GEOFENCE.DELETED') });
    this.dialogRef.close();
  }
}

import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { takeLast, takeUntil } from 'rxjs/operators'
import { Tag } from '../../models/tag.model'
import { Vehicle } from '../../models/vehicle.model'
import { DevicesService } from '../../services/devices.service'
import { GeneralService } from '../../services/general.service'
import { TagService } from '../../services/tag.service'
import { VehicleService } from '../../services/vehicle.service'

interface VehicleOption {
  keyword: string
  value: Vehicle.Vehicle
}

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css']
})
export class TagComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject()
  tagListSubject: BehaviorSubject<Tag.Tag[]> = new BehaviorSubject<Tag.Tag[]>([])
  vehicles: Vehicle.Vehicle[] = []
  vehiclesFiltered: VehicleOption[] = []
  selectedOption: number
  selectedOptionFirst = false
  showDeleteAlertModal = false
  selectedTag: Tag.Tag
  tagForDelete: Tag.Tag
  addTagInput: string = ''
  showAddVehicle = false
  selectedVehicleId: string
  selectedAssetId: string
  showDeleteVehicleAlertModal = false
  editTagName: Tag.Tag
  tempTagname: string = ''
  showAddAsset = false
  assetFiltered: any[] = []
  devices = []

  @ViewChild('options') options

  get tag$() {
    return this.tagListSubject.asObservable()
  }

  constructor(
    private tagService: TagService,
    private vehicleService: VehicleService,
    private devicesService: DevicesService,
    private renderer: Renderer2,
    private gs: GeneralService,
  ) { }

  ngOnInit(): void {
    this.getTagList()

    this.renderer.listen('document', 'click', (event) => {
      if (this.options && !this.options.nativeElement.contains(event.target)) {
        if (this.selectedOptionFirst) {
          this.selectedOption = undefined
          this.selectedOptionFirst = false
        } else {
          this.selectedOptionFirst = true
        }
      }
    })

    this.vehicleService.getCurrentVehicles.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.vehicles = data
      }
    })

    this.devicesService.getCurrentDevices.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.devices = data.filter(device => device.type === 'asset')
      }
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  addNewTag() {
    this.gs.logScreenEvents('module_tag_create', 'module_tag_create', 'module_tag_create', 'click');
    this.tagService.addTag(this.addTagInput)
      .toPromise()
      .then(() => {
        this.addTagInput = ''
        this.getTagList()
      })
  }

  openOptions(idx: number) {
    this.selectedOption = idx
  }

  // --- Edit Sections ---

  editTag(tag: Tag.Tag) {
    this.selectedTag = tag
    this.selectedOption = undefined
    this.editTagName = undefined
    this.tempTagname = ''

    this.vehiclesFiltered = this.vehicles
      .filter(vehicle => !tag.vehicles.find(v => v.id === vehicle.id))
      .map(vehicle => ({
        keyword: `${vehicle.plate} ${vehicle.brand} ${vehicle.model}`,
        value: vehicle,
      }))

    this.assetFiltered = this.devices
      .filter(device => !tag.devices.find(d => d._id === device.id))
      .map(device => ({
        keyword: `${device.alias} ${device.model} ${device.vendor}`,
        value: device,
      }))

  }

  // --- Delete Sections ---

  showDeleteAlert(tag: Tag.Tag) {
    this.gs.logScreenEvents('tag_delete', tag.name, tag.id, 'click');
    this.showDeleteAlertModal = true
    this.tagForDelete = tag
    this.selectedOption = undefined
  }

  showEditTagNameInput(tag: Tag.Tag) {
    this.gs.logScreenEvents('tag_edit_view', tag.name, tag.id, 'click');
    this.editTagName = tag
    this.tempTagname = tag.name
    this.selectedTag = tag
    this.selectedOption = undefined
  }

  keyEvent(evt: KeyboardEvent) {
    evt.preventDefault()
    evt.stopPropagation()
    if (evt.key === 'Enter') {
      const vehicles = this.selectedTag.vehicles.map(v => v.id)
      this.tagService
        .editTag(this.selectedTag.id, this.tempTagname, { vehicles })
        .pipe(takeLast(1))
        .subscribe(() => {
          this.getTagList()
          this.editTagName = undefined
          this.tempTagname = ''
        }, () => {
          this.editTagName = undefined
          this.tempTagname = ''
        })

    }

    if (evt.key === 'Escape') {
      this.editTagName = undefined
      this.tempTagname = ''
    }
  }

  deleteTag() {
    this.tagService.deleteTag(this.tagForDelete.id)
      .pipe(takeLast(1))
      .subscribe(() => {
        this.tagForDelete = undefined
        this.showDeleteAlertModal = false
        this.getTagList()
        this.refreshItems()
      })
  }

  closeDeleteAlert() {
    this.showDeleteAlertModal = false
    this.tagForDelete = undefined
  }

  addVehicle() {
    this.showAddVehicle = true
  }

  addAsset() {
    this.showAddAsset = true
  }

  vehicleEvent(item: VehicleOption) {
    this.showAddVehicle = false

    const vehiclesList = [...this.selectedTag.vehicles.map(v => v.id), item.value.id]

    this.tagService.editTag(this.selectedTag.id, this.selectedTag.name, { vehicles: vehiclesList }).toPromise().then((_response) => {
      this.getTagList()
      this.refreshItems()
    })
  }

  assetEvent(item: VehicleOption) {
    this.showAddAsset = false

    const currentTags = (this.selectedTag?.devices || []).map(a => a._id)

    const assetsList = [...currentTags, item.value.id]

    this.tagService.editTag(this.selectedTag.id, this.selectedTag.name, { devices: assetsList }).toPromise().then((_response) => {
      this.getTagList()
      this.refreshItems()
    })
  }

  showRemoveVehicleModal(id: string) {
    this.showDeleteVehicleAlertModal = true
    this.selectedVehicleId = id
  }

  showRemoveAssetModal(id: string) {
    this.showDeleteVehicleAlertModal = true
    this.selectedAssetId = id
  }

  deleteVehicle() {
    this.showDeleteVehicleAlertModal = false

    const vehicles = this.selectedVehicleId ?
      this.selectedTag.vehicles.filter(v => v.id !== this.selectedVehicleId).map(v => v.id) : this.selectedTag.vehicles.map(v => v.id)

    const devices = this.selectedAssetId ?
      this.selectedTag.devices.filter(d => d._id !== this.selectedAssetId).map(d => d._id) : this.selectedTag.devices.map(d => d._id)

    this.tagService.editTag(this.selectedTag.id, this.selectedTag.name, { vehicles, devices }).subscribe((_response) => {
      this.getTagList()
      this.selectedVehicleId = undefined
      this.selectedAssetId = undefined
      this.refreshItems()
    })
  }

  closeDeleteVehicleAlert() {
    this.showDeleteVehicleAlertModal = false
    this.selectedVehicleId = undefined
  }

  private getTagList() {
    this.tagService.getTagList('force').toPromise().then(response => {

      if (this.selectedTag) {
        this.selectedTag = response.vehicleTags.find(tag => tag.id === this.selectedTag.id)

        if (this.selectedTag) {
          this.vehiclesFiltered = this.vehicles
            .filter(vehicle => !this.selectedTag.vehicles.find(v => v.id === vehicle.id))
            .map(vehicle => ({
              keyword: `${vehicle.plate} ${vehicle.brand} ${vehicle.model}`,
              value: vehicle,
            }))

          this.assetFiltered = this.devices
            .filter(device => !this.selectedTag.devices.find(d => d._id === device.id))
            .map(device => ({
              keyword: `${device.alias} ${device.model} ${device.vendor}`,
              value: device,
            }))
        }
      }

      this.tagListSubject.next(response.vehicleTags)
    })
  }

  private refreshItems() {
    this.vehicleService.getAll().subscribe(response => {
      this.vehicles = this.vehicles.map(vehicle => {
        const newVehicle = response.find(v => v.id === vehicle.id)
        return { ...vehicle, tags: newVehicle.tags }
      })

      this.vehicleService.setCurrentVehicles(this.vehicles);
    })

    this.devicesService.getAll().subscribe((response: any) => {
      this.devices = this.devices.map(device => {
        const newDevice = response.find(d => d._id === device._id)
        return { ...device, tags: newDevice.tags }
      })

      this.devicesService.setCurrentDevices(this.devices)
    })
  }
}
